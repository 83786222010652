import {
  ORDER_LOAD,
  ORDER_LOAD_SUCCESS,
  ORDER_LOAD_FAIL,
  ORDER_CHANGE_DETAILS_MODAL_DISPLAY_TOGGLE,
  ORDER_CHANGE_DETAILS_MODAL_PRICE_VALUE_CHANGE,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL,
  ORDER_CANCEL,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CREATE_AGAIN,
  ORDER_CREATE_AGAIN_SUCCESS,
  ORDER_CREATE_AGAIN_FAIL,
  ORDER_USER_INFLICTED_STATUS_CHANGE,
  ORDER_SELECT_EXECUTOR,
  ORDER_SELECT_EXECUTOR_CONFIRM,
  ORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS,
  ORDER_SELECT_EXECUTOR_CONFIRM_FAIL,
  ORDER_SAFEDEAL_GET_FORM_DATA,
  ORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS,
  ORDER_SAFEDEAL_GET_FORM_DATA_FAIL,
  ORDER_COMPLETE_MODAL_DISPLAY_TOGGLE,
  ORDER_COMPLETE_CONFIRM,
  ORDER_COMPLETE_CONFIRM_SUCCESS,
  ORDER_COMPLETE_CONFIRM_FAIL,
  ORDER_REVIEW_SEND,
  ORDER_REVIEW_SEND_SUCCESS,
  ORDER_REVIEW_SEND_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  order: {},
  order_is_loading: false,
  order_change_details_modal_display_status: false,
  order_change_details_modal_changed_price_value: null,
  order_change_details_modal_confirm_is_loading: false,
  order_cancel_is_loading: false,
  order_create_again_is_loading: false,
  order_user_inflicted_status: undefined,
  order_select_confirm_is_loading: false,
  order_selected_executor: null,
  order_complete_modal_display_status: false,
  order_complete_modal_status: undefined,
  order_complete_is_loading: false,
  order_review_send_is_loading: false,
  order_safedeal_form_data: undefined,
  order_safedeal_form_data_is_loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Загрузка заказа
    case ORDER_LOAD:
      return { ...state, order_is_loading: true };
    case ORDER_LOAD_SUCCESS:
      return { ...state, ...INITIAL_STATE, order: action.payload };
    case ORDER_LOAD_FAIL:
      return { ...state, ...INITIAL_STATE };

    // * Уточнить / изменить детали заказа
    case ORDER_CHANGE_DETAILS_MODAL_DISPLAY_TOGGLE:
      return { ...state, order_change_details_modal_display_status: action.payload };
    case ORDER_CHANGE_DETAILS_MODAL_PRICE_VALUE_CHANGE:
      return { ...state, order_change_details_modal_changed_price_value: action.payload };
    case ORDER_CHANGE_DETAILS_MODAL_CONFIRM:
      return { ...state, order_change_details_modal_confirm_is_loading: true };
    case ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS:
      return {
        ...state,
        order_change_details_modal_confirm_is_loading: false,
        order_change_details_modal_changed_price_value: null,
        order_user_inflicted_status: 'NeedToReload',
      };
    case ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL:
      return { ...state, order_change_details_modal_confirm_is_loading: false };

    // * Отмена заказа
    case ORDER_CANCEL:
      return { ...state, order_cancel_is_loading: true };
    case ORDER_CANCEL_SUCCESS:
      return { ...state, order_cancel_is_loading: false };
    case ORDER_CANCEL_FAIL:
      return { ...state, order_cancel_is_loading: false };

    // * Создать ешё раз
    case ORDER_CREATE_AGAIN:
      return { ...state, order_create_again_is_loading: true };
    case ORDER_CREATE_AGAIN_SUCCESS:
      return { ...state, order_create_again_is_loading: false };
    case ORDER_CREATE_AGAIN_FAIL:
      return { ...state, order_create_again_is_loading: false };

    // * Изменение отображения заказа в зависимости от действий пользователя
    case ORDER_USER_INFLICTED_STATUS_CHANGE:
      return { ...state, order_user_inflicted_status: action.payload };

    // * Выборать исполнителя
    case ORDER_SELECT_EXECUTOR:
      return { ...state, order_selected_executor: action.payload };

    case ORDER_SELECT_EXECUTOR_CONFIRM: {
      return { ...state, order_select_confirm_is_loading: true };
    }
    case ORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS: {
      return {
        ...state,
        order_select_confirm_is_loading: false,
        order_selected_executor: null,
        order_user_inflicted_status: 'NeedToReload',
      };
    }
    case ORDER_SELECT_EXECUTOR_CONFIRM_FAIL: {
      return { ...state, order_select_confirm_is_loading: false, order_selected_executor: null, order_user_inflicted_status: undefined };
    }

    // * Получить данные для БС
    case ORDER_SAFEDEAL_GET_FORM_DATA:
      return { ...state, order_safedeal_form_data_is_loading: true };
    case ORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS:
      return { ...state, order_safedeal_form_data_is_loading: true, order_safedeal_form_data: action.payload };
    case ORDER_SAFEDEAL_GET_FORM_DATA_FAIL:
      return { ...state, order_safedeal_form_data_is_loading: false };

    // * Завершение заказа
    case ORDER_COMPLETE_MODAL_DISPLAY_TOGGLE: {
      return { ...state, order_complete_modal_display_status: action.payload.display, order_complete_modal_status: action.payload.status };
    }
    case ORDER_COMPLETE_CONFIRM: {
      return { ...state, order_complete_is_loading: true };
    }
    case ORDER_COMPLETE_CONFIRM_SUCCESS: {
      return { ...state, order_complete_is_loading: false, order_user_inflicted_status: 'NeedToReload' };
    }
    case ORDER_COMPLETE_CONFIRM_FAIL: {
      return { ...state, order_complete_is_loading: false };
    }

    // * Написание отзыва
    case ORDER_REVIEW_SEND: {
      return { ...state, order_review_send_is_loading: true };
    }
    case ORDER_REVIEW_SEND_SUCCESS: {
      return { ...state, order_review_send_is_loading: false, order_user_inflicted_status: 'NeedToReload' };
    }
    case ORDER_REVIEW_SEND_FAIL: {
      return { ...state, order_review_send_is_loading: false };
    }

    default:
      return { ...state };
  }
};
