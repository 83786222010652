import './ExecutorCategoriesModal.scss';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { useDispatch, useSelector } from 'react-redux';

import { executor_categories_modal_display_toggle, executor_categories_modal_save } from '../../../actions/ExecutorCategoriesModalActions';
import Button from '../../shared/Button/Button';
import Checkbox from '../../shared/Checkbox/Checkbox';
import Modal from '../../shared/Modal/Modal';

/**
 * executor categories modal component
 */
function ExecutorCategoriesModal() {
  const id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const token = useSelector((state) => state.globalVariablesReducer.token_value);
  const next_step = useSelector((state) => state.executorCategoriesModalReducer.next_step);
  const is_loading = useSelector((state) => state.executorCategoriesModalReducer.is_loading);
  const dispatch = useDispatch();
  /** @type {import('../../../typedef').TCategoryState} */
  const [categories, setCategories] = useState([]);
  /** @type {import('../../../typedef').TCategoryState} */
  const [userSettings, setUserSettings] = useState([]);
  /** @type {import('../../../typedef').TCategoryState} */
  const [serverSettings, setServerSettings] = useState([]);

  // load user settings
  useEffect(() => {
    if (id && token) {
      axios({
        method: 'get',
        url: `${global.backendURL}users/user/${id}/categories/`,
      })
        .then((response) => setUserSettings(response.data.categories))
        .catch((error) => global.debug && console.log(error.response));
    }
  }, [id, token]);

  // load full categories list
  useEffect(() => {
    axios({
      method: 'get',
      url: `${global.backendURL}tasks/category/list/full/`,
    })
      .then((response) => setServerSettings(response.data.categories))
      .catch((error) => global.debug && console.log(error.response.categories));
  }, []);

  // build actual categories list based on user settings
  useEffect(() => {
    setCategories(
      serverSettings.map((settings) => {
        const data = userSettings.find((item) => item.id === settings.id);

        if (data) {
          const subcategories = settings.subcategories.map((sub) => {
            return {
              ...sub,
              checked: !!data.subcategories.find((entry) => entry.id === sub.id),
            };
          });
          return { ...settings, subcategories };
        }

        return { ...settings };
      })
    );
  }, [userSettings, serverSettings]);

  // user settings were successfully saved
  useEffect(() => {
    if (next_step === true) {
      dispatch(executor_categories_modal_display_toggle(false));
    }
  }, [next_step, dispatch]);

  return (
    <Modal
      extraClassName='executorCategoriesModal'
      onClose={() => dispatch(executor_categories_modal_display_toggle(false))}
      isWaiting={is_loading}
    >
      <div className='title'>
        Отметьте виды работ, <br />
        которые вы хотите выполнять
      </div>
      <div className='accordionContainer'>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          {categories.map((category, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>{category.title}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {category.subcategories.map((subcategory, key) => (
                  <div key={key} className='wrapper'>
                    <Checkbox
                      text={subcategory.title}
                      extraClassName='executorCheckbox'
                      value={subcategory.checked}
                      onClick={() => {
                        const update = [...categories];
                        update[index].subcategories[key].checked = subcategory.checked === true ? false : true;
                        setCategories(update);
                      }}
                    />
                  </div>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <Button
        color='red'
        size='normal'
        text='Готово'
        width='244rem'
        extraClassName='categoriesReady'
        onClick={() => {
          const ids = categories
            .map((cat) => cat.subcategories.filter((sub) => sub.checked).map((sub) => sub.id))
            .reduce((acc, val) => acc.concat(val), []);

          dispatch(executor_categories_modal_save(id, { subcategories: ids }));
        }}
      />
    </Modal>
  );
}

export default ExecutorCategoriesModal;
