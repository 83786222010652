import './LegalEntityPrefaceModal.scss';
import LegalEntityPrefaceModal_illustration01 from '../../../assets/images/LegalEntityPrefaceModal_illustration01.svg';

import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';

import { legal_entity_confirm_modal_display_toggle, preface_legal_entity_modal_display_toggle } from '../../../actions';
import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';

function LegalEntityPrefaceModal() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);

  const next = () => {
    if (user.law_verification_status === 'in_progress') {
      dispatch(preface_legal_entity_modal_display_toggle(false));
    } else {
      dispatch(preface_legal_entity_modal_display_toggle(false));
      dispatch(legal_entity_confirm_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeLegal',
        action: 'Кнопка "Понятно" - модальное инф. оконо перед модальным окном верификации юр.лица'
      })
    }
  };

  const renderTitle = () => {
    if (user.law_verification_status === 'in_progress') {
      return <div className='title'>Ваши данные проверяются, ожидайте.</div>;
    } else if (user.law_verification_status === 'not_verified') {
      return (
        <div className='title'>
          Чтобы получить статус юридического
          <br />
          лица, необходимо заполнить
          <br />
          информацию о вашей компании
        </div>
      );
    }
  };

  return (
    <Modal extraClassName='legalEntityPrefaceModal' onClose={() => dispatch(preface_legal_entity_modal_display_toggle(false))}>
      {renderTitle()}
      <div className='sensitiveWarning'>
        Нам потребуются БИН или сканы документов, подтверждающих вашу причастность к <br />
        юрлицу. Мы запрашиваем эти сведения исключительно для проверки подлинности <br />
        компании и безопасности пользователей.
      </div>
      <img className='image' src={LegalEntityPrefaceModal_illustration01} alt='illustration' />
      <Button
        color='red'
        size='normal'
        text={user.law_verification_status === 'in_progress' ? 'Понятно' : 'Продолжить'}
        width='244rem'
        extraClassName='prefaceAgreeButton'
        onClick={next}
      />
    </Modal>
  );
}

export default LegalEntityPrefaceModal;
