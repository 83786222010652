import './FrequentQuestions.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../../../shared/Button/Button';

function FrequentQuestions() {
  let currentRoute = useLocation();

  return (
    <section className='frequentQuestions'>
      <div className='container'>
        <h2 className='h2'>
          База знаний
          <br />
          <small className='small'>Отвечаем на самые важные вопросы об использовании сервиса.</small>
        </h2>
        {currentRoute.pathname === '/' ? (
          <>
            <div className='question'>
              <h3 className='title'>Как пользоваться сервисом</h3>
              <div className='description'>
                Узнайте о самых важных правилах signedUP. Как зарегистрироваться на сайте, как разместить заказ, что такое сделка без риска
                и как общаться с другими пользователями.
              </div>
              {/* <Button type='link' size='small' color='dark' text='16 ответов' width='142rem' to='/text/' /> */}
            </div>
            <div className='question'>
              <h3 className='title'>Отзывы и репутация </h3>
              <div className='description'>
                Рассказываем, как правильно выбирать исполнителей вашего заказа; что такое рейтинги; по каким критериям оценивается
                исполнитель; что можно, а что нельзя писать в отзывах о других пользователях.
              </div>
              {/* <Button type='link' size='small' color='dark' text='16 ответов' width='142rem' to='/text/' /> */}
            </div>
          </>
        ) : (
          <>
            <div className='question'>
              <h3 className='title'>Как получать больше заказов</h3>
              <div className='description'>
                Узнайте, как правильно заполнить свой профиль, что рассказать о себе, чтобы вам доверяли больше заказов, что такое рейтинг и
                по каким критериям оценивается ваша работа.
              </div>
              {/* <Button type='link' size='small' color='dark' text='16 ответов' width='142rem' to='/text/' /> */}
            </div>
            <div className='question'>
              <h3 className='title'>Основные правила безопасности</h3>
              <div className='description'>
                Расскажем, что нужно знать для безопасного использования сервиса; как попросить заказчика создать Сделку без риска; когда
                можно, а когда не стоит оставлять свои данные удостоверения личности на сайтах.
              </div>
            </div>
          </>
        )}
        <Button
          type='link'
          href='/faq/all/'
          size='large'
          color='red'
          text='Узнать больше'
          width='340rem'
          extraClassName='knowMoreLinkButton'
        />
      </div>
    </section>
  );
}

export default FrequentQuestions;
