// import './CustomerOrdersDashbordPage.scss';
import './OrdersCategories.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { executor_categories_modal_display_toggle } from '../../../../actions';

function OrdersCategories({ categories }) {
  const dispatch = useDispatch();

  return (
    <section className='ordersCategories'>
      <div className='category active' onClick={() => dispatch(executor_categories_modal_display_toggle(true))}>
        Категории:
      </div>
      {categories.map((category) =>
        category.subcategories.map((subcategory, index) => (
          <div key={'Category' + index} className='category'>
            {subcategory.title}
          </div>
        ))
      )}
    </section>
  );
}

export default OrdersCategories;
