import {
  PROFILE_FEEDBACKS_COUNT_CHANGE,
  PROFILE_FEEDBACKS_LOAD,
  PROFILE_FEEDBACKS_LOAD_ERROR,
  PROFILE_FEEDBACKS_LOAD_SUCCESS,
  PROFILE_FEEDBACKS_START_CHANGE,
} from '../actions/types';

/** @type {import("../typedef").TFeedbacksReducer} */
const INITIAL_STATE = {
  feedback_data: [],
  feedbacks_count: 0,
  start: 0,
  count: 2,
  is_loading: false,
};

/** @returns {import("../typedef").TFeedbacksReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_FEEDBACKS_LOAD:
      return { ...state, is_loading: true };
    case PROFILE_FEEDBACKS_LOAD_SUCCESS:
      return {
        ...state,
        feedback_data: action.payload.feedback_data,
        feedbacks_count: action.payload.feedbacks_count,
        is_loading: false,
      };
    case PROFILE_FEEDBACKS_LOAD_ERROR:
      return { ...state, is_loading: false };
    case PROFILE_FEEDBACKS_START_CHANGE:
      return { ...state, start: action.payload };
    case PROFILE_FEEDBACKS_COUNT_CHANGE:
      return { ...state, count: action.payload };
    default:
      return { ...state };
  }
};
