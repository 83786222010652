import axios from 'axios';

import { USER_LOAD, USER_LOAD_ERROR, USER_LOAD_SUCCESS, USER_PHOTO_VALUE_CHANGE, USER_NEED_TO_BE_UPDATED } from './types';

/**
 * load user data
 * @param {number} id user id
 */
export const user_load = (id) => {
  return (dispatch) => {
    dispatch({ type: USER_LOAD });

    axios({
      method: 'get',
      url: `${global.backendURL}users/user/${id}/`,
    })
      .then((response) => {
        dispatch({
          type: USER_LOAD_SUCCESS,
          payload: {
            ...response.data,
            user: {
              ...response.data.user,
              birthday: new Date(response.data.user.birthday),
              email: response.data.user.email || '',
              about: response.data.user.about || '',
            },
            user_id: id,
          },
        });
        global.debug && console.log('%cUSER_LOAD_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(response);
      })
      .catch((error) => {
        dispatch({ type: USER_LOAD_ERROR });
        global.debug && console.log('%cUSER_LOAD_ERROR:', 'color: red');
        global.debug && console.log(error.response);
      });
  };
};

/**
 * @param {string} payload
 */
export const user_photo = (payload) => {
  return {
    type: USER_PHOTO_VALUE_CHANGE,
    payload,
  };
};
