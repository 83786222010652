import './DocumentContent.scss';

import React from 'react';

function DocumentContent({ children }) {
  return (
    <section className='documentContent'>
      <div className='container'>{children}</div>
    </section>
  );
}

export default DocumentContent;
