import './ProfilePortfolioEditor.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  profile_portfolio_count,
  profile_portfolio_delete,
  profile_portfolio_load,
  profile_portfolio_save,
  profile_portfolio_start,
} from '../../../../actions';
import { PROFILE_HEADER_LITE_MODE_CHANGE, PROFILE_PORTFOLIO_RESET } from '../../../../actions/types';
import closeIcon from '../../../../assets/images/closeIcon.svg';
import icon_arrow_left from '../../../../assets/images/icon_arrow_left.svg';
import { acceptImageTypes } from '../../../../const';
import Button from '../../../shared/Button/Button';
import Files from '../../../shared/Files/Files';
import PortfolioImage from '../../../shared/PortfolioImage/PortfolioImage';
import Spinner from '../../../shared/Spinner/Spinner';

const preload_count = 5;
const load_count = 3;

/**
 * profile portfolio editor component
 */
function ProfilePortfolioEditor() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  /** @type {string} */
  const user_id = useSelector((state) => state.profileReducer.user_id);
  /** @type {import('../../../../typedef').TProfilePortfolioImage[]} */
  const images = useSelector((state) => state.profilePortfolioReducer.images);
  /** @type {number} */
  const images_count = useSelector((state) => state.profilePortfolioReducer.images_count);
  /** @type {number} */
  const start = useSelector((state) => state.profilePortfolioReducer.start);
  /** @type {number} */
  const count = useSelector((state) => state.profilePortfolioReducer.count);
  /** @type {boolean} */
  const image_deleted = useSelector((state) => state.profilePortfolioReducer.image_deleted);
  /** @type {boolean} */
  const is_loading = useSelector((state) => state.profilePortfolioReducer.is_loading);
  const dispatch = useDispatch();
  const history = useHistory();

  // reset image loading parameters
  useEffect(() => {
    dispatch(profile_portfolio_start(0));
    dispatch(profile_portfolio_count(5));
    dispatch({ type: PROFILE_HEADER_LITE_MODE_CHANGE, payload: true });

    return () => {
      dispatch({ type: PROFILE_PORTFOLIO_RESET });
      dispatch({ type: PROFILE_HEADER_LITE_MODE_CHANGE, payload: false });
    };
  }, [dispatch]);

  // load user's portfolio
  useEffect(() => {
    if (user_id && image_deleted) {
      dispatch(
        profile_portfolio_load({ user_id, loaded_images: images, start: 0, count: images.length - 1, prepend: true, overwrite: true })
      );
    }

    if (user_id && ((!start && count === preload_count) || (start && count === load_count))) {
      const total = start + count;
      if (images_count < total && images.length - 1 < images_count) {
        dispatch(profile_portfolio_load({ user_id, loaded_images: images, start, count, prepend: true }));
      }
      if (images_count >= total && images.length - 1 < total) {
        dispatch(profile_portfolio_load({ user_id, loaded_images: images, start, count, prepend: true }));
      }
    }
  }, [count, image_deleted, images, images_count, start, user_id, dispatch]);

  /**
   * upload an image to backend
   * @param {File} file file to upload
   */
  const uploadCallback = (file) => {
    dispatch(profile_portfolio_save({ user_id, file, images, prepend: true }));
  };

  /**
   * delete image
   * @param {number} portfolio_image_id image id
   */
  const deleteCallback = (portfolio_image_id) => {
    dispatch(profile_portfolio_delete({ user_id, portfolio_image_id }));
  };

  /**
   * load more images
   */
  const loadMore = () => {
    const increment = start === 0 ? preload_count : load_count;
    dispatch(profile_portfolio_start(start + increment));
    if (count !== load_count) {
      dispatch(profile_portfolio_count(load_count));
    }
  };

  /**
   * back to profile
   */
  const exit = () => history.push(`/profile/${user_id}`);

  return (
    <div className='profilePortfolioEditor'>
      <Button
        color='white'
        size='normal'
        text='Вернуться в профиль'
        width='250rem'
        iconLeft={icon_arrow_left}
        onClick={exit}
        extraClassName='backToProfile'
      />
      {/* <Files
        extraClassName='addPhoto'
        onUpdate={(files) => {
          if (files && files.length) {
            const file = files.item(0);
            if (file) {
              uploadCallback(file);
            }
          }
        }}
        accept={acceptImageTypes}
      >
        <Button color='red' size='normal' text='Добавить фото' width='250rem' />
      </Files> */}
      <div className='caption'>Ваши фотографии</div>
      <img className='hidePortfolioEditor' alt='' src={closeIcon} onClick={exit} />
      <div className='wrapper'>
        {is_loading && <Spinner />}
        <div className='gallery'>
          {images.map((image, index) => {
            /** @type {import('../../../../typedef').TPortfolioImage} */
            const settings = { ...image, canDelete: !user.public, uploadCallback, deleteCallback };
            return <PortfolioImage key={index} {...settings} />;
          })}
        </div>
      </div>
      {images.length - 1 < images_count && (
        <Button
          color='white'
          size='normal'
          text='Загрузить ещё'
          width='268rem'
          extraClassName='loadMore'
          isLoading={is_loading}
          disabled={is_loading}
          onClick={() => loadMore()}
        />
      )}
    </div>
  );
}

export default ProfilePortfolioEditor;
