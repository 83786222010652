import './ChatListModal.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chat_list_header_display_toggle, chat_modal_display_toggle } from '../../../actions';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import Modal from '../../shared/Modal/Modal';

function ChatListModal() {
  const chatList = useSelector((state) => state.chatModalReducer.chat_list);
  const dispatch = useDispatch();

  return (
    <Modal extraClassName='chatListModal' onClose={() => dispatch(chat_list_header_display_toggle(false))}>
      <div className='title'>Сообщения</div>
      <div className='chatsList'>
        {chatList.length >= 1 ? (
          chatList.map((chat) => (
            <div
              className='paddingWrapper'
              key={`chatsListChat + ${chat.id}`}
              onClick={() => dispatch(chat_modal_display_toggle({ display_status: true, chat_id: chat.id }))}
            >
              <div className={`chatsListChat${chat.has_unread ? ' hasUnread' : ''}`}>
                <div
                  className={`avatar${!chat?.interlocutor?.image ? ' noAvatar' : ''}`}
                  style={{ backgroundImage: `url('${chat?.interlocutor?.image}')` }}
                />
                <div className='name'>{`${chat?.interlocutor?.first_name} ${chat?.interlocutor?.last_name}`}</div>
                <div className='time'>
                  {chat?.last_msg?.date ? format(new Date(chat.last_msg.date), 'd MMM kk:mm', { locale: ru }) : ''}
                </div>
                <div className='orderName'>
                  <span className='opacity'>Заказ «</span>
                  <span className='red'>{chat.chat_title}</span>
                  <span className='opacity'>»</span>
                </div>
                <div className='message'>{chat.last_msg.text}</div>
              </div>
            </div>
          ))
        ) : (
          <div className='empty'>У вас пока нет сообщений.</div>
        )}
      </div>
    </Modal>
  );
}

export default ChatListModal;
