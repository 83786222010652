import './ProfileFeedback.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { profile_feedbacks_count, profile_feedbacks_load, profile_feedbacks_start } from '../../../../actions/ProfileFeedbacksActions';
import Button from '../../../shared/Button/Button';
import Spinner from '../../../shared/Spinner/Spinner';
import ProfileStars from '../ProfileStars/ProfileStars';

/**
 * profile feedback component
 * @param {Object} props props of component
 * @param {string} [props.extraClassName] additional CSS class name
 * @param {string} [props.emptyBlockCaption] message to be shown for the empty block
 */
function ProfileFeedback(props) {
  const { extraClassName, emptyBlockCaption } = props;
  /** @type {string} */
  const user_id = useSelector((state) => state.profileReducer.user_id);
  /** @type {import('../../../../typedef').TFeedbacksReducer} */
  const { feedback_data, feedbacks_count, start, count, is_loading } = useSelector((state) => state.profileFeedbacksReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * extract user name from feedback entry
   * @param {import('../../../../typedef').TFeedback} data entry data
   */
  const userName = (data) => data.user_name || 'Анонимус';

  /**
   * extract user role from feedback entry
   * @param {import('../../../../typedef').TFeedback} data entry data
   */
  const userRole = (data) => {
    /** @type {string[]} */
    const roles = [];
    if (data.is_customer) {
      roles.push('Заказчик');
    }
    if (data.is_executor) {
      roles.push('Исполнитель');
    }
    return roles.join(' | ');
  };

  /**
   * extract task status
   * @param {import('../../../../typedef').TTaskStatus} status статус заказа
   */
  const taskStatus = (status) => {
    if (status === 1) {
      return 'Есть отклики';
    }
    if (status === 2) {
      return 'Выполняется';
    }
    if (status === 3) {
      return 'Выполнено';
    }
    if (status === 4) {
      return 'Не выполнено';
    }
    if (status === 5) {
      return 'Отменено';
    }
    return 'Открыто';
  };

  /**
   * set initial amount of feedbacks to load
   */
  // useEffect(() => {
  //   dispatch(profile_feedbacks_start(0));
  //   dispatch(profile_feedbacks_count(2));

  //   // reset control upon exit
  //   return () => {
  //     dispatch(profile_feedbacks_start(0));
  //     dispatch(profile_feedbacks_count(2));
  //   };
  // }, [dispatch]);

  /**
   * load feedbacks
   */
  useEffect(() => {
    dispatch(profile_feedbacks_load({ user_id }));
  }, [user_id, start, count, dispatch]);

  return (
    <div className={`profileFeedback ${extraClassName || ''}`}>
      {is_loading && <Spinner />}
      {feedback_data instanceof Array && !!feedback_data.length ? (
        <div className='full'>
          <div className='feedbackCount'>
            Отзывы <span className='feedbacks'>{feedbacks_count}</span>
          </div>
          <div className='feedbackBlocks'>
            {feedback_data.map((feedback, index) => (
              <div key={index} className='feedbackBlock'>
                <div className='profile'>
                  <div className='icon' onClick={() => history.push(`/profile/${feedback.user_id}`)}>
                    {feedback.user_photo ? (
                      <div className='avatar' style={{ backgroundImage: `url('${feedback.user_photo}')` }}></div>
                    ) : (
                      <div className='default'></div>
                    )}
                  </div>
                  <div className='bio'>
                    <div className='name'>{userName(feedback)}</div>
                    <div className='feedbacksGiven'>
                      Отзывы: <span className='mark'>{feedback.feedbacks_count || 0}</span> &mdash; {userRole(feedback)}
                    </div>
                  </div>
                </div>
                <div className='data'>
                  <div className='corner'></div>
                  <div className='content'>
                    <div className='details'>
                      <div className='taskTitle'>
                        Задание &laquo;<span className='taskTitleAccent'>{feedback.task_title}</span>&raquo;
                      </div>
                      {/* <div className='taskStatus'>{taskStatus(feedback.task_status)}</div> */}
                      {feedback.task_safe_payment && (
                        <div className='taskIsSafe'>
                          <div className='icon'></div>
                          <div className='title'>Сделка без риска</div>
                        </div>
                      )}
                    </div>
                    <div className='divider'></div>
                    <div className='rating'>
                      <div className='ratingText'>{feedback.feedback_text}</div>
                      <div className='ratingData'>
                        <div className='overallRate'>
                          Оценка <span className='mark'>{feedback.rate}</span>
                        </div>
                        <div className='rateDetails'>
                          Адекватность <ProfileStars rate={feedback.adequacy} extraClassName='detailedRate' />
                        </div>
                        <div className='rateDetails'>
                          Вежливость <ProfileStars rate={feedback.decency} extraClassName='detailedRate' />
                        </div>
                        <div className='rateDetails'>
                          Пунктуальность <ProfileStars rate={feedback.punctuality} extraClassName='detailedRate' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {feedback_data.length < feedbacks_count && (
            <Button
              color='white'
              size='normal'
              text='Смотреть все отзывы'
              width='203rem'
              extraClassName='profileFeedback'
              onClick={() => {
                const begin = start + count;
                dispatch(profile_feedbacks_start(begin));
                dispatch(profile_feedbacks_count(feedbacks_count - begin));
              }}
            />
          )}
        </div>
      ) : (
        <div className='empty'>
          <div className='icon'></div>
          <div className='data'>
            <div className='caption'>Отзывов пока нет</div>
            <div className='value'>{emptyBlockCaption}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileFeedback;
