import './DatepickerDate.scss';

import ru from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';

import Select from '../Select/Select';

registerLocale('ru', ru);

const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

/**
 * datepicker for full date component
 * @param {Object} props props of component
 * @param {Date} [props.date] target date
 * @param {(date: Date) => void} [props.action] update target date
 */
function DatepickerDate(props) {
  const { date, action } = props;
  const yearZero = 1940;
  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  /** @type {[import('../../../typedef').TOption[],React.Dispatch<React.SetStateAction<import('../../../typedef').TOption[]>>]} */
  const [days, setDays] = useState([]);
  const [day, setDay] = useState(date ? date.getDate() : null);
  /** @type {import('../../../typedef').TOption[]} */
  const months = Array.from({ length: 12 }, (value, index) => ({ id: index, title: monthNames[index] }));
  const [month, setMonth] = useState(date ? date.getMonth() : null);
  /** @type {import('../../../typedef').TOption[]} */
  const years = Array.from({ length: maxDate.getFullYear() - yearZero + 1 }, (value, index) => {
    const mark = maxDate.getFullYear() - index;
    return { id: mark, title: mark };
  });
  const [year, setYear] = useState(date ? date.getFullYear() : null);

  // update list of days for selected date
  useEffect(() => {
    /** @type {import('../../../typedef').TOption[]} */
    const update = [];
    const newDate = new Date(year, month, 1);
    let newDay = 0;
    while (newDate.getMonth() === month) {
      ++newDay;
      update.push({ id: newDay, title: newDay });
      newDate.setDate(newDate.getDate() + 1);
    }
    setDays(update);
  }, [month, year]);

  // update day after month/year was updated
  useEffect(() => {
    if (days instanceof Array && days.length && days.findIndex((item) => item.id === day) === -1) {
      const closest = days.reduce((prev, curr) => (Math.abs(parseInt(curr.id) - day) < Math.abs(parseInt(prev.id) - day) ? curr : prev));
      setDay(closest.id);
    }
  }, [days, day]);

  // dispatch value
  useEffect(() => {
    if (typeof year === 'number' && typeof month === 'number' && typeof day === 'number') {
      const update = new Date(year, month, day);
      if (typeof action === 'function' && (!date || (date instanceof Date && update.getTime() !== date.getTime()))) {
        action(update);
      }
    }
  }, [action, year, month, day, date]);

  return (
    <div className='datepickerDate'>
      <label className='dateLabel'>Дата рождения</label>
      <Select name='yearOfBirth' options={years} onClick={(event) => setYear(parseInt(event.target.id))} selection={year} noLabel />
      <Select name='monthOfBirth' options={months} onClick={(event) => setMonth(parseInt(event.target.id))} selection={month} noLabel />
      <Select name='dateOfBirth' options={days} onClick={(event) => setDay(parseInt(event.target.id))} selection={day} noLabel />
    </div>
  );
}

export default DatepickerDate;
