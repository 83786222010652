import './ProfileCategories.scss';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { executor_categories_modal_display_toggle } from '../../../../actions/ExecutorCategoriesModalActions';

/**
 * profile categories component
 */
function ProfileCategories() {
  /** @type {string} */
  const user_id = useSelector((state) => state.profileReducer.user_id);
  /** @type {boolean} */
  const next_step = useSelector((state) => state.executorCategoriesModalReducer.next_step);
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  /** @type {import('../../../../typedef').TCategoryState} */
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  /**
   * load user's categories
   * @param {string} id user id
   */
  const loadCategories = (id) => {
    axios({
      method: 'get',
      url: `${global.backendURL}users/user/${id}/categories/`,
    })
      .then((response) => setCategories(response.data.categories))
      .catch((error) => global.debug &&console.log(error.response));
  };

  // load categories selected by user
  useEffect(() => {
    if (user_id) {
      loadCategories(user_id);
    }
  }, [user_id]);

  /** reload categories after user update */
  useEffect(() => {
    if (next_step && user_id) {
      loadCategories(user_id);
    }
  }, [next_step, user_id]);

  return (
    <div className='profileCategories'>
      <div className='caption'>
        <div className='title'>Виды выполняемых работ</div>
        {!user.public && <div className='edit' onClick={() => dispatch(executor_categories_modal_display_toggle(true))}></div>}
      </div>
      {categories.map((category, index) => (
        <div key={index} className='category'>
          <div className='categoryTitle'>{category.title}</div>
          <div className='categoryDetails'>{`${category.rating || '0'} место в общем рейтинге категории`}</div>
          <div className='subcategories'>
            {category.subcategories.map((subcategory, key) => (
              <div key={key} className='subcategory'>
                <div className='subcategoryTitle'>{subcategory.title}</div>
                <div className='subcategoryPlaceholder'></div>
                <div className='subcategoryRating'>{`${subcategory.rating || '0'} место`}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProfileCategories;
