import './ProfileSettingsTab.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profile_save, profile_subscribed_news_change, profile_subscribed_notifications_change } from '../../../../actions/ProfileActions';
import Button from '../../../shared/Button/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';

function ProfileSettingsTab() {
  const user = useSelector((state) => state.profileReducer.user);
  const id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const dispatch = useDispatch();

  const forceNotifications = () => {
    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = "(pushalertbyiw = window.pushalertbyiw || []).push(['onReady', onPAReady]);function onPAReady() {PushAlertCo.forceSubscribe();}";
    document.body.appendChild(script);
  }

  const savePushNotifId = () => {
    const script = document.createElement('script');
    script.async = false;
    script.innerHTML = "(pushalertbyiw = window.pushalertbyiw || []).push(['onReady', onPAReady]);function onPAReady() {localStorage.setItem('signedUPPushID', PushAlertCo.subs_id)}";
    document.body.appendChild(script);
  }

  return (
    <div className='profileSettingsTab'>
      {/* <a href={user.tm_link} target='_blank'>Телега линк</a> */}
      <div className='title'>Хочу получать:</div>
      <Checkbox
        text='Новости сервиса на e-mail'
        value={user.subscribed_news}
        onClick={() => dispatch(profile_subscribed_news_change(!user.subscribed_news))}
      />
      <Checkbox
        text='Пиcьма о событиях с заказами '
        value={user.subscribed_notifications}
        onClick={() => {
          dispatch(profile_subscribed_notifications_change(!user.subscribed_notifications))
          // forceNotifications()
        }}
      />

      {/* <button onClick={() => PushAlertCo.forceSubscribe()}>Subscribe</button> */}

      <div className='notification'>Подписываться на заказы могут только исполнители с подтвержденным аккаунтом.</div>
      <Button color='red' size='large' text='Сохранить' extraClassName='saveSettings' onClick={() => {
        dispatch(profile_save(id, user)) 
        // savePushNotifId()
      }} />
    </div>
  );
}

export default ProfileSettingsTab;
