import './OrderChat.scss';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { chat_message_value_change, chat_modal_load_history, chat_send_message } from '../../../../actions';
import { useInterval, useWindowDimensions } from '../../../../functions';

import Input from '../../../shared/Input/Input';
import Spinner from '../../../shared/Spinner/Spinner';

function OrderChat({ forExecutor, chat_id }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const user_id = useSelector((state) => state.userReducer.user_id);
  const chatHistory = useSelector((state) => state.chatModalReducer.chat_history);
  const chatMessage = useSelector((state) => state.chatModalReducer.chat_message_value);
  const chat_history_need_to_be_updated = useSelector((state) => state.chatModalReducer.chat_history_need_to_be_updated);

  useEffect(() => {
    dispatch(chat_modal_load_history(chat_id));
  }, [chat_history_need_to_be_updated]);

  useInterval(() => {
    dispatch(chat_modal_load_history(chat_id));
  }, 30000);


  return (
    <section className='orderChat'>
      <div className='title'>{forExecutor ? 'Чат с заказчиком' : 'Чат с исполнителем'}</div>
      <div className='chat'>
        {chatHistory ? (
          chatHistory.map((chatMessage) => (
            <>
              <div className='chatMessage' key={chatMessage.id}>
                {width > 500 && (
                  <div
                  className={`avatar${chatMessage.author.image == '' ? ' noAvatar' : ''}`}
                  style={{ backgroundImage: `url('${chatMessage.author.image}')` }}
                  />
                )}
                <div className={`name${chatMessage.author.id === parseInt(user_id) ? ' yourName' : ''}`}>
                  {chatMessage.author.id === parseInt(user_id) ? 'Вы' : `${chatMessage.author.first_name} ${chatMessage.author.last_name}`}
                </div>
                <div className='messageTime'>{chatMessage.created_at}</div>
                <div className='messageText'>{chatMessage.text}</div>
              </div>
            </>
          ))
        ) : (
          <Spinner />
        )}
      </div>
      <div className='chatNewMessage'>
        <Input
          type='text'
          value={chatMessage}
          onChange={(event) => dispatch(chat_message_value_change(event.target.value))}
          placeholder='Написать'
          extraClassName='newMessageInput'
        />
        <button type='submit' className='newMessageSubmit' onClick={() => dispatch(chat_send_message(chat_id, chatMessage))}></button>
      </div>
    </section>
  );
}

export default OrderChat;
