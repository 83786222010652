import { PROFILE_HEADER_LITE_MODE_CHANGE, PROFILE_HEADER_MENU_MODE_CHANGE } from '../actions/types';

/** @type {import("../typedef").TProfileHeaderReducer} */
const INITIAL_STATE = {
  profile_header_lite_mode: false,
  profile_header_menu_mode: false,
  is_loading: false,
};

/** @returns {import("../typedef").TProfileHeaderReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_HEADER_LITE_MODE_CHANGE:
      return { ...state, profile_header_lite_mode: action.payload };
    case PROFILE_HEADER_MENU_MODE_CHANGE:
      return { ...state, profile_header_menu_mode: action.payload };
    default:
      return { ...state };
  }
};
