import React from 'react';
import './OrderStages.scss';

function OrderStages({ activeStage }) {
  return (
    <div className='orderStages'>
      <div className={`stage ${activeStage === '1' && 'activeStage'}`}>
        <div className='stageNumber'>1</div>
        <h3 className='h3'>Создайте заказ</h3>
        <div className='description'>
          Опишите, что именно и в какие сроки вам нужно сделать? Умный алгоритм бесплатно подберет наиболее подходящих исполнителей.
        </div>
      </div>
      <div className={`stage ${activeStage === '2' && 'activeStage'}`}>
        <div className='stageNumber'>2</div>
        <h3 className='h3'>Выберите лучшего </h3>
        <div className='description'>
          Пообщайтесь с подобранными для вас исполнителями в чате signedUP или по телефону. Выберите самого подходящего по рейтингу,
          стоимости работ и отзывам.
        </div>
      </div>
      <div className={`stage ${activeStage === '3' && 'activeStage'}`}>
        <div className='stageNumber'>3</div>
        <h3 className='h3'>Договоритесь об условиях</h3>
        <div className='description'>
          Обсудите с выбранным исполнителем детали, сроки и место работы. Все устраивает? Отлично. Теперь просто подождите, пока вашу задачу
          решит профессионал.
        </div>
      </div>
    </div>
  );
}

export default OrderStages;
