import './ProfilePortfolioViewer.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { profile_portfolio_load } from '../../../../actions';
import { PROFILE_PORTFOLIO_RESET } from '../../../../actions/types';
import closeIcon from '../../../../assets/images/icon_close_white.svg';
import { useWindowDimensions } from '../../../../functions';
import PortfolioImage from '../../../shared/PortfolioImage/PortfolioImage';

function ProfilePortfolioViewer() {
  /** @type {string} */
  const user_id = useSelector((state) => state.profileReducer.user_id);
  /** @type {import('../../../../typedef').TProfilePortfolioImage[]} */
  const images = useSelector((state) => state.profilePortfolioReducer.images);
  const [position, setPosition] = useState(0);
  const [link, setLink] = useState('');
  const [maxImagesCount, setMaxImagesCount] = useState(7);
  /** @type {{image_id: string}} */
  const { image_id } = useParams();
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const ref = React.createRef(null);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * flip image
   * @param {-1 | 1} direction flip direction
   */
  const flipImage = (direction) => {
    let update = position + direction;
    if (update < 0) {
      update = 0;
    }
    if (update > images.length - 1) {
      update = images.length - 1;
    }
    setPosition(update);
  };

  /**
   * select image by index
   * @param {number} index image index
   */
  const selectImage = (index) => {
    if (index !== position && index >= 0 && index < images.length) {
      setPosition(index);
    }
  };

  /**
   * load portfolio
   */
  useEffect(() => {
    dispatch(profile_portfolio_load({ user_id, loadAll: true }));

    return () => dispatch({ type: PROFILE_PORTFOLIO_RESET });
  }, [user_id, dispatch]);

  /**
   * set selected image url
   */
  useEffect(() => {
    if (images instanceof Array && images[position]) {
      setLink(images[position].url);
    }
  }, [position, images]);

  /**
   * select image by parameter from location
   */
  useEffect(() => {
    if (image_id && images instanceof Array) {
      const index = images.findIndex((image) => image.id === parseInt(image_id));
      if (index !== -1) {
        setPosition(index);
      }
    }
  }, [image_id, images]);

  // swipe events
  useEffect(() => {
    /** @type {HTMLDivElement} */
    let current = null;
    /** @type {number} */
    let start = null;

    const startTouchEvent = (event) => {
      if (event.touches.length === 1) {
        start = event.touches.item(0).clientX;
      } else {
        start = null;
      }
    };

    const endTouchEvent = (event) => {
      const offset = 100;
      if (start) {
        const end = event.changedTouches.item(0).clientX;

        if (end > start + offset) {
          flipImage(-1);
        }
        if (end < start - offset) {
          flipImage(1);
        }
      }
    };

    if (ref && ref.current) {
      current = ref.current;
      current.addEventListener('touchstart', startTouchEvent);
      current.addEventListener('touchend', endTouchEvent);
    }

    return () => {
      if (current) {
        current.removeEventListener('touchstart', startTouchEvent);
        current.removeEventListener('touchend', endTouchEvent);
      }
    };
  }, [ref]);

  // update maximum number of visible images according to resolution
  useEffect(() => {
    if (width <= 500) {
      setMaxImagesCount(2);
    } else if (width <= 768) {
      setMaxImagesCount(5);
    } else if (width <= 1024) {
      setMaxImagesCount(7);
    }
  }, [width]);

  return (
    <div className='profilePortfolioViewer'>
      <div className='viewerHeader'>
        <div className='caption'>Примеры работ</div>
        <div className='position'>
          {images.length ? position + 1 : 0} из {images.length}
        </div>
        <img src={closeIcon} alt='' className='closeButton' onClick={() => history.push(`/profile/${user_id}`)} />
      </div>
      <div className='viewerBody'>
        <div>{!!position && <div className='buttonPrevious' onClick={() => flipImage(-1)}></div>}</div>
        <div className='content' style={{ backgroundImage: `url('${link}')` }}></div>
        <div>{position < images.length - 1 && <div className='buttonNext' onClick={() => flipImage(1)}></div>}</div>
      </div>
      <div className='viewerFooter' ref={ref}>
        {images.map((image, index) => {
          /** @type {import('../../../../typedef').TPortfolioImage} */
          const settings = {
            ...image,
            extraClassName: 'portfolioViewer',
            selected: index === position,
            clickCallback: () => selectImage(index),
          };
          global.debug &&console.log({ maxImagesCount });
          if (position < maxImagesCount) {
            return <PortfolioImage key={index} {...settings} />;
          } else {
            if (index > position - maxImagesCount && index < position + 2) {
              return <PortfolioImage key={index} {...settings} />;
            }
          }
          return undefined;
        })}
      </div>
    </div>
  );
}

export default ProfilePortfolioViewer;
