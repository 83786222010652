import './PageTitle.scss';
import icon_h1success from '../../../assets/images/icon_h1success.svg';

import React from 'react';

function PageTitle({ text, smallText, successIcon, extraClassName }) {
  return (
    <h1 className={`h1 ${extraClassName ? extraClassName : ''}`}>
      {text}
      {successIcon && <img src={icon_h1success} className='success' alt='Успех!' />}
      {smallText && <small className='small'>{smallText}</small>}
    </h1>
  );
}

export default PageTitle;
