import './Header__Mobile.scss';

import { Link, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  chat_list_header_display_toggle,
  executor_categories_modal_display_toggle,
  header_mobile_menu_display_toggle,
  login_modal_display_toggle,
  preface_modal_display_toggle,
  profile_header_menu_mode_toggle,
  registr_modal_display_toggle,
} from '../../../actions';
import { isAndroid, isIOS, isMacOs } from 'react-device-detect';
import { signedUpToken, signedUpUserId } from '../../../const';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button/Button';
import HeaderChatList from './HeaderChatList';
import { LOGOUT } from '../../../actions/types';
import ReactGA from 'react-ga';
import icon_appleStore from '../../../assets/images/icon_appleStore.svg';
import icon_burgerMenu from '../../../assets/images/icon_burgerMenu.svg';
import icon_close from '../../../assets/images/closeIcon.svg';
import icon_close_white from '../../../assets/images/icon_close--white.svg';
import icon_googlePlay from '../../../assets/images/icon_googlePlay.svg';
import logo from '../../../assets/images/logo.svg';
import logoSquare from '../../../assets/images/logo--square.svg';

function Header__Mobile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.globalVariablesReducer.token_value);
  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const user = useSelector((state) => state.userReducer.user);
  const tasks_customer_count = useSelector((state) => state.userReducer.task_data.customer.tasks_count);
  const tasks_executor_count = useSelector((state) => state.userReducer.task_data.executor.tasks_count);
  const header_mobile_menu_display_status = useSelector((state) => state.headerReducer.header_mobile_menu_display_status);
  const chat_list_new_messages = useSelector((state) => state.chatModalReducer.chat_list_new_messages);
  const chat_list_header_display_status = useSelector((state) => state.chatModalReducer.chat_list_header_display_status);

  const renderCustomerOrdersLinks = () => {
    if (user.is_customer) {
      return (
        <>
          <Link to='/create_order/' className='link' onClick={() => dispatch(header_mobile_menu_display_toggle(false))}>
            Создать заказ
          </Link>
          <Link to='/created_orders/' className='link' onClick={() => dispatch(header_mobile_menu_display_toggle(false))}>
            <span className='text'>Мои заказы </span>
            <span className='counter'>{tasks_customer_count}</span>
          </Link>
        </>
      );
    }
    return (
      <Link to='/create_order/' className='link' onClick={() => dispatch(header_mobile_menu_display_toggle(false))}>
        Создать заказ
      </Link>
    );
  };

  const renderExecutorOrdersButtons = () => {
    if (user.verification_status === 'verified' && user.is_executor) {
      return (
        <>
          <Link to='/orders/' className='link' onClick={() => dispatch(header_mobile_menu_display_toggle(false))}>
            Найти заказ
          </Link>

          <Link to='/orders/in_work/' className='link' onClick={() => dispatch(header_mobile_menu_display_toggle(false))}>
            <span className='text'>Принятые заказы </span>
            <span className='counter'>{tasks_executor_count}</span>
          </Link>
        </>
      );
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      return (
        <div
          className='link'
          onClick={() => {
            dispatch(executor_categories_modal_display_toggle(true));
            dispatch(header_mobile_menu_display_toggle(false));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Стать исполнителем ( добавить категории )" - меню ( мобайл )',
            });
          }}
        >
          Стать исполнителем
        </div>
      );
    }
    return (
      <div
        className='link'
        onClick={() => {
          dispatch(preface_modal_display_toggle(true));
          dispatch(header_mobile_menu_display_toggle(false));
          ReactGA.event({
            category: 'BecomeExecutor',
            action: 'Кнопка "Стать исполнителем" - меню ( мобайл )',
          });
        }}
      >
        Стать исполнителем
      </div>
    );
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem(signedUpToken);
    localStorage.removeItem(signedUpUserId);
    history.push('/');
  };

  const redirectTo = () => {
    if (isIOS || isMacOs) {
      return 'https://apps.apple.com/ru/app/signedup/id1552841938';
    } else if (isAndroid) {
      return 'https://play.google.com/store/apps/details?id=me.signedup.twa';
    } else {
      return 'https://terminal.signedup.me/order_new';
    }
  };

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    if (html) {
      header_mobile_menu_display_status ? html.classList.add('htmlNoScroll') : html.classList.remove('htmlNoScroll');
    }
  }, [header_mobile_menu_display_status]);

  return (
    <header className={'header__mobile ' + (localStorage.getItem('appAlertStatus') !== 'false' ? 'downloadAppAlertShown' : '')}>
      {localStorage.getItem('appAlertStatus') !== 'false' ? (
        <>
          {document.querySelector('body').classList.add('downloadAppAlertShown')}
          <div className='downloadAppContainer'>
            <a href={redirectTo()} target='_blank' rel='noopener noreferrer'>
              <img src={logoSquare} className='logoSquare' alt='logo' />
            </a>
            <a href={redirectTo()} target='_blank' rel='noopener noreferrer' className='downloadText'>
              Скачайте наше мобильное
              <br /> приложение
            </a>
            <button
              type='button'
              className='buttonClose'
              onClick={() => {
                localStorage.setItem('appAlertStatus', false);
                document.querySelector('.downloadAppContainer').classList.add('hidden');
                document.querySelector('.header__mobile').classList.remove('downloadAppAlertShown');
                document.querySelector('body').classList.remove('downloadAppAlertShown');
              }}
            >
              <img src={icon_close_white} className='iconClose' alt='close' />
            </button>
          </div>
        </>
      ) : null}

      <div className='container'>
        {!header_mobile_menu_display_status ? (
          <img
            src={icon_burgerMenu}
            className='burgerMenuButton'
            onClick={() => {
              dispatch(profile_header_menu_mode_toggle(true));
              dispatch(header_mobile_menu_display_toggle(true));
            }}
            alt='Меню'
          />
        ) : (
          <img
            src={icon_close}
            className='closeMenuButton'
            onClick={() => {
              dispatch(profile_header_menu_mode_toggle(false));
              dispatch(header_mobile_menu_display_toggle(false));
            }}
            alt='Закрыть'
          />
        )}

        <Link to='/' className='logo'>
          <img className='logoImage' src={logo} alt='Лого' />
        </Link>

        {token && user_id && (
          <div className='notificationButtons'>
            <div className={`news`}></div>
            <div
              className={`chat${chat_list_new_messages ? ' active' : ''}`}
              onClick={() => {
                dispatch(header_mobile_menu_display_toggle(false));
                dispatch(chat_list_header_display_toggle(true));
              }}
            >
              {chat_list_header_display_status && <HeaderChatList />}
            </div>
          </div>
        )}
      </div>

      {header_mobile_menu_display_status && (
        <div className={'popupContainer ' + (token && user_id ? 'logged ' : '')}>
          {!token && !user_id ? (
            <div className='authButtons'>
              <Button
                type='button'
                onClick={() => {
                  dispatch(login_modal_display_toggle(true));
                  dispatch(header_mobile_menu_display_toggle(false));
                  ReactGA.event({
                    category: 'Login',
                    action: 'Логин - кнопка в меню ( мобайл )',
                  });
                }}
                size='large'
                color='white'
                text='Войти'
                width='430rem'
                extraClassName='loginButton'
              />
              <Button
                type='button'
                onClick={() => {
                  dispatch(registr_modal_display_toggle(true));
                  dispatch(header_mobile_menu_display_toggle(false));
                  ReactGA.event({
                    category: 'Registration',
                    action: 'Регистрация - кнопка в меню ( мобайл )',
                  });
                }}
                size='large'
                color='dark'
                text='Регистрация'
                width='430rem'
                extraClassName='registrButton'
              />
            </div>
          ) : (
            <div className='profileButtons'>
              <Button
                type='button'
                href={`/profile/${user_id}`}
                size='large'
                color='red'
                text='Профиль'
                width='430rem'
                extraClassName='profileButton'
                onClick={() => {
                  dispatch(header_mobile_menu_display_toggle(false));
                  history.push(`/profile/${user_id}`);
                }}
              />
            </div>
          )}
          <div className='mainLinks'>
            {global.prerelease || renderCustomerOrdersLinks()}
            {renderExecutorOrdersButtons()}
          </div>
          {token && user_id && (
            <div className='logoutLink'>
              <div className='link' onClick={() => logout()}>
                Выйти
              </div>
            </div>
          )}
          <div className='footerLinks'>
            <div
              className='link'
              onClick={() => {
                dispatch(header_mobile_menu_display_toggle(false));
                history.push(`/faq/about_us/`);
              }}
            >
              О сервисе
            </div>
            <div
              className='link'
              onClick={() => {
                dispatch(header_mobile_menu_display_toggle(false));
                history.push(`/faq/orders_rules/`);
              }}
            >
              Правила работы с заказами
            </div>
            <div
              className='link'
              onClick={() => {
                dispatch(header_mobile_menu_display_toggle(false));
                history.push(`/faq/all/`);
              }}
            >
              Частые вопросы
            </div>
            <div
              className='link'
              onClick={() => {
                dispatch(header_mobile_menu_display_toggle(false));
                history.push(`/documents/user_agreement/`);
              }}
            >
              Пользовательское соглашение
            </div>
          </div>

          <div className='downloadAppLinks'>
            <a href='https://play.google.com/store/apps/details?id=me.signedup.twa' className='link'>
              <img src={icon_appleStore} className='storeImg' alt='Google Play' />
            </a>
            <a href='https://apps.apple.com/ru/app/signedup/id1552841938' className='link'>
              <img src={icon_googlePlay} className='storeImg' alt='App Store' />
            </a>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header__Mobile;
