import './DownloadOutApp.scss';

import DownloadOurApp_Icon01 from '../../../../assets/images/DownloadOurApp_Icon01.png';
import DownloadOurApp_Icon02 from '../../../../assets/images/DownloadOurApp_Icon02.png';
import DownloadOurApp_Phone from '../../../../assets/images/DownloadOurApp_Phone.svg';
import React from 'react';
import { useLocation } from 'react-router-dom';

function DownloadOurApp() {
  let currentRoute = useLocation();

  return (
    <section className='downloadOurApp'>
      <div className='container'>
        <div className='info'>
          {currentRoute.pathname === '/' ? (
            <>
              <h2 className='h2'>Всегда под&nbsp;рукой</h2>
              <div className='description'>
                Скачайте наше приложение и пользуйтесь <br />
                SignedUp в любом месте и в любое время.
              </div>
            </>
          ) : (
            <>
              <h2 className='h2'>
                Моментально узнавайте
                <br /> о новых заказах
              </h2>
              <div className='description'>
                Скачайте наше мобильное приложение и <br /> мгновенно узнавайте о новых заказах.
              </div>
            </>
          )}

          <div className='links'>
            <a href='https://apps.apple.com/ru/app/signedup/id1552841938' target='_blank' rel='noopener noreferrer' className='link'>
              <img src={DownloadOurApp_Icon01} className='img' alt='AppStore icon' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=me.signedup.twa'
              target='_blank'
              rel='noopener noreferrer'
              className='link'
            >
              <img src={DownloadOurApp_Icon02} className='img' alt='GooglePlay icon' />
            </a>
          </div>
        </div>
        <img src={DownloadOurApp_Phone} className='phoneImage' alt='App icon' />
      </div>
    </section>
  );
}

export default DownloadOurApp;
