import './OrderPage.scss';
import icon_orderCompleteButton from '../../assets/images/icon_orderCompleteButton.svg';
import icon_orderNotCompleteButton from '../../assets/images/icon_orderNotCompleteButton.svg';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { order_complete_modal_display_toggle, order_safedeal_get_form_data, add_payment_method_modal_get_form_data } from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import Button from '../../components/shared/Button/Button';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderResponse from '../../components/pageSpecific/OrderPage/OrderResponse/OrderResponse';
import OrderChat from '../../components/pageSpecific/OrderPage/OrderChat/OrderChat';

function OrderPage_InWork_Customer({ order_id, order }) {
  const dispatch = useDispatch();
  const order_safedeal_form_data = useSelector((state) => state.orderReducer.order_safedeal_form_data);
  const add_payment_method_modal_form_data = useSelector((state) => state.addPaymentMethodModalReducer.add_payment_method_modal_form_data);
  const user_id = useSelector((state) => state.userReducer.user_id);
  const user_has_card = useSelector((state) => state.userReducer.user?.customer_tool);

  useEffect(() => {
    order.safe_payment &&
      order.payment_status === '0' &&
      dispatch(order_safedeal_get_form_data(order_id, `${global.siteURL}/order/${order_id}`));
  }, [dispatch, order]);

  // const rednerButtons = () => {
  //   if ( order.safe_payment && order.payment_status === '0' && order_safedeal_form_data ) {
  //     if ( !user_has_card ) {
  //       return (
  //         <>
  //           <form method='post' action={order_safedeal_form_data.action}>
  //             <input type="hidden" name="PlatformId" value={order_safedeal_form_data.PlatformId} id="PlatformId" />
  //             <input type="hidden" name="PlatformPayerId" value={order_safedeal_form_data.PlatformPayerId} id="PlatformPayerId" />
  //             <input type="hidden" name="PhoneNumber" value={order_safedeal_form_data.PhoneNumber} id="PhoneNumber" />
  //             <input type="hidden" name="Title" value={order_safedeal_form_data.Title} id="Title" />
  //             <input type="hidden" name="ReturnUrl" value={order_safedeal_form_data.ReturnUrl} id="ReturnUrl" />
  //             <input type="hidden" name="Timestamp" value={order_safedeal_form_data.Timestamp} id="Timestamp" />
  //             <input type="hidden" name="Language" value={order_safedeal_form_data.Language} id="Language"/>
  //             <input type="hidden" name="RedirectToPaymentToolAddition" value={order_safedeal_form_data.RedirectToPaymentToolAddition} id="RedirectToPaymentToolAddition" />
  //             <input type="hidden" name="Signature" value={order_safedeal_form_data.Signature} id="Signature" />
  //             <Button
  //               type='button'
  //               text='Добавить карту для сделки'
  //               size='large'
  //               color='red'
  //               width='360rem'
  //             />
  //           </form>
  //           <Button
  //             type='button'
  //             text='Написать в поддержку'
  //             size='large'
  //             color='white'
  //             width='360rem'
  //           />
  //         </>
  //       )
  //     }
  //     else {
  //       return (
  //         <>
  //           <form method='post' action={order_safedeal_form_data.action}>
  //             <input type="hidden" name="PlatformId" value={order_safedeal_form_data.PlatformId} id="PlatformId" />
  //             <input type="hidden" name="PlatformDealId" value={order_safedeal_form_data.PlatformDealId} id="PlatformDealId" />
  //             {/*<input type="hidden" name="RedirectToCardAddition" value={order_safedeal_form_data.RedirectToCardAddition} id="RedirectToCardAddition" />*/}
  //             <input type="hidden" name="ReturnUrl" value={order_safedeal_form_data.ReturnUrl} id="ReturnUrl" />
  //             <input type="hidden" name="Signature" value={order_safedeal_form_data.Signature} id="Signature" />
  //             <input type="hidden" name="Timestamp" value={order_safedeal_form_data.Timestamp} id="Timestamp" />
  //             <input type="hidden" name="Language" value={order_safedeal_form_data.Language} id="Language"/>
  //             <Button
  //               type='button'
  //               text='Зарезервировать сумму сделки'
  //               size='large'
  //               color='red'
  //               width='360rem'
  //             />
  //           </form>
  //           <Button
  //             type='button'
  //             text='Написать в поддержку'
  //             size='large'
  //             color='white'
  //             width='360rem'
  //           />
  //         </>
  //       )
  //     }
  //   }
  //   else if ( order.safe_payment && ( order.payment_status === '1' || order.payment_status === '2' || order.payment_status === '3' ) && order_safedeal_form_data ) {
  //     return (
  //       <div className='buttons completingOrder'>
  //         <Button
  //           type='button'
  //           onClick={() => dispatch(order_complete_modal_display_toggle({ display: true, status: 'Success' }))}
  //           text='Заказ выполнен'
  //           iconLeft={icon_orderCompleteButton}
  //           iconLeftExtraClassName='iconOrderCompleteButton'
  //           size='large'
  //           color='dark'
  //           width='360rem'
  //           extraClassName='orderCompleteButton'
  //         />
  //         <Button
  //           type='button'
  //           onClick={() => dispatch(order_complete_modal_display_toggle({ display: true, status: 'Fail' }))}
  //           text='Заказ невыполнен'
  //           iconLeft={icon_orderNotCompleteButton}
  //           iconLeftExtraClassName='iconOderNotCompleteButton'
  //           size='large'
  //           color='dark'
  //           width='360rem'
  //         />
  //       </div>
  //     )
  //   }
  // }

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Заказ выполняется' />

        <OrderInfo order={order} />

        {order.safe_payment && order.payment_status === '0' && order_safedeal_form_data ? (
          <div className='buttons safeDealButtons'>
            {!user_has_card ? (
              <form method='post' action={order_safedeal_form_data.action}>
                <input type='hidden' name='PlatformId' value={order_safedeal_form_data.PlatformId} id='PlatformId' />
                <input type='hidden' name='PlatformPayerId' value={order_safedeal_form_data.PlatformPayerId} id='PlatformPayerId' />
                <input type='hidden' name='PhoneNumber' value={order_safedeal_form_data.PhoneNumber} id='PhoneNumber' />
                <input type='hidden' name='Title' value={order_safedeal_form_data.Title} id='Title' />
                <input type='hidden' name='ReturnUrl' value={order_safedeal_form_data.ReturnUrl} id='ReturnUrl' />
                <input type='hidden' name='Timestamp' value={order_safedeal_form_data.Timestamp} id='Timestamp' />
                <input type='hidden' name='Language' value={order_safedeal_form_data.Language} id='Language' />
                <input
                  type='hidden'
                  name='RedirectToPaymentToolAddition'
                  value={order_safedeal_form_data.RedirectToPaymentToolAddition}
                  id='RedirectToPaymentToolAddition'
                />
                <input type='hidden' name='Signature' value={order_safedeal_form_data.Signature} id='Signature' />
                <Button type='button' text='Добавить карту для сделки' size='large' color='red' width='360rem' />
              </form>
            ) : (
              <form method='post' action={order_safedeal_form_data.action}>
                <input type='hidden' name='PlatformId' value={order_safedeal_form_data.PlatformId} id='PlatformId' />
                <input type='hidden' name='PlatformDealId' value={order_safedeal_form_data.PlatformDealId} id='PlatformDealId' />
                <input type='hidden' name='ReturnUrl' value={order_safedeal_form_data.ReturnUrl} id='ReturnUrl' />
                <input type='hidden' name='Signature' value={order_safedeal_form_data.Signature} id='Signature' />
                <input type='hidden' name='Timestamp' value={order_safedeal_form_data.Timestamp} id='Timestamp' />
                <input type='hidden' name='Language' value={order_safedeal_form_data.Language} id='Language' />
                <Button type='button' text='Зарезервировать сумму сделки' size='large' color='red' width='360rem' />
              </form>
            )}
            <Button type='button' text='Написать в поддержку' size='large' color='white' width='360rem' />
          </div>
        ) : (
          <div className='buttons completingOrder'>
            <Button
              type='button'
              onClick={() => dispatch(order_complete_modal_display_toggle({ display: true, status: 'Success' }))}
              text='Заказ выполнен'
              iconLeft={icon_orderCompleteButton}
              iconLeftExtraClassName='iconOrderCompleteButton'
              size='large'
              color='dark'
              width='360rem'
              extraClassName='orderCompleteButton'
            />
            <Button
              type='button'
              onClick={() => dispatch(order_complete_modal_display_toggle({ display: true, status: 'Fail' }))}
              text='Заказ невыполнен'
              iconLeft={icon_orderNotCompleteButton}
              iconLeftExtraClassName='iconOderNotCompleteButton'
              size='large'
              color='dark'
              width='360rem'
            />
          </div>
        )}

        <div className='orderResponses'>
          <OrderResponse response={order.applications[0]} order_selected_executor={true} />
        </div>

        <OrderChat chat_id={order.applications[0].chat_id} />
      </div>
    </section>
  );
}

export default OrderPage_InWork_Customer;
