import './OrderCompleteModal.scss';
import icon_orderCompleteButtonActive from '../../../../assets/images/icon_orderCompleteButtonActive.svg';
import icon_orderNotCompleteButtonActive from '../../../../assets/images/icon_orderNotCompleteButtonActive.svg';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { order_complete_modal_display_toggle, order_complete_confrim } from '../../../../actions';

import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Button/Button';

function OrderCompleteModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const order_complete_modal_status = useSelector((state) => state.orderReducer.order_complete_modal_status);
  const order_id = useSelector((state) => state.orderReducer.order.id);
  console.log(order_id);

  return (
    <Modal
      extraClassName='orderCompleteModal'
      onClose={() => dispatch(order_complete_modal_display_toggle({ display: false, status: undefined }))}
    >
      {order_complete_modal_status === 'Success' && (
        <>
          <div className='title'>Заказ выполнен</div>
          <img className='icon' src={icon_orderCompleteButtonActive} alt='icon' />
          <Button
            type='button'
            onClick={() => dispatch(order_complete_confrim(order_id, history))}
            color='red'
            size='normal'
            width='245rem'
            text='Оставить отзыв'
          />
          <Button
            type='button'
            onClick={() => dispatch(order_complete_modal_display_toggle({ display: false, status: undefined }))}
            color='white'
            size='normal'
            width='245rem'
            text='Отмена'
          />
        </>
      )}
      {order_complete_modal_status === 'Fail' && (
        <>
          <div className='title'>Заказ не выполнен</div>
          <img className='icon' src={icon_orderNotCompleteButtonActive} alt='icon' />
          <Button
            type='button'
            onClick={() => dispatch(order_complete_modal_display_toggle(false))}
            color='red'
            size='normal'
            width='260rem'
            text='Опубликовать заказ еще раз'
          />
          <Button
            type='button'
            onClick={() => dispatch(order_complete_modal_display_toggle(false))}
            color='dark'
            size='normal'
            width='260rem'
            text='Написать в поддержку'
          />
          <Button
            type='button'
            onClick={() => dispatch(order_complete_modal_display_toggle({ display: false, status: undefined }))}
            color='white'
            size='normal'
            width='260rem'
            text='Отмена'
          />
        </>
      )}
    </Modal>
  );
}

export default OrderCompleteModal;
