import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function WhyWeAreSafePage() {
  return (
    <main className='whyWeAreSafePage'>
      <DocumentContent>
        <h1>Что нужно знать для безопасного использования сервиса</h1>
        <p>
          Мы просим наших пользователей прислать фотографии или сканы удостоверений личности, чтобы сравнить данные, которые они указали в
          анкете signedUP, с данными в документе. Также мы проверяем подлинность юридических лиц и просим пользователей, желающих работать
          как юрлицо, предоставить подтверждающие документы. Эти действия снижают вероятность регистрации мошенников, однако полностью не
          исключают возможность столкнуться с недобросовестными заказчиками или исполнителями.
        </p>
        <p>Чтобы максимально обезопасить себя от мошенников, рекомендуем следовать простым правилам:</p>
        <ul>
          <li>
            Обращайте внимание на отзывы других пользователей. Если вы видите высокий рейтинг и много хороших отзывов, то человеку дорога
            его репутация, и ему можно доверить заказ. Особенно тщательно проверяйте отзывы, если вы собираетесь доверить кому-то ценные
            вещи или документы.
          </li>
          <li>
            Оформляйте договор на оказание услуг или проведение работ, а также берите с исполнителя расписку, если доверяете ему деньги или
            ценные вещи. Если исполнитель добросовестный, он с легкостью согласится оформить ваше сотрудничество на бумаге, мошенники же
            обойдут вас стороной. При составлении договора не обязательно обращаться к юристам. Для большинства заданий подойдут эти три
            шаблона:
            <br />
            <br />
            <a href='https://storage.googleapis.com/signedup-storage/public/upload/legal/agreement_kz.doc' download>
              Договор на возмездное оказание услуг;
            </a>
            <br />
            <a href='https://storage.googleapis.com/signedup-storage/public/upload/legal/work%20agreement_kz.docx' download>
              Договор подряда;
            </a>
            <br />
            <a href='https://storage.googleapis.com/signedup-storage/public/upload/legal/receipt_kz.doc' download>
              Расписка.
            </a>
            <br />
            <br />
            Вам достаточно распечатать документы и заполнить их от руки. Сформулируйте своими словами условия работы, сроки, стоимость, а
            также впишите данные удостоверений личности обеих сторон. Вы потратите на заполнение данных всего несколько минут, но договор и
            расписка очень помогут при возникновении спорных ситуаций, при обращении в поддержку пользователей signedUP или в суд.
            <br /> <br />
            Рекомендуем оформить расписку в случае, если вы передаете исполнителю деньги или объекты, имеющие материальную ценность. Всегда
            фиксируйте факт передачи денег, если заказываете доставку ценного груза или оплачиваете покупку материалов для работы. Ситуации
            могут быть разными, иногда совершенно без злого умысла одна из сторон может забыть размер оплаты, что может вызвать споры и
            недоверие в работе.
          </li>
          <li>
            Проверяйте данные удостоверения личности. Если вы считаете, что составлять договор и требовать расписку — избыточно для вашего
            случая, по крайней мере, попросите исполнителя предъявить вам удостоверение личности. <br />
            <br />
            Будьте внимательны, юридически мы не являемся работодателем наших пользователей и не несем ответственность за их решения о
            сотрудничестве. Заказчик и исполнитель выбирают друг друга самостоятельно.
          </li>
        </ul>
      </DocumentContent>
    </main>
  );
}

export default WhyWeAreSafePage;
