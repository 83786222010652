import './OrderPage.scss';

import { order_cancel, order_change_notifications_behaviour } from '../../actions';

import Button from '../../components/shared/Button/Button';
import Checkbox from '../../components/shared/Checkbox/Checkbox';
import OrderChangeDetails from '../../components/pageSpecific/OrderPage/OrderChangeDetails/OrderChangeDetails';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderStages from '../../components/shared/OrderStages/OrderStages';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function OrderPage_Created_Customer({ order_id, order }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle
          text='Заказ опубликован'
          smallText='Исполнители откликнутся на вашу заявку. Мы будем уведомлять вас о новых откликах.'
          successIcon={true}
        />

        <div className='orderCreatedNotifications'>
          <div className='spinner'></div>
          <h2 className='h2'>
            Мы ищем предложения
            <small className='small'>
              Скоро тут появятся предложения исполнителей, <br />
              готовых выполнить вашу заявку.
            </small>
          </h2>
          <Checkbox
            text='Получать уведомления о новых событиях с заказом'
            value={order.send_email}
            onClick={() => dispatch(order_change_notifications_behaviour(order_id, !order.send_email, history))}
          />
        </div>

        <OrderStages activeStage='2' />

        <OrderInfo order={order} executorsNotified={order.sent_to_count} statusBar={'50%'} />

        <OrderChangeDetails />

        <Button
          type='button'
          onClick={() => dispatch(order_cancel(order_id, history))}
          text='Отменить заказ'
          size='large'
          color='white'
          width='360rem'
          extraClassName='cancelButton'
        />
      </div>
    </section>
  );
}

export default OrderPage_Created_Customer;
