import './AddEmailModal.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { add_email_display_toggle, profile_email_value_change, profile_email_submit } from '../../../actions';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Modal from '../../shared/Modal/Modal';

/**
 * add email modal component
 */
function AddEmailModal() {
  const id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  /** @type {import('../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setDisabled(!user.email);
  }, [user.email]);

  return (
    <Modal extraClassName='addEmailModal' onClose={() => dispatch(add_email_display_toggle(false))}>
      <div className='title'>Добавить e-mail</div>
      <Input
        extraClassName='email'
        label='Ваш e-mail'
        type='email'
        value={user.email}
        onChange={(event) => dispatch(profile_email_value_change(event.target.value))}
      />
      <Button
        extraClassName='ready'
        color='red'
        size='large'
        text='Подписаться'
        disabled={disabled}
        width='400rem'
        onClick={() => {
          dispatch(profile_email_submit(id, user.email));
          dispatch(add_email_display_toggle(false));
        }}
      />
    </Modal>
  );
}

export default AddEmailModal;
