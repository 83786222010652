import { GLOBAL_VARIABLES_SET_TOKEN, GLOBAL_VARIABLES_SET_USER_ID, LOGIN_MODAL_PHONE_VALUE_CHANGE } from './actions/types';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { chat_list_load, user_load } from './actions';
import { pathErrorPage, pathServicePage, signedUpPhone, signedUpToken, signedUpUserId } from './const';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval, useWindowDimensions } from './functions';

import AboutUsPage from './pages/FAQPages/FAQ_AboutUsPage';
import AddEmailModal from './components/modal/AddEmailModal/AddEmailModal';
import AddPaymentMetodModal from './components/modal/AddPaymentMetodModal/AddPaymentMetodModal';
import AlertModal from './components/modal/AlertModal/AlertModal';
import ChatListModal from './components/modal/ChatListModal/ChatListModal';
import ChatModal from './components/modal/ChatModal/ChatModal';
import ChatRulesPage from './pages/FAQPages/FAQ_ChatRulesPage';
import CitySelectModal from './components/modal/CitySelectModal/CitySelectModal';
import ConfidentialityPolicyPage from './pages/DocumentsPages/ConfidentialityPolicyPage';
import CustomerOrdersDashboardPage from './pages/OrdersDashboardsPages/CustomerOrdersDashboardPage';
import EmailVerificationModal from './components/modal/EmailVerificationModal/EmailVerificationModal';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ExecutorCategories from './components/modal/ExecutorCategoriesModal/ExecutorCategoriesModal';
import ExecutorOrdersDashboardPage from './pages/OrdersDashboardsPages/ExecutorOrdersDashboardPage';
import ExecutorPrefaceModal from './components/modal/ExecutorPrefaceModal/ExecutorPrefaceModal';
// * FAQ
import FAQPage from './pages/FAQPages/FAQPage';
import Footer from './components/shared/Footer/Footer';
import Header from './components/shared/Header/Header';
import Header__Mobile from './components/shared/Header/Header__Mobile';
import HomePageCustomer from './pages/HomePage/HomePageCustomer';
import HomePageExecutor from './pages/HomePage/HomePageExecutor';
import HowToBecomeExecutor from './pages/FAQPages/FAQ_HowToBecomeExecutor';
import HowToCreateOrderPage from './pages/FAQPages/FAQ_HowToCreateOrderPage';
import HowToGetMoreOrdersPage from './pages/FAQPages/FAQ_HowToGetMoreOrdersPage';
import HowToRegistPage from './pages/FAQPages/FAQ_HowToRegistPage';
import LegalEntityConfirmModal from './components/modal/LegalEntityConfirmModal/LegalEntityConfirmModal';
import LegalEntityPrefaceModal from './components/modal/LegalEntityPrefaceModal/LegalEntityPrefaceModal';
import Loader from './components/shared/Loader/Loader';
import Login from './components/shared/Login/Login';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import OrderChangeDetailsModal from './components/pageSpecific/OrderPage/OrderChangeDetailsModal/OrderChangeDetailsModal';
import OrderCompleteModal from './components/pageSpecific/OrderPage/OrderCompleteModal/OrderCompleteModal';
import OrderCreatePage from './pages/OrderCreatePage/OrderCreatePage';
import OrderPage from './pages/OrderPage/OrderPage';
import OrderResponseModal from './components/pageSpecific/OrdersDasbordsPages/OrderResponseModal/OrderResponseModal';
import OrdersRulesPage from './pages/FAQPages/FAQ_OrdersRulesPage';
// * Юр. информация
import PaymentPolicyPage from './pages/DocumentsPages/PaymentPolicyPage';
import PersonalDataSafery from './pages/FAQPages/FAQ_PersonalDataSafety';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ProfilePassportModal from './components/pageSpecific/ProfilePage/ProfilePassportModal/ProfilePassportModal';
import PromoPage from './pages/PromoPage/PromoPage';
import RatesPage from './pages/FAQPages/FAQ_RatesPage';
import ReactGA from 'react-ga';
import Registration from './components/shared/Registration/Registration';
import ScrollToTop from './ScrollToTop';
import ServicePage from './pages/ServicePage/ServicePage';
import UserAgreementPage from './pages/DocumentsPages/UserAgreementPage';
import WhatIsSafeDealPage from './pages/FAQPages/FAQ_WhatIsSafeDealPage';
import WhyWeAreSafePage from './pages/FAQPages/FAQ_WhyWeAreSafePage';
import axios from 'axios';
import ym from 'react-yandex-metrika';

function RouterComponent() {
  const user_id = localStorage.getItem(signedUpUserId);
  const user_phone = localStorage.getItem(signedUpPhone);
  const token = localStorage.getItem(signedUpToken);
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const userReducer_is_loading = useSelector((state) => state.userReducer.is_loading);
  const userReducer_need_to_be_updated = useSelector((state) => state.userReducer.need_to_be_updated);
  const chatList_need_to_be_updated = useSelector((state) => state.chatModalReducer.chat_list_need_to_be_updated);
  const [isLoading, setIsLoading] = useState(true);

  // * Отсылаем в ЯМ и GA всю инфомрацию по передвижению пользователя
  useEffect(() => {
    global.siteURL === 'https://signedup.me' && ym('hit', location.pathname);
    global.siteURL === 'https://signedup.me' && console.log(`y.metric hit ${location.pathname}`);
    global.siteURL === 'https://signedup.me' && ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  // * Ищем token & user_id в localStorage и выгружаем с бека данные о пользователе
  useEffect(() => {
    if (token && user_id) {
      axios({
        method: 'get',
        url: global.backendURL + 'auth/token/check/',
        headers: {
          Authorization: 'Token ' + token,
        },
      })
        .then(() => {
          dispatch({
            type: GLOBAL_VARIABLES_SET_TOKEN,
            payload: token,
          });
          dispatch({
            type: GLOBAL_VARIABLES_SET_USER_ID,
            payload: user_id,
          });
          dispatch(user_load(user_id));
        })
        .catch(() => {
          localStorage.removeItem(signedUpToken);
          dispatch({
            type: GLOBAL_VARIABLES_SET_TOKEN,
            payload: '',
          });
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch, token, user_id]);

  useEffect(() => {
    if (!token || !user_id) {
      const script = document.createElement('script');
      script.async = true;
      script.innerHTML = "(pushalertbyiw = window.pushalertbyiw || []).push(['disableAutoInit', true]);";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [token, user_id]);

  // * Предзаполняем поле телефона для логина если в localStorage есть данные
  useEffect(() => {
    if (user_phone) {
      dispatch({ type: LOGIN_MODAL_PHONE_VALUE_CHANGE, payload: user_phone.replace(/[^0-9]/g, '') });
    }
  }, [dispatch, user_phone]);

  // * Отслеживаем статус загрузки данных с бека данных о пользователе aka preloader status
  useEffect(() => {
    if (!userReducer_is_loading) {
      setIsLoading(false);
    }
  }, [userReducer_is_loading]);

  // * Отслеживаем нужно ли обновить данные по юзеру
  useEffect(() => {
    if (userReducer_need_to_be_updated) {
      dispatch(user_load(user_id));
      setIsLoading(true);
    }
  }, [dispatch, user_id, userReducer_need_to_be_updated]);

  // * Нотификейшены чайта
  useEffect(() => {
    dispatch(chat_list_load());
  }, [dispatch]);

  useEffect(() => {
    chatList_need_to_be_updated && dispatch(chat_list_load());
  }, [dispatch, chatList_need_to_be_updated]);

  useInterval(() => {
    if (token) {
      dispatch(chat_list_load());
    }
  }, 30000);

  const city_id = useSelector((state) => state.userReducer.user.city_id);

  // * Отображение модалок
  const city_select_modal_display_status = useSelector((state) => state.citySelectModalReducer.city_select_modal_display_status);
  const chat_list_header_display_status = useSelector((state) => state.chatModalReducer.chat_list_header_display_status);
  const order_change_details_modal_display_status = useSelector((state) => state.orderReducer.order_change_details_modal_display_status);
  const registr_display_status = useSelector((state) => state.registrationReducer.registr_modal_display_status);
  const login_display_status = useSelector((state) => state.loginReducer.login_modal_display_status);
  const preface_modal_display_status = useSelector((state) => state.prefaceReducer.preface_modal_display_status);
  const preface_legal_entity_modal_display_status = useSelector(
    (state) => state.legalEntityPrefaceModalReducer.preface_legal_entity_modal_display_status
  );
  const profile_passport_display_status = useSelector((state) => state.profilePassportReducer.profile_passport_display_status);
  const categories_display_status = useSelector((state) => state.executorCategoriesModalReducer.categories_display_status);
  const add_email_display_status = useSelector((state) => state.addEmailReducer.add_email_display_status);
  const alert_modal_display_status = useSelector((state) => state.alertModalReducer.alert_modal_display_status);
  const order_complete_modal_display_status = useSelector((state) => state.orderReducer.order_complete_modal_display_status);
  const order_response_modal_display_status = useSelector((state) => state.orderResponseModalReducer.order_response_modal_display_status);

  const chat_modal_display_status = useSelector((state) => state.chatModalReducer.chat_modal_display_status);
  const confirm_legal_entity_display_status = useSelector(
    (state) => state.legalEntityConfirmModalReducer.confirm_legal_entity_display_status
  );
  const add_payment_method_modal_display_status = useSelector(
    (state) => state.addPaymentMethodModalReducer.add_payment_method_modal_display_status
  );
  const noScroll = () => {
    if (
      chat_list_header_display_status ||
      order_change_details_modal_display_status ||
      registr_display_status ||
      login_display_status ||
      preface_modal_display_status ||
      preface_legal_entity_modal_display_status ||
      profile_passport_display_status ||
      categories_display_status ||
      add_email_display_status ||
      alert_modal_display_status ||
      order_complete_modal_display_status ||
      order_response_modal_display_status ||
      chat_modal_display_status ||
      confirm_legal_entity_display_status ||
      add_payment_method_modal_display_status
    ) {
      return true;
    }
  };

  return (
    <>
      <ScrollToTop />
      <div className={`app${noScroll() ? ' noScroll' : ''}`}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {width <= 1024 ? <Header__Mobile /> : <Header />}

            <Switch location={location} key={location.pathname}>
              <Route path='/documents/payment_policy/'>
                <PaymentPolicyPage />
              </Route>
              <Route path='/documents/user_agreement/'>
                <UserAgreementPage />
              </Route>
              <Route path='/documents/confidentiality_policy/'>
                <ConfidentialityPolicyPage />
              </Route>
              <Route path='/faq/all/'>
                <FAQPage />
              </Route>
              <Route path='/faq/about_us/'>
                <AboutUsPage />
              </Route>
              <Route path='/faq/how_to_registrate/'>
                <HowToRegistPage />
              </Route>
              <Route path='/faq/how_to_create_order/'>
                <HowToCreateOrderPage />
              </Route>
              <Route path='/faq/orders_rules/'>
                <OrdersRulesPage />
              </Route>
              <Route path='/faq/what_is_safe_deal/'>
                <WhatIsSafeDealPage />
              </Route>
              <Route path='/faq/chat_rules/'>
                <ChatRulesPage />
              </Route>
              <Route path='/faq/how_to_get_more_orders/'>
                <HowToGetMoreOrdersPage />
              </Route>
              <Route path='/faq/why_we_are_safe/'>
                <WhyWeAreSafePage />
              </Route>
              <Route path='/faq/rates/'>
                <RatesPage />
              </Route>
              <Route path='/faq/how_to_become_executor/'>
                <HowToBecomeExecutor />
              </Route>
              <Route path='/faq/personal_data_safety/'>
                <PersonalDataSafery />
              </Route>

              <Route path={pathServicePage}>
                <ServicePage />
              </Route>
              <Route path={pathErrorPage}>
                <ErrorPage />
              </Route>

              <Route path='/email-verification/:key_id/'>
                <HomePageExecutor />
                <EmailVerificationModal />
              </Route>
              <Route path='/profile/:user_id/'>
                <ProfilePage />
              </Route>

              <Route path='/create_order/'>
                <OrderCreatePage />
              </Route>

              <Route path='/created_orders/'>
                <CustomerOrdersDashboardPage />
              </Route>

              <Route path='/orders/'>
                <ExecutorOrdersDashboardPage />
              </Route>

              <Route path='/order/:order_id'>
                <OrderPage />
              </Route>

              <Route path='/executor/'>
                <HomePageExecutor />
              </Route>

              <Route path='/promo/:key1/:key2/:key3/:key4/:key5/'>
                <PromoPage />
              </Route>
              <Route path='/promo/:key1/:key2/:key3/:key4/'>
                <PromoPage />
              </Route>
              <Route path='/promo/:key1/:key2/:key3/'>
                <PromoPage />
              </Route>
              <Route path='/promo/:key1/:key2/'>
                <PromoPage />
              </Route>
              <Route path='/promo/:key1/'>
                <PromoPage />
              </Route>

              <Route path='/' exact>
                <HomePageCustomer />
              </Route>

              <Route path='/404/'>
                <NotFoundPage />
              </Route>

              <Redirect to='/404/' />
            </Switch>
            <Footer />
            {chat_list_header_display_status && width <= 500 && <ChatListModal />}
            {order_response_modal_display_status && <OrderResponseModal />}
            {order_change_details_modal_display_status && <OrderChangeDetailsModal />}
            {order_complete_modal_display_status && <OrderCompleteModal />}
            {registr_display_status && <Registration />}
            {login_display_status && <Login />}
            {preface_modal_display_status && <ExecutorPrefaceModal />}
            {preface_legal_entity_modal_display_status && <LegalEntityPrefaceModal />}
            {profile_passport_display_status && <ProfilePassportModal />}
            {categories_display_status && <ExecutorCategories />}
            {add_email_display_status && <AddEmailModal />}
            {confirm_legal_entity_display_status && <LegalEntityConfirmModal />}
            {chat_modal_display_status && <ChatModal />}
            {add_payment_method_modal_display_status && <AddPaymentMetodModal />}
            {city_select_modal_display_status && user_id && token && !city_id && !registr_display_status && <CitySelectModal />}
            {/* * Должна быть последней, т.к. может появиться поверх остальных модалок */}
            {alert_modal_display_status && <AlertModal />}
          </>
        )}
      </div>
    </>
  );
}

export default RouterComponent;
