import './WhyWeBetter.scss';
import WhyWeBetter_Icon01 from '../../../../assets/images/WhyWeBetter_Icon01.svg';
import WhyWeBetter_Icon02 from '../../../../assets/images/WhyWeBetter_Icon02.svg';
import WhyWeBetter_Icon03 from '../../../../assets/images/WhyWeBetter_Icon03.svg';
import WhyWeBetter_Icon04 from '../../../../assets/images/WhyWeBetter_Icon04.svg';

import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { executor_categories_modal_display_toggle, preface_modal_display_toggle } from '../../../../actions';

import Button from '../../../shared/Button/Button';

function WhyWeBetter() {
  let currentRoute = useLocation();
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const becomeExecutor = () => {
    if (user.verification_status !== 'verified') {
      dispatch(preface_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем" - 2-ой экран',
      });
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      dispatch(executor_categories_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем ( добавить категории )" - 2-ой экран',
      });
    }
  };

  return (
    <section className={`whyWeBetter ${currentRoute.pathname === '/executor/' ? 'whyWeBetterExecutor' : ''}`}>
      <div className='container'>
        {currentRoute.pathname === '/' ? (
          <>
            <h2 className='h2'>Сэкономьте время и деньги</h2>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon01} className='img' alt='icon' />
                <h3 className='h3'>
                  Быстрая обработка
                  <br /> заказов
                </h3>
              </div>
              <div className='description'>
                Наши умные алгоритмы быстро подберут профессионалов под ваши требования. Не нужно просматривать десятки анкет. Вам останется
                только выбрать лучшего мастера из подходящих.
              </div>
            </div>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon02} className='img' alt='icon' />
                <h3 className='h3'>
                  В рамках вашего
                  <br /> бюджета{' '}
                </h3>
              </div>
              <div className='description'>
                Укажите, сколько вы планируете потратить на решение вашей задачи. Мы подберем выгодные предложения в соответствии с вашим
                бюджетом.
              </div>
            </div>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon03} className='img' alt='icon' />
                <h3 className='h3'>
                  Дополнительная
                  <br /> безопасность
                </h3>
              </div>
              <div className='description'>
                Не хотите рисковать? Закажите услугу через безопасную сделку. Сумма, равная стоимости работы, будет заморожена на вашей
                карте, и спишется только тогда, когда мастер выполнит работу.
              </div>
            </div>
            <Button
              type='link'
              href='/create_order/'
              size='large'
              color='red'
              extraClassName='orderCreateButton'
              width='340rem'
              text='Создать заказ'
            />
          </>
        ) : (
          <>
            <h2 className='h2'>
              Делайте то, что умеете, <br />и зарабатывайте без рисков
            </h2>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon02} className='img' alt='icon' />
                <h3 className='h3'>Достойный доход</h3>
              </div>
              <div className='description'>Вы сами решаете, сколько стоят ваши услуги, и выполняете заказы на выгодных условиях.</div>
            </div>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon01} className='img' alt='icon' />
                <h3 className='h3'>Гибкий график работы</h3>
              </div>
              <div className='description'>
                В какое время выполнять заказы, сколько и какие — зависит только от вашего желания и возможностей.
              </div>
            </div>
            <div className='advantage'>
              <div className='positionContainer'>
                <img src={WhyWeBetter_Icon04} className='img' alt='icon' />
                <h3 className='h3'>Безопасность</h3>
              </div>
              <div className='description'>При работе через Сделку без риска вы гарантированно получите оплату за выполненный заказ.</div>
            </div>
            <Button
              type={user.is_executor ? 'link' : 'button'}
              href='/orders/available/'
              color='red'
              size='large'
              text={user.is_executor ? 'Найти заказ' : 'Стать исполнителем'}
              width={user.is_executor ? '360rem' : '340rem'}
              onClick={() => becomeExecutor()}
              extraClassName='becomeExecutorButton'
            />
          </>
        )}
      </div>
    </section>
  );
}

export default WhyWeBetter;
