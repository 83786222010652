import './Footer.scss';

import { Link, useLocation } from 'react-router-dom';
import { executor_categories_modal_display_toggle, preface_modal_display_toggle } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import ReactGA from 'react-ga';
import logo from '../../../assets/images/logo.svg';

function Footer() {
  let location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const renderCustomerOrdersButtons = () => {
    if (user.is_customer) {
      return (
        <>
          <Link to='/create_order/' className='link'>
            Создать заказ
          </Link>
          <Link to='/created_orders/' className='link'>
            Мои заказы
          </Link>
        </>
      );
    } else {
      return (
        <Link to='/create_order/' className='link'>
          Создать заказ
        </Link>
      );
    }
  };

  const renderExecutorOrdersButtons = () => {
    if (user.verification_status === 'verified' && user.is_executor) {
      return (
        <>
          <Link to='/orders/' className='link'>
            Найти заказ
          </Link>

          <Link to='/orders/in_work/' className='link'>
            Принятые заказы
          </Link>
        </>
      );
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      return (
        <div
          onClick={() => {
            dispatch(executor_categories_modal_display_toggle(true));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Стать исполнителем ( добавить категории )" - футер',
            });
          }}
          className='link'
        >
          Стать исполнителем
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            dispatch(preface_modal_display_toggle(true));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Стать исполнителем" - футер',
            });
          }}
          className='link'
        >
          Стать исполнителем
        </div>
      );
    }
  };

  const isExtraPaddingRequired = () => {
    if (
      location.pathname.indexOf('/profile/') > -1 ||
      location.pathname.indexOf('/created_orders/') > -1 ||
      location.pathname.indexOf('/orders/') > -1
    ) {
      return true;
    }
  };

  return (
    <footer className='footer'>
      <div className={`container${isExtraPaddingRequired() ? ' extraPaddingBottom' : ''}`}>
        <div className='logo-copyrights'>
          <img className='logo' src={logo} alt='logo' />
          <div className='build'>
            {process.env.REACT_APP_BUILD_VERSION ? `Build v.${process.env.REACT_APP_BUILD_VERSION}` : 'Build local'}
          </div>
          <div className='copyrights'>© 2020 - 2021</div>
        </div>
        <div className='footerLinks'>{renderCustomerOrdersButtons()}</div>
        <div className={`footerLinks${user.is_customer ? ' twoLinks' : ' oneLink'}`}>{renderExecutorOrdersButtons()}</div>
        <div className='footerLinks'>
          <Link to='/faq/all/' className='link'>
            Частые вопросы
          </Link>
          <Link to='/faq/about_us/' className='link'>
            О сервисе
          </Link>
          <Link to='/faq/orders_rules/' className='link'>
            Правила работы с заказами
          </Link>
          <Link to='/documents/user_agreement/' className='link'>
            Пользовательское соглашение
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
