import './OrderResponses.scss';

import React from 'react';

import OrderResponse from '../OrderResponse/OrderResponse';

function OrderResponses({ responses, order_selected_executor }) {
  return (
    <div className='orderResponses'>
      {responses && !order_selected_executor
        ? responses.map((response) => <OrderResponse key={`OrderResponse ${response.id}`} response={response} />)
        : responses.map(
            (response) =>
              response.executor.id === order_selected_executor && (
                <OrderResponse key={`OrderResponse ${response.id}`} response={response} order_selected_executor={true} />
              )
          )}
    </div>
  );
}

export default OrderResponses;
