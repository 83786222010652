import './OrdersDashboardTabs.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import ExecutorOrdersDashbordItem from '../../../shared/OrdersDashbordItem/ExecutorOrdersDashbordItem';
import Spinner from '../../../shared/Spinner/Spinner';

function CustomerOrdersDashboardTabs() {
  const user_id = useSelector((state) => state.userReducer.user_id);
  const is_loading = useSelector((state) => state.ordersDashboardsReducer.is_loading);
  const available_orders_all = useSelector((state) => state.ordersDashboardsReducer.available_orders_all);
  const available_orders_you_responeded = useSelector((state) => state.ordersDashboardsReducer.available_orders_you_responeded);
  const available_orders_in_work = useSelector((state) => state.ordersDashboardsReducer.available_orders_in_work);
  const available_orders_archive = useSelector((state) => state.ordersDashboardsReducer.available_orders_archive);

  const { path } = useRouteMatch();
  const pathAvailable = `${path}available/`;
  const pathYouResponded = `${path}you_responded/`;
  const pathInWork = `${path}in_work/`;
  const pathArchive = `${path}archive/`;

  const links = [
    { to: pathAvailable, label: 'Доступные' },
    { to: pathYouResponded, label: 'Вы откликнулись' },
    { to: pathInWork, label: 'В работе' },
    { to: pathArchive, label: 'Архив' },
  ];

  return (
    <>
      <div className='ordersDashboardTabs executorOrdersDashboardTabs'>
        <div className='navTabs'>
          {links.map((link, index) => {
            return (
              <NavLink key={'NavLink' + index} to={link.to} activeClassName='active' className='navTab'>
                <span className='tabName'>{link.label}</span>
                {/* <span className={`tabAmount ${link.label === 'В работе' ? 'active' : ''}`}>{link.number}</span> */}
              </NavLink>
            );
          })}
        </div>
        <div className='tabsContainer'>
          {is_loading ? (
            <Spinner />
          ) : (
            <Switch>
              <Redirect exact from={path} to={pathAvailable} />
              <Route path={pathAvailable}>
                {available_orders_all.length ? (
                  available_orders_all.map((order) => <ExecutorOrdersDashbordItem order={order} key={'All' + order.id} />)
                ) : (
                  <div className='emptyResult'>
                    Сейчас в выбранных вами категориях нет доступных заказов, мы оповестим вас когда они появятся. <br /> Вы можете изменить
                    список рабочих категорий у себя в <Link to={`/profile/${user_id}/data/`}>профиле</Link>.
                  </div>
                )}
              </Route>
              <Route path={pathYouResponded}>
                {available_orders_you_responeded.length ? (
                  available_orders_you_responeded.map((order) => (
                    <ExecutorOrdersDashbordItem order={order} key={'YouResponded' + order.id} />
                  ))
                ) : (
                  <div className='emptyResult'>Нет заказов с активными откликами</div>
                )}
              </Route>
              <Route path={pathInWork}>
                {available_orders_in_work.length ? (
                  available_orders_in_work.map((order) => <ExecutorOrdersDashbordItem order={order} key={'InWork' + order.id} />)
                ) : (
                  <div className='emptyResult'>Нет заказов в работе</div>
                )}
              </Route>
              <Route path={pathArchive}>
                {available_orders_archive.length ? (
                  available_orders_archive.map((order) => <ExecutorOrdersDashbordItem order={order} key={'Archive' + order.id} />)
                ) : (
                  <div className='emptyResult'>Нет заказов в архиве</div>
                )}
              </Route>
            </Switch>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerOrdersDashboardTabs;
