import FAQ_HowToCreateOrder from '../../assets/images/howToCreateOrder.png';

import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';
import { Link } from 'react-router-dom';

function PersonalDataSafery() {
  return (
    <main className='howToCreateOrder'>
      <DocumentContent>
        <h1>Когда можно оставлять личные данные, а когда не стоит</h1>
        <p>
          Многие сайты, в том числе signedUP, просят сообщить данные вашего удостоверения личности и прислать фотографии документа, чтобы
          убедиться, что вы указали достоверную информацию. Но иногда так делают и мошенники. Рассказываем, зачем мошенникам нужны ваши
          данные и как отличить добропорядочные сайты от опасных.
        </p>
        <p>
          Распространенная практика, когда пользователь присылает фотографии своего удостоверения личности, чтобы подтвердить что он — это
          действительно он, вызывает много споров. С одной стороны, это очень удобно, не нужно никуда идти, отправляете фото, администрация
          сайта сравнивает данные в анкете с данными в документе, вуаля, ваш профиль подтвержден. С другой стороны, многие не хотят
          оставлять где-либо свои данные, опасаясь, что они попадут в руки мошенников.
        </p>
        <p>
          Давайте разбираться, как отличить обычный сайт от поддельного, как может выглядеть мошенническая схема, а также зачем преступникам
          фото ваших документов.
        </p>
        <p>
          <b>Подтверждение личности</b>
        </p>
        <p>
          Представьте, вы получили письмо от банка, социальной сети или любого другого сайта, в котором вас просят для большей безопасности
          (или по какой-либо другой причине) подтвердить свою личность.
        </p>
        <p>
          Вы кликаете ссылку из письма и переходите на страницу с формой, в которую вам нужно ввести номер удостоверения личности, логин и
          пароль от вашего профиля, домашний адрес, телефон или информацию о банковской карте. Кроме того, иногда требуется фото страниц
          вашего удостоверения личности или селфи с открытым документом в руках.{' '}
        </p>
        <p>На этом месте стоит остановиться и подумать. Возможно, это мошенники. </p>
        <p>
          <b>Зачем мошенникам ваши фото удостоверения личности</b>
        </p>
        <p>Чтобы они смогли зарегистрироваться от вашего имени на некоторых сервисах, например, на криптовалютных биржах.</p>
        <p>
          Фото удостоверения личности можно выгодно продать на черном рынке, а селфи с паспортом стоят еще дороже. Продавец при этом
          получает солидную сумму, а покупатель — возможность совершать нелегальные операции от вашего имени, например, отмывать деньги,
          полученные нелегальным путем. В результате у вас могут возникнуть проблемы с законом. Перспектива не очень, правда?{' '}
        </p>
        <p>
          <b>Основные признаки мошенничества</b>
        </p>
        <p>
          Есть и хорошие новости. Чаще всего мошенники — далеко не самые внимательные к деталям люди. Если хорошенько изучить письмо и
          форму, на которую ведет ссылка, можно найти много подозрительных вещей.
        </p>
        <ol>
          <li>
            <span>1.</span>
            <span>
              <b>Ошибки и опечатки</b>
              <br /> Часто форма и письмо составлены не очень грамотным языком, с грамматическими ошибками и опечатками. Как правило,
              написанием текстов на официальных сайтах занимаются редакторы или контент-менеджеры, у которых с грамотностью все в порядке.
              Мошенники же сочиняют письма на скорую руку, не особо заботясь о стиле и ошибках.
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>
              <b>Спешка и сжатые сроки</b>
              <br /> Иногда мошенники пытаются принудить вас действовать очень быстро, иначе ваш аккаунт будет заблокирован или будут
              применены другие санкции. Добропорядочные компании вряд ли станут навязывать жесткие временные рамки при подтверждении вашей
              личности, а вот мошенники могут использовать этот трюк, чтобы вы не успели внимательно изучить детали и заметить противоречия.
            </span>
          </li>
          <li>
            <span>3.</span>
            <span>
              <b>Давление и требование</b>
              <br /> Обычные сервисы предложат вам доступ к расширенному функционалу сайта и расскажут обо всех преимуществах
              подтвержденного аккаунта. Как правило, от них приходят сообщения в форме предложения, от которого можно отказаться. Мошенники
              же действуют иначе. В форме, которая открывается по ссылке из мошеннического письма, чаще всего одна кнопка, означающая, что
              без фото удостоверения личности продолжить не получится.
            </span>
          </li>
          <li>
            <span>4.</span>
            <span>
              <b>Странный адрес отправителя письма</b>
              <br /> Если письмо пришло с бесплатного почтового сервиса, или имя отправителя никак не связано с компанией, от имени которой
              отправлено письмо, это тоже тревожный звонок. У настоящих компаний после символа «@» стоит название, например, для проекта
              signedUP почта, с которой может прийти письмо, выглядит так: support@signedup.me. Если в адресе отправителя указано что-то
              наподобие 777example849304@gmail.com, лучше проигнорировать такое письмо.
            </span>
          </li>{' '}
          <li>
            <span>5.</span>
            <span>
              <b>Подозрительный адрес сайта</b>
              <br /> Всегда обращайте внимание на адрес сайта в адресной строке браузера. Иногда мошенники создают сайт, похожий на
              оригинальный сайт компании, но меняют один или пару символов в адресной строке.
              <br /> Например: <br />
              https://signedup.me/ — оригинальный сайт <br /> А вот так может выглядеть адрес его клона, созданного мошенниками: <br />
              http://sainedup.me/ <br /> Кроме того, на мошеннических сайтах вас могут попросить приложить фотографии с помощью сторонних
              сервисов, например, загрузить их в облако и отправить ссылку.
            </span>
          </li>{' '}
          <li>
            <span>6.</span>
            <span>
              <b>Повторный запрос информации</b>
              <br /> Если какая-либо компания в письме просит предоставить фото паспорта, однако вы уверены, что уже отправляли все
              необходимые сведения ранее, при регистрации, есть вероятность, что перед вами мошенники. А в случае, если сообщение пришло от
              банка, вероятность, что это мошенники, еще выше. Открыв счет, вы уже подтвердили свою личность, и банку незачем запрашивать
              ваши данные по почте еще раз.
            </span>
          </li>{' '}
          <li>
            <span>7.</span>
            <span>
              <b>Нет информации о необходимости удостоверения личности на официальном сайте</b>
              <br /> Когда какой-либо сервис принимает решение работать только с подтвержденными пользователями, информация об этом сразу
              появляется на официальном сайте. Поэтому первое, что стоит сделать, получив письмо с просьбой подтвердить личность, это зайти
              на сайт отправителя письма и посмотреть новости компании. Там наверняка будет подробно расписано, с какого момента и для каких
              целей требуется дополнительная проверка подлинности. Если же на официальном сайте нет ни слова о новых правилах, лучше
              проявить бдительность и не отправлять персональные данные.
            </span>
          </li>
        </ol>

        <p>
          При любых сомнениях вы можете позвонить или написать в техподдержку сервиса, отправившего e-mail. При этом никогда не используйте
          контакты, указанные в подозрительном письме. Поищите контакты на официальном сайте компании или задайте вопрос в чате с
          поддержкой.{' '}
        </p>
      </DocumentContent>
    </main>
  );
}

export default PersonalDataSafery;
