import './OrderCreatePhotos.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { order_creation_image1_change, order_creation_image2_change, order_creation_image3_change } from '../../../../actions';
import { acceptImageTypes } from '../../../../const';
import Button from '../../../shared/Button/Button';
import Files from '../../../shared/Files/Files';

function OrderCreatePhotos() {
  const { order_creation_image1, order_creation_image2, order_creation_image3 } = useSelector((state) => state.orderCreationReducer);
  const [images, setImages] = useState([
    { value: order_creation_image1, url: '', action: order_creation_image1_change },
    { value: order_creation_image2, url: '', action: order_creation_image2_change },
    { value: order_creation_image3, url: '', action: order_creation_image3_change },
  ]);
  const dispatch = useDispatch();
  const appendPhoto = (files, image) => {
    if (files && files.length) {
      const file = files.item(0);
      if (file) {
        dispatch(image.action(file));
        if (image.url) {
          URL.revokeObjectURL(image.url);
        }
        const blob = new Blob([file.slice()], { type: file.type });
        image.value = file;
        image.url = URL.createObjectURL(blob);
      }
    }
  };

  const removePhoto = (image) => {
    if (image.url) {
      URL.revokeObjectURL(image.url);
    }
    image.value = null;
    image.url = '';

    const update = [...images];

    images.forEach((entry, index, array) => {
      const next = index + 1;
      if (!entry.value && next < images.length) {
        const swap = { ...entry };
        update[index].value = array[next].value;
        update[index].url = array[next].url;
        update[next].value = swap.value;
        update[next].url = swap.url;
      }
    });
    update.forEach((entry) => dispatch(entry.action(entry.value)));

    setImages(update);
  };

  if (images.some((image) => !!image.value)) {
    return (
      <div className='orderCreatePhotos'>
        {images.map((image, index, array) => {
          if (image.value instanceof File) {
            return (
              <div key={index} className='orderCreatePhotoPreview' style={{ backgroundImage: `url('${image.url}')` }}>
                <div className='orderCreatePhotoDelete' onClick={() => removePhoto(image)}></div>
              </div>
            );
          } else if (index && array[index - 1].value) {
            return (
              <Files key={index} onUpdate={(files) => appendPhoto(files, image)} accept={acceptImageTypes}>
                <div className='orderCreatePhotoAdd'></div>
              </Files>
            );
          }
          return undefined;
        })}
      </div>
    );
  }

  return (
    <Files onUpdate={(files) => appendPhoto(files, images[0])} accept={acceptImageTypes}>
      <Button color='white' size='normal' text='Загрузить фото' width='170rem' extraClassName='orderCreateAppendPhoto' />
    </Files>
  );
}

export default OrderCreatePhotos;
