import './ProfileSideBlockData.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { add_email_display_toggle, profile_passport_display_status_change } from '../../../../actions';
import { createMaskForPhone } from '../../../../functions/Masks';

/**
 * menu block
 * @param {Object} props
 * @param {boolean} props.confirmed block is confirmed
 * @param {boolean} [props.status] block status visibility
 * @param {string} props.icon block icon
 * @param {string} [props.label] block label
 * @param {string} [props.value] block value
 * @param {string} [props.action] block action name
 * @param {() => void} [props.onClick] onClick handler
 * @param {boolean} [props.editable] block is editable
 */
function Block(props) {
  const { confirmed, status, icon, label, value, action, onClick, editable } = props;

  return (
    <div className={`block ${editable ? 'editable' : ''}`}>
      <div className={`icon ${icon} ${confirmed ? 'confirmed' : ''}`}></div>
      <div
        className='info'
        onClick={() => {
          if (editable && typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        {confirmed ? (
          <>
            <div className='label'>{label}</div>
            <div className='value'>{value}</div>
          </>
        ) : (
          <div className='action'>{action}</div>
        )}
      </div>
      <div className='status'>{status && <div className='sign'></div>}</div>
    </div>
  );
}

/**
 * profile side block user's data component
 */
function ProfileSideBlockData() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const [verificationStatus, setVerificationStatus] = useState('');
  const dispatch = useDispatch();

  const showEmail = () => (user.public && user.email_verified) || !user.public;

  useEffect(() => {
    if (user.verification_status === 'verified') {
      setVerificationStatus('Подтверждён');
    } else if (user.verification_status === 'not_verified') {
      setVerificationStatus('Не подтверждён');
    } else if (user.verification_status === 'in_progress') {
      setVerificationStatus('Подтверждается');
    } else if (user.verification_status === 'declined') {
      setVerificationStatus('Отклонён');
    } else {
      setVerificationStatus('');
    }
  }, [user.verification_status]);

  return (
    <div className='profileSideBlockData'>
      <div className='caption'>Подтвержденные данные</div>
      <div className='data'>
        <Block
          confirmed={user.phone_verified}
          status={user.phone_verified}
          icon='phone'
          label='Телефон'
          value={user.public ? '* *** *** ** **' : createMaskForPhone(user.phone)}
        />
        {showEmail() && (
          <>
            <div className='divider'></div>            
            <Block
              confirmed={user.email_verified || (user.email_verification_status === 'in_progress')}
              status={user.email_verified}
              icon='email'
              label='E-mail'
              value={user.public ? '************' : user.email_verification_status === 'in_progress' ? 'Подтверждается' : user.email}
              action='Добавить почту'
              onClick={() => dispatch(add_email_display_toggle(true))}
              editable={!user.public}
            />
          </>
        )}
        <div className='divider'></div>
        <Block
          confirmed={
            user.verification_status === 'verified' || user.verification_status === 'in_progress' || user.verification_status === 'declined'
          }
          status={user.verification_status === 'verified'}
          icon='passport'
          label='Паспорт'
          value={verificationStatus}
          action='Добавить паспорт'
          onClick={() => dispatch(profile_passport_display_status_change(true, true))}
          editable={!user.public && user.verification_status === 'not_verified'}
        />
      </div>
    </div>
  );
}

export default ProfileSideBlockData;
