/**
 * * Глобальные переменные
 **/

// * Сохранить токен
export const GLOBAL_VARIABLES_SET_TOKEN = 'global_variables_set_token';

// * Сохранить user_id
export const GLOBAL_VARIABLES_SET_USER_ID = 'global_variables_set_user_id';

/**
 * * Пререлиз
 **/

// * Показать приветсвенную модалку для пререлиза
export const PRERELEASE_MODAL_DISPLAY_TOGGLE = 'prerelease_modal_display_toggle';

/**
 * * Регистрация, авторизация
 **/

// * Показать / скрыть модальное окно регистрации
export const REGISTR_MODAL_DISPLAY_TOGGLE = 'registr_modal_display_toggle';

// * Измение значения полей модального онка регистрации
export const REGISTR_MODAL_FIRST_NAME_VALUE_CHANGE = 'registr_modal_first_name_value_change';
export const REGISTR_MODAL_LAST_NAME_VALUE_CHANGE = 'registr_modal_last_name_value_change';
export const REGISTR_MODAL_PHONE_VALUE_CHANGE = 'registr_modal_phone_value_change';
export const REGISTR_MODAL_OTP_VALUE_CHANGE = 'registr_modal_otp_value_change';

// * Получить ОТП ( проверочный смс-код ) при регистрации
export const REGISTR_MODAL_GET_OTP = 'reigster_modal_get_otp';
export const REGISTR_MODAL_GET_OTP_SUCCESS = 'register_modal_get_otp_success';
export const REGISTR_MODAL_GET_OTP_FAIL = 'register_modal_get_otp_fail';

// * Подтвердить ОТП ( проверочный смс-код ) и зарегистрироваться
export const REGISTR_MODAL_CONFIRM_OTP = 'register_modal_confirm_otp';
export const REGISTR_MODAL_CONFIRM_OTP_SUCCESS = 'register_modal_confirm_otp_success';
export const REGISTR_MODAL_CONFIRM_OTP_FAIL = 'register_modal_confirm_otp_fail';

// * Показать / скрыть модальное окно логина
export const LOGIN_MODAL_DISPLAY_TOGGLE = 'login_modal_display_toggle';

// * Измение значения полей модального онка логина
export const LOGIN_MODAL_PHONE_VALUE_CHANGE = 'login_modal_phone_value_change';
export const LOGIN_MODAL_OTP_VALUE_CHANGE = 'login_modal_otp_value_change';

// * Получить ОТП ( проверочный смс-код ) при логине
export const LOGIN_MODAL_GET_OTP = 'login_modal_get_otp';
export const LOGIN_MODAL_GET_OTP_SUCCESS = 'login_modal_get_otp_success';
export const LOGIN_MODAL_GET_OTP_FAIL = 'login_modal_get_otp_fail';

// * Подтвердить ОТП ( проверочный смс-код ) и залогиниться
export const LOGIN_MODAL_CONFIRM_OTP = 'login_modal_confirm_otp';
export const LOGIN_MODAL_CONFIRM_OTP_SUCCESS = 'login_modal_confirm_otp_success';
export const LOGIN_MODAL_CONFIRM_OTP_FAIL = 'login_modal_confirm_otp_fail';

/**
 * * Выход
 **/

// * Глобальный logout
export const LOGOUT = 'logout';

/**
 * * Выбор города
 **/

export const CITY_SELECT_MODAL_DISPLAY_TOGGLE = 'city_select_modal_display_toggle';
export const CITY_SELECT_MODAL_SELECTED_CITY_ID_VALUE_CHANGE = 'city_select_modal_selected_city_id_value_change';
export const CITY_SELECT_MODAL_SUBMIT = 'city_select_modal_submit';
export const CITY_SELECT_MODAL_SUBMIT_SUCCESS = 'city_select_modal_submit_success';
export const CITY_SELECT_MODAL_SUBMIT_FAIL = 'city_select_modal_submit_fail';

/**
 * * Создание заказа
 **/

// * Измение значения полей при создании заказа
export const ORDER_CREATION_TITLE_VALUE_CHANGE = 'order_creation_title_value_change';
export const ORDER_CREATION_CATEGORY_ID_VALUE_CHANGE = 'order_creation_category_id_value_change';
export const ORDER_CREATION_SUBCATEGORY_ID_VALUE_CHANGE = 'order_creation_subcategory_id_value_change';
export const ORDER_CREATION_DESCRIPTION_VALUE_CHANGE = 'order_creation_description_value_change';
export const ORDER_CREATION_EXECUTOR_PLACE_VALUE_CHANGE = 'order_creation_executor_place_value_change';
export const ORDER_CREATION_PLACE_VALUE_CHANGE = 'order_creation_place_value_change';
export const ORDER_CREATION_CITY_ID_VALUE_CHANGE = 'order_creation_city_id_value_change';
export const ORDER_CREATION_EXECUTOR_DATE_VALUE_CHANGE = 'order_creation_executor_data_value_change';
export const ORDER_CREATION_DATE_VALUE_CHANGE = 'order_creation_date_value_change';
export const ORDER_CREATION_TIME_VALUE_CHANGE = 'order_creation_time_value_change';
export const ORDER_CREATION_NEED_FAST_VALUE_CHANGE = 'order_creation_need_fast_value_change';
export const ORDER_CREATION_EXECUTOR_PRICE_VALUE_CHANGE = 'order_creation_executor_price_value_change';
export const ORDER_CREATION_PRICE_MIN_VALUE_CHANGE = 'order_creation_price_min_value_change';
export const ORDER_CREATION_PRICE_MAX_VALUE_CHANGE = 'order_creation_price_max_value_change';
export const ORDER_CREATION_FIRST_NAME_VALUE_CHANGE = 'order_creation_first_name_value_change';
export const ORDER_CREATION_LAST_NAME_VALUE_CHANGE = 'order_creation_last_name_value_change';
export const ORDER_CREATION_PHONE_VALUE_CHANGE = 'order_creation_phone_value_change';
export const ORDER_CREATION_SEND_EMAIL_VALUE_CHANGE = 'order_creation_send_email_value_change';
export const ORDER_CREATION_SAFE_PAYMENT_VALUE_CHANGE = 'order_creation_safe_payment_value_change';
export const ORDER_CREATION_ONLY_YUR_VALUE_CHANGE = 'order_creation_only_yur_value_change';
export const ORDER_CREATION_IMAGE1_VALUE_CHANGE = 'order_creation_image1_value_change';
export const ORDER_CREATION_IMAGE2_VALUE_CHANGE = 'order_creation_image2_value_change';
export const ORDER_CREATION_IMAGE3_VALUE_CHANGE = 'order_creation_image3_value_change';

// * Создать заказ
export const ORDER_CREATION_CREATE = 'order_creation_create';
export const ORDER_CREATION_CREATE_SUCCESS = 'order_creation_create_success';
export const ORDER_CREATION_CREATE_FAIL = 'order_creation_create_fail';

/**
 * * Дашборд заказов
 **/

// * Дашборд заказов для заказчика
export const CUSTOMER_ORDERS_DASHBOARD_LOAD = 'customer_orders_dashboard_load';
export const CUSTOMER_ORDERS_DASHBOARD_LOAD_SUCCESS = 'customer_orders_dashboard_success';
export const CUSTOMER_ORDERS_DASHBOARD_LOAD_FAIL = 'customer_orders_dashboard_load_fail';

// * Дашборд заказов для исполнителя
export const EXECUTOR_ORDERS_DASHBOARD_LOAD = 'executor_orders_dashboard_load';
export const EXECUTOR_ORDERS_DASHBOARD_LOAD_SUCCESS = 'executor_orders_dashboard_success';
export const EXECUTOR_ORDERS_DASHBOARD_LOAD_FAIL = 'executor_orders_dashboard_load_fail';

/**
 * * Действия с заказом
 **/

// * Загрузить заказ
export const ORDER_LOAD = 'order_load';
export const ORDER_LOAD_SUCCESS = 'order_load_success';
export const ORDER_LOAD_FAIL = 'order_load_fail';

// * Уточнить / изменить детали заказа
export const ORDER_CHANGE_DETAILS_MODAL_DISPLAY_TOGGLE = 'order_change_details_modal_display_toggle';
export const ORDER_CHANGE_DETAILS_MODAL_PRICE_VALUE_CHANGE = 'order_change_details_modal_price_value_change';
export const ORDER_CHANGE_DETAILS_MODAL_CONFIRM = 'order_change_details_modal_confirm';
export const ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS = 'order_change_details_modal_confirm_success';
export const ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL = 'order_change_details_modal_confirm_fail';

// * Отмена заказа
export const ORDER_CANCEL = 'order_cancel';
export const ORDER_CANCEL_SUCCESS = 'order_cancel_success';
export const ORDER_CANCEL_FAIL = 'order_cancel_fail';

// * Создать ещё раз
export const ORDER_CREATE_AGAIN = 'order_create_again';
export const ORDER_CREATE_AGAIN_SUCCESS = 'order_create_again_success';
export const ORDER_CREATE_AGAIN_FAIL = 'order_create_again_fail';

// * Изменение отображения заказа в зависимости от действий пользователя
export const ORDER_USER_INFLICTED_STATUS_CHANGE = 'order_user_inflicted_status_change';

// * Выбор исполнителя
export const ORDER_SELECT_EXECUTOR = 'order_select_executor';
export const ORDER_SELECT_EXECUTOR_CONFIRM = 'order_select_executor_confirm';
export const ORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS = 'order_select_executor_confirm_success';
export const ORDER_SELECT_EXECUTOR_CONFIRM_FAIL = 'order_select_executor_confirm_fail';

// * Внести деньги по БС
export const ORDER_SAFEDEAL_GET_FORM_DATA = 'order_safedeal_get_form_data';
export const ORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS = 'order_safedeal_get_form_data_success';
export const ORDER_SAFEDEAL_GET_FORM_DATA_FAIL = 'order_safedeal_get_form_data_fail';

// * Завершение заказа
export const ORDER_COMPLETE_MODAL_DISPLAY_TOGGLE = 'order_complete_modal_display_toggle';
export const ORDER_COMPLETE_CONFIRM = 'order_complete_confirm';
export const ORDER_COMPLETE_CONFIRM_SUCCESS = 'order_complete_confirm_success';
export const ORDER_COMPLETE_CONFIRM_FAIL = 'order_complete_confirm_fail';
export const ORDER_REVIEW_SEND = 'order_review_send';
export const ORDER_REVIEW_SEND_SUCCESS = 'order_review_send_success';
export const ORDER_REVIEW_SEND_FAIL = 'order_review_send_fail';

/**
 * * Отклик на заказ
 **/

export const ORDER_RESPONSE_MODAL_DISPLAY_TOGGLE = 'order_response_modal_display_toggle';
export const ORDER_RESPONSE_MODAL_RESPONSE_TEXT_VALUE_CHANGE = 'order_response_modal_response_text_value_change';
export const ORDER_RESPONSE_MODAL_ORDER_ID_VALUE_CHANGE = 'order_response_modal_order_id_value_change';
export const ORDER_RESPONSE_MODAL_SUBMIT = 'order_response_modal_submit';
export const ORDER_RESPONSE_MODAL_SUBMIT_SUCCESS = 'order_response_modal_submit_success';
export const ORDER_RESPONSE_MODAL_SUBMIT_FAIL = 'order_response_modal_submit_fail';

/**
 * * Чат с пользователем
 **/
export const CHAT_MODAL_DISPLAY_TOGGLE = 'chat_modal_display_toggle';
export const CHAT_LIST_HEADER_DISPLAY_TOGGLE = 'chat_list_header_display_toggle';
export const CHAT_LIST_LOAD = 'chat_list_load';
export const CHAT_LIST_LOAD_SUCCESS = 'chat_list_load_success';
export const CHAT_LIST_LOAD_FAIL = 'chat_list_load_fail';
export const CHAT_LOAD_HISTORY = 'chat_load_history';
export const CHAT_LOAD_HISTORY_SUCCESS = 'chat_load_history_success';
export const CHAT_LOAD_HISTORY_FAIL = 'chat_load_history_fail';
export const CHAT_MESSAGE_VALUE_CHANGE = 'chat_message_value_change';
export const CHAT_SEND_MESSAGE = 'chat_send_message';
export const CHAT_SEND_MESSAGE_SUCCESS = 'chat_send_message_success';
export const CHAT_SEND_MESSAGE_FAIL = 'chat_send_message_fail';
export const CHAT_READ_MESSAGE = 'chat_read_message';
export const CHAT_READ_MESSAGE_SUCCESS = 'chat_read_message_success';
export const CHAT_READ_MESSAGE_FAIL = 'chat_read_message_fail';

/**
 * * Добавление банковской карты
 **/
export const ADD_PAYMENT_METHOD_MODAL_DISPLAY_TOGGLE = 'add_payment_method_modal_display_toogle';
export const ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA = 'add_payment_method_modal_get_form_data ';
export const ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS = 'add_payment_method_modal_get_form_data_success';
export const ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL = 'add_payment_method_modal_get_form_data_fail';

export const NOTIFICATIONS_DISPLAY_TOGGLE = 'notifications_display_toggle';

/**
 * Страница профиля
 */
export const PROFILE_LOAD = 'profile_load';
export const PROFILE_LOAD_SUCCESS = 'profile_load_success';
export const PROFILE_LOAD_ERROR = 'profile_load_error';
export const PROFILE_SAVE = 'profile_save';
export const PROFILE_SAVE_SUCCESS = 'profile_save_success';
export const PROFILE_SAVE_ERROR = 'profile_save_error';
export const PROFILE_PHOTO_CHANGE = 'profile_photo_change';
export const PROFILE_SUBSCRIBED_NEWS_CHANGE = 'profile_subscribed_news_change';
export const PROFILE_SUBSCRIBED_NOTIFICATIONS_CHANGE = 'profile_subscribed_notifications_change';
export const PROFILE_FIRST_NAME_VALUE_CHANGE = 'profile_first_name_value_change';
export const PROFILE_MIDDLE_NAME_VALUE_CHANGE = 'profile_middle_name_value_change';
export const PROFILE_LAST_NAME_VALUE_CHANGE = 'profile_last_name_value_change';
export const PROFILE_BIRTHDAY_VALUE_CHANGE = 'profile_birthday_value_change';
export const PROFILE_GENDER_VALUE_CHANGE = 'profile_gender_value_change';
export const PROFILE_CITY_VALUE_CHANGE = 'profile_city_value_change';
export const PROFILE_ABOUT_VALUE_CHANGE = 'profile_about_value_change';
export const PROFILE_RESET = 'profile_reset';
export const PROFILE_EMAIL_VALUE_CHANGE = 'profile_email_value_change';
export const PROFILE_EMAIL_SUBMIT = 'profile_email_submit';
export const PROFILE_EMAIL_SUBMIT_SUCCESS = 'profile_email_submit_success';
export const PROFILE_EMAIL_SUBMIT_FAIL = 'profile_email_submit_fail';

/**
 * passport load modal
 */
export const PROFILE_PASSPORT_SAVE = 'profile_passport_save';
export const PROFILE_PASSPORT_SAVE_SUCCESS = 'profile_passport_save_success';
export const PROFILE_PASSPORT_SAVE_ERROR = 'profile_passport_save_error';
export const PROFILE_PASSPORT_DISPLAY_STATUS_CHANGE = 'profile_passport_display_status_change';
export const PROFILE_PASSPORT_FIRST_NAME_CHANGE = 'profile_passport_first_name_change';
export const PROFILE_PASSPORT_LAST_NAME_CHANGE = 'profile_passport_last_name_change';
export const PROFILE_PASSPORT_SURNAME_CHANGE = 'profile_passport_surname_change';
export const PROFILE_PASSPORT_BIRTHDAY_CHANGE = 'profile_passport_birthday_change';
export const PROFILE_PASSPORT_GENDER_CHANGE = 'profile_passport_gender_change';
export const PROFILE_PASSPORT_CITY_CHANGE = 'profile_passport_city_change';
export const PROFILE_PASSPORT_ADDRESS_CHANGE = 'profile_passport_address_change';
export const PROFILE_PASSPORT_IMAGE1_CHANGE = 'profile_passport_image1_change';
export const PROFILE_PASSPORT_IMAGE2_CHANGE = 'profile_passport_image2_change';
export const PROFILE_PASSPORT_IMAGE3_CHANGE = 'profile_passport_image3_change';
export const PROFILE_PASSPORT_IMAGE1_ERROR_CHANGE = 'profile_passport_image1_error_change';
export const PROFILE_PASSPORT_IMAGE2_ERROR_CHANGE = 'profile_passport_image2_error_change';
export const PROFILE_PASSPORT_IMAGE3_ERROR_CHANGE = 'profile_passport_image3_error_change';

/**
 * header actions
 */
export const HEADER_MENU_DISPLAY_TOGGLE = 'header_menu_display_toggle';
export const HEADER_MOBILE_MENU_DISPLAY_TOGGLE = 'header_mobile_menu_display_toggle';
export const HEADER_MENU_LOGOUT = 'header_menu_logout';

/**
 *  preface modal actions
 */
export const PREFACE_MODAL_DISPLAY_TOGGLE = 'preface_modal_display_toggle';

/**
 * legal entity preface modal actions
 */
export const PREFACE_LEGAL_ENTITY_MODAL_DISPLAY_TOGGLE = 'preface_legal_entity_modal_display_toggle';

/**
 * executor categories modal actions
 */
export const EXECUTOR_CATEGORIES_MODAL_DISPLAY_TOGGLE = 'executor_categories_modal_display_toggle';
export const EXECUTOR_CATEGORIES_MODAL_SAVE = 'executor_categories_modal_save';
export const EXECUTOR_CATEGORIES_MODAL_SAVE_SUCCESS = 'executor_categories_modal_save_success';
export const EXECUTOR_CATEGORIES_MODAL_SAVE_ERROR = 'executor_categories_modal_save_error';

/**
 * add email modal actions
 */
export const ADD_EMAIL_DISPLAY_TOGGLE = 'add_email_display_toggle';

/**
 * global loader actions
 */
export const LOADER_DISPLAY_TOGGLE = 'loader_display_toggle';

/**
 * user actions
 */
export const USER_LOAD = 'user_load';
export const USER_LOAD_SUCCESS = 'user_load_success';
export const USER_LOAD_ERROR = 'user_load_error';
export const USER_PHOTO_VALUE_CHANGE = 'user_photo_value_change';
export const USER_NEED_TO_BE_UPDATED = 'user_need_to_be_updated';

/**
 * profile categories actions
 */
export const PROFILE_CATEGORIES_LOAD = 'profile_categories_load';
export const PROFILE_CATEGORIES_LOAD_SUCCESS = 'profile_categories_load_success';
export const PROFILE_CATEGORIES_LOAD_ERROR = 'profile_categories_load_error';

/**
 * profile portfolio actions
 */
export const PROFILE_PORTFOLIO_LOAD = 'profile_portfolio_load';
export const PROFILE_PORTFOLIO_LOAD_SUCCESS = 'profile_portfolio_load_success';
export const PROFILE_PORTFOLIO_LOAD_ERROR = 'profile_portfolio_load_error';
export const PROFILE_PORTFOLIO_SAVE = 'profile_portfolio_save';
export const PROFILE_PORTFOLIO_SAVE_SUCCESS = 'profile_portfolio_save_success';
export const PROFILE_PORTFOLIO_SAVE_ERROR = 'profile_portfolio_save_error';
export const PROFILE_PORTFOLIO_DELETE = 'profile_portfolio_delete';
export const PROFILE_PORTFOLIO_DELETE_SUCCESS = 'profile_portfolio_delete_success';
export const PROFILE_PORTFOLIO_DELETE_ERROR = 'profile_portfolio_delete_error';
export const PROFILE_PORTFOLIO_START_CHANGE = 'profile_portfolio_start_change';
export const PROFILE_PORTFOLIO_COUNT_CHANGE = 'profile_portfolio_count_change';
export const PROFILE_PORTFOLIO_RESET = 'profile_portfolio_reset';

/**
 * alert modal actions
 */
export const ALERT_MODAL_DISPLAY_TOGGLE = 'alert_modal_display_toggle';
export const ALERT_MODAL_TITLE_CHANGE = 'alert_modal_title_change';
export const ALERT_MODAL_EXTRA_CLASS_NAME_CHANGE = 'alert_modal_extra_class_name_change';
export const ALERT_MODAL_CONTENT_CHANGE = 'alert_modal_content_change';
export const ALERT_MODAL_BUTTONS_CHANGE = 'alert_modal_buttons_change';
export const ALERT_MODAL_SETTINGS_CHANGE = 'alert_modal_settings_change';

/**
 * profile header actions
 */
export const PROFILE_HEADER_LITE_MODE_CHANGE = 'profile_header_lite_mode_change';
export const PROFILE_HEADER_MENU_MODE_CHANGE = 'profile_header_menu_mode_change';

/**
 * profile feedback actions
 */
export const PROFILE_FEEDBACKS_LOAD = 'profile_feedbacks_load';
export const PROFILE_FEEDBACKS_LOAD_SUCCESS = 'profile_feedbacks_load_success';
export const PROFILE_FEEDBACKS_LOAD_ERROR = 'profile_feedbacks_load_error';
export const PROFILE_FEEDBACKS_START_CHANGE = 'profile_feedbacks_start_change';
export const PROFILE_FEEDBACKS_COUNT_CHANGE = 'profile_feedbacks_count_change';

/**
 * order info actions
 */
export const ORDER_INFO_GALLERY_DISPLAY_TOGGLE = 'order_info_gallery_display_toggle';

/**
 * profile identity modal actions
 */
export const PROFILE_IDENTITY_MODAL_DISPLAY_TOGGLE = 'profile_identity_modal_display_toggle';
export const PROFILE_IDENTITY_MODAL_IS_LOADING_VALUE_CHANGE = 'profile_identity_modal_is_loading_value_change';
export const PROFILE_IDENTITY_MODAL_SAVE = 'profile_identity_modal_save';
export const PROFILE_IDENTITY_MODAL_SAVE_SUCCESS = 'profile_identity_modal_save_success';
export const PROFILE_IDENTITY_MODAL_SAVE_ERROR = 'profile_identity_modal_save_error';

/**
 * legal entity confirm modal actions
 */
export const LEGAL_ENTITY_CONFIRM_MODAL_DISPLAY_TOGGLE = 'legal_entity_confirm_modal_display_toggle';
export const LEGAL_ENTITY_CONFIRM_BIN_VALUE_CHANGE = 'legal_entity_confirm_bin_value_change';
export const LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE = 'legal_entity_confirm_image_value_change';
export const LEGAL_ENTITY_CONFIRM_SAVE = 'legal_entity_confirm_save';
export const LEGAL_ENTITY_CONFIRM_SAVE_SUCCESS = 'legal_entity_confirm_save_success';
export const LEGAL_ENTITY_CONFIRM_SAVE_ERROR = 'legal_entity_confirm_save_error';
