import { HEADER_MENU_DISPLAY_TOGGLE, HEADER_MOBILE_MENU_DISPLAY_TOGGLE } from './types';

export const header_mobile_menu_display_toggle = (payload) => ({
  type: HEADER_MOBILE_MENU_DISPLAY_TOGGLE,
  payload,
});

export const header_menu_display_toggle = (payload) => ({
  type: HEADER_MENU_DISPLAY_TOGGLE,
  payload,
});
