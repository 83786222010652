import './ProfileOverview.scss';

import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import ProfileCategories from '../ProfileCategories/ProfileCategories';
import ProfileFeedback from '../ProfileFeedback/ProfileFeedback';
import ProfileHeaderRatings from '../ProfileHeader/ProfileHeaderRatings';
import ProfilePortfolio from '../ProfilePortfolio/ProfilePortfolio';
import ProfilePortfolioViewer from '../ProfilePortfolioViewer/ProfilePortfolioViewer';
import ProfileRating from '../ProfileRating/ProfileRating';
import { useSelector } from 'react-redux';

/**
 * profile overview component
 */
function ProfileOverview() {
  /** @type {import('../../../../typedef').TUserDescription} */
  const { user, images_count } = useSelector((state) => state.profileReducer);
  /** @type {import('../../../../typedef').TTaskData} */
  const task_data = useSelector((state) => state.profileReducer.task_data);
  /** @type {import('../../../../typedef').TRatingsData} */
  const ratings_data = useSelector((state) => state.profileReducer.ratings_data);
  const { url } = useRouteMatch();
  const history = useHistory();

  /** redirect to root profile path if there are some elements after user_id param */
  useEffect(() => {
    if (window && window.location.href.match(/\d\//i)) {
      history.push(url);
    }
  }, [url, history]);

  return (
    <Switch>
      <Route exact path={url}>
        <div className='profileOverview'>
          <div className='overviewRatings'>
            {/* user is customer */}
            {user.is_customer && !user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Создано заказов', mark: task_data.customer.tasks_count },
                  { label: 'Рейтинг', icon: true, mark: ratings_data.customer_rate || 0 },
                ]}
              />
            )}
            {/* user is executor */}
            {!user.is_customer && user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: task_data.executor.tasks_count },
                  { label: 'Рейтинг', icon: true, mark: ratings_data.executor_rate || 0 },
                ]}
              />
            )}
            {/* user is customer & executor */}
            {user.is_customer && user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: task_data.executor.tasks_count, details: ratings_data.executor_rate || 0 },
                  { label: 'Создано заданий', mark: task_data.customer.tasks_count, details: ratings_data.customer_rate || 0 },
                ]}
              />
            )}
            {/* special case */}
            {!user.is_customer && !user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: 0, details: 0 },
                  { label: 'Создано заданий', mark: 0, details: 0 },
                ]}
              />
            )}
          </div>
          <div className='about'>
            <div className='label'>О себе</div>
            <div className='value'>{user.about || 'Не заполнено.'}</div>
          </div>
          {user.is_executor && <ProfileCategories />}
          {user.is_executor && !!images_count && <ProfilePortfolio />}
          <ProfileRating
            extraClassName='ratings'
            emptyBlockCaption='Исполнители высказывают мнение о заказчиках по критериям: "Адекватность", "Вежливость", "Пунктуальность".'
          />
          <ProfileFeedback extraClassName='feedback' emptyBlockCaption='Отзывы появятся после того, как вы успешно выполните заказ.' />
        </div>
      </Route>
      <Route path={`${url}/portfolio/:image_id?`}>
        <ProfilePortfolioViewer />
      </Route>
    </Switch>
  );
}

export default ProfileOverview;
