import './Safedeal.scss';
import Safedeal_icon01 from '../../../../assets/images/Safedeal_icon01.svg';
import Safedeal_icon02 from '../../../../assets/images/Safedeal_icon02.svg';

import React from 'react';

function Safedeal() {
  return (
    <>
      <section className='safedeal'>
        <div className='container'>
          <div className='illustrationImage' />
          <h2 className='h2'>
            Подробнее о<br /> безопасности
          </h2>
          <div className='description'>
            Каждому заказчику хочется гарантий, что его заявка будет выполнена добросовестным мастером, а мастеру — что его труд будет
            оплачен. Именно поэтому мы предусмотрели две опции для дополнительной защиты наших пользователей.
          </div>
        </div>
      </section>
      <section className='safedealAdvanatages'>
        <div className='container'>
          <div className='safedealAdvanatage'>
            <div className='positionContainer'>
              <img src={Safedeal_icon01} className='img' alt='icon' />
              <h3 className='h3'>Сделка без риска</h3>
            </div>
            <div className='description'>
              Мы добавили эту функцию, чтобы мастер мог гарантированно получить оплату за выполненный заказ.
            </div>
          </div>
          <div className='safedealAdvanatage'>
            <div className='positionContainer'>
              <img src={Safedeal_icon02} className='img' alt='icon' />
              <h3 className='h3'>Помощь в спорной ситуации</h3>
            </div>
            <div className='description'>
              Наша поддержка пользователей готова прийти на помощь в спорной ситуации, разобраться в причинах и урегулировать конфликт между заказчиком и исполнителем.
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Safedeal;
