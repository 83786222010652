import './Select.scss';

import React, { useEffect, useState } from 'react';

import onClickOutside from 'react-onclickoutside';
import selectorIcon from '../../../assets/images/selectArrow_icon.svg';

/**
 * dropdown control
 * @param {Object} props props of control
 * @param {string} props.name unique control name
 * @param {string} props.label label for control
 * @param {import('../../../typedef').TOption[]} props.options list of items
 * @param {number} [props.selection] selected item
 * @param {string} [props.extraClassName] additional CSS class name
 * @param {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} props.onClick item click handler
 * @param {boolean} [props.disabled] control disabled
 * @param {boolean} [props.required] input required
 * @param {boolean} [props.requiredStatusDescription] show required status explanation
 * @param {boolean} [props.noLabel] hide label
 */
function Select(props) {
  const { name, label, options, selection, extraClassName, onClick, disabled, required, requiredStatusDescription, noLabel } = props;
  const [dropdownActive, setDropdown] = useState(false);
  const [selectorText, setSelectorText] = useState('');

  useEffect(() => {
    setSelectorText('');
    if (options && options.length > 0) {
      if (typeof selection === 'number') {
        const value = options.find((option) => option.id === selection || option.city_id === selection);
        value && setSelectorText(value.title);
      }
    } else {
      setSelectorText('');
    }
  }, [selection, options]);

  /**
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event mouse click event
   */
  const onClickOption = (event) => {
    onClick(event);
    setDropdown(false);
  };

  /**
   * extract id
   * @param {import('../../../typedef').TOption} option option data
   */
  const extract_id = (option) => {
    if (typeof option.city_id === 'number') {
      return option.city_id;
    }
    return option.id;
  };

  const titleDisplayCustomize = (title) => {
    switch (title) {
      case 'Ремонтные и мелкие строительные работы':
        return (
          <>
            Ремонтные
            <br /> и мелкие строительные работы
          </>
        );
      case 'Онлайн задачи (дизайн, разработка, полиграфия)':
        return (
          <>
            Онлайн задачи
            <br /> (дизайн, разработка, полиграфия)
          </>
        );
      default:
        return title;
    }
  };

  // ! https://github.com/Pomax/react-onclickoutside/issues/329
  // ! Workaround чтобы использовать react-onclickoutside, хуки, и несколько инстансев одного компонента на странице.
  Select['handleClickOutside_' + name] = () => setDropdown(false);

  return (
    <div className={`fieldset select ${extraClassName || ''} ${dropdownActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}>
      {!noLabel && (
        <label className='label' htmlFor={name}>
          <span className='text'>{label}&nbsp;</span>
          {required && <span className='required'>*</span>}
          {requiredStatusDescription && (
            <span className='requiredStatusDescription'>
              <span className='required'>*&nbsp;</span>Обязательные поля
            </span>
          )}
        </label>
      )}
      <div onClick={() => setDropdown(!dropdownActive)} className={`selector ${extraClassName || ''}`} id={name}>
        <span className='selectorText'>{selectorText}</span>
        <img className='selectorIcon' src={selectorIcon} alt='select' />
      </div>
      <div className={`selectDropdownContainer ${extraClassName || ''}`}>
        <div className={`selectDropdown ${extraClassName || ''}`}>
          {options instanceof Array &&
            options.map((option, key) => (
              <div
                className={`selectOption ${extraClassName || ''}`}
                onClick={(event) => onClickOption(event)}
                key={`Option: ${key}`}
                id={extract_id(option)}
              >
                {titleDisplayCustomize(option.title)}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => Select['handleClickOutside_' + props.name],
};

export default onClickOutside(Select, clickOutsideConfig);
