import './Spinner.scss';

import React from 'react';

/**
 * spinner component
 * @param {Object} props
 * @param {string} [props.size] spinner size
 * @param {string} [props.color] spinner color
 * @param {string} [props.extraClassName] additional CSS class name
 */
function Spinner(props) {
  const { size, color, extraClassName } = props;

  return (
    <div className={`spinner ${size || ''} ${color || ''}`}>
      <div className={`icon animated ${extraClassName || ''}`}></div>
    </div>
  );
}

export default Spinner;
