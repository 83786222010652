export const createMaskForPhone = (phone) => {
  let phoneWithMask;
  if (phone.length >= 11) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 11)}`;
    return phoneWithMask;
  } else if (phone.length > 9) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9)}`;
    return phoneWithMask;
  } else if (phone.length === 9) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)}`;
    return phoneWithMask;
  } else if (phone.length > 7) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7)}`;
    return phoneWithMask;
  } else if (phone.length === 7) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)}`;
    return phoneWithMask;
  } else if (phone.length > 4) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4)}`;
    return phoneWithMask;
  } else if (phone.length === 4) {
    phoneWithMask = `+ ${phone.slice(0, 1)} ${phone.slice(1, 4)}`;
    return phoneWithMask;
  } else if (phone.length > 1) {
    phoneWithMask = `+ 7 ${phone.slice(1)}`;
    return phoneWithMask;
  } else if (phone.length <= 1) {
    phoneWithMask = `+ 7 `;
    return phoneWithMask;
  }
};

export const createMaskForTime = (time) => {
  let timeWithMask;
  if (time.length > 2) {
    if (parseInt(time.slice(0, 2)) > 24) {
      timeWithMask = `${time.slice(0, 1)}:${time.slice(1, 3)}`;
    } else {
      timeWithMask = `${time.slice(0, 2)}:${time.slice(2, 4)}`;
    }
    return timeWithMask;
  } else if (time.length === 2) {
    if (parseInt(time.slice(0, 2)) > 24) {
      timeWithMask = `${time.slice(0, 1)}:${time.slice(1)}`;
    } else {
      timeWithMask = `${time.slice(0, 2)}`;
    }
    return timeWithMask;
  } else if (time.length === 1) {
    timeWithMask = `${time.slice(0, 1)}`;
    return timeWithMask;
  } else if (time.length > 0) {
    timeWithMask = `${time.slice(0)}`;
    return timeWithMask;
  }
};

export const createMaskForPrice = (price) => {
  let priceWithMask;
  if (price.length >= 9) {
    priceWithMask = `₸ ${price.slice(0, 3)}.${price.slice(3, 6)}.${price.slice(6, 9)}`;
    return priceWithMask;
  } else if (price.length === 8) {
    priceWithMask = `₸ ${price.slice(0, 2)}.${price.slice(2, 5)}.${price.slice(5, 8)}`;
    return priceWithMask;
  } else if (price.length === 7) {
    priceWithMask = `₸ ${price.slice(0, 1)}.${price.slice(1, 4)}.${price.slice(4, 7)}`;
    return priceWithMask;
  } else if (price.length === 6) {
    priceWithMask = `₸ ${price.slice(0, 3)}.${price.slice(3, 6)}`;
    return priceWithMask;
  } else if (price.length === 5) {
    priceWithMask = `₸ ${price.slice(0, 2)}.${price.slice(2, 5)}`;
    return priceWithMask;
  } else if (price.length === 4) {
    priceWithMask = `₸ ${price.slice(0, 1)}.${price.slice(1, 4)}`;
    return priceWithMask;
  } else if (price.length > 0) {
    priceWithMask = `₸ ${price.slice(0)}`;
    return priceWithMask;
  } else if (price.length === 0) {
    priceWithMask = `₸ `;
    return priceWithMask;
  }
};
