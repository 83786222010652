import {
  PROFILE_ABOUT_VALUE_CHANGE,
  PROFILE_BIRTHDAY_VALUE_CHANGE,
  PROFILE_CITY_VALUE_CHANGE,
  PROFILE_EMAIL_SUBMIT,
  PROFILE_EMAIL_SUBMIT_FAIL,
  PROFILE_EMAIL_SUBMIT_SUCCESS,
  PROFILE_EMAIL_VALUE_CHANGE,
  PROFILE_FIRST_NAME_VALUE_CHANGE,
  PROFILE_GENDER_VALUE_CHANGE,
  PROFILE_LAST_NAME_VALUE_CHANGE,
  PROFILE_LOAD,
  PROFILE_LOAD_ERROR,
  PROFILE_LOAD_SUCCESS,
  PROFILE_MIDDLE_NAME_VALUE_CHANGE,
  PROFILE_PHOTO_CHANGE,
  PROFILE_RESET,
  PROFILE_SAVE,
  PROFILE_SAVE_ERROR,
  PROFILE_SAVE_SUCCESS,
  PROFILE_SUBSCRIBED_NEWS_CHANGE,
  PROFILE_SUBSCRIBED_NOTIFICATIONS_CHANGE,
} from './types';

import axios from 'axios';
import { format } from 'date-fns';

/**
 * load profile
 * @param {string} id profile id
 */
export const profile_load = (id) => {
  return (dispatch) => {
    dispatch({ type: PROFILE_LOAD });
    axios({
      method: 'GET',
      url: `${global.backendURL}users/user/${id}/`,
    })
      .then((response) => {
        dispatch({
          type: PROFILE_LOAD_SUCCESS,
          payload: {
            ...response.data,
            user: {
              ...response.data.user,
              email: response.data.user.email || '',
              about: response.data.user.about || '',
              birthday: response?.data?.user?.birthday === null ? '' : new Date(response?.data?.user?.birthday),
            },
            user_id: id,
          },
        });
        global.debug && console.log('Загрузка данных пользователя - Success:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: PROFILE_LOAD_ERROR });
        global.debug && console.log('Загрузка данных пользователя - Error:');
        global.debug && console.log(error.response);
      });
  };
};

/**
 * save user profile
 * @param {number} id
 * @param {import('../typedef').TUser} user
 */
export const profile_save = (id, user) => {
  return (dispatch) => {
    dispatch({ type: PROFILE_SAVE });

    const data = new FormData();

    user.photo instanceof File && data.append('image', user.photo);
    user.birthday && data.append('birthday', format(user.birthday, 'yyyy-MM-dd'));
    user.gender && data.append('gender', user.gender.toString());
    user.about && data.append('about', user.about);
    user.city_id && data.append('city_id', user.city_id);
    user.subscribed_notifications !== undefined && data.append('subscribed_notifications', user.subscribed_notifications);
    user.subscribed_notifications !== undefined && data.append('send_email', user.send_email);
    user.subscribed_news !== undefined && data.append('subscribed_news', user.subscribed_news);
    user.first_name && data.append('first_name', user.first_name);
    user.surname && data.append('surname', user.surname);
    user.last_name && data.append('last_name', user.last_name);

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${id}/`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => dispatch({ type: PROFILE_SAVE_SUCCESS }))
      .catch((error) => dispatch({ type: PROFILE_SAVE_ERROR, payload: error.response.data }));
  };
};

/**
 * @param {File | string} payload
 */
export const profile_photo_change = (payload) => ({
  type: PROFILE_PHOTO_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_first_name_value_change = (payload) => ({
  type: PROFILE_FIRST_NAME_VALUE_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_middle_name_value_change = (payload) => ({
  type: PROFILE_MIDDLE_NAME_VALUE_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_last_name_value_change = (payload) => ({
  type: PROFILE_LAST_NAME_VALUE_CHANGE,
  payload,
});

/**
 * @param {Date} payload
 */
export const profile_birthday_value_change = (payload) => ({
  type: PROFILE_BIRTHDAY_VALUE_CHANGE,
  payload,
});

/**
 * @param {number} payload
 */
export const profile_gender_value_change = (payload) => ({
  type: PROFILE_GENDER_VALUE_CHANGE,
  payload,
});

/**
 * @param {number} payload
 */
export const profile_city_value_change = (payload) => ({
  type: PROFILE_CITY_VALUE_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_about_value_change = (payload) => ({
  type: PROFILE_ABOUT_VALUE_CHANGE,
  payload,
});

/**
 * @param {boolean} payload
 */
export const profile_subscribed_news_change = (payload) => ({
  type: PROFILE_SUBSCRIBED_NEWS_CHANGE,
  payload,
});

/**
 * @param {boolean} payload
 */
export const profile_subscribed_notifications_change = (payload) => ({
  type: PROFILE_SUBSCRIBED_NOTIFICATIONS_CHANGE,
  payload,
});

export const profile_reset = () => ({
  type: PROFILE_RESET,
});

/**
 * @param {string} payload
 */
export const profile_email_value_change = (payload) => ({
  type: PROFILE_EMAIL_VALUE_CHANGE,
  payload,
});

export const profile_email_submit = (id, email) => {
  return (dispatch) => {
    dispatch({ type: PROFILE_EMAIL_SUBMIT });
    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${id}/`,
      data: {
        email,
      },
    })
      .then(() => dispatch({ type: PROFILE_EMAIL_SUBMIT_SUCCESS }))
      .catch((error) => dispatch({ type: PROFILE_EMAIL_SUBMIT_FAIL }));
  };
};
