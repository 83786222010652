import {
  ADD_PAYMENT_METHOD_MODAL_DISPLAY_TOGGLE,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL }
  from '../actions/types';

const INITIAL_STATE = {
  add_payment_method_modal_display_status: false,

  add_payment_method_modal_form_data: undefined,
  add_payment_method_modal_form_data_is_loading: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PAYMENT_METHOD_MODAL_DISPLAY_TOGGLE:
      return { ...state, add_payment_method_modal_display_status: action.payload };
    case ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA:
      return { ...state, add_payment_method_modal_form_data_is_loading: true };
    case ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS:
      return { ...state, add_payment_method_modal_form_data_is_loading: false, add_payment_method_modal_form_data: action.payload };
    case ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL:
      return { ...state, add_payment_method_modal_form_data_is_loading: false };
    default:
      return { ...state };
  }
};
