import './LegalEntityConfirmModal.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  legal_entity_confirm_bin_value_change,
  legal_entity_confirm_image_value_change,
  legal_entity_confirm_modal_display_toggle,
  legal_entity_confirm_save,
  profile_load,
} from '../../../actions';
import { acceptImageTypes } from '../../../const';

import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Modal from '../../shared/Modal/Modal';
import PortfolioImage from '../../shared/PortfolioImage/PortfolioImage';
import Spinner from '../../shared/Spinner/Spinner';

function LegalEntityConfirmModal() {
  const history = useHistory();
  /** @type {import('../../../typedef').TUserDescription} */
  const { user_id } = useSelector((state) => state.userReducer);
  /** @type {import('../../../typedef').TConfirmLegalEntityModalReducer} */
  const { confirm_legal_entity_bin: bin, confirm_legal_entity_image: image, is_complete, is_loading } = useSelector(
    (state) => state.legalEntityConfirmModalReducer
  );

  const [url, setUrl] = useState('');
  /** @type {import('../../../typedef').TPortfolioImage} */
  const imageSettings = {
    accept: acceptImageTypes,
    canDelete: !!image,
    placeholder: !image,
    deleteCallback: () => dispatch(legal_entity_confirm_image_value_change(null)),
    uploadCallback: (file) => dispatch(legal_entity_confirm_image_value_change(file)),
    url,
  };
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => setDisabled(!bin), [bin]);

  useEffect(() => {
    if (url) {
      URL.revokeObjectURL(url);
    }
    if (image) {
      const blob = new Blob([image.slice()], { type: image.type });
      setUrl(URL.createObjectURL(blob));
    }
  }, [image]);

  useEffect(() => {
    if (is_complete) {
      dispatch(legal_entity_confirm_modal_display_toggle(false));
      dispatch(profile_load(user_id));
    }
  }, [is_complete, user_id, dispatch]);

  return (
    <Modal extraClassName='legalEntityConfirmModal' onClose={() => dispatch(legal_entity_confirm_modal_display_toggle(false))}>
      {is_loading && <Spinner />}
      <div className='title'>Данные юридического лица</div>
      <div className='sensitiveWarning'>Если юридическое лицо зарегистрировано на ваше имя, достаточно указать его БИН.</div>
      <Input
        type='text'
        extraClassName='legalEntityInput'
        label='БИН юридического лица'
        value={bin}
        onChange={(event) => dispatch(legal_entity_confirm_bin_value_change(event.target.value))}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        guide={false}
      />
      <div className='scansTitle'>
        Если юридическое лицо зарегистрировано не на вас, приложите фото&nbsp;/&nbsp;сканы <br />
        документов, подтверждающих, что вы являетесь представителем данного
        <br />
        юридического лица.
      </div>
      <PortfolioImage {...imageSettings} />
      <Button
        color='red'
        size='normal'
        text='Продолжить'
        disabled={disabled}
        type='button'
        onClick={() => dispatch(legal_entity_confirm_save({ bin, image, user_id }, history))}
        extraClassName='legalEntityButton'
      />
      <div className='disclaimer'>
        Нажимая «Продолжить», вы подтверждаете, <br /> что указали о себе достоверные данные и согласны на обработку персональных данных в{' '}
        <br />
        соответствии с нашей{' '}
        <Link to='/documents/confidentiality_policy/' target='_blank' className='accent'>
          Политикой&nbsp;конфиденциальности
        </Link>
        .
      </div>
    </Modal>
  );
}

export default LegalEntityConfirmModal;
