import './OrdersDashboardTabs.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import CustomerOrdersDashbordItem from '../../../shared/OrdersDashbordItem/CustomerOrdersDashbordItem';
import Spinner from '../../../shared/Spinner/Spinner';

function CustomerOrdersDashboardTabs() {
  const is_loading = useSelector((state) => state.ordersDashboardsReducer.is_loading);
  const created_orders_all = useSelector((state) => state.ordersDashboardsReducer.created_orders_all);
  const created_orders_processing = useSelector((state) => state.ordersDashboardsReducer.created_orders_processing);
  const created_orders_in_work = useSelector((state) => state.ordersDashboardsReducer.created_orders_in_work);
  const created_orders_completed = useSelector((state) => state.ordersDashboardsReducer.created_orders_completed);

  const { path } = useRouteMatch();
  const pathAll = `${path}all/`;
  const pathCompleted = `${path}completed/`;
  const pathInWork = `${path}in_work/`;
  const pathProcessing = `${path}procesing/`;

  const links = [
    { to: pathAll, label: 'Все' },
    { to: pathProcessing, label: 'В обработке' },
    { to: pathInWork, label: 'В работе' },
    { to: pathCompleted, label: 'Исполнены' },
  ];

  return (
    <div className='ordersDashboardTabs customerOrdersDashboardTabs'>
      <div className='navTabs'>
        {links.map((link, index) => {
          return (
            <NavLink key={index} to={link.to} activeClassName='active' className='navTab'>
              <span className='tabName'>{link.label}</span>
              {/* <span className={`tabAmount ${link.label === 'В работе' ? 'active' : ''}`}>{link.number}</span> */}
            </NavLink>
          );
        })}
      </div>
      <div className='tabsContainer'>
        {is_loading ? (
          <Spinner />
        ) : (
          <Switch>
            <Redirect exact from={path} to={pathAll} />
            <Route path={pathAll}>
              {created_orders_all.length > 0 ? (
                created_orders_all.map((order) => <CustomerOrdersDashbordItem order={order} key={'All' + order.id} />)
              ) : (
                <div className='emptyResult'>Нет истории заказов</div>
              )}
            </Route>
            <Route path={pathProcessing}>
              {created_orders_processing.length > 0 ? (
                created_orders_processing.map((order) => <CustomerOrdersDashbordItem order={order} key={'All' + order.id} />)
              ) : (
                <div className='emptyResult'>Нет заказов в обработке</div>
              )}
            </Route>
            <Route path={pathInWork}>
              {created_orders_in_work.length > 0 ? (
                created_orders_in_work.map((order) => <CustomerOrdersDashbordItem order={order} key={'All' + order.id} />)
              ) : (
                <div className='emptyResult'>Нет заказов в работе</div>
              )}
            </Route>
            <Route path={pathCompleted}>
              {created_orders_completed.length > 0 ? (
                created_orders_completed.map((order) => <CustomerOrdersDashbordItem order={order} key={'All' + order.id} />)
              ) : (
                <div className='emptyResult'>Нет исполненных заказов</div>
              )}
            </Route>
          </Switch>
        )}
      </div>
    </div>
  );
}

export default CustomerOrdersDashboardTabs;
