import axios from 'axios';
import {
  ORDER_LOAD,
  ORDER_LOAD_SUCCESS,
  ORDER_LOAD_FAIL,
  ORDER_CANCEL,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CREATE_AGAIN,
  ORDER_CREATE_AGAIN_SUCCESS,
  ORDER_CREATE_AGAIN_FAIL,
  ORDER_SAFEDEAL_GET_FORM_DATA,
  ORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS,
  ORDER_SAFEDEAL_GET_FORM_DATA_FAIL,
  ORDER_USER_INFLICTED_STATUS_CHANGE,
  ORDER_SELECT_EXECUTOR,
  ORDER_SELECT_EXECUTOR_CONFIRM,
  ORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS,
  ORDER_SELECT_EXECUTOR_CONFIRM_FAIL,
  ORDER_COMPLETE_MODAL_DISPLAY_TOGGLE,
  ORDER_COMPLETE_CONFIRM,
  ORDER_COMPLETE_CONFIRM_SUCCESS,
  ORDER_COMPLETE_CONFIRM_FAIL,
  ORDER_REVIEW_SEND,
  ORDER_REVIEW_SEND_SUCCESS,
  ORDER_REVIEW_SEND_FAIL,
  ORDER_CHANGE_DETAILS_MODAL_DISPLAY_TOGGLE,
  ORDER_CHANGE_DETAILS_MODAL_PRICE_VALUE_CHANGE,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS,
  ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL,
} from './types';

// * Загрузка заказа
export const order_load = (order_id, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_LOAD });
    axios({
      method: 'get',
      url: global.backendURL + `tasks/task/${order_id}/`,
    })
      .then((result) => {
        dispatch({ type: ORDER_LOAD_SUCCESS, payload: result.data });
        global.debug && console.log('%cORDER_LOAD_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_LOAD_FAIL });
        global.debug && console.log('%cORDER_LOAD_FAIL:', 'color: red');
        global.debug && console.log(error.response);
        history.push('/500/');
      });
  };
};

export const order_user_inflicted_status_change = (status) => {
  return { type: ORDER_USER_INFLICTED_STATUS_CHANGE, payload: status };
};

// * Изменение / учтонение деталей заказа
export const order_change_details_modal_display_toggle = (status) => {
  return { type: ORDER_CHANGE_DETAILS_MODAL_DISPLAY_TOGGLE, payload: status };
};
export const order_change_details_modal_price_value_change = (price) => {
  return { type: ORDER_CHANGE_DETAILS_MODAL_PRICE_VALUE_CHANGE, payload: price.replace(/[^0-9]/g, '') };
};
export const order_change_details_modal_confirm = (order_id, price, notifications, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/`,
      data: {
        price_min: price,
        price_max: price,
        executor_price: false,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS });
        global.debug && console.log('%cORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

export const order_change_notifications_behaviour = (order_id, notifications, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/`,
      data: {
        send_email: notifications,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS });
        global.debug && console.log('%cORDER_CHANGE_DETAILS_MODAL_CONFIRM_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_CHANGE_DETAILS_MODAL_CONFIRM_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

// * Выбор исполнителя
export const order_select_executor = (executor_id) => {
  return { type: ORDER_SELECT_EXECUTOR, payload: executor_id };
};

export const order_select_executor_confirm = (order_id, executor_id, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_SELECT_EXECUTOR_CONFIRM });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/offer/${executor_id}/`,
    })
      .then((result) => {
        dispatch({ type: ORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS });
        global.debug && console.log('%cORDER_SELECT_EXECUTOR_CONFIRM_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_SELECT_EXECUTOR_CONFIRM_FAIL });
        global.debug && console.log('%cORDER_SELECT_EXECUTOR_CONFIRM_FAIL:', 'color: red;');
        history.push('/500/');
        global.debug && console.log(error.response);
      });
  };
};

// * Отмена заказа
export const order_cancel = (order_id, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_CANCEL });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/`,
      data: {
        cancel: true,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_CANCEL_SUCCESS });
        history.push('/created_orders/');
        global.debug && console.log('%cORDER_CANCEL_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_CANCEL_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_CANCEL_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

// * Создать ещё раз
export const order_create_again = (order_id, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_CREATE_AGAIN });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/reopen/`,
    })
      .then((result) => {
        dispatch({ type: ORDER_CREATE_AGAIN_SUCCESS });
        history.push(`/order/${result.data.task_id}/`);
        global.debug && console.log('%cORDER_CREATE_AGAIN_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_CREATE_AGAIN_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_CREATE_AGAIN_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

// * Получаем данные для скрытых полей формы оплаты БС
export const order_safedeal_get_form_data = (task_id, return_url) => {
  return (dispatch) => {
    dispatch({ type: ORDER_SAFEDEAL_GET_FORM_DATA });
    axios({
      method: 'post',
      url: global.backendURL + `wallet/pay/`,
      data: {
        task_id,
        return_url,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS, payload: result.data });
        global.debug && console.log('%cORDER_SAFEDEAL_GET_FORM_DATA_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_SAFEDEAL_GET_FORM_DATA_FAIL, payload: error.data });
        global.debug && console.log('%cORDER_SAFEDEAL_GET_FORM_DATA_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

// * Модалка для преподтверждение успешного / некуспешного завершения заказа
export const order_complete_modal_display_toggle = ({ display, status }) => {
  return { type: ORDER_COMPLETE_MODAL_DISPLAY_TOGGLE, payload: { display, status } };
};
export const order_complete_confrim = (order_id, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_COMPLETE_CONFIRM });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/complete/`,
      data: {
        completed: true,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_COMPLETE_CONFIRM_SUCCESS });
        global.debug && console.log('%cORDER_COMPLETE_CONFIRM_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_COMPLETE_CONFIRM_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_COMPLETE_CONFIRM_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

// * Оставить отзыв
export const order_review_send = (order_id, decency, adequacy, punctuality, feedback_text, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_REVIEW_SEND });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id}/complete/`,
      data: {
        completed: true,
        decency,
        adequacy,
        punctuality,
        feedback_text,
      },
    })
      .then((result) => {
        dispatch({ type: ORDER_REVIEW_SEND_SUCCESS });
        global.debug && console.log('%cORDER_REVIEW_SEND_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_REVIEW_SEND_FAIL });
        history.push('/500/');
        global.debug && console.log('%cORDER_REVIEW_SEND_FAIL:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};
