import './ProfileStars.scss';

import React from 'react';

/**
 * graphical representation of rate
 * @param {Object} props props of component
 * @param {number} props.rate profile rate from 0.0 to 5.0
 * @param {string} [props.extraClassName] additional CSS class name
 */
function ProfileStars(props) {
  const { rate, extraClassName } = props;

  return (
    <div className={`profileStars ${extraClassName || ''}`}>
      <div className='background'>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
        <div className='star'></div>
      </div>
      <div className='foreground' style={{ width: `${100 - Math.floor(rate * 20)}%` }}></div>
    </div>
  );
}

export default ProfileStars;
