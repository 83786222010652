import './ProfileSideBlock.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import SafeDealBanner from '../../../shared/SafeDealBanner/SafeDealBanner';
import ProfileSideBlockActions from '../ProfileSideBlockActions/ProfileSideBlockActions';
import ProfileSideBlockData from '../ProfileSideBlockData/ProfileSideBlockData';

/**
 * profile side block
 */
function ProfileSideBlock() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);

  return (
    <div className='profileSideBlock'>
      {(user.verification_status !=='verified' || user.law_verification_status !== "verified" ) && <ProfileSideBlockActions />}
      {(user.verification_status !=='verified' || user.law_verification_status !== "verified") && <div className='blockDivider'></div>}
      <ProfileSideBlockData />
      <div className='blockDivider'></div>
      <SafeDealBanner />
    </div>
  );
}

export default ProfileSideBlock;
