import './ProfileHeaderRatings.scss';

import React from 'react';

/**
 * @typedef {Object} TProfileHeaderRating
 * @property {string} label block label
 * @property {boolean} [icon] display icon
 * @property {number} [mark] display mark
 * @property {number} [details] display details
 */

/**
 * header ratings block
 * @param {Object} props props of component
 * @param {[TProfileHeaderRating, TProfileHeaderRating]} props.blocks blocks data description
 */
function ProfileHeaderRatings(props) {
  const { blocks } = props;

  return (
    <div className='userStats'>
      <div className='bar'></div>
      {blocks instanceof Array &&
        blocks.length &&
        blocks.map((block, index) => (
          <React.Fragment key={index}>
            <div className='block'>
              <div className='title'>{block.label}</div>
              <div className='value'>
                {block.icon && <div className='icon'></div>}
                {typeof block.mark === 'number' && <div className='mark'>{block.mark}</div>}
                {typeof block.details === 'number' && (
                  <div className='details'>
                    <div className='icon'></div>
                    <div className='rate'>{block.details}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='bar'></div>
          </React.Fragment>
        ))}
    </div>
  );
}

export default ProfileHeaderRatings;
