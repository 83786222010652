import './OrderChangeDetailsModal.scss';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  order_change_details_modal_display_toggle,
  order_change_details_modal_price_value_change,
  order_change_details_modal_confirm,
} from '../../../../actions';
import { placeholderPrice } from '../../../../const';
import createNumberMask from 'text-mask-addons/src/createNumberMask';

import Modal from '../../../shared/Modal/Modal';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Button/Button';

function OrderChangeDetailsModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const price = useSelector((state) => state.orderReducer.order_change_details_modal_changed_price_value);
  const maskPrice = createNumberMask({ prefix: '₸ ', thousandsSeparatorSymbol: '.', integerLimit: 7 });
  const order_id = useSelector((state) => state.orderReducer.order.id);
  console.log(order_id);

  return (
    <Modal extraClassName='orderChangeDetailsModal' onClose={() => dispatch(order_change_details_modal_display_toggle(false))}>
      <div className='title'>Уточнить стоимость</div>
      <Input
        type='text'
        name='price'
        label='Цена'
        value={price}
        onChange={(event) => dispatch(order_change_details_modal_price_value_change(event.target.value))}
        mask={maskPrice}
        placeholder={placeholderPrice}
      />
      <Button
        type='button'
        onClick={() => {
          dispatch(order_change_details_modal_confirm(order_id, price, history));
        }}
        size='normal'
        color='red'
        text='Сохранить'
        width='300rem'
      />
    </Modal>
  );
}

export default OrderChangeDetailsModal;
