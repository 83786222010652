import './Loader.scss';

import React from 'react';

/**
 * loader component
 * @param {Object} props props of component
 * @param {string} [props.extraClassName] additional CSS class name
 */
function Loader(props) {
  const { extraClassName } = props;

  return (
    <div className='loader'>
      <div className={`loaderSpinner ${extraClassName || ''}`}>
        <img alt='loading...' />
      </div>
    </div>
  );
}

export default Loader;
