import './OrderResponseModal.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  order_response_modal_display_toggle,
  order_response_modal_response_text_value_change,
  order_response_modal_submit,
} from '../../../../actions';
import { useHistory } from 'react-router-dom';

import Button from '../../../shared/Button/Button';
import Input from '../../../shared/Input/Input';
import Modal from '../../../shared/Modal/Modal';

function OrderResponseModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const order_id_value = useSelector((state) => state.orderResponseModalReducer.order_response_modal_order_id_value);
  const response_text_value = useSelector((state) => state.orderResponseModalReducer.order_response_modal_response_text_value);

  const disabled = () => {
    if (response_text_value.trim().length >= 10) {
      return false;
    }
    return true;
  };

  return (
    <Modal extraClassName='orderResponseModal' onClose={() => dispatch(order_response_modal_display_toggle(false))}>
      <div className='title'>Отправить отклик</div>
      <div className='subtitle'>Напишите заказчику пару слов о себе и о своем желании выполнить заказ. </div>
      <Input
        type='textarea'
        name='orderResponseTextarea'
        extraClassName='orderResponseModalTextarea'
        placeholder='Минимум 10 символов.'
        value={response_text_value}
        onChange={(event) => dispatch(order_response_modal_response_text_value_change(event.target.value))}
      />
      <Button
        color='red'
        size='normal'
        text='Откликнуться'
        width='400rem'
        extraClassName='orderResponseModalSubmit'
        disabled={disabled()}
        onClick={() => dispatch(order_response_modal_submit(order_id_value, response_text_value, history))}
      />
    </Modal>
  );
}

export default OrderResponseModal;
