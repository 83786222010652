import '../../../shared/Datepicker/DatepickerCustom.scss';
import './OrderCreateForm.scss';
import 'react-datepicker/dist/react-datepicker.css';

import DatePicker, { registerLocale } from 'react-datepicker';
import React, { useEffect, useState } from 'react';
import { maskAddress, maskName, maskPhone, placeholderPhone, placeholderPrice } from '../../../../const';
import {
  order_creation_category_id_value_change,
  order_creation_city_id_value_change,
  order_creation_create,
  order_creation_date_value_change,
  order_creation_description_value_change,
  order_creation_executor_date_value_change,
  order_creation_executor_place_value_change,
  order_creation_executor_price_value_change,
  order_creation_first_name_value_change,
  order_creation_last_name_value_change,
  order_creation_need_fast_value_change,
  order_creation_only_yur_value_change,
  order_creation_phone_value_change,
  order_creation_place_value_change,
  order_creation_price_max_value_change,
  order_creation_price_min_value_change,
  order_creation_safe_payment_value_change,
  order_creation_send_email_value_change,
  order_creation_subcategory_id_value_change,
  order_creation_time_value_change,
  order_creation_title_value_change,
  registr_modal_display_toggle,
  registr_modal_first_name_value_change,
  registr_modal_last_name_value_change,
  registr_modal_phone_value_change,
} from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../shared/Button/Button';
import Checkbox from '../../../shared/Checkbox/Checkbox';
import Input from '../../../shared/Input/Input';
import OrderCreatePhotos from '../OrderCreatePhotos/OrderCreatePhotos';
import Select from '../../../shared/Select/Select';
import axios from 'axios';
import { createMaskForPhone } from '../../../../functions';
import createNumberMask from 'text-mask-addons/src/createNumberMask';
import icon_dateField from '../../../../assets/images/icon_dateField.svg';
import icon_hint from '../../../../assets/images/icon_hint.svg';
import icon_safeDealActive from '../../../../assets/images/icon_safeDealActive.svg';
import ru from 'date-fns/locale/ru';
import { useHistory } from 'react-router-dom';

registerLocale('ru', ru);

function OrderCreateForm() {
  // * Значения полей формы создания заказа.
  const {
    // * Main часть.
    order_creation_title_value: title,
    order_creation_category_id_value: category_id,
    order_creation_subcategory_id_value: subcategory_id,
    order_creation_description_value: description,
    // * Место.
    order_creation_city_id_value: city_id,
    order_creation_executor_place_value: executor_place,
    order_creation_place_value: place,
    // * Сроки.
    order_creation_executor_date_value: executor_date,
    order_creation_date_value: date,
    order_creation_time_value: time,
    order_creation_need_fast_value: need_fast,
    // * Цена.
    order_creation_executor_price_value: executor_price,
    order_creation_price_min_value: price_min,
    order_creation_price_max_value: price_max,
    // * Контакты.
    order_creation_first_name_value: first_name,
    order_creation_last_name_value: last_name,
    order_creation_phone_value: phone,
    // *  Уведомления.
    order_creation_send_email_value: send_email,
    // *  Безопасная сделка & юр. лица.
    order_creation_safe_payment_value: safe_payment,
    order_creation_only_yur_value: only_yur,

    // * Фотографии
    order_creation_image1: image_1,
    order_creation_image2: image_2,
    order_creation_image3: image_3,

    // * loading state
    is_loading,
  } = useSelector((state) => state.orderCreationReducer);

  // * Контактные данные юзера если залогигнен.
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  // * Раскрыть/скрыть поля.
  const [formDetailsPartToggled, formDetailsPartToggle] = useState(false);
  const [formContactsPartToggled, formContactsPartToggle] = useState(false);

  // * Загрузить в селект достпные категории.
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios({
      method: 'get',
      url: global.backendURL + 'tasks/category/list/',
    }).then((result) => setCategories(result.data.categories));
  }, []);

  // * Загрузить в селект досутпные подкатегории на основе выбранной основной категории.
  const [subcategories, setSubCategories] = useState([]);
  useEffect(() => {
    if (category_id) {
      axios({
        method: 'get',
        url: global.backendURL + 'tasks/category/' + category_id + '/subcategory/list/',
      }).then((result) => {
        setSubCategories(result.data.subcategories);
        global.debug && console.log(result.data.subcategories);
      });
    }
  }, [category_id]);

  // * Загрузить в селект достпные города.
  const [cities, setCities] = useState([]);
  useEffect(() => {
    axios({
      method: 'get',
      url: global.backendURL + 'cities/',
    }).then((result) => {
      setCities(result.data.cities);
    });
  }, []);

  // * Определяем залогинен ли пользователь и предзаполняем поля формы данными из профиля пользователя.
  const [userIsLogged, setUserIsLogged] = useState(false);
  useEffect(() => {
    if (user.first_name && user.last_name && user.phone) {
      dispatch(order_creation_first_name_value_change(user.first_name));
      dispatch(order_creation_last_name_value_change(user.last_name));
      dispatch(order_creation_phone_value_change(createMaskForPhone(user.phone)));
      user.city_id && dispatch(order_creation_city_id_value_change(user.city_id));
      setUserIsLogged(true);
    } else {
      setUserIsLogged(false);
      formContactsPartToggle(true);
      formDetailsPartToggle(true);
    }
  }, [user, dispatch]);

  // * Валидация. Обязательные поля: загаловок, категория, подкатегоиря, описание.
  const buttonDisabledStatus = () => {
    return category_id !== undefined && subcategory_id !== undefined && title.length > 0 && description.length > 0;
  };

  // * Для редиректа после создания заказа, предаём в сабмит.
  const history = useHistory();

  // * Сабмит формы или редирект на попап с регистрацией с предзаполнением полей.
  const buttonSubmit = () => {
    if (userIsLogged) {
      dispatch(
        order_creation_create({
          title,
          category_id,
          subcategory_id,
          description,
          city_id,
          executor_place,
          place,
          executor_date,
          date,
          time,
          need_fast,
          executor_price,
          price_min,
          price_max,
          send_email,
          safe_payment,
          only_yur,
          first_name,
          last_name,
          phone,
          history,
          image_1,
          image_2,
          image_3,
        })
      );
    } else {
      dispatch(registr_modal_first_name_value_change(first_name));
      dispatch(registr_modal_last_name_value_change(last_name));
      dispatch(registr_modal_phone_value_change(phone));
      dispatch(registr_modal_display_toggle(true));
    }
  };

  const maskPrice = createNumberMask({ prefix: '₸ ', thousandsSeparatorSymbol: '.', integerLimit: 7 });
  const [focus, setFocus] = useState(false);

  return (
    <section className='orderCreateForm'>
      {is_loading && <div className='isLoadingDisabler'></div>}
      <div className='formMainPart'>
        <Input
          type='text'
          name='purpose'
          label=' Какая услуга вам нужна?'
          extraClassName='purpose'
          value={title}
          onChange={(event) => dispatch(order_creation_title_value_change(event.target.value))}
          required={true}
          requiredStatusDescription={true}
        />
        <Select
          name='category'
          label='Категория услуги'
          extraClassName='category'
          disabled={categories.length !== 0 ? false : true}
          options={categories.length !== 0 ? categories : ''}
          onClick={(event) => dispatch(order_creation_category_id_value_change(event.target.id))}
          selection={parseInt(category_id)}
          required={true}
        />
        <Select
          name='subcategory'
          label='Подкатегория услуги'
          extraClassName='subcategory'
          disabled={subcategories.length !== 0 ? false : true}
          options={subcategories.length !== 0 ? subcategories : ''}
          onClick={(event) => dispatch(order_creation_subcategory_id_value_change(event.target.id))}
          selection={parseInt(subcategory_id)}
          required={true}
        />
        <Input
          type='textarea'
          name='description'
          label='Описание, детали и пожелания по заказу'
          extraClassName='description'
          value={description}
          onChange={(event) => dispatch(order_creation_description_value_change(event.target.value))}
          required={true}
        />
        <OrderCreatePhotos />
      </div>
      <div className='formDetailsPart'>
        <h3
          className={formDetailsPartToggled ? 'h3 toggleBtn toggled' : 'h3 toggleBtn'}
          onClick={() => formDetailsPartToggle(!formDetailsPartToggled)}
        >
          Место, сроки и стоимость
        </h3>
        <div className={formDetailsPartToggled ? 'toggleContainer toggled' : 'toggleContainer'}>
          <div className='place'>
            <div className='fieldsTitle'>Место оказания услуги</div>
            <button
              className={`placeType withoutAddress ${executor_place ? 'active' : ''}`}
              onClick={() => dispatch(order_creation_executor_place_value_change(true))}
            >
              У исполнителя
            </button>
            <button
              className={`placeType withAddress ${!executor_place ? 'active' : ''}`}
              onClick={() => dispatch(order_creation_executor_place_value_change(false))}
            >
              Указать место
            </button>
            {!executor_place ? (
              <Input
                type='text'
                name='address'
                label='Укажите точный адрес'
                extraClassName='address'
                value={place}
                onChange={(event) => dispatch(order_creation_place_value_change(event.target.value))}
                mask={maskAddress}
                guide={false}
              />
            ) : undefined}
            <Select
              name='city_id'
              label='Город'
              extraClassName='city'
              disabled={cities.length !== 0 ? false : true}
              options={cities.length !== 0 ? cities : ''}
              onClick={(event) => {
                console.log(event.target.id);
                dispatch(order_creation_city_id_value_change(event.target.id));
              }}
              selection={parseInt(city_id)}
              required={true}
            />
          </div>
          <div className='time'>
            <div className='fieldsTitle'>Сроки работы</div>
            <button
              className={`timeType withoutTime ${executor_date ? 'active' : ''}`}
              onClick={() => {
                dispatch(order_creation_executor_date_value_change(true));
              }}
            >
              По договоренности
            </button>
            <button
              className={`timeType withTime ${!executor_date ? 'active' : ''}`}
              onClick={() => {
                dispatch(order_creation_executor_date_value_change(false));
              }}
            >
              Точная дата
            </button>
            {!executor_date ? (
              <div className='timeFieldsWrapper'>
                <div className='datepickerContainer'>
                  <DatePicker
                    selected={date}
                    onChange={(date) => dispatch(order_creation_date_value_change(date))}
                    locale='ru'
                    dateFormat='yyyy-MM-dd'
                    minDate={new Date()}
                    customInput={
                      <Input
                        type='text'
                        name='date'
                        label='Дата'
                        extraClassName='date'
                        icon={icon_dateField}
                        iconClassName='dateFieldIcon'
                        readonly={true}
                      />
                    }
                  />
                </div>
                <Input
                  type='text'
                  name='time'
                  label='Уточните время'
                  extraClassName='exactTime'
                  value={time}
                  onChange={(event) => dispatch(order_creation_time_value_change(event.target.value))}
                  placeholder='07:00'
                  mask={createNumberMask({
                    includeThousandsSeparator: false,
                    prefix: '',
                    allowDecimal: true,
                    decimalSymbol: ':',
                    requireDecimal: true,
                  })}
                />
              </div>
            ) : (
              <div className='executorTimeFieldsWrapper'>
                <Checkbox
                  text='Как можно быстрее'
                  value={need_fast}
                  onClick={() => dispatch(order_creation_need_fast_value_change())}
                  extraClassName='asFastAsPossibleCheckbox'
                />
                <div className='fieldset executorTimeTextHelp'>
                  <label className='label'>
                    <span className='text'>
                      Дождитесь предложений от исполнителей или <br />
                      укажите детальные предпочтения в описании к заказу
                    </span>
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className='price'>
            <div className='fieldsTitle'>Стоимость</div>
            <button
              className={`priceType withoutPrice ${executor_price ? 'active' : ''}`}
              onClick={() => dispatch(order_creation_executor_price_value_change(true))}
            >
              Исполнитель укажет стоимость
            </button>
            <button
              className={`priceType withPrice ${!executor_price ? 'active' : ''}`}
              onClick={() => dispatch(order_creation_executor_price_value_change(false))}
            >
              Указать стоимость
            </button>
            {!executor_price ? (
              <div className='priceFieldsWrapper'>
                <Input
                  type='text'
                  name='priceFrom'
                  label='Цена от'
                  extraClassName='priceFrom'
                  value={price_min}
                  onChange={(event) => dispatch(order_creation_price_min_value_change(event.target.value))}
                  mask={maskPrice}
                  placeholder={placeholderPrice}
                  pipe={(value) => {
                    if (!focus) {
                      const min = parseInt(value.replace(/\D/g, ''));
                      const max = parseInt(price_max.replace(/\D/g, ''));
                      return max && min > max ? value.slice(0, -1) : value;
                    }
                    return value;
                  }}
                />
                <Input
                  type='text'
                  name='priceTo'
                  label='Цена до'
                  extraClassName='priceTo'
                  value={price_max}
                  onChange={(event) => dispatch(order_creation_price_max_value_change(event.target.value))}
                  mask={maskPrice}
                  placeholder={placeholderPrice}
                  onBlur={() => {
                    setFocus(false);
                    if (parseInt(price_min) > parseInt(price_max)) {
                      dispatch(order_creation_price_max_value_change('0'));
                    }
                  }}
                  onFocus={() => setFocus(true)}
                />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
      <div className='formContactsPart'>
        <h3
          className={formContactsPartToggled ? 'h3 toggleBtn toggled' : 'h3 toggleBtn'}
          onClick={() => formContactsPartToggle(!formContactsPartToggled)}
        >
          Ваши контакты
        </h3>
        <div className={formContactsPartToggled ? 'toggleContainer toggled' : 'toggleContainer'}>
          <Input
            type='text'
            name='firstName'
            label='Ваше имя'
            extraClassName='firstName'
            value={first_name}
            onChange={(event) => dispatch(order_creation_first_name_value_change(event.target.value))}
            required={true}
            mask={maskName}
            guide={false}
          />
          <Input
            type='text'
            name='lastName'
            label='Ваша фамилия'
            extraClassName='lastName'
            value={last_name}
            onChange={(event) => dispatch(order_creation_last_name_value_change(event.target.value))}
            required={true}
            mask={maskName}
            guide={false}
          />
          <Input
            type='tel'
            name='phone'
            label='Телефон'
            extraClassName='phone'
            value={phone}
            onChange={(event) => dispatch(order_creation_phone_value_change(event.target.value))}
            required={true}
            mask={maskPhone}
            placeholder={placeholderPhone}
          />
          <Checkbox
            text='Получать уведомления о новых событиях с заказом'
            value={send_email}
            onClick={() => dispatch(order_creation_send_email_value_change())}
            extraClassName='emailNotifCheckbox'
          />
          {/* <Checkbox 
              text="Получать WhatsApp уведомления о новых предложениях"
              value={whatsAppNotif}
              onClick={() => setWhatsAppNotif(!whatsAppNotif)}
              extraClassName="whatsAppNotifCheckbox"
            />
            <Checkbox 
              text="Получать Telegramm уведомления о новых предложениях"
              value={tgNotif}
              onClick={() => setTgNotif(!tgNotif)}
              extraClassName="tgNotifCheckbox"
            /> */}
        </div>
      </div>
      <div className='formExtraOptions'>
        <div className='safeDealPart'>
          <Checkbox
            text={
              <>
                Оплата через <img src={icon_safeDealActive} className='saveDealCheckboxIcon' alt='Иконка сделки без риска' />
                <span className='red'>Сделку без риска</span>
              </>
            }
            value={safe_payment}
            onClick={() => dispatch(order_creation_safe_payment_value_change())}
            extraClassName='saveDealCheckbox'
          />
          <div className='filedHint'>
            <img src={icon_hint} className='safeDealHintIcon fieldHintIcon' alt='Подсказка' />
            <div className='fieldHintPopup'>
              <div className='hintStep'>1</div>
              <div className='hintText'>
                Вам понадобиться внести
                <br /> данные своей банковской
                <br /> карты.
              </div>
              <div className='divider'></div>
              <div className='hintStep'>2</div>
              <div className='hintText'>
                Стоимость заказа резервируется на специальном счете в платежной системе, и списывается только тогда, когда исполнитель
                завершает работу.
              </div>
              <div className='divider'></div>
              <div className='hintStep'>3</div>
              <div className='hintText'>Если исполнитель отказывается от выполнения заказа, деньги возвращаются к вам на карту.</div>
              <div className='divider'></div>
              <div className='hintStep'>4</div>
              <div className='hintText'>
                При возникновении разногласий по заказу вы можете открыть спор. Мы подключимся и разберемся в ситуации.
              </div>
            </div>
          </div>
        </div>
        <div className='businessPart'>
          <Checkbox
            text='Я использую сервис для бизнеса, нужны закрывающие документы'
            value={only_yur}
            onClick={() => dispatch(order_creation_only_yur_value_change())}
            extraClassName='businessCheckbox'
          />
          <div className='filedHint'>
            <img src={icon_hint} className='businessHintIcon fieldHintIcon' alt='Подсказка' />
            <div className='fieldHintPopup noSteps'>
              <div className='hintText'>
                Если вы — юрлицо, и вам нужно, чтобы исполнители могли предоставить закрывающие документы, укажите это при создании заказа.
                Мы покажем ваше объявление только тем исполнителям, которые могут предоставить подписанные:
                <br /> - Счет на оплату;
                <br /> - Акт выполненных работ;
                <br /> - Счет-фактуру.
              </div>
            </div>
          </div>
        </div>
      </div>
      {!buttonDisabledStatus() && (
        <div className='requiredStatusReminder'>
          <span className='required'>*&nbsp;</span>Обязательные поля не заполнены
        </div>
      )}
      <Button
        type='button'
        onClick={() => buttonSubmit()}
        disabled={!buttonDisabledStatus() || is_loading}
        size='large'
        color='red'
        text={userIsLogged ? 'Опубликовать' : 'Зарегистрироваться'}
        width='390rem'
        extraClassName='publicButton'
        isLoading={is_loading}
      />
    </section>
  );
}

export default OrderCreateForm;
