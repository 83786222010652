import FAQ_HowToBecomeExecutor_01 from '../../assets/images/FAQ_HowToBecomeExecutor_01.jpg';
import FAQ_HowToBecomeExecutor_02 from '../../assets/images/FAQ_HowToBecomeExecutor_02.jpg';

import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';
import { Link } from 'react-router-dom';

function HowToBecomeExecutor() {
  return (
    <main className='howToCreateOrder'>
      <DocumentContent>
        <h1>Как стать исполнителем</h1>
        <p>
          Начать работать на себя, искать новых клиентов и больше зарабатывать — легко, с новым сервисом signedUP! Для этого нужно
          зарегистрироваться в проекте и подтвердить свою личность. Для начала зарегистрируйтесь как обычный пользователь:
        </p>
        <ol>
          <li>
            <span>1.</span>
            <span>
              Зайдите на <Link to='/executor/'>https://signedup.me/executor/</Link>.
            </span>
          </li>
          <li>
            <span>2.</span>
            <span>Нажмите кнопку «Регистрация» в правом верхнем углу страницы.</span>
          </li>

          <li>
            <span>3.</span>
            <span>В появившемся окне введите ваши настоящие имя, фамилию и укажите номер телефона.</span>
          </li>
          <li>
            <span>4.</span>
            <span>
              На указанный номер будет отправлено SMS-сообщение с одноразовым кодом доступа на сайт. Введите код из SMS в соответствующее
              поле.
            </span>
          </li>
        </ol>
        <img src={FAQ_HowToBecomeExecutor_01} alt='screenshot' />
        <p>
          Поздравляем, вы присоединились к проекту signedUP! На всякий случай напомним, что код из SMS — ваш пароль для доступа на сайт. Для
          безопасности вашего профиля никому не сообщайте код доступа. Вам потребуется каждый раз запрашивать новый код для всех последующих
          заходов в профиль.
        </p>
        <p>Итак, вы успешно зарегистрировались. Теперь вам необходимо пройти процедуру подтверждения личности:</p>
        <ol>
          <li>
            <span>1. </span>
            <span>Нажмите круглую иконку с логотипом signedUP в правом верхнем углу страницы → «Мой профиль».</span>
          </li>
          <li>
            <span>2. </span>
            <span>Выберите «Стать исполнителем».</span>
          </li>
        </ol>
        <img src={FAQ_HowToBecomeExecutor_02} alt='screenshot' />
        <ol>
          <li>
            <span>3. </span>
            <span>
              Заполните ваши персональные данные (имя, фамилию, дату рождения и адрес регистрации) точно так, как указано в вашем
              удостоверении личности.
            </span>
          </li>
          <li>
            <span>4. </span>
            <span>
              Прикрепите фотографии или сканы вашего паспорта/ удостоверения личности, нажав на кнопку «+» в левом нижнем углу. Необходимо
              прикрепить страницу с вашими персональными данными, а также страницу с пропиской.
            </span>
          </li>
          <li>
            <span>5. </span>
            <span>После заполнения всех данных нажмите кнопку «Продолжить».</span>
          </li>
          <li>
            <span>6. </span>
            <span>
              Администрация сайта проверит достоверность ваших данных и подтвердит ваш статус исполнителя. Как только это произойдет, вы
              получите уведомление, кнопка «Стать исполнителем» исчезнет из вашего профиля, а статус вашего документа в правом нижем углу
              профиля изменится на «Подтверждено».
            </span>
          </li>
        </ol>
        <p>
          Нам нужны ваши паспортные данные исключительно в целях безопасности. Подробнее о безопасном использовании сервиса можно узнать{' '}
          <Link to='/faq/why_we_are_safe/'>здесь</Link> и <Link to='/faq/personal_data_safety/'>здесь</Link>.
        </p>
        <p>
          P.S. Вот еще пара полезных ссылок, которые помогут вам подробнее познакомиться с сервисом:
          <br /> Как заполнить профиль, чтобы получать как можно больше заказов:{' '}
          <Link to='/faq/how_to_get_more_orders/' className='cutOnMobile'>
            https://signedup.me/faq/how_to_get_more_orders/
          </Link>{' '}
          <br />
          Что такое Сделка без риска и почему она выгодна:{' '}
          <Link to='/faq/what_is_safe_deal/' className='cutOnMobile'>
            https://signedup.me/faq/what_is_safe_deal/
          </Link>
        </p>
      </DocumentContent>
    </main>
  );
}

export default HowToBecomeExecutor;
