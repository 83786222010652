import {
  PROFILE_ABOUT_VALUE_CHANGE,
  PROFILE_BIRTHDAY_VALUE_CHANGE,
  PROFILE_CITY_VALUE_CHANGE,
  PROFILE_FIRST_NAME_VALUE_CHANGE,
  PROFILE_GENDER_VALUE_CHANGE,
  PROFILE_LAST_NAME_VALUE_CHANGE,
  PROFILE_LOAD,
  PROFILE_LOAD_ERROR,
  PROFILE_LOAD_SUCCESS,
  PROFILE_MIDDLE_NAME_VALUE_CHANGE,
  PROFILE_PHOTO_CHANGE,
  PROFILE_RESET,
  PROFILE_SAVE,
  PROFILE_SAVE_ERROR,
  PROFILE_SAVE_SUCCESS,
  PROFILE_SUBSCRIBED_NEWS_CHANGE,
  PROFILE_SUBSCRIBED_NOTIFICATIONS_CHANGE,
  PROFILE_EMAIL_VALUE_CHANGE,
  PROFILE_EMAIL_SUBMIT,
  PROFILE_EMAIL_SUBMIT_SUCCESS,
  PROFILE_EMAIL_SUBMIT_FAIL,
} from '../actions/types';

/** @type {import('../typedef').TUserDescription} */
const INITIAL_STATE = {
  ratings_data: {
    customer_adequacy: null,
    customer_decency: null,
    customer_punctuality: null,
    customer_rate: null,
    executor_adequacy: null,
    executor_decency: null,
    executor_punctuality: null,
    executor_rate: null,
  },
  task_data: {
    customer: {
      tasks_count: 0,
    },
    executor: {
      tasks_count: 0,
    },
  },
  user: {
    first_name: '',
    last_name: '',
    surname: '',
    gender: '',
    about: '',
    birthday: null,
    photo: null,
    verification_status: 'not_verified',
    phone: null,
    phone_verified: false,
    email: '',
    email_verified: false,
    email_verification_status: 'not_verified',
    is_customer: false,
    is_executor: false,
    is_juridical: false,
    is_law: false,
    law_verification_status: 'not_verified',
    date_joined: '',
    send_email: undefined,
    subscribed_news: undefined,
    subscribed_notifications: undefined,
    public: undefined,
    app_access_count: undefined,
    // city_id: null,
    // city_title: null,
    // country_id: null,
    // country_title: null,
  },
  feedbacks_count: 0,
  images_count: 0,
  is_empty: true,
  is_loading: false,
  user_id: undefined,
};

/** @returns {import('../typedef').TUserDescription} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_LOAD:
      return { ...state, is_loading: true, is_empty: true };
    case PROFILE_LOAD_SUCCESS:
      return { ...action.payload, is_loading: true, is_empty: false };
    case PROFILE_LOAD_ERROR:
      return { ...state, is_loading: false, is_empty: true };
    case PROFILE_SAVE:
      return { ...state, is_loading: true };
    case PROFILE_SAVE_SUCCESS:
      return { ...state, is_loading: false };
    case PROFILE_SAVE_ERROR:
      return { ...state, is_loading: false, image_error: action.payload.image[0] };
    case PROFILE_PHOTO_CHANGE:
      return { ...state, user: { ...state.user, photo: action.payload } };
    case PROFILE_SUBSCRIBED_NEWS_CHANGE:
      return {
        ...state,
        user: { ...state.user, subscribed_news: action.payload },
      };
    case PROFILE_SUBSCRIBED_NOTIFICATIONS_CHANGE:
      return {
        ...state,
        user: { ...state.user, subscribed_notifications: action.payload, send_email: action.payload },
      };
    case PROFILE_FIRST_NAME_VALUE_CHANGE:
      return { ...state, user: { ...state.user, first_name: action.payload } };
    case PROFILE_MIDDLE_NAME_VALUE_CHANGE:
      return { ...state, user: { ...state.user, surname: action.payload } };
    case PROFILE_LAST_NAME_VALUE_CHANGE:
      return { ...state, user: { ...state.user, last_name: action.payload } };
    case PROFILE_BIRTHDAY_VALUE_CHANGE:
      return { ...state, user: { ...state.user, birthday: action.payload } };
    case PROFILE_GENDER_VALUE_CHANGE:
      return { ...state, user: { ...state.user, gender: action.payload } };
    case PROFILE_CITY_VALUE_CHANGE:
      return {
        ...state,
        user: {
          ...state.user,
          city_id: action.payload.city_id,
          city_title: action.payload.title,
        },
      };
    case PROFILE_ABOUT_VALUE_CHANGE:
      return { ...state, user: { ...state.user, about: action.payload } };
    case PROFILE_RESET:
      return { ...INITIAL_STATE };

    // * Изменение e-mail
    case PROFILE_EMAIL_VALUE_CHANGE:
      return { ...state, user: { ...state.user, email: action.payload } };
    case PROFILE_EMAIL_SUBMIT:
      return { ...state };
    case PROFILE_EMAIL_SUBMIT_SUCCESS:
      return { ...state, user: { ...state.user, email_verification_status: 'in_progress' } };
    case PROFILE_EMAIL_SUBMIT_FAIL:
      return { ...state };

    default:
      return state;
  }
};
