import './PortfolioImage.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { alert_modal_display, alert_modal_settings } from '../../../actions';
import { acceptImageTypes } from '../../../const';

import AlertPopup from '../AlertPopup/AlertPopup';
import Files from '../Files/Files';

/**
 * portfolio image component
 * @param {import('../../../typedef').TPortfolioImage} props props of component
 */
function PortfolioImage(props) {
  const {
    id,
    filename,
    url,
    placeholder,
    uploadCallback,
    canDelete,
    deleteCallback,
    clickCallback,
    accept,
    extraClassName,
    selected,
    error,
  } = props;
  const dispatch = useDispatch();

  /**
   * upload image
   * @param {FileList} files files uploaded by user
   */
  const uploadImage = (files) => {
    if (files && files.length) {
      const file = files.item(0);
      if (file && typeof uploadCallback === 'function') {
        uploadCallback(file);
      }
    }
  };

  /**
   * delete image modal
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event click event
   * @param {number} id image id
   * @param {string} [filename] image name
   */
  const deleteModal = (event, id, filename) => {
    const name = filename ? ` '${filename}' ` : ' ';

    event.stopPropagation();

    // setup modal
    dispatch(
      alert_modal_settings({
        title: `Удаление файла`,
        content: `Файл${name}будет удалён. Вы уверены?`,
        extraClassName: 'alertModalBody',
        buttons: [
          {
            color: 'red',
            size: 'large',
            text: 'Ок',
            type: 'button',
            width: '150rem',
            onClick: () => {
              if (typeof deleteCallback === 'function') {
                deleteCallback(id);
              }
              dispatch(alert_modal_display(false));
            },
          },
          {
            color: 'dark',
            size: 'large',
            text: 'Отмена',
            type: 'button',
            width: '150rem',
            onClick: () => dispatch(alert_modal_display(false)),
          },
        ],
      })
    );
    // show modal
    dispatch(alert_modal_display(true));
  };

  /**
   * image click handler
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event click event
   */
  const imageClick = (event) => {
    if (typeof clickCallback === 'function') {
      event.stopPropagation();
      clickCallback(props);
    }
  };

  if (!placeholder) {
    return (
      <div className={`portfolioImage active ${selected ? 'selected' : ''} ${extraClassName || ''}`}>
        {error && <AlertPopup extraClassName='portfolioImageError'>{error}</AlertPopup>}
        <div className='image' style={{ backgroundImage: `url(${url})` }}></div>
        <div className='cover' onClick={(event) => imageClick(event)}>
          {canDelete && <div className='delete' onClick={(event) => deleteModal(event, id, filename)}></div>}
        </div>
      </div>
    );
  }
  return (
    <Files onUpdate={(files) => uploadImage(files)} accept={accept || acceptImageTypes}>
      <div className={`portfolioImage add ${extraClassName || ''}`}></div>
    </Files>
  );
}

export default PortfolioImage;
