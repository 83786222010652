import './PromoIndex.scss';

import React from 'react';
import Button from '../../../shared/Button/Button';
import { useDispatch } from 'react-redux';
import { useHistory, Link, useParams } from 'react-router-dom';
import { order_creation_category_id_value_change, order_creation_subcategory_id_value_change, order_creation_title_value_change } from '../../../../actions';

function PromoIndex({ promoData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { key1, key2, key3, key4, key5 } = useParams();

  const renderH1 = () => {
    if (promoData.L5) {
      return promoData.L5;
    } else if (promoData.L4) {
      return promoData.L4;
    } else if (promoData.L3) {
      return promoData.L3;
    } else if (promoData.L2) {
      return promoData.L2;
    } else if (promoData.L1) {
      return promoData.L1;
    }
  };

  const onClick = () => {
    dispatch(order_creation_category_id_value_change(promoData.category_id));
    promoData.subcategory_id && dispatch(order_creation_subcategory_id_value_change(promoData.subcategory_id));
    if (promoData.L5) {
      dispatch(order_creation_title_value_change(promoData.L5))
    } else if (promoData.L4) {
      dispatch(order_creation_title_value_change(promoData.L4))
    } else if (promoData.L3) {
      dispatch(order_creation_title_value_change(promoData.L3))
    }
    history.push('/create_order/');
  };

  return (
    <section className='promoIndex'>
      <div className='container'>
        <div className='breadcrumps'>
          {promoData.L2 ? (
            <Link to={`/promo/${key1}/`} className='breadcrump'>
              {promoData.L1}
            </Link>
          ) : (
            <div className='breadcrump selected'>{promoData.L1}</div>
          )}
          {promoData.L2 &&
            (promoData.L3 ? (
              <Link to={`/promo/${key1}/${key2}/`} className='breadcrump'>
                {promoData.L2}
              </Link>
            ) : (
              <div className='breadcrump selected'>{promoData.L2}</div>
            ))}
          {promoData.L3 &&
            (promoData.L4 ? (
              <Link to={`/promo/${key1}/${key2}/${key3}/`} className='breadcrump'>
                {promoData.L3}
              </Link>
            ) : (
              <div className='breadcrump selected'>{promoData.L3}</div>
            ))}
          {promoData.L4 &&
            (promoData.L5 ? (
              <Link to={`/promo/${key1}/${key2}/${key3}/${key4}/`} className='breadcrump'>
                {promoData.L4}
              </Link>
            ) : (
              <div className='breadcrump selected'>{promoData.L4}</div>
            ))}
          {promoData.L5 &&            
            <div className='breadcrump selected'>{promoData.L5}</div>
          }
        </div>
        <h1 className='h1'>
          {renderH1()} <br /> в Алматы недорого
        </h1>
        <Button type='button' onClick={onClick} color='red' size='large' width='340rem' text='Найти мастера' />
      </div>
    </section>
  );
}

export default PromoIndex;
