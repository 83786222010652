import './ErrorPage.scss';
import ErrorPage_illustration01 from '../../assets/images/ErrorPage_illustration01.svg';

import React from 'react';

function ErrorPage() {
  return (
    <section className='errorPage'>
      <div className='container'>
        <div className='errorPageCaption'>
          Внутренняя
          <br />
          ошибка сервера
        </div>
        <div className='errorPageLabel'>Мы всё знаем. Совсем скоро всё будет хорошо!</div>
        <img className='errorPageIllustration' src={ErrorPage_illustration01} alt='Error page illustration'/>
      </div>
    </section>
  );
}

export default ErrorPage;
