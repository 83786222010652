import {
  PROFILE_IDENTITY_MODAL_DISPLAY_TOGGLE,
  PROFILE_IDENTITY_MODAL_IS_LOADING_VALUE_CHANGE,
  PROFILE_IDENTITY_MODAL_SAVE,
  PROFILE_IDENTITY_MODAL_SAVE_ERROR,
  PROFILE_IDENTITY_MODAL_SAVE_SUCCESS,
} from '../actions/types';

/** @type {import("../typedef").TProfileIdentityModalReducer} */
const INITIAL_STATE = {
  profile_identity_modal_display_status: false,
  next_step: false,
  is_loading: false,
};

/** @returns {import("../typedef").TProfileIdentityModalReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_IDENTITY_MODAL_DISPLAY_TOGGLE:
      // reset flag to prevent window close
      const next_step = !action.payload;
      return { ...state, profile_identity_modal_display_status: action.payload, next_step };
    case PROFILE_IDENTITY_MODAL_IS_LOADING_VALUE_CHANGE:
      return { ...state, is_loading: action.payload };
    case PROFILE_IDENTITY_MODAL_SAVE:
      return { ...state, is_loading: true };
    case PROFILE_IDENTITY_MODAL_SAVE_SUCCESS:
      return { ...state, is_loading: false, next_step: true };
    case PROFILE_IDENTITY_MODAL_SAVE_ERROR:
      return { ...state, is_loading: false };
    default:
      return { ...state };
  }
};
