import './OrderPage.scss';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  order_response_modal_display_toggle,
  order_response_modal_order_id_value_change,
  add_payment_method_modal_display_toggle,
  alert_modal_settings,
  alert_modal_display,
  preface_legal_entity_modal_display_toggle,
} from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import Button from '../../components/shared/Button/Button';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderChat from '../../components/pageSpecific/OrderPage/OrderChat/OrderChat';

function OrderPage_InWork_Customer({ order_id, order }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const user_id = useSelector((state) => state.userReducer.user_id);
  const user_has_card = useSelector((state) => state.userReducer.user.executor_tool);
  const [alreadyResponded, setAlreadyResponded] = useState(false);
  const [chatId, setChatId] = useState(null);

  // ? Рефакток методов в redux-action'ы
  const buy = () => {
    axios({
      method: 'post',
      url: global.backendURL + 'payment/access/',
      data: {
        count: 2,
      },
    })
      .then((result) => {
        global.debug && console.log('%cPayment - Success:', 'color: $00ee00;');
        global.debug && console.log(result.data.url);
        window.location.replace(result.data.url);
      })
      .catch((error) => {
        global.debug && console.log('%cPayment - Fail:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
  const onResponse = () => {
    if (user.app_access_count <= 0) {
      dispatch(
        alert_modal_settings({
          title: `Доступ закрыт`,
          content: `Заказы без комиссии выполнены. Чтобы продолжить работу с сервисом, купите хотя бы 2 заказа. После покупки вы сможете откликаться на любое количество заказов. Комиссия спишется только после того, как заказчик назначит вас исполнителем.`,
          extraClassName: 'alertModalBody',
          buttons: [
            {
              color: 'red',
              size: 'large',
              text: 'Купить',
              type: 'button',
              width: '360rem',
              onClick: () => buy(),
            },
          ],
        })
      );
      dispatch(alert_modal_display(true));
    } else if (!user_has_card && order.safe_payment) {
      dispatch(add_payment_method_modal_display_toggle(true));
    } else if (!user.is_law && order.only_yur) {
      dispatch(
        alert_modal_settings({
          title: `Доступ закрыт`,
          content: `Заказчик указал, что ему требуются закрывающие юридические документы. Откликаться на заказ могут только исполнители, прошедшие верификацию как юр.лицо.`,
          extraClassName: 'alertModalBody',
          buttons: [
            {
              color: 'red',
              size: 'large',
              text: 'Пройти верификацию',
              type: 'button',
              width: '360rem',
              onClick: () => {
                dispatch(alert_modal_display(false));
                dispatch(preface_legal_entity_modal_display_toggle(true));
              },
            },
          ],
        })
      );
      dispatch(alert_modal_display(true));
    } else {
      dispatch(order_response_modal_display_toggle(true));
      dispatch(order_response_modal_order_id_value_change(order.id));
    }
  };

  useEffect(() => {
    order.applications.map((application) => {
      if (application.executor.id === parseInt(user_id)) {
        setAlreadyResponded(true);
        setChatId(application.chat_id);
        console.log(application.chat_id);
      }
    });
  }, []);

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Новый заказ' />

        <OrderInfo order={order} />

        {!alreadyResponded && (
          <Button
            type='button'
            onClick={() => onResponse()}
            text='Откликнуться'
            size='large'
            color='red'
            width='360rem'
            extraClassName='orderResponseButton'
          />
        )}

        {alreadyResponded && <OrderChat forExecutor={true} chat_id={chatId} />}
      </div>
    </section>
  );
}

export default OrderPage_InWork_Customer;
