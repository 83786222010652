import './Economy.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';

function Economy() {
  let currentRoute = useLocation();

  return (
    <section className='economy'>
      <div className='container'>
        {currentRoute.pathname === '/' ? (
          <>
            <h2 className='h2'>
              Как это работает
            </h2>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>1</div>
                <h3 className='h3'>
                  Создайте
                  <br /> заказ
                </h3>
                <div className='description'>
                  Опишите, что именно и в какие сроки вам нужно сделать? Умный алгоритм бесплатно подберет наиболее подходящих исполнителей.
                </div>
              </div>
            </div>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>2</div>
                <h3 className='h3'>
                  Выберите <br />
                  лучшего мастера
                </h3>
                <div className='description'>
                  Пообщайтесь подобранными для вас мастерами в чате signedUP или по телефону. Выберите самого подходящего по рейтингу,
                  стоимости работ и отзывам.
                </div>
              </div>
            </div>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>3</div>
                <h3 className='h3'>
                  Договоритесь <br />
                  об условиях
                </h3>
                <div className='description'>
                  Обсудите с выбранным мастером детали, сроки и место работы. Все устраивает? Отлично. Теперь просто подождите, пока вашу
                  задачу решит профессионал.
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className='h2'>Как взять заказ?</h2>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>1</div>
                <h3 className='h3'>Станьте исполнителем</h3>
                <div className='description'>
                  Заполните личные данные, расскажите, какую работу вы хотите выполнять. Если у вас есть статус индивидуального
                  предпринимателя или вы готовы работать с юрлицами, не забудьте указать это в вашей анкете.
                </div>
              </div>
            </div>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>2</div>
                <h3 className='h3'>Ознакомьтесь с предложениями</h3>
                <div className='description'>
                  Наши умные алгоритмы подберут для вас подходящие заказы. Откликнитесь на предложение, пообщайтесь с заказчиком в чате или по
                  телефону, обсудите детали и приступайте к выполнению заказа.
                </div>
              </div>
            </div>
            <div className='step'>
              <div className='positionContainer'>
                <div className='stepNumber'>3</div>
                <h3 className='h3'>Получите оплату</h3>
                <div className='description'>
                  Закончите заказ, получите денежное вознаграждение и наслаждайтесь результатом проделанной работы. Теперь можно приступать
                  к поиску следующего клиента.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Economy;
