import {
  LOGIN_MODAL_CONFIRM_OTP,
  LOGIN_MODAL_CONFIRM_OTP_FAIL,
  LOGIN_MODAL_CONFIRM_OTP_SUCCESS,
  LOGIN_MODAL_DISPLAY_TOGGLE,
  LOGIN_MODAL_GET_OTP,
  LOGIN_MODAL_GET_OTP_FAIL,
  LOGIN_MODAL_GET_OTP_SUCCESS,
  LOGIN_MODAL_OTP_VALUE_CHANGE,
  LOGIN_MODAL_PHONE_VALUE_CHANGE,
} from '../actions/types';
import { createMaskForPhone } from '../functions';

/** @type {import('../typedef').TLoginReducer} */
const INITIAL_STATE = {
  login_modal_display_status: false,
  login_modal_phone_value: '+ 7 ',
  login_modal_phone_error: '',
  login_modal_sms_repeat_available_error: null,
  login_modal_otp_get_status: false,
  login_modal_otp_value: '',
  login_modal_otp_error: '',
  login_modal_sms_key_value: '',
  is_loading: false,
};

/** @returns {import('../typedef').TLoginReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть модальное окно логина
    case LOGIN_MODAL_DISPLAY_TOGGLE:
      return { ...state, login_modal_display_status: action.payload };

    // * Измение значения полей модального окна логина
    case LOGIN_MODAL_PHONE_VALUE_CHANGE:
      return { ...state, login_modal_phone_value: createMaskForPhone(action.payload) };
    case LOGIN_MODAL_OTP_VALUE_CHANGE:
      return { ...state, login_modal_otp_value: action.payload };

    // * Получить ОТП ( проверочный смс-код ) при логине
    case LOGIN_MODAL_GET_OTP:
      return {
        ...state,
        is_loading: true,
        login_modal_phone_error: '',
      };
    case LOGIN_MODAL_GET_OTP_SUCCESS:
      return {
        ...state,
        is_loading: false,
        login_modal_otp_get_status: true,
        login_modal_sms_key_value: action.payload,
        login_modal_phone_error: '',
      };
    case LOGIN_MODAL_GET_OTP_FAIL:
      return {
        ...state,
        is_loading: false,
        login_modal_phone_error: action.payload?.phone ? action.payload?.phone : '',
        login_modal_sms_repeat_available_error: action.payload?.sms_repeat_available ? action.payload?.sms_repeat_available : '',
      };

    // * Подтвердить ОТП ( проверочный смс-код ) и залогинется
    case LOGIN_MODAL_CONFIRM_OTP:
      return {
        ...state,
        is_loading: true,
        login_modal_otp_error: '',
      };
    case LOGIN_MODAL_CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        login_modal_display_status: false,
      };
    case LOGIN_MODAL_CONFIRM_OTP_FAIL:
      return {
        ...state,
        is_loading: false,
        login_modal_otp_error: action.payload?.code ? action.payload?.code : '',
      };

    default:
      return state;
  }
};
