import './NeedToLogin.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { login_modal_display_toggle } from '../../../actions';
import Button from '../Button/Button';

function NeedToLogin() {
  const dispatch = useDispatch();

  return (
    <div className='needToLogin'>
      <div className='container'>
        <div className='servicePageCaption'>Для доступа к странице требуется залогиниться.</div>
        <Button type='button' onClick={() => dispatch(login_modal_display_toggle(true))} width='200rem' size='small' color='red' text='Войти' />      
      </div>
    </div>
  );
}

export default NeedToLogin;