import './ExecutorPrefaceModal.scss';
import Preface_bg from '../../../assets/images/Preface_bg.svg';

import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { preface_modal_display_toggle, profile_passport_display_status_change, registr_modal_display_toggle } from '../../../actions';

import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';

function ExecutorPrefaceModal() {
  /** @type {string} */
  const token = useSelector((state) => state.globalVariablesReducer.token_value);
  /** @type {import('../../../typedef').TUserDescription} */
  const { user } = useSelector((state) => state.userReducer);
  const awaitsVerification = user && user.verification_status === 'in_progress';
  const dispatch = useDispatch();

  const close = () => {
    dispatch(preface_modal_display_toggle(false));
  };

  const next = () => {
    if (awaitsVerification) {
      close();
    } else if (!token) {
      close();
      dispatch(registr_modal_display_toggle(true, 'become_executor'));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Понятно" - модальное инф. оконо перед модальным окном верфикации исполнителя'
      })
    } else {
      close();
      dispatch(profile_passport_display_status_change(true));
    }
  };

  const renderTitle = () => {
    if (awaitsVerification) {
      return <div className='title'>Ваши данные проверяются, ожидайте.</div>;
    }
    if (!token) {
      return (
        <div className='title'>
          Чтобы стать исполнителем,
          <br /> необходимо зарегистрироваться,
          <br /> а потом пройти верификацию
        </div>
      );
    }
    return (
      <div className='title'>
        Чтобы стать исполнителем,
        <br /> необходимо заполнить данные
        <br /> вашего удостоверения личности
      </div>
    );
  };

  return (
    <Modal extraClassName='executorPrefaceModal' onClose={() => dispatch(preface_modal_display_toggle(false))}>
      {renderTitle()}
      <div className='sensitiveWarning'>
        Не волнуйтесь, ваша информация в безопасности. Нам нужны данные и фото вашего
        <br /> удостоверения личности исключительно для проверки подлинности сведений в анкете.
      </div>
      <img className='image' src={Preface_bg} alt='illustration' />
      <Button
        color='red'
        size='normal'
        text={awaitsVerification ? 'Понятно' : 'Продолжить'}
        width='244rem'
        extraClassName='prefaceAgreeButton'
        onClick={next}
      />
    </Modal>
  );
}

export default ExecutorPrefaceModal;
