import './HeaderChatList.scss';

import React, { useEffect, useRef } from 'react';
import { chat_list_header_display_toggle, chat_modal_display_toggle } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useWindowDimensions } from '../../../functions';

function HeaderChatList() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const chat_list = useSelector((state) => state.chatModalReducer.chat_list);
  const chat_list_header_display_status = useSelector((state) => state.chatModalReducer.chat_list_header_display_status);
  const node = useRef();

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target) || e.target.className === 'chat' || e.target.className === 'chat active' || width <= 500) {
      return;
    }
    dispatch(chat_list_header_display_toggle(false));
  };

  useEffect(() => {
    chat_list_header_display_status
      ? document.addEventListener('mousedown', handleClickOutside)
      : document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [chat_list_header_display_status]);

  return (
    <div className='headerChatListWrapper' ref={node}>
      <div className={`headerChatList${chat_list.length === 0 ? ' empty' : ''}`}>
        {chat_list.length >= 1 ? (
          chat_list.map((chat) => (
            <div
              className='paddingWrapper'
              key={`headerChatListChat + ${chat.id}`}
              onClick={() => dispatch(chat_modal_display_toggle({ display_status: true, chat_id: chat.id }))}
            >
              <div className={`headerChatListChat${chat.has_unread ? ' hasUnread' : ''}`}>
                <div
                  className={`avatar${!chat?.interlocutor?.image ? ' noAvatar' : ''}`}
                  style={{ backgroundImage: `url('${chat?.interlocutor?.image}')` }}
                />
                <div className='name'>{`${chat?.interlocutor?.first_name} ${chat?.interlocutor?.last_name}`}</div>
                <div className='time'>
                  {chat?.last_msg?.date ? format(new Date(chat.last_msg.date), 'd MMM kk:mm', { locale: ru }) : ''}
                </div>
                <div className='orderName'>
                  <span className='opacity'>Заказ «</span>
                  <span className='red'>{chat.chat_title}</span>
                  <span className='opacity'>»</span>
                </div>
                <div className='message'>{chat.last_msg.text}</div>
              </div>
            </div>
          ))
        ) : (
          <div className='epmty'>У вас пока нет сообщений.</div>
        )}
      </div>
    </div>
  );
}

export default HeaderChatList;
