import './OrdersDashboardsPages.scss';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { executor_orders_dashboard_load } from '../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import useUserData from '../../hooks/useUserData';
import useUserExecutorCategoris from '../../hooks/useUserExecutorCategoris';

import ExecutorOrdersDashboardTabs from '../../components/pageSpecific/OrdersDasbordsPages/OrdersDashboardTabs/ExecutorOrdersDashboardTabs';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrdersCategories from '../../components/pageSpecific/OrdersDasbordsPages/OrdersCategories/OrdersCategories';
import NeetToLogin from '../../components/shared/NeedToLogIn/NeedToLogin';

function ExecutorOrdersDashboardPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const { data: user_data, status: user_data_loading_status, isFetching: user_data_is_fetching } = useUserData(user_id);
  const {
    data: user_executor_categoris,
    status: user_executor_categoris_loading_status,
    isFetching: user_executor_categoris_is_fetching,
  } = useUserExecutorCategoris(user_id);

  const available_orders_all = useSelector((state) => state.ordersDashboardsReducer.available_orders_all);
  const available_orders_you_responeded = useSelector((state) => state.ordersDashboardsReducer.available_orders_you_responeded);
  const available_orders_in_work = useSelector((state) => state.ordersDashboardsReducer.available_orders_in_work);
  const available_orders_archive = useSelector((state) => state.ordersDashboardsReducer.available_orders_archive);

  useEffect(() => {
    if (user_data_loading_status === 'fail') {
      history.push('/500/');
    } else if (user_id && user_data_loading_status === 'success') {
      user_data.user.is_executor ? dispatch(executor_orders_dashboard_load(user_id)) : history.push('/404/');
    }
  }, [user_id, user_data, user_data_loading_status, dispatch]); 

  const renderTitleText = () => {
    switch (location.pathname) {
      case '/orders/available/':
        return <PageTitle text={`Доступно ${available_orders_all && available_orders_all.length} заказов`} />;
      case '/orders/you_responded/':
        return <PageTitle text={`В обсуждении ${available_orders_you_responeded && available_orders_you_responeded.length} заказов`} />;
      case '/orders/in_work/':
        return <PageTitle text={`В работе ${available_orders_in_work && available_orders_in_work.length} заказов`} />;
      case '/orders/archive/':
        return <PageTitle text={`В архиве ${available_orders_archive && available_orders_archive.length} заказов`} />;
      default:
        break;
    }
  };

  return (
    <>
      {user_id ? (
        <section className='ordersList executorOrdersList'>
          <div className='container'>
            {renderTitleText()}
            <ExecutorOrdersDashboardTabs />
            {user_executor_categoris_loading_status === 'success' && <OrdersCategories categories={user_executor_categoris.categories} />}
          </div>
        </section>
      ) : (
        <NeetToLogin />
      )}
    </>
  );
}

export default ExecutorOrdersDashboardPage;
