import {
  REGISTR_MODAL_CONFIRM_OTP,
  REGISTR_MODAL_CONFIRM_OTP_FAIL,
  REGISTR_MODAL_CONFIRM_OTP_SUCCESS,
  REGISTR_MODAL_DISPLAY_TOGGLE,
  REGISTR_MODAL_FIRST_NAME_VALUE_CHANGE,
  REGISTR_MODAL_GET_OTP,
  REGISTR_MODAL_GET_OTP_FAIL,
  REGISTR_MODAL_GET_OTP_SUCCESS,
  REGISTR_MODAL_LAST_NAME_VALUE_CHANGE,
  REGISTR_MODAL_OTP_VALUE_CHANGE,
  REGISTR_MODAL_PHONE_VALUE_CHANGE,
} from '../actions/types';
import { createMaskForPhone } from '../functions';

/** @type {import('../typedef').TRegistrationReducer} */
const INITIAL_STATE = {
  registr_modal_display_status: false,
  // * Место из которого вызвали модалку.
  registr_modal_initiation_point: undefined,
  registr_modal_first_name_value: '',
  registr_modal_first_name_error: '',
  registr_modal_last_name_value: '',
  registr_modal_last_name_error: '',
  registr_modal_phone_value: '+ 7 ',
  registr_modal_sms_repeat_available_error: null,
  registr_modal_otp_get_status: false,
  registr_modal_sms_key_value: '',
  registr_modal_otp_value: '',
  registr_modal_otp_error: '',
  registr_complete: false,
  is_loading: false,
};

/** @returns {import('../typedef').TRegistrationReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть модальное окно регистрации
    case REGISTR_MODAL_DISPLAY_TOGGLE:
      return {
        ...state,
        registr_modal_display_status: action?.payload?.status,
        registr_modal_initiation_point: action?.payload?.initiation_point,
        registr_complete: false,
      };

    // * Измение значения полей модального онка регистрации
    case REGISTR_MODAL_FIRST_NAME_VALUE_CHANGE:
      return { ...state, registr_modal_first_name_value: action.payload };
    case REGISTR_MODAL_LAST_NAME_VALUE_CHANGE:
      return { ...state, registr_modal_last_name_value: action.payload };
    case REGISTR_MODAL_PHONE_VALUE_CHANGE:
      return { ...state, registr_modal_phone_value: createMaskForPhone(action.payload) };
    case REGISTR_MODAL_OTP_VALUE_CHANGE:
      return { ...state, registr_modal_otp_value: action.payload };

    // * Получить ОТП ( проверочный смс-код ) при регистрации
    case REGISTR_MODAL_GET_OTP:
      return {
        ...state,
        is_loading: true,
        registr_modal_first_name_error: '',
        registr_modal_last_name_error: '',
        registr_modal_phone_error: '',
      };
    case REGISTR_MODAL_GET_OTP_SUCCESS:
      return {
        ...state,
        is_loading: false,
        registr_modal_otp_get_status: true,
        registr_modal_sms_key_value: action.payload,
        registr_modal_first_name_error: '',
        registr_modal_last_name_error: '',
        registr_modal_phone_error: '',
      };
    case REGISTR_MODAL_GET_OTP_FAIL:
      return {
        ...state,
        is_loading: false,
        registr_modal_first_name_error: action?.payload?.first_name ? action?.payload?.first_name : '',
        registr_modal_last_name_error: action?.payload?.last_name ? action?.payload?.last_name : '',
        registr_modal_phone_error: action?.payload?.phone ? action?.payload?.phone : '',
        registr_modal_sms_repeat_available_error: action?.payload?.sms_repeat_available ? action?.payload?.sms_repeat_available : '',
      };

    // * Подтвердить ОТП ( проверочный смс-код ) и зарегистрироваться
    case REGISTR_MODAL_CONFIRM_OTP:
      return {
        ...state,
        registr_modal_otp_error: '',
        is_loading: true,
      };
    case REGISTR_MODAL_CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        registr_modal_initiation_point: state.registr_modal_initiation_point,
        registr_complete: true,
        registr_modal_display_status: true,
      };
    case REGISTR_MODAL_CONFIRM_OTP_FAIL:
      return {
        ...state,
        is_loading: false,
        registr_modal_otp_error: action.payload.code ? action.payload.code : '',
      };
    default:
      return state;
  }
};
