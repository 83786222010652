import './NotFoundPage.scss';

import React from 'react';

function NotFoundPage() {
  return (
    <main className='notFoundPage'>
      <div className='notFoundTitle'>Страница не найдена</div>
      <div className='notFoundNote'>Страница, на которую вы хотели перейти, не найдена.</div>
      <div className='notFoundSplash'></div>
    </main>
  );
}

export default NotFoundPage;
