import './Registration.scss';

import React, { useEffect, useState } from 'react';
import { addSeconds, format } from 'date-fns';
import {
  login_modal_display_toggle,
  preface_modal_display_toggle,
  registr_modal_confirm_otp,
  registr_modal_display_toggle,
  registr_modal_first_name_value_change,
  registr_modal_get_otp,
  registr_modal_last_name_value_change,
  registr_modal_otp_value_change,
  registr_modal_phone_value_change,
} from '../../../actions';
import { maskName, maskPhone, maskSms, placeholderPhone, placeholderSms } from '../../../const';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import { Link } from 'react-router-dom';
import Modal from '../../shared/Modal/Modal';
import ReactGA from 'react-ga';
import bg from '../../../assets/images/Registration_completeBg.svg';
import { useWindowDimensions } from '../../../functions';

function Registration() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const {
    registr_modal_initiation_point: initiation_point,
    registr_modal_first_name_value: first_name_value,
    registr_modal_first_name_error: first_name_error,
    registr_modal_last_name_value: last_name_value,
    registr_modal_last_name_error: last_name_error,
    registr_modal_phone_value: phone_value,
    registr_modal_phone_error: phone_error,
    registr_modal_sms_repeat_available_error: sms_repeat_error,
    registr_modal_otp_get_status: opt_get_status,
    registr_modal_otp_value: otp_value,
    registr_modal_otp_error: otp_error,
    registr_modal_sms_key_value: sms_key,
    registr_complete,
    is_loading,
  } = useSelector((state) => state.registrationReducer);
  const [disabled, setDisabled] = useState(true);

  const swapToLogin = () => {
    dispatch(login_modal_display_toggle(true));
    dispatch(registr_modal_display_toggle(false));
  };

  // * Google Analitycs
  useEffect(() => {
    ReactGA.modalview('/registration/');
  }, []);

  /**
   * track disabled state
   */
  useEffect(() => {
    if (!opt_get_status) {
      setDisabled(!first_name_value || !last_name_value || phone_value.length !== 17);
    }
  }, [opt_get_status, first_name_value, last_name_value, phone_value]);

  /**
   * track disabled state
   */
  useEffect(() => {
    if (opt_get_status) {
      setDisabled(!otp_value || otp_value.match(/_/));
    }
  }, [opt_get_status, otp_value]);

  const [cooldownTime, setCooldownTime] = useState(30);

  useEffect(() => {
    if (opt_get_status) {
      const timer = setInterval(() => {
        if (cooldownTime > 0) {
          setCooldownTime((cooldownTime) => cooldownTime - 1);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [opt_get_status, cooldownTime]);

  // * Рендер кнопки завершения регистрации ( варьируется текст на кнопке )
  const renderRegistrationCompeleteButton = () => {
    if (initiation_point === 'become_executor') {
      return (
        <Button
          type='button'
          onClick={() => {
            dispatch(registr_modal_display_toggle(false, ''));
            dispatch(preface_modal_display_toggle(true));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Продолжить" - после завершения регистрации инициированнной через кнопки "Стать исполнителем"',
            });
          }}
          size='normal'
          color='red'
          width='400rem'
          text='Продолжить'
          extraClassName='finishRegistrButton'
        />
      );
    } else {
      return (
        <Button
          type='button'
          onClick={() => {
            dispatch(registr_modal_display_toggle(false, ''));
          }}
          size='normal'
          color='red'
          width='400rem'
          text='Готово'
          extraClassName='finishRegistrButton'
        />
      );
    }
  };

  // * Модальное окно регистрации
  if (!registr_complete) {
    return (
      <Modal extraClassName='registrationForm' onClose={() => dispatch(registr_modal_display_toggle(false, ''))}>
        <div className='title'>Регистрация</div>
        {opt_get_status === false ? (
          <>
            <Input
              type='text'
              name='firstName'
              label='Имя'
              extraClassName='firstName'
              value={first_name_value}
              onChange={(event) => dispatch(registr_modal_first_name_value_change(event.target.value))}
              notValid={first_name_error}
              mask={maskName}
              guide={false}
            />
            <Input
              type='text'
              name='lastName'
              label='Фамилия'
              extraClassName='lastName'
              value={last_name_value}
              onChange={(event) => dispatch(registr_modal_last_name_value_change(event.target.value))}
              notValid={last_name_error}
              mask={maskName}
              guide={false}
            />
            <Input
              type='tel'
              name='phone'
              label='Номер телефона'
              extraClassName='phone'
              value={phone_value}
              onChange={(event) => dispatch(registr_modal_phone_value_change(event.target.value))}
              notValid={phone_error}
            />
            <Button
              type='button'
              onClick={() => dispatch(registr_modal_get_otp(first_name_value, last_name_value, phone_value))}
              size='normal'
              color='red'
              width='400rem'
              extraClassName='getSmsCode'
              text='Получить код по SMS'
              isLoading={is_loading}
              disabled={disabled}
            />
          </>
        ) : (
          <>
            <div className='borderWrapper'>
              <fieldset className='fieldset registrOtpInfo'>
                <label className='label registrOtpInfo'>
                  Вам отправлено SMS на номер <br />
                  {phone_value}
                </label>
              </fieldset>
              <Input
                type='text'
                name='registrOtp'
                label='Введите код из SMS'
                extraClassName='registrOtp'
                value={otp_value}
                onChange={(event) => dispatch(registr_modal_otp_value_change(event.target.value))}
                notValid={otp_error}
                placeholder={placeholderSms}
              />
            </div>
            {cooldownTime > 0 && !phone_error && (
              <div className='repeatSMSCooldown'>
                Запросить код повторно можно будет
                <br /> через {cooldownTime} секунд
              </div>
            )}

            {phone_error && (
              <div className='repeatSMSCooldown'>
                Лимит SMS превышен,
                <br /> повторно запросить можно будет в {format(addSeconds(new Date(), sms_repeat_error), 'kk:mm')}
              </div>
            )}
            {cooldownTime === 0 && !phone_error && (
              <Button
                type='button'
                onClick={() => {
                  dispatch(registr_modal_get_otp(first_name_value, last_name_value, phone_value));
                  setCooldownTime(30);
                }}
                size='normal'
                color='white'
                width='400rem'
                extraClassName='getSmsCode'
                text='Получить код по SMS'
                isLoading={is_loading}
              />
            )}
            <Button
              type='button'
              onClick={() => dispatch(registr_modal_confirm_otp(sms_key, otp_value, phone_value))}
              size='normal'
              color='red'
              width='400rem'
              extraClassName='confirmSmsCode'
              text='Зарегистрироваться'
              isLoading={is_loading}
              disabled={disabled}
            />
          </>
        )}
        <div className='swapToLogin'>
          Уже зарегистрированы?{' '}
          <span className='loginHref' onClick={swapToLogin}>
            Войти.
          </span>
          <br />
          Продолжая регистрацию, вы соглашаетесь с условиями{' '}
          <Link to='/documents/user_agreement/' target='_blank' className='rules'>
            Пользовательского&nbsp;соглашения.
          </Link>
        </div>
      </Modal>
    );
  }
  // * Модальное окно после окончания регистрации
  else {
    return (
      <>
        <Modal extraClassName='registrationFinished' onClose={() => dispatch(registr_modal_display_toggle(false))}>
          <div className='title'>Регистрация завершена</div>
          <img src={bg} width='600rem' className='registrCompleteImage' alt='Success registration illustration' />
          {renderRegistrationCompeleteButton()}
        </Modal>
      </>
    );
  }
}

export default Registration;
