import FAQ_HowToCreateOrderScreen from '../../assets/images/FAQ_HowToCreateOrderScreen.png';

import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function HowToCreateOrderPage() {
  return (
    <main className='howToCreateOrder'>
      <DocumentContent>
        <h1>Как разместить заказ</h1>
        <p>
          Сформулируйте задачу, которую вам необходимо решить. Зайдите на сайт, опишите, что вам нужно сделать в соответствующем поле, и
          нажмите кнопку «Создать заказ».
        </p>
        <img src={FAQ_HowToCreateOrderScreen} alt='screenshot'/>
        <p>
          На странице создания заказа выберите категорию услуги, которая вам нужна, и уточните вид услуги. Например, если вам нужно
          покрасить стены, выберите «Ремонтные и мелкие строительные работы» → «Малярные работы». Чем четче вы опишите задачу и укажете
          категорию услуги, тем быстрее и точнее мы сможем подобрать для вас исполнителей.
        </p>
        <p>Если вы не нашли подходящего типа услуг в нашем списке, выберите «Другое».</p>
        <p>
          При создании заказа опишите как можно более детально, что и как вам нужно сделать. Расскажите, есть ли у вас инструменты или
          исполнителю потребуются свои, приложите фото объекта, укажите ваш адрес и предполагаемое время начала работы.
        </p>
        <p>
          Если вы впервые создаете заказ, система предложит вам указать свой номер телефона, на который будет выслан код доступа. Введите
          полученный код, и можете приступать к работе с signedUP.
        </p>
      </DocumentContent>
    </main>
  );
}

export default HowToCreateOrderPage;
