import './Login.scss';

import React, { useEffect, useState } from 'react';
import { addSeconds, format } from 'date-fns';
import {
  login_modal_confirm_otp,
  login_modal_display_toggle,
  login_modal_get_otp,
  login_modal_otp_value_change,
  login_modal_phone_value_change,
  registr_modal_display_toggle,
} from '../../../actions';
import { maskSms, placeholderSms } from '../../../const';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Login_illustration_01 from '../../../assets/images/Login_illustration_01.svg';
import Modal from '../../shared/Modal/Modal';
import ReactGA from 'react-ga';

function Login() {
  const dispatch = useDispatch();
  const {
    login_modal_phone_value: phone_value,
    login_modal_phone_error: phone_error,
    login_modal_sms_repeat_available_error: sms_repeat_error,
    login_modal_otp_get_status: opt_get_status,
    login_modal_otp_value: otp_value,
    login_modal_otp_error: otp_error,
    login_modal_sms_key_value: sms_key,
    is_loading,
  } = useSelector((state) => state.loginReducer);
  const is_profile_loading = useSelector((state) => state.userReducer.is_loading);

  const swapToRegistration = () => {
    dispatch(login_modal_display_toggle(false));
    dispatch(registr_modal_display_toggle(true));
  };

  const [cooldownTime, setCooldownTime] = useState(30);
  useEffect(() => {
    if (opt_get_status) {
      const timer = setInterval(() => {
        if (cooldownTime > 0) {
          setCooldownTime((cooldownTime) => cooldownTime - 1);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [opt_get_status, cooldownTime]);

  // * Google Analitycs
  useEffect(() => {
    ReactGA.modalview('/login/');
  }, []);

  return (
    <Modal extraClassName='loginForm' onClose={() => dispatch(login_modal_display_toggle(false))}>
      <div className='title'>Войти</div>
      {opt_get_status === false ? (
        <>
          <img src={Login_illustration_01} className='illustrationImage' alt='login illustration' />
          <Input
            type='tel'
            name='phone'
            label='Номер телефона'
            extraClassName='phone'
            value={phone_value}
            onChange={(event) => dispatch(login_modal_phone_value_change(event.target.value))}
            notValid={phone_error}
          />
          {sms_repeat_error && (
            <div className='repeatSMSCooldown'>
              Лимит SMS превышен,
              <br /> повторно запросить можно будет в {format(addSeconds(new Date(), sms_repeat_error), 'kk:mm')}
            </div>
          )}
          <Button
            type='button'
            onClick={() => dispatch(login_modal_get_otp(phone_value))}
            size='normal'
            color='red'
            width='400rem'
            extraClassName='getSmsCode'
            text='Получить код по SMS'
            isLoading={is_loading}
          />
        </>
      ) : (
        <>
          <div className='borderWrapper'>
            <fieldset className='fieldset loginOtpInfo'>
              <label className='label loginOtpInfo'>
                Вам отправлено SMS на номер <br />
                {phone_value}
              </label>
            </fieldset>
            <Input
              type='text'
              name='loginOtp'
              label='Введите код из SMS'
              extraClassName='loginOtp'
              value={otp_value}
              onChange={(event) => dispatch(login_modal_otp_value_change(event.target.value))}
              notValid={otp_error}
              placeholder={placeholderSms}
            />
          </div>
          {cooldownTime > 0 && !phone_error && (
            <div className='repeatSMSCooldown'>
              Запросить код повторно можно будет
              <br /> через {cooldownTime} секунд
            </div>
          )}

          {phone_error && (
            <div className='repeatSMSCooldown'>
              Лимит SMS превышен,
              <br /> повторно запросить можно будет в {format(addSeconds(new Date(), sms_repeat_error), 'kk:mm')}
            </div>
          )}

          {cooldownTime === 0 && !phone_error && (
            <Button
              type='button'
              onClick={() => {
                dispatch(login_modal_get_otp(phone_value));
                setCooldownTime(30);
              }}
              size='normal'
              color='white'
              width='400rem'
              extraClassName='getSmsCode'
              text='Получить код по SMS'
              isLoading={is_loading}
            />
          )}
          <Button
            type='button'
            onClick={() => dispatch(login_modal_confirm_otp(sms_key, otp_value, phone_value))}
            size='normal'
            color='red'
            width='400rem'
            extraClassName='login'
            text='Войти'
            isLoading={is_loading}
          />
        </>
      )}
      <div className='swapToRegistration'>
        Еще не зарегистрированы?
        <br />
        <span className='registrLinkButton' onClick={swapToRegistration}>
          Регистрация.
        </span>
      </div>
    </Modal>
  );
}

export default Login;
