import * as serviceWorker from './serviceWorker';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

// process.env.REACT_APP_BACKEND_URL
// 'http://localhost:8000/api/'
// 'https://dev.signedup.me/api/'
global.backendURL = process.env.REACT_APP_BACKEND_URL || 'https://api.dev.signedup.me/api/';
global.siteURL = 'https://signedup.me';
global.prerelease = false;
global.send_sms = process.env.REACT_APP_BACKEND_URL === 'https://signedup.me/api/' ? true : false;
global.debug = process.env.REACT_APP_BACKEND_URL === 'https://signedup.me/api/' ? false : true;

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
