import './HeaderChatList.scss';

import React from 'react';
import { notifications_display_toggle } from '../../../actions';
import onClickOutside from 'react-onclickoutside';
import { useDispatch } from 'react-redux';
import useUserNotifications from '../../../hooks/useUserNotifications';
import { useWindowDimensions } from '../../../functions';

function HeaderNotificationsList() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { status, data } = useUserNotifications();

  const hide = (event) => {
    event && event.stopPropagation();
    width > 500 && dispatch(notifications_display_toggle(false));
  };
  HeaderNotificationsList['handleClickOutside_HeaderNotificationsList'] = () => hide();

  return (
    <div className='headerChatListWrapper'>
      <div className={`headerChatList${data.notifications.length === 0 ? ' empty' : ''}`}>
        {status === 'success' && data.notifications.length >= 1 ? (
          data.notifications.map((notification) => (
            <div className='paddingWrapper' key={`headerChatListChat + ${notification.id}`}>
              <div className={`headerChatListChat${notification.has_unread ? ' hasUnread' : ''}`}>
                <div className='orderName'>
                  <span className='opacity'>Заказ «</span>
                  <span className='red'>{notification.text}</span>
                  <span className='opacity'>»</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='epmty'>У вас пока нет сообщений.</div>
        )}
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => HeaderNotificationsList['handleClickOutside_HeaderNotificationsList'],
};

export default onClickOutside(HeaderNotificationsList, clickOutsideConfig);
