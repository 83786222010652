import './Index.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useWindowDimensions } from '../../../../functions';
import {
  order_creation_title_value_change,
  order_creation_category_id_value_change,
  order_creation_subcategory_id_value_change,
} from '../../../../actions';

import Button from '../../../shared/Button/Button';

function IndexCustomer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const title = useSelector((state) => state.orderCreationReducer.title);

  const renderH1 = () => {
    if (width > 1024) {
      return (
        <h1 className='h1'>
          Бесплатный подбор <br />
          мастера под ваши задачи
          <br />
          <small className='h1subTitle'>
            Задумали ремонт? Нужны уборка или доставка? Экономьте деньги и<br />
            время. Создайте заказ, и мы подберем для вас подходящих мастеров.
          </small>
        </h1>
      );
    } else {
      return (
        <h1 className='h1'>
          Бесплатный подбор <br />
          мастера под ваши
          <br /> задачи
          <br />
          <small className='h1subTitle'>
            Задумали ремонт? Нужны уборка или доставка?
            <br /> Экономьте деньги и время. Создайте заказ, и мы
            <br /> подберем для вас подходящих мастеров.
          </small>
        </h1>
      );
    }
  };

  return (
    <section className='index customer bgTypeOne'>
      <div className='container'>
        <div className='userTypeLinks'>
          <Link className='link active' to='/'>
            Создать заказ
          </Link>
          <Link className='link' to='/executor/'>
            Стать исполнителем
          </Link>
        </div>
        {renderH1()}
        <div className='search'>
          <input
            type='text'
            placeholder='Какую задачу вам нужно решить? |'
            className='searchInput'
            value={title}
            onChange={(event) => dispatch(order_creation_title_value_change(event.target.value))}
          />
          <Button
            type='link'
            href='/create_order/'
            color='red'
            size='large'
            text='Создать заказ'
            extraClassName='procedeOrderButton'
            width='225rem'
          />
        </div>
        <div className='proposedOrder'>
          Например,{' '}
          <span
            className='proposedOrderLink'
            onClick={() => {
              dispatch(order_creation_title_value_change('Ремонт стиральной машины'));
              dispatch(order_creation_category_id_value_change(5));
              dispatch(order_creation_subcategory_id_value_change(16));
              history.push('/create_order/');
            }}
          >
            ремонт стиральной машины
          </span>
        </div>
      </div>
    </section>
  );
}

export default IndexCustomer;
