import { useQuery } from 'react-query';
import axios from 'axios';

const getUserExecutorCategoris = async (_, user_id) => {
  const { data } = await axios.get(`${global.backendURL}users/user/${user_id}/categories/`);
  return data;
};

export default function useUserExecutorCategoris(user_id) {
  return useQuery(['userExecutorCategoris', user_id], getUserExecutorCategoris);
}
