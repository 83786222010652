import { useQuery } from 'react-query';
import axios from 'axios';

const getUserData = async (_, user_id) => {
  const { data } = await axios.get(`${global.backendURL}users/user/${user_id}/`);
  return data;
};

export default function useUserData(user_id) {
  return useQuery(['userData', user_id], getUserData);
}
