import './FAQPage.scss';
import React from 'react';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import Button from '../../components/shared/Button/Button';

function FAQPage() {
  return (
    <main className='faqPage'>
      <div className='container'>
        <PageTitle text='Ответы на частые вопросы' />
        <div className='faqQuestion'>
          <div className='title'>О сервисе</div>
          <div className='description'>
            signedUP — это удобный сервис, который помогает быстро найти подходящих исполнителей для решения бытовых задач. Задачи могут
            быть разными...
          </div>
          <Button type='link' href='/faq/about_us/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Как зарегистрироваться</div>
          <div className='description'>
            В signedUP вы можете быть одновременно и заказчиком, и исполнителем. Регистрация в сервисе бесплатна, достаточно указать свой
            номер телефона и получить на него код доступа...
          </div>
          <Button type='link' href='/faq/how_to_registrate/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Как стать исполнителем</div>
          <div className='description'>
            Начать работать на себя, искать новых клиентов и больше зарабатывать — легко, с новым сервисом signedUP...
          </div>
          <Button type='link' href='/faq/how_to_become_executor/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Когда можно оставлять личные данные, а когда не стоит</div>
          <div className='description'>
            Многие сайты, в том числе signedUP, просят сообщить данные вашего удостоверения личности и прислать фотографии документа, чтобы
            убедиться, что вы указали достоверную информацию. Но иногда так делают и мошенники. Рассказываем, зачем мошенникам нужны ваши
            данные и как отличить добропорядочные сайты от опасных...
          </div>
          <Button type='link' href='/faq/personal_data_safety/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Как разместить заказ</div>
          <div className='description'>
            Сформулируйте задачу, которую вам необходимо решить. Зайдите на сайт, опишите, что вам нужно сделать в соответствующем поле, и
            нажмите кнопку «Создать заказ»...
          </div>
          <Button type='link' href='/faq/how_to_create_order/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Какие заказы запрещено размещать</div>
          <div className='description'>
            Просим вас внимательно прочитать эту страницу. Здесь вы найдете правила размещения заказов. Пожалуйста, для комфортной и
            безопасной работы с сайтом, следуйте этим правилам...
          </div>
          <Button type='link' href='/faq/orders_rules/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Что такое Сделка без риска</div>
          <div className='description'>
            Услуга Сделка без риска помогает заказчикам и исполнителям безопасно взаимодействовать друг с другом. Сделка без риска работает
            следующим образом...
          </div>
          <Button type='link' href='/faq/what_is_safe_deal/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Правила использования чата</div>
          <div className='description'>
            Пользователи могут переписываться в чате сервиса, однако такая переписка не является личной. Администрация...
          </div>
          <Button type='link' href='/faq/chat_rules/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Как заполнить профиль, чтобы получать больше заказов</div>
          <div className='description'>Профиль — это первое, что видят заказчики, просматривая отклик исполнителя. Очень важно...</div>
          <Button type='link' href='/faq/how_to_get_more_orders/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Что нужно знать для безопасного использования сервиса</div>
          <div className='description'>
            Мы просим наших пользователей прислать фотографии или сканы удостоверений личности, чтобы сравнить данные, которые они указали в
            анкете signedUP, с данными в документе. Также мы проверяем подлинность юридических лиц и просим пользователей, желающих работать
            как юрлицо, предоставить подтверждающие документы...
          </div>
          <Button type='link' href='/faq/why_we_are_safe/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
        <div className='faqQuestion'>
          <div className='title'>Рейтинги и критерии оценки пользователей</div>
          <div className='description'>
            При выборе заказчика или исполнителя всегда обращайте внимание на отзывы и рейтинг пользователя. Рейтинг формируется...
          </div>
          <Button type='link' href='/faq/rates/' color='dark' size='small' width='140rem' text='Подробнее' />
        </div>
      </div>
    </main>
  );
}

export default FAQPage;
