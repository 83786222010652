import { HEADER_MENU_DISPLAY_TOGGLE, HEADER_MOBILE_MENU_DISPLAY_TOGGLE } from '../actions/types';

/** @type {import('../typedef').THeaderReducer} */
const INITIAL_STATE = {
  header_menu_display_status: false,
  header_mobile_menu_display_status: false,
};

/** @returns {import('../typedef').THeaderReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADER_MENU_DISPLAY_TOGGLE:
      return { ...state, header_menu_display_status: action.payload };
    case HEADER_MOBILE_MENU_DISPLAY_TOGGLE:
      return { ...state, header_mobile_menu_display_status: action.payload };
    default:
      return { ...state };
  }
};
