import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function OrdersRulesPage() {
  return (
    <main className='ordersRulesPage'>
      <DocumentContent>
        <h1>Какие заказы запрещено размещать</h1>
        <p>
          Просим вас внимательно прочитать эту страницу. Здесь вы найдете правила размещения заказов. <br />
          Пожалуйста, для комфортной и безопасной работы с сайтом, следуйте этим правилам.
        </p>
        <p>
          При размещении заказов, пользователи должны соблюдать требования действующего законодательства Республики Казахстан. Заказы должны
          соответствовать всем требованиям и правилам действующего законодательства Республики Казахстан. Запрещено размещать заказы, не
          соответствующие и/или нарушающие требования и правила действующего законодательства Республики Казахстан, а так же заказы,
          предметом которых является деятельность (товары, работы, услуги) незаконная или запрещенная законодательством Республики
          Казахстан.
          <br />В том числе запрещено размещать заказы, целью или предметом которых является:
        </p>
        <ul>
          <li>Регистрация пользователей на сторонних сайтах, реклама услуг третьих лиц, фальсификация отзывов;</li>
          <li>Услуги эротического характера, проституция, любая порнография, сайты с откровенными фотографиями;</li>
          <li>Займы, операции, связанные с пополнением баланса в платежных системах, денежные переводы;</li>
          <li>Накрутка подписчиков в социальных сетях, махинации со статистикой сайтов;</li>
          <li>Заказ спам-рассылок, поиск людей для рассылки сообщений пользователям социальных сетей, email-рассылок;</li>
          <li>Услуг по продаже товаров Заказчика;</li>
          <li>
            Любая деятельность, связанная с лекарствами, БАДами, ядами, психотропными веществами и их прекурсорами, выращиванием
            наркосодержащих растений, в том числе их хранение, распространение, транспортировка, изготовление и т.д;
          </li>
          <li>Любая деятельность, связанная с алкоголем и этиловым спиртом;</li>
          <li>Золото, слитки, ювелирные изделия;</li>
          <li>
            Любая деятельность, касающаяся ведения боевых действий, поставок любого вида оружия и его составных частей, военной техники и
            запчастей, взрывчатых веществ, средств взрывания, пороха, ракетного топлива, военных средств связи, гуманитарная помощь,
            независимо от стороны конфликта и пр.;
          </li>
          <li>Любое взаимодействие с музейными объектами и коллекциями</li>
          <li>Деятельность, связанная с использованием радиоактивных материалов и изотопов;</li>
          <li>
            Космическое оборудование, аппараты и объекты наземной космической инфраструктуры, в том числе спутниковые навигационные системы,
            ракетно-космические комплексы, средства связи и нормативно-техническая документация на создание и эксплуатацию;
          </li>
          <li>Казино, азартные игры, лотереи, букмекерская деятельность и тотализаторы;</li>
          <li>Финансовые пирамиды, HYIP;</li>
          <li>Деятельность, порочащая честь и достоинство человека, нарушение тайны переписки, личной жизни;</li>
          <li>
            Услуги по созданию, использованию и распространению вредоносного программного обеспечения, противозаконное предоставление
            доступа к информации в том числе с использованием специальных средств для получения сведений, таких как персональная информация,
            коммерческая, налоговая, банковская, врачебная тайны;
          </li>
          <li>Кардшаринг;</li>
          <li>Услуги по подделыванию документов, изготовлению поддельных кредитных карт и финансовых документов;</li>
          <li>
            Призывы к экстремистской деятельности, возбуждению ненависти либо вражды, дискриминации и унижению человеческого достоинства;
          </li>
          <li>Посредничество во взяточничестве;</li>
          <li>Любые услуги, товары, работы, ограниченные в обороте или запрещенные согласно законодательству Республики Казахстан.</li>
        </ul>
        <p>
          Пользователи самостоятельно несут полную ответственность за размещенные Заказы в соответствии с действующим законодательством
          Республики Казахстан, и за их соответствие нормам и требованиям законодательства Республики Казахстан.
        </p>
      </DocumentContent>
    </main>
  );
}

export default OrdersRulesPage;
