import React from 'react';
import { Link } from 'react-router-dom';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function ConfidentialityPolicyPage() {
  return (
    <main className='confidentialityPolicyPage'>
      <DocumentContent>
        <h1>Политика конфиденциальности</h1>
        <p>
          Настоящая Политика конфиденциальности, далее — «Политика», регулирует отношения между компанией Товарищество с ограниченной
          ответственностью «Unicorn Prod» (БИН 200740022394), A05D5F5, Республика Казахстан, г. Алматы, Алмалинский,район ул. Жамбыла, д.
          114/85, блок В, офис 5, далее — «Компания» и всеми Пользователями Сервисов, принадлежащих Компании, далее — «Пользователи». Под
          Сервисами подразумеваются веб-сайт{' '}
          <Link target='_blank' to='/'>
            signedup.me
          </Link>{' '}
          и (или) версии для мобильных устройств на платформе iOS и Android.
        </p>
        <p>
          Настоящая Политика размещена по адресу
          <Link target='_blank' to='/documents/confidentiality_policy/' className='cutOnMobile'>
            {' '}
            signedup.me/documents/confidentiality_policy
          </Link>{' '}
          и является неотъемлемой частью Пользовательского соглашения на использование Сервисов Компании, размещенного по адресу{' '}
          <Link target='_blank' to='/documents/user_agreement/' className='cutOnMobile'>
            signedup.me/documents/user_agreement
          </Link>
        </p>
        <p>
          При использовании Сервисов Компании Пользователи в прямом виде соглашаются на обработку и хранение своих персональных данных
          способом, описанным в настоящей Политике.
        </p>
        <p>
          Под обработкой подразумевается любое действие Компании с персональными данными Пользователей вне зависимости от используемых
          средств, в частности сбор, накопление, упорядочение, хранение, редактирование данных, а также их анализ, распространение,
          передача, обезличивание и удаление.
        </p>
        <p>
          Компания контролирует и устанавливает цели обработки персональных данных Пользователей, в том числе с возможностью передачи
          персональных данных третьим лицам для анализирования информации. Доступ к персональным данным может быть предоставлен
          компаниям-партнерам, находящимся за рубежом, а также сторонним системам коммуникации и сбора статистики. Передача третьим лицам
          осуществляется в порядке, предусмотренном законодательством.
        </p>
        <p>
          В процессе использования Сервисов Компании, для формирования Заказов и получения услуг, Пользователи соглашаются на передачу
          информации о своих коммуникационных реквизитах. Чтобы устанавливать связь с клиентами и улучшать качество сервиса, а также для
          осуществления расследования любых жалоб Пользователей, предоставления им эффективного обслуживания при обращении в службу
          поддержки и при использовании чата, рассылки сообщений через электронную почту и СМС (от чего Пользователи могут отказаться в
          любой момент), Компании необходим доступ к следующим данным:
        </p>
        <p>
          <ul>
            <li>Фамилия Имя Отчество Пользователя, название юридического лица;</li>
            <li>мобильный телефон;</li>
            <li>место проживания;</li>
            <li>
              адрес проживания и иная контактная информация (в зависимости от вида используемых услуг), для юр.лица — адрес регистрации;
            </li>
            <li>адрес электронной почты;</li>
            <li>индивидуальный идентификационный номер (ИИН), для юр.лица — БИН;</li>
            <li>пол;</li>
            <li>дата рождения;</li>
            <li>фотографии, опционально предоставляемые Пользователем для более подробного объяснения к Заказу;</li>
            <li>
              сообщения из переписки между Пользователями, данные с микрофона и камеры при передаче голосовых сообщений и фотографий, а
              также сообщения и медиа-файлы, адресованные Компании;
            </li>
            <li>информация, созданная Пользователем при размещении запросов к службе поддержки Сервисов Компании;</li>
            <li>
              иная информация, которую Компания запрашивает у Пользователей для удостоверения их личности. Например, Компания в праве
              запросить копии/сканы удостоверения личности Заказчика или Исполнителя, подтверждение о получении образования, сертификаты или
              иные документы, соответствующие ситуации, для подтверждения личности и квалификации Исполнителя, а также личности Заказчика
              для дополнительных гарантий безопасности использования Сервисов Компании.
            </li>
          </ul>
        </p>
        <p>
          Компании необходимы следующие данные для внутреннего контроля, улучшения содержания Сервисов, предотвращения и пресечения любых
          ошибок и неисправностей, которые могут возникать при использовании Сервисов Компании, а также для уведомления Пользователей об
          изменениях информации, персонализированного подбора услуг и показа рекламы, качественного распределения Заказов между
          Пользователями, анализа эффективности и обеспечения приватности и безопасности Пользователей, а также с целью маркетинговых
          исследований, отслеживания доходов, охраны прав и интересов Пользователей:
        </p>
        <p>
          <ul>
            <li>данные об устройстве, в т.ч IP-адрес хоста;</li>
            <li>вид и версия операционной системы (персонального компьютера или мобильного устройства) Пользователя;</li>
            <li>геолокация;</li>
            <li>информация, которая содержится в файлах cookie.</li>
          </ul>
        </p>
        <p>
          Компания в праве скомпилировать данные в обезличенном виде со сведениями, полученными от третьих сторон. <br />
          Также компания может предоставлять персональные данные Пользователей:
        </p>
        <p>
          <ul>
            <li>
              провайдерам услуг, а также правоохранительным органам и другим государственным структурам и лицам при соответствующем запросе
              от них в рамках расследования уголовных дел и иной противоправной деятельности, которая может привлечь к ответственности
              Компанию или Пользователей ее Сервисов;
            </li>
            <li>аналитическим сервисам, таким как Google.analytics, Яндекс.метрика и т.д;</li>
          </ul>
        </p>
        <p>
          Компания уважает права Пользователей на приватность, поэтому не будет предоставлять или продавать персональные данные третьим
          сторонам в целях, не предусмотренных настоящей Политикой, без прямого согласия Пользователей. Кроме того, Компания откажет в
          предоставлении персональных данных любым лицам и организациям, включая уполномоченные государственные органы, если запрос не будет
          оформлен в строгом соответствии с законом.
        </p>
        <p>
          Компания предпринимает все меры предосторожности для защиты конфиденциальности данных. Любые данные Пользователей, которые
          становятся доступны Компании, собираются и хранятся на защищенном сервере. К этим сведениям имеют доступ только те сотрудники
          Компании, которым необходимо выполнять рабочие функции, связанные со взаимодействием с персональными данными.
        </p>
        <p>
          Если Пользователи самостоятельно разглашают персональные данные третьим сторонам, например, Исполнителям или другим Пользователям,
          то к данному случаю могут применяться другие правила. Компания рекомендует Пользователям уточнять цели использования их данных
          третьими лицами до их предоставления.
        </p>
        <p>
          Компания в любой момент может изменить настоящую Политику, уведомив об этом Пользователей путем размещения новой редакции
          документа на сайте. Пользователь обязуется самостоятельно проверять Политику на предмет внесения изменений. Внесенные изменения
          вступают в силу со дня, следующего за днем публикации этих изменений на сайте. Пользователь вправе не принять изменения и
          дополнения в Политику, что означает отказ Пользователя от использования Сервисов Компании, а также отказ от всех предоставленных
          ему в связи с использованием прав.
        </p>
        <p>
          Если вы хотите связаться с нами, чтобы высказать свое мнение о наших принципах соблюдения конфиденциальности, Вы можете написать
          сообщение по адресу{' '}
          <a target='_blank' rel='noopener noreferrer' href='mailto:artur@signedup.me'>
            artur@signedup.me
          </a>
        </p>
      </DocumentContent>
    </main>
  );
}

export default ConfidentialityPolicyPage;
