import './Files.scss';

import React, { useRef } from 'react';
import { FileDrop } from 'react-file-drop';

/**
 * files component
 * @param {Object} props
 * @param {ReactNode} [props.children] children
 * @param {string} [props.extraClassName] additional class name
 * @param {(files: FileList) => void} [props.onUpdate] files change handler
 * @param {string} [props.accept] list of accepted file types
 * @param {boolean} [props.multiple] able to upload multiple files
 */
function Files(props) {
  const { children, extraClassName, onUpdate, accept, multiple } = props;
  /** @type {React.MutableRefObject<React.LegacyRef<HTMLInputElement>>} */
  const ref = useRef(null);

  /**
   * upload files routine
   * @param {FileList} files files list
   */
  const uploadFiles = (files) => {
    if (files && typeof onUpdate === 'function') {
      onUpdate(files);
    }
  };

  /**
   * imitate user click for hidden input
   */
  const handleClick = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  /**
   * input handler
   * @param {React.ChangeEvent<HTMLInputElement>} event event object
   */
  const handleFileChange = (event) => {
    uploadFiles(event.currentTarget.files);
  };

  /**
   * handle for file drop
   * @param {FileList} files files list
   */
  const handleDrop = (files) => {
    uploadFiles(files);
  };

  return (
    <div className={`filesSharedComponent ${extraClassName || ''}`} onClick={() => handleClick()}>
      <input type='file' ref={ref} onChange={handleFileChange} accept={accept} multiple={multiple ? true : null} hidden />
      <FileDrop onDrop={handleDrop}>{children}</FileDrop>
    </div>
  );
}

export default Files;
