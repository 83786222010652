import './PromoJoinUs.scss';

import React from 'react';
import Button from '../../../shared/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { executor_categories_modal_display_toggle, preface_modal_display_toggle } from '../../../../actions';

function PromoJoinUs() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);

  const becomeExecutor = () => {
    if (user.verification_status !== 'verified') {
      dispatch(preface_modal_display_toggle(true));      
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      dispatch(executor_categories_modal_display_toggle(true));      
    }
  };

  return (
    <section className='promoJoinUs'>
      <div className='container'>
        <h2 className='h2'>
          Присоединяйтесь к <br /> проекту signedUP, <br /> работайте на себя и <br /> зарабатывайте с нами!
        </h2>
        <Button
          type={user.is_executor ? 'link' : 'button'}
          href='/orders/available/'
          color='red'
          size='large'
          text={user.is_executor ? 'Найти заказ' : 'Стать исполнителем'}
          width={user.is_executor ? '360rem' : '340rem'}
          onClick={() => becomeExecutor()}
          extraClassName='becomeExecutorButton'
        />        
      </div>
    </section>
  );
}

export default PromoJoinUs;
