import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function ChatRulesPage() {
  return (
    <main className='chatRulesPage'>
      <DocumentContent>
        <h1>Правила использования чата</h1>
        <p>
          Пользователи могут переписываться в чате сервиса, однако такая переписка не является личной. Администрация в любой момент имеет
          доступ к отправляемым сообщениям. Это предусмотрено на случай, если возникнет спорная ситуация.
        </p>
        <p>
          Пользователь в любое время может получать сообщения от администрации сервиса. Эти сообщения могут быть любого характера, в том
          числе и рекламного.
        </p>
        <p>
          Администрация не модерирует, не блокирует и не фильтрует сообщения из чата между пользователями. Однако в случае нарушения правил
          сервиса аккаунт пользователя может быть заблокирован. Пользователям запрещается использовать чат для следующего:
        </p>
        <ul>
          <li>Для совершения действий, нарушающих законодательство Республики Казахстан, а также норм международного права;</li>
          <li>Для отправки рекламы, спама, «схем-пирамид» и «писем счастья»;</li>
          <li>
            Для отправки незаконных, запрещенных материалов, вредоносных программ, угрожающих, оскорбительных сообщений, клеветы, сообщений,
            нарушающих авторские права, пропагандирующими ненависть или дискриминацию людей по расовому, этническому, половому, социальному
            признакам;
          </li>
          <li>Для отправки материалов, нарушающих права третьих лиц.</li>
        </ul>
        <p>
          В случаях, предусмотренных законодательством Республики Казахстан, администрация сервиса имеет право предоставить доступ третьим
          лицам к личным сообщениям пользователей.
        </p>
      </DocumentContent>
    </main>
  );
}

export default ChatRulesPage;
