import axios from 'axios';

import { PROFILE_CATEGORIES_LOAD, PROFILE_CATEGORIES_LOAD_ERROR, PROFILE_CATEGORIES_LOAD_SUCCESS } from './types';

/**
 * load categories for profile
 * @param {number} id profile id
 */
export const profile_categories_load = (id) => {
  return (dispatch) => {
    dispatch({ type: PROFILE_CATEGORIES_LOAD });

    axios({
      method: 'get',
      url: `${global.backendURL}users/user/${id}/categories/`,
    })
      .then((response) => {
        dispatch({ type: PROFILE_CATEGORIES_LOAD_SUCCESS, payload: response.data });
        global.debug &&console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: PROFILE_CATEGORIES_LOAD_ERROR, payload: error.response });
        global.debug &&console.log(error.response);
      });
  };
};
