import {
  LEGAL_ENTITY_CONFIRM_BIN_VALUE_CHANGE,
  LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE,
  LEGAL_ENTITY_CONFIRM_MODAL_DISPLAY_TOGGLE,
  LEGAL_ENTITY_CONFIRM_SAVE,
  LEGAL_ENTITY_CONFIRM_SAVE_ERROR,
  LEGAL_ENTITY_CONFIRM_SAVE_SUCCESS,
} from '../actions/types';

/** @type {import("../typedef").TConfirmLegalEntityModalReducer} */
const INITIAL_STATE = {
  confirm_legal_entity_display_status: false,
  confirm_legal_entity_bin: '',
  confirm_legal_entity_image: null,
  is_complete: false,
  is_loading: false,
};

/** @returns {import("../typedef").TConfirmLegalEntityModalReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEGAL_ENTITY_CONFIRM_MODAL_DISPLAY_TOGGLE:
      return { ...state, confirm_legal_entity_display_status: action.payload };
    case LEGAL_ENTITY_CONFIRM_BIN_VALUE_CHANGE:
      return { ...state, confirm_legal_entity_bin: action.payload };
    case LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE:
      return { ...state, confirm_legal_entity_image: action.payload };
    case LEGAL_ENTITY_CONFIRM_SAVE:
      return { ...state, is_loading: true };
    case LEGAL_ENTITY_CONFIRM_SAVE_SUCCESS:
      return { ...state, is_loading: false, is_complete: true };
    case LEGAL_ENTITY_CONFIRM_SAVE_ERROR:
      return { ...state, is_loading: false };
    default:
      return { ...state };
  }
};
