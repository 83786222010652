import './ProfileFinancesTab.scss';

import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';

import SafeDealBanner from '../../../shared/SafeDealBanner/SafeDealBanner';
import Button from '../../../shared/Button/Button';
import { useWindowDimensions } from '../../../../functions';

function ProfileFinancesTab() {
  const { width } = useWindowDimensions();
  // const dispatch = useDispatch();
  const is_executor = useSelector((state) => state.profileReducer.user.is_executor);
  const app_access_count = useSelector((state) => state.profileReducer.user.app_access_count);

  const buy = () => {
    axios({
      method: 'post',
      url: global.backendURL + 'payment/access/',
      data: {
        count: 2,
      },
    })
      .then((result) => {
        global.debug && console.log('%cPayment - Success:', 'color: $00ee00;');
        global.debug && console.log(result.data.url);
        window.location.replace(result.data.url);
      })
      .catch((error) => {
        global.debug && console.log('%cPayment - Fail:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };

  return (
    <div className='profileFinancesTab'>
      {is_executor && (
        <div className='payment'>
          <div className='description'>
            {app_access_count > 0 && (
              <span>
                Вам доступно заказов: {app_access_count}
                <br />
                <br />
                Когда вы выполните 2 пробных заказа без комиссии, вам потребуется пополнить счет, чтобы брать новые заказы. В signedUP
                предусмотрена комиссия 500 тг за заказ. Деньги списываются только в случае, когда вас назначат исполнителем конкретного
                заказа. Отклики на заказы бесплатные.
              </span>
            )}
          </div>
          <div className='buy'>Купить доступ к ешё 2 заказам:</div>
          <Button type='button' onClick={() => buy()} color='red' size='large' width='270rem' text='Купить' />
        </div>
      )}
      <SafeDealBanner />
    </div>
  );
}

export default ProfileFinancesTab;
