import {
  CITY_SELECT_MODAL_DISPLAY_TOGGLE,
  CITY_SELECT_MODAL_SELECTED_CITY_ID_VALUE_CHANGE,
  CITY_SELECT_MODAL_SUBMIT,
  CITY_SELECT_MODAL_SUBMIT_FAIL,
  CITY_SELECT_MODAL_SUBMIT_SUCCESS,
} from './types';
import { profile_load, user_load } from '.';

import axios from 'axios';

export const city_select_modal_submit = (user_id, city_id) => {
  return (dispatch) => {
    dispatch({ type: CITY_SELECT_MODAL_SUBMIT });

    axios({
      method: 'post',
      url: global.backendURL + `users/user/${user_id}/`,
      data: { city_id },
    })
      .then((response) => {
        dispatch({ type: CITY_SELECT_MODAL_SUBMIT_SUCCESS });
        dispatch(profile_load(user_id));
        dispatch(user_load(user_id));
        global.debug && console.log('Город выбран - Success:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: CITY_SELECT_MODAL_SUBMIT_FAIL });
        global.debug && console.log('Город выбран - Fail:');
        global.debug && console.log(error.response);
      });
  };
};

export const city_select_modal_selected_city_id_value_change = (payload) => ({
  type: CITY_SELECT_MODAL_SELECTED_CITY_ID_VALUE_CHANGE,
  payload,
});

export const city_select_modal_display_toggle = (payload) => ({
  type: CITY_SELECT_MODAL_DISPLAY_TOGGLE,
  payload,
});
