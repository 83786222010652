import './Index.scss';

import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { executor_categories_modal_display_toggle, preface_modal_display_toggle } from '../../../../actions';

import Button from '../../../shared/Button/Button';

function IndexExecutor() {
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const becomeExecutor = () => {
    if (user.verification_status !== 'verified') {
      dispatch(preface_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем" - 1-ый экран',
      });
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      dispatch(executor_categories_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем ( добавить категории )" - 1-ый экран',
      });
    }
  };

  return (
    <section className='index executor bgTypeFive'>
      <div className='container'>
        <div className='userTypeLinks'>
          <Link className='link' to='/'>
            Создать заказ
          </Link>
          <Link className='link active' to='/executor/'>
            Стать исполнителем
          </Link>
        </div>
        <h1 className='h1'>
          Новые возможности <br />
          для заработка <br />
          <small className='h1subTitle'>
            Делаете ремонт под ключ? Оказываете услуги клининга?
            <br /> Занимаетесь ногтевым сервисом? Находите новых
            <br /> клиентов вместе с signedUP. Зарегистрируйтесь как
            <br /> исполнитель и начните работать на себя.
          </small>
        </h1>
        <Button
          type={user.is_executor ? 'link' : 'button'}
          href='/orders/available/'
          color='red'
          size='large'
          text={user.is_executor ? 'Найти заказ' : 'Стать исполнителем'}
          width={user.is_executor ? '360rem' : '340rem'}
          onClick={() => becomeExecutor()}
          extraClassName='becomeExecutorButton'
        />
      </div>
    </section>
  );
}

export default IndexExecutor;
