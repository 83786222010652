import axios from 'axios';

import {
  CUSTOMER_ORDERS_DASHBOARD_LOAD,
  CUSTOMER_ORDERS_DASHBOARD_LOAD_SUCCESS,
  CUSTOMER_ORDERS_DASHBOARD_LOAD_FAIL,
  EXECUTOR_ORDERS_DASHBOARD_LOAD,
  EXECUTOR_ORDERS_DASHBOARD_LOAD_SUCCESS,
  EXECUTOR_ORDERS_DASHBOARD_LOAD_FAIL,
} from './types';

export const customer_orders_dashboard_load = (customer_id) => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ORDERS_DASHBOARD_LOAD });
    axios({
      method: 'get',
      url: global.backendURL + 'tasks/',
      params: {
         customer_id,
        task_status: 'all',
      },
    })
      .then((result) => {
        dispatch({
          type: CUSTOMER_ORDERS_DASHBOARD_LOAD_SUCCESS,
          payload: result.data.tasks,
        });
        global.debug &&console.log('Все созданные заказы пользователя:\n');
        global.debug &&console.log(result);
      })
      .catch((error) => {
        dispatch({
          type: CUSTOMER_ORDERS_DASHBOARD_LOAD_FAIL,
        });
        global.debug &&console.log('Ошибка при получении созданных заказов пользователя:\n');
        global.debug &&console.log(error.response);
        // history.push('/500/');
      });
  };
};

export const executor_orders_dashboard_load = (user_id) => {
  return (dispatch) => {
    dispatch({ type: EXECUTOR_ORDERS_DASHBOARD_LOAD });
    axios({
      method: 'get',
      url: global.backendURL + 'tasks/user/',
      params: {
        user_id,
      },
    })
      .then((result) => {
        dispatch({
          type: EXECUTOR_ORDERS_DASHBOARD_LOAD_SUCCESS,
          payload: result.data.tasks,
        });
        global.debug &&console.log('Все доступные исполнителю заказы:\n');
        global.debug &&console.log(result);
      })
      .catch((error) => {
        dispatch({
          type: EXECUTOR_ORDERS_DASHBOARD_LOAD_FAIL,
        });
        global.debug &&console.log('Ошибка при получении доступных исполнителю заказов:\n');
        global.debug &&console.log(error.response);
        // history.push('/500/');
      });
  };
};
