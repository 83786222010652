import {
  ORDER_RESPONSE_MODAL_DISPLAY_TOGGLE,
  ORDER_RESPONSE_MODAL_RESPONSE_TEXT_VALUE_CHANGE,
  ORDER_RESPONSE_MODAL_ORDER_ID_VALUE_CHANGE,
  ORDER_RESPONSE_MODAL_SUBMIT,
  ORDER_RESPONSE_MODAL_SUBMIT_SUCCESS,
  ORDER_RESPONSE_MODAL_SUBMIT_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  order_response_modal_response_text_value: '',
  order_response_modal_display_status: false,
  order_response_modal_order_id_value: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть модальное окно отклика
    case ORDER_RESPONSE_MODAL_DISPLAY_TOGGLE:
      return { ...state, order_response_modal_display_status: action.payload };

    case ORDER_RESPONSE_MODAL_RESPONSE_TEXT_VALUE_CHANGE:
      return { ...state, order_response_modal_response_text_value: action.payload };
    case ORDER_RESPONSE_MODAL_ORDER_ID_VALUE_CHANGE:
      return { ...state, order_response_modal_order_id_value: action.payload };

    // * Отправка отклика
    case ORDER_RESPONSE_MODAL_SUBMIT:
      return { ...state, is_loading: true };
    case ORDER_RESPONSE_MODAL_SUBMIT_SUCCESS:
      return { ...state, ...INITIAL_STATE };
    case ORDER_RESPONSE_MODAL_SUBMIT_FAIL:
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};
