import './OrderPage.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { order_create_again } from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import Button from '../../components/shared/Button/Button';

function OrderPage_Canceled_Customer({ order_id, order, is_executor }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Заказ отменен' />

        <OrderInfo order={order} />
        {!is_executor && (
          <Button
            type='button'
            onClick={() => dispatch(order_create_again(order_id, history))}
            text='Опубликовать заказ еще раз'
            size='large'
            color='dark'
            width='360rem'
            extraClassName='recreateOrderButton'
          />
        )}
      </div>
    </section>
  );
}

export default OrderPage_Canceled_Customer;
