import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function WhatIsSafeDealPage() {
  return (
    <main className='whatIsSafeDealPage'>
      <DocumentContent>
        <h1>Что такое Сделка без риска</h1>
        <p>
          Услуга Сделка без риска помогает заказчикам и исполнителям безопасно взаимодействовать друг с другом. Сделка без риска работает
          следующим образом:
        </p>
        <ul>
          <li>Заказчик и исполнитель привязывают свои банковские карты к сервису signedUP и договариваются о точной стоимости работ;</li>
          <li>
            Заказчик создает Сделку без риска, и оговоренная сумма списывается с его карты, но не поступает напрямую на карту исполнителя, а
            резервируется на специальном счету в платежной системе;
          </li>
          <li>Деньги списываются только тогда, когда исполнитель успешно завершает заказ;</li>
          <li>
            Если исполнитель отказывается от выполнения работы, заказчик закрывает заказ со статусом «Не выполнен», и деньги возвращаются на
            карту заказчика;
          </li>
          <li>
            При возникновении спорных ситуаций, рекомендуем незамедлительно писать в поддержку signedUP. Наши специалисты изучат все детали
            и помогут решить проблему.
          </li>
        </ul>
        <p>
          Преимущества Сделки без риска очевидны: гарантированная оплата работы, безопасное взаимодействие между заказчиком и исполнителем и
          возврат денег, если что-то пошло не так.
        </p>
        <p>
          При сотрудничестве через Сделку без риска сервисом signedUP предусмотрена комиссия за услуги банка — 12% от стоимости заказа.{' '}
        </p>
      </DocumentContent>
    </main>
  );
}

export default WhatIsSafeDealPage;
