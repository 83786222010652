import { ORDER_INFO_GALLERY_DISPLAY_TOGGLE } from './types';

/**
 * toggle visibility of order info gallery component
 * @param {boolean} payload
 */
export const order_info_gallery_display_toggle = (payload) => ({
  type: ORDER_INFO_GALLERY_DISPLAY_TOGGLE,
  payload,
});
