import {
  ORDER_CREATION_CATEGORY_ID_VALUE_CHANGE,
  ORDER_CREATION_CITY_ID_VALUE_CHANGE,
  ORDER_CREATION_CREATE,
  ORDER_CREATION_CREATE_FAIL,
  ORDER_CREATION_CREATE_SUCCESS,
  ORDER_CREATION_DATE_VALUE_CHANGE,
  ORDER_CREATION_DESCRIPTION_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_DATE_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_PLACE_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_PRICE_VALUE_CHANGE,
  ORDER_CREATION_FIRST_NAME_VALUE_CHANGE,
  ORDER_CREATION_IMAGE1_VALUE_CHANGE,
  ORDER_CREATION_IMAGE2_VALUE_CHANGE,
  ORDER_CREATION_IMAGE3_VALUE_CHANGE,
  ORDER_CREATION_LAST_NAME_VALUE_CHANGE,
  ORDER_CREATION_NEED_FAST_VALUE_CHANGE,
  ORDER_CREATION_ONLY_YUR_VALUE_CHANGE,
  ORDER_CREATION_PHONE_VALUE_CHANGE,
  ORDER_CREATION_PLACE_VALUE_CHANGE,
  ORDER_CREATION_PRICE_MAX_VALUE_CHANGE,
  ORDER_CREATION_PRICE_MIN_VALUE_CHANGE,
  ORDER_CREATION_SAFE_PAYMENT_VALUE_CHANGE,
  ORDER_CREATION_SEND_EMAIL_VALUE_CHANGE,
  ORDER_CREATION_SUBCATEGORY_ID_VALUE_CHANGE,
  ORDER_CREATION_TIME_VALUE_CHANGE,
  ORDER_CREATION_TITLE_VALUE_CHANGE,
} from '../actions/types';

import { createMaskForTime } from '../functions';

/** @type {import('../typedef').TOrderCreationReducer} */
const INITIAL_STATE = {
  // * Значения полей формы создания заказа.
  // * Main часть ( always required fields ).
  order_creation_title_value: '',
  order_creation_category_id_value: undefined,
  order_creation_subcategory_id_value: undefined,
  order_creation_description_value: '',

  // * Место.
  order_creation_city_id_value: '',
  order_creation_executor_place_value: true,
  order_creation_place_value: '',

  // * Дата и время.
  order_creation_executor_date_value: true,
  order_creation_date_value: undefined,
  order_creation_time_value: '',
  order_creation_need_fast_value: false,

  // * Цена.
  order_creation_executor_price_value: false,
  order_creation_price_min_value: '',
  order_creation_price_max_value: '',

  // * Контакты.
  order_creation_first_name_value: '',
  order_creation_last_name_value: '',
  order_creation_phone_value: '',

  // * Уведомления.
  order_creation_send_email_value: true,

  // * Безопасная сделка и юр. лица.
  order_creation_safe_payment_value: false,
  order_creation_only_yur_value: false,

  // * Photos
  order_creation_image1: null,
  order_creation_image2: null,
  order_creation_image3: null,

  // * Статусы компонентов
  order_created_status: false,
  is_loading: false,
};

/** @returns {import('../typedef').TOrderCreationReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Измение значения полей формы создания заказа.
    // * Main часть.
    case ORDER_CREATION_TITLE_VALUE_CHANGE:
      return { ...state, order_creation_title_value: action.payload };
    case ORDER_CREATION_CATEGORY_ID_VALUE_CHANGE:
      return { ...state, order_creation_category_id_value: action.payload, order_creation_subcategory_id_value: undefined };
    case ORDER_CREATION_SUBCATEGORY_ID_VALUE_CHANGE:
      return { ...state, order_creation_subcategory_id_value: action.payload };
    case ORDER_CREATION_DESCRIPTION_VALUE_CHANGE:
      return { ...state, order_creation_description_value: action.payload };

    // * Место.
    case ORDER_CREATION_CITY_ID_VALUE_CHANGE:
      return { ...state, order_creation_city_id_value: action.payload };
    case ORDER_CREATION_EXECUTOR_PLACE_VALUE_CHANGE:
      return { ...state, order_creation_executor_place_value: action.payload };
    case ORDER_CREATION_PLACE_VALUE_CHANGE:
      return { ...state, order_creation_place_value: action.payload };

    // * Сроки.
    case ORDER_CREATION_EXECUTOR_DATE_VALUE_CHANGE:
      return { ...state, order_creation_executor_date_value: action.payload };
    case ORDER_CREATION_DATE_VALUE_CHANGE:
      return { ...state, order_creation_date_value: action.payload };
    case ORDER_CREATION_TIME_VALUE_CHANGE:
      return { ...state, order_creation_time_value: createMaskForTime(action.payload) };
    case ORDER_CREATION_NEED_FAST_VALUE_CHANGE:
      return { ...state, order_creation_need_fast_value: !state.order_creation_need_fast_value };

    // * Цена.
    case ORDER_CREATION_EXECUTOR_PRICE_VALUE_CHANGE:
      return {
        ...state,
        order_creation_executor_price_value: action.payload,
        order_creation_price_min_value: '',
        order_creation_price_max_value: '',
      };
    case ORDER_CREATION_PRICE_MIN_VALUE_CHANGE:
      return { ...state, order_creation_price_min_value: action.payload };
    case ORDER_CREATION_PRICE_MAX_VALUE_CHANGE:
      return { ...state, order_creation_price_max_value: action.payload };

    // * Контакты.
    case ORDER_CREATION_FIRST_NAME_VALUE_CHANGE:
      return { ...state, order_creation_first_name_value: action.payload };
    case ORDER_CREATION_LAST_NAME_VALUE_CHANGE:
      return { ...state, order_creation_last_name_value: action.payload };
    case ORDER_CREATION_PHONE_VALUE_CHANGE:
      return { ...state, order_creation_phone_value: action.payload };

    // * Уведомления.
    case ORDER_CREATION_SEND_EMAIL_VALUE_CHANGE:
      return { ...state, order_creation_send_email_value: !state.order_creation_send_email_value };

    // * Безопасная сделка & юр. лица.
    case ORDER_CREATION_SAFE_PAYMENT_VALUE_CHANGE:
      return { ...state, order_creation_safe_payment_value: !state.order_creation_safe_payment_value };
    case ORDER_CREATION_ONLY_YUR_VALUE_CHANGE:
      return { ...state, order_creation_only_yur_value: !state.order_creation_only_yur_value };

    // * Создание заказа
    case ORDER_CREATION_CREATE:
      return { ...state, is_loading: true };
    case ORDER_CREATION_CREATE_SUCCESS:
      return { ...state, ...INITIAL_STATE };
    case ORDER_CREATION_CREATE_FAIL:
      return { ...state, is_loading: false };

    // * photo append
    case ORDER_CREATION_IMAGE1_VALUE_CHANGE:
      return { ...state, order_creation_image1: action.payload };
    case ORDER_CREATION_IMAGE2_VALUE_CHANGE:
      return { ...state, order_creation_image2: action.payload };
    case ORDER_CREATION_IMAGE3_VALUE_CHANGE:
      return { ...state, order_creation_image3: action.payload };
    default:
      return state;
  }
};
