import './EmailVerificationModal.scss';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';

function EmailVerificationModal() {
  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const history = useHistory();
  const {key_id} = useParams();
  const [error, setError] = useState('Упс, что-то пошло не так.(');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState('');

  useEffect(() => {
    setIsLoading(true);
    if (key_id) {

      axios({
        method: 'post',
        url: `${global.backendURL}users/email-verification/`,
        data: { key: key_id },
      })
        .then(() => {
          setSuccess('E-mail успешно подтверждён.');
          setIsLoading(false);
        })
        .catch((error) => {
          error?.response?.data?.code === 'email_already_verified' ? setSuccess('E-mail уже подтверждён.') : setError('Упс, что-то пошло не так.(\n\nПопробуйте ещё раз.');
          setIsLoading(false);
        });
    }
  }, [key_id]);

  return (
    <Modal extraClassName='emailVerificationModal' onClose={() => history.push('/')}>
      <div className='title'>Подтверждение E-mail</div>
      {isLoading && <div className='status'>Пожалуйста, подождите...</div>}
      {!isLoading && !success && <div className='status'>{error}</div>}
      {!isLoading && success && <div className='status'>{success}</div>}
      <Button
        type='button'
        color='red'
        size='large'
        text='Готово'
        width='380rem'
        disabled={!success}
        extraClassName='ready'
        onClick={() => {
          if (user_id) {
            history.push(`/profile/${user_id}`);
          } else {
            history.push('/');
          }
        }}
      />
    </Modal>
  );
}

export default EmailVerificationModal;
