import './ProfilePassportModal.scss';

import ru from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { executor_categories_modal_display_toggle, profile_load, user_load } from '../../../../actions';
import {
  profile_passport_address_change,
  profile_passport_birthday_change,
  profile_passport_display_status_change,
  profile_passport_first_name_change,
  profile_passport_gender_change,
  profile_passport_image1_change,
  profile_passport_image1_error_change,
  profile_passport_image2_change,
  profile_passport_image2_error_change,
  profile_passport_image3_change,
  profile_passport_image3_error_change,
  profile_passport_last_name_change,
  profile_passport_save,
  profile_passport_surname_change,
} from '../../../../actions/ProfilePassportActions';
import { acceptImageTypes, maskAddress, maskName } from '../../../../const';

import Button from '../../../shared/Button/Button';
import DatepickerDate from '../../../shared/DatepickerDate/DatepickerDate';
import Input from '../../../shared/Input/Input';
import Modal from '../../../shared/Modal/Modal';
import PortfolioImage from '../../../shared/PortfolioImage/PortfolioImage';
import Select from '../../../shared/Select/Select';

registerLocale('ru', ru);

/**
 * profile passport modal component
 */
function ProfilePassportModal() {
  const history = useHistory();
  /** @type {string} */
  const id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  /** @type {import('../../../../typedef').TProfilePassportReducer} */
  const {
    first_name,
    surname,
    last_name,
    birthday,
    gender,
    // will be used in the future
    // city_id,
    address,
    passport_image1,
    passport_image1_error,
    passport_image2,
    passport_image2_error,
    passport_image3,
    passport_image3_error,
    is_loading,
    next_step,
    single_mode,
  } = useSelector((state) => state.profilePassportReducer);
  // will be used in the future
  // /** @type {import('../../../../typedef').TUser} */
  // const user = useSelector((state) => state.userReducer.user);
  const genders = [
    { id: 0, title: 'Мужской' },
    { id: 1, title: 'Женский' },
  ];
  /**
   * settings for image uploaders
   * @type {[import('../../../../typedef').TImage[],React.Dispatch<React.SetStateAction<import('../../../../typedef').TImage[]>>]}
   */
  const [images, setImages] = useState([
    { value: passport_image1, url: null, action: profile_passport_image1_change, error: passport_image1_error },
    { value: passport_image2, url: null, action: profile_passport_image2_change, error: passport_image2_error },
    { value: passport_image3, url: null, action: profile_passport_image3_change, error: passport_image3_error },
  ]);

  // will be used in the future
  // const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  // will be used in the future
  // /**
  //  * find city by id
  //  * @param {number} id city id
  //  */
  // const selectCity = (id) => {
  //   const city = cities.find((item) => item.city_id === id);
  //   if (city) {
  //     dispatch(profile_passport_city_change(city));
  //   }
  // };

  // will be used in the future
  // /**
  //  * load list of cities
  //  */
  // useEffect(() => {
  //   axios({
  //     method: 'get',
  //     url: `${global.backendURL}cities/`,
  //     params: { country_id: user.country_id || 4 },
  //   })
  //     .then((result) => setCities(result.data.cities))
  //     .catch((error) => global.debug &&console.log(error));
  // }, [user.country_id]);

  /**
   * settings for inputs
   */
  const bio = [
    {
      value: last_name,
      label: 'Фамилия',
      onChange: (event) => dispatch(profile_passport_last_name_change(event.target.value)),
    },
    {
      value: first_name,
      label: 'Имя',
      onChange: (event) => dispatch(profile_passport_first_name_change(event.target.value)),
    },
    {
      value: surname,
      label: 'Отчество',
      onChange: (event) => dispatch(profile_passport_surname_change(event.target.value)),
    },
  ];

  /**
   * remove gaps between images
   * @param {-1 | 1} direction bubbling direction
   */
  const bubbleImage = (direction) => {
    const update = [...images];
    const start = direction === 1 ? 0 : images.length - 1;
    const end = direction === 1 ? images.length : 0;

    for (let i = start; i !== end; i += direction) {
      const next = i + direction;
      const entry = { ...images[i] };
      const condition = direction === 1 ? !entry.value && !!images[next] : !!entry.value && !!images[next] && !images[next].value;
      if (condition) {
        const swap = { ...entry };
        update[i].value = images[next].value;
        update[i].url = images[next].url;
        update[next].value = swap.value;
        update[next].url = swap.url;
      }
    }

    update.forEach((entry) => dispatch(entry.action(entry.value)));

    setImages(update);
  };

  /**
   * update image
   * @param {File} file list of files
   * @param {import('../../../../typedef').TImage} image image description
   */
  const updateImage = (file, image) => {
    if (file) {
      const blob = new Blob([file.slice()], { type: file.type });

      image.value = file;
      image.url = URL.createObjectURL(blob);

      bubbleImage(-1);
    }
  };

  /**
   * delete image
   * @param {import('../../../../typedef').TImage} image image description
   * @param {number} index index of deleted image
   */
  const deleteImage = (image, index) => {
    const { url } = image;
    if (url) {
      URL.revokeObjectURL(url);
    }
    debugger;
    image.value = null;
    image.url = '';

    if (index === 0) {
      dispatch(profile_passport_image1_error_change(''));
    }

    if (index === 1) {
      dispatch(profile_passport_image2_error_change(''));
    }

    if (index === 2) {
      dispatch(profile_passport_image3_error_change(''));
    }

    bubbleImage(1);
  };

  /**
   * tracking values to set disabled state for ready button
   */
  useEffect(() => {
    setDisabled(
      !first_name ||
      !last_name ||
      !birthday ||
      !gender ||
      // will be used in the future
      // !city_id ||
      !address ||
      !(!!passport_image1 || !!passport_image2 || !!passport_image3)
    );
    // will be used in the future
    // }, [first_name, last_name, surname, birthday, gender, city_id, address, passport_image1, passport_image2, passport_image3]);
  }, [first_name, last_name, surname, birthday, gender, address, passport_image1, passport_image2, passport_image3]);

  // user settings were successfully saved
  useEffect(() => {
    if (next_step === true) {
      // hide current modal
      dispatch(profile_passport_display_status_change(false));
      // show next modal
      if (!single_mode) {
        dispatch(executor_categories_modal_display_toggle(true));
      }
      // update page header
      dispatch(user_load(id));
      // update profile
      dispatch(profile_load(id));
    }
  }, [next_step, single_mode, id, dispatch]);

  useEffect(() => {
    const errors = [passport_image1_error, passport_image2_error, passport_image3_error];
    if (images.some((entry, index) => entry.error !== errors[index])) {
      setImages(images.map((entry, index) => ({ ...entry, error: errors[index] })));
    }
  }, [images, passport_image1_error, passport_image2_error, passport_image3_error]);

  return (
    <Modal
      extraClassName='profilePassportModal'
      onClose={() => dispatch(profile_passport_display_status_change(false))}
      isWaiting={is_loading}
    >
      <div className='title'>Данные удостоверения личности</div>
      <>
        <div className='bio'>
          {bio.map((item, index) => (
            <Input key={index} type='text' value={item.value} label={item.label} onChange={item.onChange} mask={maskName} guide={false} />
          ))}
        </div>
        <div className='extra'>
          <div className='datepickerContainer'>
            <DatepickerDate date={birthday} action={(date) => dispatch(profile_passport_birthday_change(date))} />
          </div>
          <Select
            name='gender'
            label='Ваш пол'
            value={gender}
            options={genders}
            onClick={(event) => dispatch(profile_passport_gender_change(event.target.id))}
            selection={parseInt(gender)}
          />
        </div>
        <div className='address'>
          {/* will be used in the future */}
          {/* <Select name='city' label='Город' value={city_id} options={cities} onClick={(event) => selectCity(parseInt(event.target.id))} /> */}
          <Input
            type='text'
            label='Адрес по прописке'
            value={address}
            onChange={(event) => dispatch(profile_passport_address_change(event.target.value))}
            mask={maskAddress}
            guide={false}
          />
        </div>
        <div className='attention'>
          Будьте внимательны при заполнении данных. Если предоставленная информация
          <br />
          окажется недостоверной, ваш профиль может быть заблокирован.
        </div>
        <div className='scans_title'>Загрузите сканы вашего паспорта или удостоверения личности <span className='attention'>Макс. допустимый размер файла - 5мб.</span></div>
        <div className='scans'>
          {images.map((image, index) => {
            /** @type {import('../../../../typedef').TPortfolioImage} */
            const settings = {
              ...image,
              accept: acceptImageTypes,
              extraClassName: 'passportModal',
              canDelete: !!image.value,
              placeholder: !image.value,
              uploadCallback: (file) => updateImage(file, image),
              deleteCallback: () => deleteImage(image, index),
            };
            return <PortfolioImage key={index} {...settings} />;
          })}
        </div>
        <Button
          color='red'
          size='normal'
          text='Продолжить'
          width='245rem'
          extraClassName='profilePassportReady'
          disabled={disabled}
          onClick={() =>
            dispatch(
              profile_passport_save(id, {
                first_name,
                last_name,
                surname,
                birthday,
                // will be used in the future
                // city_id,
                gender,
                address,
                passport_image1,
                passport_image2,
                passport_image3,
              }, history)
            )
          }
        />
        <div className='agreement'>
          Нажимая «Продолжить», вы подтверждаете, что проходите регистрацию впервые, <br />
          указали о себе достоверные данные и согласны на обработку персональных данных в <br />
          соответствии с нашей{' '}
          <Link to='/documents/confidentiality_policy/' target='_blank' className='accent'>
            Политикой&nbsp;конфиденциальности
          </Link>
          .
        </div>
      </>
    </Modal>
  );
}

export default ProfilePassportModal;
