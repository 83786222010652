import './OrderInfo.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { order_info_gallery_display_toggle } from '../../../../actions';
import { createMaskForPrice } from '../../../../functions';
import OrderGallery from '../OrderGallery/OrderGallery';

function OrderInfo({ order, executorsNotified, statusBar }) {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const { order_info_gallery_display } = useSelector((state) => state.orderInfoReducer);
  const photos = [order.image_1, order.image_2, order.image_3].filter((image) => !!image);

  const photoClick = (position) => {
    setIndex(position);
    dispatch(order_info_gallery_display_toggle(true));
  };

  const renderPlace = (executor_place, place) => {
    if (executor_place || !place) {
      // return 'Место: у исполнителя';
      return 'Место: по договорённости';
    }
    return `${place}`;
  };

  const renderTime = (executor_date, need_fast, date, time) => {
    if (executor_date || !date) {
      if (need_fast) {
        return 'Сроки: как можно быстрее';
      }
      return 'Сроки: по договорённости';
    }
    return `${date} ${time ? 'в ' + time.toString().slice(0, 5) : ''}`;
  };

  const renderPrice = (executor_price, price_max, price_min) => {
    if (executor_price || (!price_max && !price_min)) {
      return 'Стоимость: по договорённости';
    }
    if (price_max === price_min) {
      return `${createMaskForPrice(price_max.toString())}`;
    }
    return `${price_min ? 'от ' + createMaskForPrice(price_min.toString()) : ''} 
            ${price_max ? 'до ' + createMaskForPrice(price_max.toString()) : ''}`;
  };

  return (
    <div className='orderInfo'>
      <h2 className='title'>{order.title}</h2>
      <div className='categorySubcategory'>
        <div className='category'>{order.category_title}</div>
        <div className='subcategory'>{order.subcategory_title}</div>
      </div>
      {(order.safe_payment || order.only_yur) && (
        <div className='tags'>
          {order.safe_payment && <div className='safeDeal'>Сделка без риска</div>}
          {order.only_yur && <div className='legal'>Нужны закрывающие документы</div>}
        </div>
      )}

      {executorsNotified && statusBar ? (
        <div className='executorsNotified'>
          <div className='notificationsAmount'>
            <span className='number'>{executorsNotified}</span>
            <span className='text'>
              исполнителей получили
              <br />
              уведомление о заявке
            </span>
          </div>
          <div className='statusBar'>
            <div className='statusBarFilled' style={{ width: statusBar }}></div>
          </div>
        </div>
      ) : undefined}
      <div className='description'>{order.description}</div>
      <div className='placeTimePrice'>
        <div className='place'>{renderPlace(order.executor_place, order.place)}</div>
        <div className='time'>{renderTime(order.executor_date, order.need_fast, order.date, order.time)}</div>
        <div className='price'>{renderPrice(order.executor_price, order.price_max, order.price_min)}</div>
      </div>
      {photos instanceof Array && !!photos.length && (
        <div className='photos'>
          {photos.map((photo, index) => (
            <div key={index} className='photo' style={{ backgroundImage: `url('${photo}')` }} onClick={() => photoClick(index)}>
              <div className='cover'></div>
            </div>
          ))}
        </div>
      )}
      {order.only_yur && (
        <a
          href='https://storage.googleapis.com/signedup-storage/public/upload/legal/%D0%A8%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B.zip'
          target='_blank'
          rel='noopener noreferrer'
          className='filesForLegal'
        >
          Скачать шаблоны закрывающих документов
        </a>
      )}

      <div className='importantAlert'>
        Если вы доверяете исполнителю ценные вещи, крупную задачу или впускаете в дом, проверьте его паспорт и попросите подписать
        документы.
      </div>
      {order_info_gallery_display && <OrderGallery index={index} photos={photos} />}
    </div>
  );
}

export default OrderInfo;
