import './ServicePage.scss';

import React from 'react';

function ServicePage() {
  return (
    <div className='servicePage'>
      <div className='servicePageCaption'>На сайте идут технические работы</div>
      <div className='servicePageLabel'>Мы работаем над улучшением нашего сервиса.</div>
    </div>
  );
}

export default ServicePage;
