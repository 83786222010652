import axios from 'axios';

import { GLOBAL_VARIABLES_SET_TOKEN, GLOBAL_VARIABLES_SET_USER_ID } from '../actions/types';

/** @type {import('../typedef').TGlobalVariablesReducer} */
const INITIAL_STATE = {
  user_id_value: '',
  token_value: '',
};

/** @returns {import('../typedef').TGlobalVariablesReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_VARIABLES_SET_TOKEN:
      /** @type {string} */
      const token_value = action.payload;

      if (token_value) {
        // adding auth header to all requests
        axios.defaults.headers.common['Authorization'] = `Token ${token_value}`;
      } else {
        // removing auth header from all requests
        delete axios.defaults.headers.common['Authorization'];
      }

      return { ...state, token_value };
    case GLOBAL_VARIABLES_SET_USER_ID:
      return { ...state, user_id_value: action.payload };
    default:
      return { ...state };
  }
};
