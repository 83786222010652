import './OrdersDashbordItem.scss';
import icon_starActive from '../../../assets/images/icon_starActive.svg';
import icon_chatActive from '../../../assets/images/icon_chatActive.svg';
import icon_safeDealActive from '../../../assets/images/icon_safeDealActive.svg';

import React from 'react';
import { createMaskForPrice } from '../../../functions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { order_create_again } from '../../../actions';

import Button from '../Button/Button';

function CustomerOrdersDashbordItem({ order }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const renderStatus = () => {
    switch (order.task_status) {
      case '0':
        return <div className='status'>Ищем исполнителей</div>;
      case '1':
        return <div className='status'>Есть отклики</div>;
      case '2':
        return <div className='status active'>В работе</div>;
      case '3':
        return <div className='status'>Выполнен</div>;
      case '4':
        return <div className='status'>Не выполнен</div>;
      case '5':
        return <div className='status'>Отменён</div>;
      default:
        break;
    }
  };

  const renderPlace = () => {
    if (order.executor_place || !order.place) {
      return 'Место: у исполнителя';
    } else {
      return `${order.place}`;
    }
  };

  const renderTime = () => {
    if (order.executor_date || !order.date) {
      if (order.need_fast) {
        return 'Сроки: как можно быстрее';
      } else {
        return 'Сроки: по договорённости';
      }
    } else {
      return `${order.date} ${order.time ? 'в ' + order.time.toString().slice(0, 5) : ''}`;
    }
  };

  const renderPrice = () => {
    if (order.executor_price || (!order.price_max && !order.price_min)) {
      return 'Стоимость: по договорённости';
    } else {
      if (order.price_max === order.price_min) {
        return `${createMaskForPrice(order.price_max.toString())}`;
      } else {
        return `${order.price_min ? 'от ' + createMaskForPrice(order.price_min.toString()) : ''} 
                ${order.price_max ? 'до ' + createMaskForPrice(order.price_max.toString()) : ''}`;
      }
    }
  };

  const renderExecutor = () => {
    if (order.task_status === '2' || order.task_status === '3' || order.task_status === '4') {
      return (
        <div className='executor'>
          <div className='title'>Исполнитель</div>
          <div
            className={`avatar${!order.applications[0]?.executor.image ? ' noAvatar' : ''}`}
            style={{ backgroundImage: `url('${order.applications[0]?.executor.image}')` }}
          ></div>
          <div className='name'>
            {order.applications[0]?.executor.first_name} {order.applications[0]?.executor.last_name}
          </div>
          <div className='rating'>
            <img className='ratingIcon' src={icon_starActive} alt='icon' />
            <span className='ratingText'>{order?.applications[0]?.executor?.executor_rate || 0}</span>
          </div>
          <div className='reviews'>
            <img className='reviewsIcon' src={icon_chatActive} alt='icon' />
            <span className='reviewsText'>{order?.applications[0]?.executor?.feedback_count || 0} отзывов</span>
          </div>
        </div>
      );
    }
  };

  const renderButtons = () => {
    switch (order.task_status) {
      case '0':
        return <Button type='link' size='small' color='red' text='Перейти к заказу' width='185rem' href={`/order/${order.id}/`} />;
      case '1':
        return (
          <>
            <Button type='link' size='small' color='red' text='Перейти к заказу' width='185rem' href={`/order/${order.id}/`} />
          </>
        );
      case '2':
        return (
          <>
            <Button type='link' href={`/order/${order.id}/`} size='small' color='red' text='Перейти к заказу' width='185rem' />;
            <Button type='button' size='small' color='white' text='Написать в поддержку' width='225rem' />
          </>
        );
      case '3':
        return (
          <>
            <Button
              type='link'
              size='small'
              color='red'
              text={`${order?.feedback?.rate ? 'Перейти к заказу' : 'Оставить отзыв'}`}
              width='185rem'
              href={`/order/${order.id}/`}
            />
            ;
            <Button
              type='button'
              onClick={() => dispatch(order_create_again(order.id, history))}
              size='small'
              color='dark'
              text='Опубликовать заказ еще раз'
              width='270rem'
            />
          </>
        );
      case '4':
        return (
          <Button
            type='button'
            onClick={() => dispatch(order_create_again(order.id, history))}
            size='small'
            color='dark'
            text='Опубликовать заказ еще раз'
            width='270rem'
          />
        );
      case '5':
        return (
          <>
            <Button type='link' size='small' color='red' text='Перейти к заказу' width='185rem' href={`/order/${order.id}/`} />;
            <Button
              type='button'
              onClick={() => dispatch(order_create_again(order.id, history))}
              size='small'
              color='dark'
              text='Опубликовать заказ еще раз'
              width='270rem'
            />
            ;
          </>
        );
      default:
        break;
    }
  };

  return (
    <div className='ordersDashbordItem customerOrdersDashbordItem'>
      <h2 className='title'>{order.title}</h2>
      {renderStatus()}
      {(order.safe_payment || order.only_yur) && (
        <div className='tags'>
          {order.safe_payment && <div className='safeDeal'>Сделка без риска</div>}
          {order.only_yur && <div className='legal'>Нужны закрывающие документы</div>}
        </div>
      )}
      <div className='address'>{renderPlace()}</div>
      <div className='dateTime'>{renderTime()}</div>
      <div className='price'>{renderPrice()}</div>
      {renderExecutor()}
      <div className='buttons'>{renderButtons()}</div>
    </div>
  );
}

export default CustomerOrdersDashbordItem;
