import {
  ALERT_MODAL_BUTTONS_CHANGE,
  ALERT_MODAL_CONTENT_CHANGE,
  ALERT_MODAL_DISPLAY_TOGGLE,
  ALERT_MODAL_EXTRA_CLASS_NAME_CHANGE,
  ALERT_MODAL_SETTINGS_CHANGE,
  ALERT_MODAL_TITLE_CHANGE,
} from '../actions/types';

/** @type {import('../typedef').TAlertModalReducer} */
const INITIAL_STATE = {
  alert_modal_display_status: false,
  title: '',
  extraClassName: '',
  content: '',
  buttons: [],
};

/** @returns {import('../typedef').TAlertModalReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALERT_MODAL_DISPLAY_TOGGLE:
      return { ...state, alert_modal_display_status: action.payload };
    case ALERT_MODAL_TITLE_CHANGE:
      return { ...state, title: action.payload };
    case ALERT_MODAL_EXTRA_CLASS_NAME_CHANGE:
      return { ...state, extraClassName: action.payload };
    case ALERT_MODAL_CONTENT_CHANGE:
      return { ...state, content: action.payload };
    case ALERT_MODAL_BUTTONS_CHANGE:
      return { ...state, buttons: action.payload };
    case ALERT_MODAL_SETTINGS_CHANGE:
      return {
        ...state,
        title: action.payload.title,
        extraClassName: action.payload.extraClassName,
        content: action.payload.content,
        buttons: action.payload.buttons,
      };
    default:
      return { ...state };
  }
};
