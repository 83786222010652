import './OrderPage.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { order_cancel } from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrderStages from '../../components/shared/OrderStages/OrderStages';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderChangeDetails from '../../components/pageSpecific/OrderPage/OrderChangeDetails/OrderChangeDetails';
import OrderResponses from '../../components/pageSpecific/OrderPage/OrderResponses/OrderResponses';
import Button from '../../components/shared/Button/Button';

function OrderPage_WithResponses_Customer({ order_id, order }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text={`Мы нашли ${order.applications.length} исполнителя`} smallText='Мы сообщим вам о новых исполнителях.' />

        <OrderStages activeStage='2' />

        <OrderInfo order={order} />

        <OrderChangeDetails />

        <Button
          type='button'
          onClick={() => dispatch(order_cancel(order_id, history))}
          text='Отменить заказ'
          size='large'
          color='white'
          width='360rem'
          extraClassName='cancelButton'
        />

        <OrderResponses id={order.id} responses={order.applications} />
      </div>
    </section>
  );
}

export default OrderPage_WithResponses_Customer;
