import Categories from '../../components/pageSpecific/HomePage/Categories/Categories.js';
import DownloadOurApp from '../../components/pageSpecific/HomePage/DownloadOurApp/DownloadOurApp.js';
import Economy from '../../components/pageSpecific/HomePage/Economy/Economy.js';
import Features from '../../components/pageSpecific/HomePage/Features/Features.js';
import FrequentQuestions from '../../components/pageSpecific/HomePage/FrequentQuestions/FrequentQuestions.js';
import IndexCustomer from '../../components/pageSpecific/HomePage/Index/IndexCustomer.js';
import React from 'react';
import WhyWeBetter from '../../components/pageSpecific/HomePage/WhyWeBetter/WhyWeBetter.js';

function HomePage() {
  return (
    <main className='homePage'>
      <IndexCustomer />
      <Categories />
      <Features />
      <WhyWeBetter />
      <DownloadOurApp />
      <Economy />
      <FrequentQuestions />
    </main>
  );
}

export default HomePage;
