import './PromoSafeDeal.scss';

import React from 'react';

function PromoSafeDeal() {
  return (
    <section className='promoSafeDeal'>
      <div className='container'>
        <h2 className='h2'>Сделка без риска</h2>
        <div className='safeDealAdvantage'>
          <div className='number'>83%</div>
          <div className='description'>положительных отзывов о мастерах в категории</div>
        </div>
        <div className='safeDealAdvantage'>
          <div className='number'>521</div>
          <div className='description'>отзывов заказчиков оставили за последние 12 месяцев</div>
        </div>
        <div className='safeDealAdvantage'>
          <div className='number'>478</div>
          <div className='description'>положительных отзывов</div>
        </div>
      </div>
    </section>
  );
}

export default PromoSafeDeal;
