import { LOADER_DISPLAY_TOGGLE } from '../actions/types';

/** @type {import('../typedef').TLoaderReducer} */
const INITIAL_STATE = {
  loader_display_status: false,
};

/** @returns {import('../typedef').TLoaderReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADER_DISPLAY_TOGGLE:
      return { ...state, loader_display_status: action.payload };
    default:
      return { ...state };
  }
};
