import { PREFACE_LEGAL_ENTITY_MODAL_DISPLAY_TOGGLE } from './types';

/**
 * toggle legal entity preface modal state
 * @param {boolean} payload
 */
export const preface_legal_entity_modal_display_toggle = (payload) => ({
  type: PREFACE_LEGAL_ENTITY_MODAL_DISPLAY_TOGGLE,
  payload,
});
