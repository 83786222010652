import {
  ADD_PAYMENT_METHOD_MODAL_DISPLAY_TOGGLE,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS,
  ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL }
  from './types';
import axios from 'axios';

export const add_payment_method_modal_display_toggle = (payload) => ({
  type: ADD_PAYMENT_METHOD_MODAL_DISPLAY_TOGGLE,
  payload,
});


export const add_payment_method_modal_get_form_data = (user_id, return_url) => {
  return (dispatch) => {
    dispatch({ type: ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA });
    axios({
      method: 'post',
      url: global.backendURL + `wallet/card-executor/`,
      data: {
        // user_id,
        return_url
      }
    })
      .then((result) => {
        dispatch({ type: ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS, payload: result.data });
        global.debug && console.log('%cADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: ADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL, payload: error.data });
        global.debug && console.log('%cADD_PAYMENT_METHOD_MODAL_GET_FORM_DATA_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

