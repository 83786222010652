import { NOTIFICATIONS_DISPLAY_TOGGLE } from '../actions/types';

const INITIAL_STATE = {
  notifications_display_status: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть окно уведомлений
    case NOTIFICATIONS_DISPLAY_TOGGLE:
      return { ...state, notifications_display_status: action.payload };

    default:
      return state;
  }
};
