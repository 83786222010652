import './Header.scss';

import {
  chat_list_header_display_toggle,
  executor_categories_modal_display_toggle,
  header_menu_display_toggle,
  login_modal_display_toggle,
  notifications_display_toggle,
  preface_modal_display_toggle,
  registr_modal_display_toggle,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../shared/Button/Button';
import HeaderChatList from './HeaderChatList';
import HeaderMenu from './HeaderMenu';
import HeaderNotificationsList from './HeaderNotificationsList';
import { Link } from 'react-router-dom';
import React from 'react';
import ReactGA from 'react-ga';
import icon_plus from '../../../assets/images/icon_plus.svg';
import icon_search from '../../../assets/images/icon_search.svg';
import logo from '../../../assets/images/logo.svg';
import { useWindowDimensions } from '../../../functions';

function Header() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const token = useSelector((state) => state.globalVariablesReducer.token_value);
  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const user = useSelector((state) => state.userReducer.user);
  const tasks_customer_count = useSelector((state) => state.userReducer.task_data.customer.tasks_count);
  const tasks_executor_count = useSelector((state) => state.userReducer.task_data.executor.tasks_count);
  const header_menu_display_status = useSelector((state) => state.headerReducer.header_menu_display_status);
  const chat_list_new_messages = useSelector((state) => state.chatModalReducer.chat_list_new_messages);
  const chat_list_header_display_status = useSelector((state) => state.chatModalReducer.chat_list_header_display_status);
  const notifications_display_status = useSelector((state) => state.notificationsReducer.notifications_display_status);

  const renderCustomerOrdersButtons = () => {
    if (user.is_customer) {
      return (
        <>
          <Button
            type='link'
            href='/create_order/'
            size='small'
            color='red'
            text='Создать заказ'
            width='180rem'
            extraClassName='createOrderButton'
            iconLeft={icon_plus}
          />
          <Button
            type='link'
            href='/created_orders'
            size='small'
            color='dark'
            text='Мои заказы'
            width='170rem'
            extraClassName='myOrdersButton'
            extraTextRight={tasks_customer_count}
          />
        </>
      );
    } else {
      return (
        <Button
          type='link'
          href='/create_order/'
          size='small'
          color='red'
          text='Создать заказ'
          width='170rem'
          extraClassName='createOrderButton'
        />
      );
    }
  };

  const renderExecutorOrdersButtons = () => {
    if (user.verification_status === 'verified' && user.is_executor) {
      return (
        <>
          <Button
            type={global.prerelease ? 'button' : 'link'}
            href='/orders/'
            size='small'
            color='red'
            text={global.prerelease ? 'Найти заказ (скоро доступно)' : 'Найти заказ'}
            width={global.prerelease ? '290rem' : '185rem'}
            extraClassName='findOrderButton'
            iconLeft={icon_search}
          />
          <Button
            type={global.prerelease ? 'button' : 'link'}
            href='/orders/in_work/'
            size='small'
            color='dark'
            text={global.prerelease ? 'Принятые заказы (скоро доступно)' : 'Принятые заказы'}
            width={global.prerelease ? '325rem' : '210rem'}
            extraClassName='acceptedOrdersButton'
            extraTextRight={tasks_executor_count}
          />
        </>
      );
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      return (
        <Button
          type='button'
          onClick={() => {
            dispatch(executor_categories_modal_display_toggle(true));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Стать исполнителем ( добавить категории )" - хедер',
            });
          }}
          size='small'
          color={global.prerelease ? 'red' : 'white'}
          text='Стать исполнителем'
          width='215rem'
          extraClassName='becomeExecutorBtn'
        />
      );
    } else {
      return (
        <Button
          type='button'
          onClick={() => {
            dispatch(preface_modal_display_toggle(true));
            ReactGA.event({
              category: 'BecomeExecutor',
              action: 'Кнопка "Стать исполнителем" - хедер',
            });
          }}
          size='small'
          color={global.prerelease ? 'red' : 'white'}
          text='Стать исполнителем'
          width='215rem'
          extraClassName='becomeSpecialistBtn'
        />
      );
    }
  };

  return (
    <header className='header'>
      <div className='container'>
        <Link to='/' className='logo'>
          <img className='logoImage' src={logo} alt='logo' />
        </Link>
        <div className='ordersButtons'>
          {global.prerelease || renderCustomerOrdersButtons()}
          {global.prerelease || <div className='divideLine'></div>}
          {renderExecutorOrdersButtons()}
        </div>
        <div className='authButtons'>
          {!token && !user_id ? (
            <>
              <Button
                type='button'
                onClick={() => {
                  dispatch(login_modal_display_toggle(true));
                  ReactGA.event({
                    category: 'Login',
                    action: 'Логин - кнопка в хедере',
                  });
                }}
                size='small'
                color='white'
                text='Войти'
                width='110rem'
                extraClassName='loginBtn'
              />
              <Button
                type='button'
                onClick={() => {
                  dispatch(registr_modal_display_toggle(true));
                  ReactGA.event({
                    category: 'Registration',
                    action: 'Регистрация - кнопка в хедере',
                  });
                }}
                size='small'
                color='dark'
                text='Регистрация'
                width='155rem'
                extraClassName='registerBtn'
              />
            </>
          ) : (
            <div className='userMenu'>
              <div
                className={`news${notifications_display_status ? ' active' : ''}`}
                onClick={() => console.log('notifications')}
                // onClick={() => dispatch(notifications_display_toggle(!notifications_display_status))}
              >
                {notifications_display_status && width > 500 && <HeaderNotificationsList />}
              </div>
              <div
                className={`chat${chat_list_new_messages ? ' active' : ''}`}
                onClick={() => dispatch(chat_list_header_display_toggle(!chat_list_header_display_status))}
              >
                {chat_list_header_display_status && width > 500 && <HeaderChatList />}
              </div>
              <div className='menu' onClick={() => dispatch(header_menu_display_toggle(true))}>
                {typeof user.photo === 'string' ? (
                  <div className='avatar' style={{ backgroundImage: `url('${user.photo}')` }}></div>
                ) : (
                  <div className='default'></div>
                )}
              </div>
              {header_menu_display_status && <HeaderMenu />}
            </div>
          )}
        </div>
        {/* <div className='phone'>
          <div className='schedule'>Звоните нам с 10.00 до 19.00</div>
          <a href='tel:+77788939430'>+7 778 893 9430</a>
        </div> */}
      </div>
    </header>
  );
}

export default Header;
