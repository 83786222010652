import {
  ALERT_MODAL_BUTTONS_CHANGE,
  ALERT_MODAL_CONTENT_CHANGE,
  ALERT_MODAL_DISPLAY_TOGGLE,
  ALERT_MODAL_EXTRA_CLASS_NAME_CHANGE,
  ALERT_MODAL_SETTINGS_CHANGE,
  ALERT_MODAL_TITLE_CHANGE,
} from './types';

/**
 * toggle alert modal display mode
 * @param {boolean} payload
 */
export const alert_modal_display = (payload) => ({
  type: ALERT_MODAL_DISPLAY_TOGGLE,
  payload,
});

/**
 * define title for modal
 * @param {string} payload alert modal title
 */
export const alert_modal_title = (payload) => ({
  type: ALERT_MODAL_TITLE_CHANGE,
  payload,
});

/**
 * define alert body additional CSS class name
 * @param {string} payload alert body additional CSS class name
 */
export const alert_modal_extra_class_name = (payload) => ({
  type: ALERT_MODAL_EXTRA_CLASS_NAME_CHANGE,
  payload,
});

/**
 * define content for modal
 * @param {React.ReactNode} payload alert modal content
 */
export const alert_modal_content = (payload) => ({
  type: ALERT_MODAL_CONTENT_CHANGE,
  payload,
});

/**
 * define buttons for modal
 * @param {import('../typedef').TButton[]} payload list of buttons
 */
export const alert_modal_buttons = (payload) => ({
  type: ALERT_MODAL_BUTTONS_CHANGE,
  payload,
});

/**
 * define title, content & buttons for modal
 * @param {Object} payload alert modal settings
 * @param {string} [payload.title] alert modal title
 * @param {string} [payload.extraClassName] alert body additional CSS class name
 * @param {React.ReactNode} [payload.content] alert modal content
 * @param {import('../typedef').TButton[]} [payload.buttons] list of buttons
 */
export const alert_modal_settings = (payload) => ({
  type: ALERT_MODAL_SETTINGS_CHANGE,
  payload,
});
