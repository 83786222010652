import './HeaderMenu.scss';

import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { header_menu_display_toggle } from '../../../actions';
import { LOGOUT } from '../../../actions/types';
import { signedUpToken, signedUpUserId } from '../../../const';

/**
 * header menu
 */
function HeaderMenu() {
  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const dispatch = useDispatch();
  const history = useHistory();

  const hide = (event) => {
    if (event) {
      event.stopPropagation();
    }
    dispatch(header_menu_display_toggle(false));
  };
  HeaderMenu['handleClickOutside_HeaderMenu'] = () => hide();

  const logout = (event) => {
    hide(event);
    dispatch({ type: LOGOUT });
    localStorage.removeItem(signedUpToken);
    localStorage.removeItem(signedUpUserId);
    history.push('/');
  };

  return (
    <div className='headerMenu'>
      <div className='connector'></div>
      <div className='list'>
        <Link className='item' to={`/profile/${user_id}`} onClick={(event) => hide(event)}>
          Мой профиль
        </Link>
        <div className='divider'></div>
        <div className='item' onClick={(event) => logout(event)}>
          Выйти
        </div>
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => HeaderMenu['handleClickOutside_HeaderMenu'],
};

export default onClickOutside(HeaderMenu, clickOutsideConfig);
