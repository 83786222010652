import './OrderGallery.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { order_info_gallery_display_toggle } from '../../../../actions';
import closeIcon from '../../../../assets/images/icon_close_white.svg';

import PortfolioImage from '../../../../components/shared/PortfolioImage/PortfolioImage';

/**
 * order gallery component
 * @param {Object} props props of component
 * @param {number} props.index index of selected photo
 * @param {string[]} props.photos list of photos
 */
function OrderGallery(props) {
  const { index, photos } = props;
  const [position, setPosition] = useState(index);
  const dispatch = useDispatch();

  /**
   * flip image
   * @param {-1 | 1} direction flip direction
   */
  const flipImage = (direction) => {
    let update = position + direction;
    if (update < 0) {
      update = 0;
    }
    if (update > photos.length - 1) {
      update = photos.length - 1;
    }
    setPosition(update);
  };

  return (
    <div className='orderGallery'>
      <div className='galleryHeader'>
        <div className='caption'>Фото заказа</div>
        <div className='position'>
          {position + 1} из {photos.length}
        </div>
        <img className='closeButton' src={closeIcon} alt='closeIcon' onClick={() => dispatch(order_info_gallery_display_toggle(false))} />
      </div>
      <div className='galleryBody'>
        <div className='navButtonContainer'>{!!position && <div className='buttonPrevious' onClick={() => flipImage(-1)}></div>}</div>
        <div className='content' style={{ backgroundImage: `url('${photos[position]}')` }}></div>
        <div className='navButtonContainer'>
          {position < photos.length - 1 && <div className='buttonNext' onClick={() => flipImage(1)}></div>}
        </div>
      </div>
      <div className='galleryFooter'>
        {photos.map((photo, index) => (
          <PortfolioImage
            key={index}
            clickCallback={() => setPosition(index)}
            url={photo}
            id={index}
            extraClassName='galleryViewer'
            selected={position === index}
          />
        ))}
        <div className='emptySpaceFix'></div>
      </div>
    </div>
  );
}

export default OrderGallery;
