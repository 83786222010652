import './OrdersDashbordItem.scss';

import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  order_response_modal_display_toggle,
  order_response_modal_order_id_value_change,
  add_payment_method_modal_display_toggle,
  alert_modal_settings,
  alert_modal_display,
  preface_legal_entity_modal_display_toggle,
} from '../../../actions';
import { createMaskForPrice } from '../../../functions';

import Button from '../Button/Button';

function ExecutorOrdersDashbordItem({ order }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const user_has_card = useSelector((state) => state.userReducer.user.executor_tool);

  // ? Рефакток методов в redux-action'ы
  const buy = () => {
    axios({
      method: 'post',
      url: global.backendURL + 'payment/access/',
      data: {
        count: 2,
      },
    })
      .then((result) => {
        global.debug && console.log('%cPayment - Success:', 'color: $00ee00;');
        global.debug && console.log(result.data.url);
        window.location.replace(result.data.url);
      })
      .catch((error) => {
        global.debug && console.log('%cPayment - Fail:', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
  const onResponse = () => {
    if (user.app_access_count <= 0) {
      dispatch(
        alert_modal_settings({
          title: `Доступ закрыт`,
          content: `Заказы без комиссии выполнены. Чтобы продолжить работу с сервисом, купите хотя бы 2 заказа. После покупки вы сможете откликаться на любое количество заказов. Комиссия спишется только после того, как заказчик назначит вас исполнителем.`,
          extraClassName: 'alertModalBody',
          buttons: [
            {
              color: 'red',
              size: 'large',
              text: 'Купить',
              type: 'button',
              width: '360rem',
              onClick: () => buy(),
            },
          ],
        })
      );
      dispatch(alert_modal_display(true));
    } else if (!user_has_card && order.safe_payment) {
      dispatch(add_payment_method_modal_display_toggle(true));
    } else if (!user.is_law && order.only_yur) {
      dispatch(
        alert_modal_settings({
          title: `Доступ закрыт`,
          content: `Заказчик указал, что ему требуются закрывающие юридические документы. Откликаться на заказ могут только исполнители, прошедшие верификацию как юр.лицо.`,
          extraClassName: 'alertModalBody',
          buttons: [
            {
              color: 'red',
              size: 'large',
              text: 'Пройти верификацию',
              type: 'button',
              width: '360rem',
              onClick: () => {
                dispatch(alert_modal_display(false));
                dispatch(preface_legal_entity_modal_display_toggle(true));
              },
            },
          ],
        })
      );
      dispatch(alert_modal_display(true));
    } else {
      dispatch(order_response_modal_display_toggle(true));
      dispatch(order_response_modal_order_id_value_change(order.id));
    }
  };

  const renderPlace = () => {
    if (order.executor_place || !order.place) {
      return 'Место: у исполнителя';
    } else {
      return `${order.place}`;
    }
  };

  const renderTime = () => {
    if (order.executor_date || !order.date) {
      if (order.need_fast) {
        return 'Сроки: как можно быстрее';
      } else {
        return 'Сроки: по договорённости';
      }
    } else {
      return `${order.date} ${order.time ? 'в ' + order.time.toString().slice(0, 5) : ''}`;
    }
  };

  const renderPrice = () => {
    if (order.executor_price || (!order.price_max && !order.price_min)) {
      return 'Стоимость: по договорённости';
    } else {
      if (order.price_max === order.price_min) {
        return `${createMaskForPrice(order.price_max.toString())}`;
      } else {
        return `${order.price_min ? 'от ' + createMaskForPrice(order.price_min.toString()) : ''} 
                ${order.price_max ? 'до ' + createMaskForPrice(order.price_max.toString()) : ''}`;
      }
    }
  };

  const renderButtons = () => {
    switch (order.user_status) {
      case 0:
        return (
          <>
            <Button type='link' size='small' color='red' text='Перейти к заказу' width='175rem' href={`/order/${order.id}`} />
            <Button type='button' size='small' color='dark' text='Откликнуться' width='175rem' onClick={() => onResponse()} />
            {/* {!user_has_card && order.safe_payment ? (
              <Button
                type='button'
                size='small'
                color='dark'
                text='Откликнуться'
                width='175rem'
                onClick={() => dispatch(add_payment_method_modal_display_toggle(true))}
              />
            ) : (
              <Button
                type='button'
                size='small'
                color='dark'
                text='Откликнуться'
                width='175rem'
                onClick={() => {
                  dispatch(order_response_modal_display_toggle(true));
                  dispatch(order_response_modal_order_id_value_change(order.id));
                }}
              />
            )} */}
          </>
        );
      case 1:
        return (
          <>
            <Button type='link' size='small' color='white' text='Перейти к заказу' width='175rem' href={`/order/${order.id}`} />
          </>
        );
      case 2:
        return (
          <>
            <Button type='link' size='small' color='red' text='Перейти к заказу' width='175rem' href={`/order/${order.id}`} />
            <Button type='button' size='small' color='dark' text='Заказ выполнен' width='175rem' />
          </>
        );
      case 6:
        return (
          <>
            <Button type='button' size='small' color='white' text='Заказ более недоступен' width='250rem' />
          </>
        );
      case 7:
        return (
          <>
            <Button type='button' size='small' color='white' text='Заказ более недоступен' disabled={true} width='250rem' />
          </>
        );
      default:
        break;
    }
  };

  const renderStatus = () => {
    if (order.user_status === 1) {
      return <div className='status'>Вы откликнулись</div>;
    } else if (order.user_status === 2 && order.task_status === '3') {
      return <div className='status'>Заказ выполнен</div>;
    } else if (order.user_status === 2) {
      return <div className='status'>Вас выбрали исполнителем</div>;
    }
  };

  return (
    <div className='ordersDashbordItem executorOrdersDashbordItem'>
      <div className='title'>{order.title}</div>
      {renderStatus()}
      {(order.safe_payment || order.only_yur) && (
        <div className='tags'>
          {order.safe_payment && <div className='safeDeal'>Сделка без риска</div>}
          {order.only_yur && <div className='legal'>Нужны закрывающие документы</div>}
        </div>
      )}
      <div className='address'>{renderPlace()}</div>
      <div className='dateTime'>{renderTime()}</div>
      <div className='price'>{renderPrice()}</div>
      <div className='buttons'>{renderButtons()}</div>
    </div>
  );
}

export default ExecutorOrdersDashbordItem;
