import './ProfileTabs.scss';

import React from 'react';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ProfileFinancesTab from '../ProfileFinancesTab/ProfileFinancesTab';
import ProfilePortfolioEditor from '../ProfilePortfolioEditor/ProfilePortfolioEditor';
import ProfileSettingsTab from '../ProfileSettingsTab/ProfileSettingsTab';
import ProfileUserDataTab from '../ProfileUserDataTab/ProfileUserDataTab';

/**
 * @typedef {Object} TProfileTabLink profile tab description
 * @property {string} to tab link
 * @property {string} label tab caption
 * @property {string} icon tab icon
 */

/**
 * tabs wrapper
 * @param {Object} props props of component
 * @param {TProfileTabLink[]} props.links list of tabs
 * @param {React.ReactNode} props.children child component
 */
function ProfileTabsWrapper(props) {
  const { links, children } = props;
  const { user } = useSelector((state) => state.profileReducer);
  return (
    <>
      <div className={`navTabs ${user.is_executor && user.law_verification_status === "verified" ? 'noMargin' : ''}`}>
        {links.map((link, index) => {
          return (
            <NavLink key={index} to={link.to} activeClassName='active' className='navTab'>
              <>
                {<div className={`icon ${link.icon}`}></div>}
                {link.label}
              </>
            </NavLink>
          );
        })}
      </div>
      <div className={`tabsContainer ${user.is_executor && user.law_verification_status === "verified" ? 'noMargin' : ''}`}>{children}</div>
    </>
  );
}

/**
 * profile tabs component
 */
function ProfileTabs() {
  const { url } = useRouteMatch();
  const pathData = `${url}/data`;
  const pathFinances = `${url}/finances`;
  const pathSettings = `${url}/settings`;
  const pathPortfolio = `${url}/portfolio/:image_id?`;

  /** @type {TProfileTabLink[]} */
  const links = [
    { to: pathData, label: 'Ваши данные', icon: 'user' },
    { to: pathFinances, label: 'Финансы', icon: 'finances' },
    { to: pathSettings, label: 'Настройки', icon: 'gear' },
  ];

  return (
    <div className='profileTabs'>
      <Switch>
        <Redirect exact from={url} to={pathData} />
        <Route path={pathData}>
          <ProfileTabsWrapper links={links}>
            <ProfileUserDataTab />
          </ProfileTabsWrapper>
        </Route>
        <Route path={pathFinances}>
          <ProfileTabsWrapper links={links}>
            <ProfileFinancesTab />
          </ProfileTabsWrapper>
        </Route>
        <Route path={pathSettings}>
          <ProfileTabsWrapper links={links}>
            <ProfileSettingsTab />
          </ProfileTabsWrapper>
        </Route>
        <Route path={pathPortfolio}>
          <ProfilePortfolioEditor />
        </Route>
      </Switch>
    </div>
  );
}

export default ProfileTabs;
