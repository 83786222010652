import './OrderChangeDetails.scss';
import icon_orderChangeDetails_01 from '../../../../assets/images/icon_orderChangeDetails_01.svg';

import React from 'react';
import { order_change_details_modal_display_toggle } from '../../../../actions';
import { useDispatch } from 'react-redux';

function OrderChangeDetails() {
  const dispatch = useDispatch();

  return (
    <section className='orderChangeDetails'>
      <div className='changePrice'>
        <img className='icon' src={icon_orderChangeDetails_01} alt='changePriceIcon' />
        <div className='description' onClick={() => dispatch(order_change_details_modal_display_toggle(true))}>
          Уточнить / изменить стоимость
        </div>
      </div>
    </section>
  );
}

export default OrderChangeDetails;
