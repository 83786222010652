import { PROFILE_HEADER_LITE_MODE_CHANGE, PROFILE_HEADER_MENU_MODE_CHANGE } from './types';

/**
 * toggle lite mode for profile header
 * @param {boolean} payload lite mode
 */
export const profile_header_lite_mode = (payload) => ({
  type: PROFILE_HEADER_LITE_MODE_CHANGE,
  payload,
});

/**
 * toggle menu mode for profile header
 * @param {boolean} payload menu mode
 */
export const profile_header_menu_mode_toggle = (payload) => ({
  type: PROFILE_HEADER_MENU_MODE_CHANGE,
  payload,
});
