import './ProfilePage.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams, useLocation } from 'react-router-dom';

import { loader_display_toggle, profile_load, profile_reset } from '../../actions';
import ProfileHeader from '../../components/pageSpecific/ProfilePage/ProfileHeader/ProfileHeader';
import ProfileOverview from '../../components/pageSpecific/ProfilePage/ProfileOverview/ProfileOverview';
import ProfileSideBlock from '../../components/pageSpecific/ProfilePage/ProfileSideBlock/ProfileSideBlock';
import ProfileSideBlockActions from '../../components/pageSpecific/ProfilePage/ProfileSideBlockActions/ProfileSideBlockActions';
import ProfileTabs from '../../components/pageSpecific/ProfilePage/ProfileTabs/ProfileTabs';
import { pathNotFoundPage, sizeLarge } from '../../const';
import { useWindowDimensions } from '../../functions';

/**
 * user profile component
 */
function Profile() {
  /** @type {import('../../typedef').TUserDescription} */
  const { user, is_empty, is_loading } = useSelector((state) => state.profileReducer);
  /** @type {import('../../typedef').TGlobalVariablesReducer} */
  const { user_id_value: current_user_id, token_value } = useSelector((state) => state.globalVariablesReducer);
  /** @type {{user_id: string}} */
  const { user_id } = useParams();
  const [notFound, setNotFound] = useState(false);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  let location = useLocation();

  // control of global loader component
  // useEffect(() => {
  //   if (user_id && current_user_id !== user_id) {
  //     dispatch(loader_display_toggle(is_loading));
  //   }
  // }, [is_loading, current_user_id, user_id, dispatch]);

  // loading profile for given user id
  useEffect(() => {
    if (user_id) {
      dispatch(profile_load(user_id));
    }
  }, [user_id, dispatch]);

  // set not found flag
  useEffect(() => {
    if (is_loading) {
      setNotFound(false);
    } else {
      setNotFound(!is_empty && user.public ? !(user.is_customer || user.is_executor) : false);
    }
  }, [user, is_empty, is_loading]);

  // clean-up profile data
  useEffect(() => {
    if (notFound) {
      dispatch(profile_reset());
    }
  }, [notFound, dispatch]);

  return (
    <main className='profile'>
      <div className='container '>
        {!is_loading && notFound ? (
          <Redirect to={pathNotFoundPage} />
        ) : (
          !is_empty && (
            <>
              {width <= sizeLarge &&
                location.pathname.indexOf('portfolio') <= 0 &&
                (!user.is_executor || user.law_verification_status !== 'verified') &&
                !user.public && <ProfileSideBlockActions />}
              {width > sizeLarge && <ProfileHeader user={user} user_id={user_id} />}
              {typeof user.public === 'boolean' && user.public ? <ProfileOverview /> : <ProfileTabs />}
              {width > sizeLarge && <ProfileSideBlock />}
            </>
          )
        )}
      </div>
    </main>
  );
}

export default Profile;
