import './OrdersDashboardsPages.scss';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customer_orders_dashboard_load } from '../../actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowDimensions } from '../../functions';

import CustomerOrdersDashboardTabs from '../../components/pageSpecific/OrdersDasbordsPages/OrdersDashboardTabs/CustomerOrdersDashboardTabs';
import PageTitle from '../../components/shared/PageTitle/PageTitle';
import NeetToLogin from '../../components/shared/NeedToLogIn/NeedToLogin';
import SafeDealBanner from '../../components/shared/SafeDealBanner/SafeDealBanner';

import useUserData from '../../hooks/useUserData';

function CustomerOrdersDashboardPage() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const { data: user_data, status: user_data_loading_status } = useUserData(user_id);

  const created_orders_all = useSelector((state) => state.ordersDashboardsReducer.created_orders_all);
  const created_orders_processing = useSelector((state) => state.ordersDashboardsReducer.created_orders_processing);
  const created_orders_in_work = useSelector((state) => state.ordersDashboardsReducer.created_orders_in_work);
  const created_orders_completed = useSelector((state) => state.ordersDashboardsReducer.created_orders_completed);

  useEffect(() => {
    if (user_data_loading_status === 'fail') {
      history.push('/500/');
    } else if (user_id && user_data_loading_status === 'success') {
      user_data.user.is_customer ? dispatch(customer_orders_dashboard_load(user_id)) : history.push('/404/');
    }
  }, [user_id, user_data, user_data_loading_status, dispatch]); 

  const renderTitleText = () => {
    switch (location.pathname) {
      case '/created_orders/all/':
        return <PageTitle text={`Вы создали ${created_orders_all && created_orders_all.length} заказов`} />;
      case '/created_orders/procesing/':
        return <PageTitle text={`В поиске исполнителя ${created_orders_processing && created_orders_processing.length} заказов`} />;
      case '/created_orders/in_work/':
        return <PageTitle text={`В работе ${created_orders_in_work && created_orders_in_work.length} заказов`} />;
      case '/created_orders/completed/':
        return <PageTitle text={`Завершено ${created_orders_completed && created_orders_completed.length} заказов`} />;
      default:
        break;
    }
  };

  return (
    <>
    {user_id ? (
      <section className={`ordersList ${width <= 500 && 'paddingBottom'}`}>
        <div className='container'>
          {renderTitleText()}
          <CustomerOrdersDashboardTabs />
          <SafeDealBanner />
        </div>
      </section>
      ) : (
        <NeetToLogin />
      )}
    </>
  );
}

export default CustomerOrdersDashboardPage;
