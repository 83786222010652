import icon_executor from '../../../../assets/images/icon_executor.svg';
import icon_share_yl from '../../../../assets/images/icon_share_yl.svg';

import './Features.scss';

import React from 'react';

function Features() {
  return (
    <section className='features'>
      <div className='container'>
        <div className='feature'>
          <img className='image' src={icon_executor} alt='Иконка исполнителя' />
          <h3 className='title'>
            Мы помогаем подобрать
            <br /> оптимальных исполнителей
          </h3>
          <div className='description'>
            Для безопасности мы просим у<br /> исполнителей фото с удостоверением
            <br /> личности, чтобы убедиться, что данные
            <br /> в их анкетах достоверные.
          </div>
        </div>
        <div className='feature'>
          <img className='image' src={icon_share_yl} alt='Иконка юр-лица' />
          <h3 className='title'>
            Мы работаем с<br /> юридическими лицами
          </h3>
          <div className='description'>
            Наш сервис предоставляет
            <br /> закрывающие документы для
            <br /> юридических лиц.
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
