import React from 'react';

import IndexExecutor from '../../components/pageSpecific/HomePage/Index/IndexExecutor.js';
import DownloadOurApp from '../../components/pageSpecific/HomePage/DownloadOurApp/DownloadOurApp.js';
import FrequentQuestions from '../../components/pageSpecific/HomePage/FrequentQuestions/FrequentQuestions.js';
import Economy from '../../components/pageSpecific/HomePage/Economy/Economy.js';
import WhyWeBetter from '../../components/pageSpecific/HomePage/WhyWeBetter/WhyWeBetter.js';
import Safedeal from '../../components/pageSpecific/HomePage/Safedeal/Safedeal.js';
import Tariffs from '../../components/pageSpecific/HomePage/Tariffs/Tariffs.js';

function HomePage() {
  return (
    <main className='homePage'>
      <IndexExecutor />
      <WhyWeBetter />
      <DownloadOurApp />
      <Economy />
      <Safedeal />
      <Tariffs />
      <FrequentQuestions />
    </main>
  );
}

export default HomePage;
