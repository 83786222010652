import {
  PROFILE_PORTFOLIO_COUNT_CHANGE,
  PROFILE_PORTFOLIO_DELETE,
  PROFILE_PORTFOLIO_DELETE_ERROR,
  PROFILE_PORTFOLIO_DELETE_SUCCESS,
  PROFILE_PORTFOLIO_LOAD,
  PROFILE_PORTFOLIO_LOAD_ERROR,
  PROFILE_PORTFOLIO_LOAD_SUCCESS,
  PROFILE_PORTFOLIO_RESET,
  PROFILE_PORTFOLIO_SAVE,
  PROFILE_PORTFOLIO_SAVE_ERROR,
  PROFILE_PORTFOLIO_SAVE_SUCCESS,
  PROFILE_PORTFOLIO_START_CHANGE,
} from '../actions/types';

/** @type {import('../typedef').TProfilePortfolioReducer} */
const INITIAL_STATE = {
  images: [],
  images_count: 0,
  start: 0,
  count: 0,
  image_deleted: false,
  is_loading: false,
};

/** @returns {import('../typedef').TProfilePortfolioReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_PORTFOLIO_LOAD:
    case PROFILE_PORTFOLIO_SAVE:
      return { ...state, is_loading: true };
    case PROFILE_PORTFOLIO_LOAD_SUCCESS:
    case PROFILE_PORTFOLIO_SAVE_SUCCESS:
      const { images, images_count } = action.payload;
      return { ...state, images, images_count, image_deleted: false, is_loading: false };
    case PROFILE_PORTFOLIO_LOAD_ERROR:
    case PROFILE_PORTFOLIO_SAVE_ERROR:
      return { ...state, is_loading: false };
    case PROFILE_PORTFOLIO_DELETE:
      return { ...state, image_deleted: false, is_loading: true };
    case PROFILE_PORTFOLIO_DELETE_SUCCESS:
      return { ...state, image_deleted: true, is_loading: false };
    case PROFILE_PORTFOLIO_DELETE_ERROR:
      return { ...state, image_deleted: false, is_loading: false };
    case PROFILE_PORTFOLIO_START_CHANGE:
      return { ...state, start: action.payload };
    case PROFILE_PORTFOLIO_COUNT_CHANGE:
      return { ...state, count: action.payload };
    case PROFILE_PORTFOLIO_RESET:
      return { ...INITIAL_STATE };
    default:
      return { ...state };
  }
};
