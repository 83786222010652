import {
  EXECUTOR_CATEGORIES_MODAL_DISPLAY_TOGGLE,
  EXECUTOR_CATEGORIES_MODAL_SAVE,
  EXECUTOR_CATEGORIES_MODAL_SAVE_ERROR,
  EXECUTOR_CATEGORIES_MODAL_SAVE_SUCCESS,
} from '../actions/types';

/** @type {import('../typedef').TExecutorCategoriesModalReducer} */
const INITIAL_STATE = {
  categories_display_status: false,
  next_step: false,
  is_loading: false,
};

/** @returns {import('../typedef').TExecutorCategoriesModalReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXECUTOR_CATEGORIES_MODAL_DISPLAY_TOGGLE:
      // reset flag to prevent window close
      const next_step = !action.payload;
      return { ...state, categories_display_status: action.payload, next_step };
    case EXECUTOR_CATEGORIES_MODAL_SAVE:
      return { ...state, is_loading: true };
    case EXECUTOR_CATEGORIES_MODAL_SAVE_SUCCESS:
      return { ...state, is_loading: false, next_step: true };
    case EXECUTOR_CATEGORIES_MODAL_SAVE_ERROR:
      return { ...state, is_loading: false };
    default:
      return { ...state };
  }
};
