import axios from 'axios';

import {
  ORDER_RESPONSE_MODAL_DISPLAY_TOGGLE,
  ORDER_RESPONSE_MODAL_RESPONSE_TEXT_VALUE_CHANGE,
  ORDER_RESPONSE_MODAL_ORDER_ID_VALUE_CHANGE,
  ORDER_RESPONSE_MODAL_SUBMIT,
  ORDER_RESPONSE_MODAL_SUBMIT_SUCCESS,
  ORDER_RESPONSE_MODAL_SUBMIT_FAIL,
  ORDER_USER_INFLICTED_STATUS_CHANGE,
} from './types';

/**
 * @param {boolean} payload
 */
export const order_response_modal_display_toggle = (payload) => {
  return {
    type: ORDER_RESPONSE_MODAL_DISPLAY_TOGGLE,
    payload,
  };
};

export const order_response_modal_order_id_value_change = (payload) => {
  return {
    type: ORDER_RESPONSE_MODAL_ORDER_ID_VALUE_CHANGE,
    payload,
  };
};

export const order_response_modal_response_text_value_change = (payload) => {
  return {
    type: ORDER_RESPONSE_MODAL_RESPONSE_TEXT_VALUE_CHANGE,
    payload,
  };
};

export const order_response_modal_submit = (order_id_value, response_text_value, history) => {
  return (dispatch) => {
    dispatch({ type: ORDER_RESPONSE_MODAL_SUBMIT });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/task/${order_id_value}/application/`,
      data: {
        text: response_text_value,
      },
    })
      .then((response) => {
        dispatch({
          type: ORDER_RESPONSE_MODAL_SUBMIT_SUCCESS,
        });
        global.debug && console.log('Коллбек для перезагрузки:\n');
        dispatch({
          type: ORDER_USER_INFLICTED_STATUS_CHANGE,
          payload: 'NeedToReload',
        });
        global.debug && console.log('Отклик отправлен успешно:\n');
        global.debug && console.log(response.data);
        history.go();
      })
      .catch((error) => {
        dispatch({
          type: ORDER_RESPONSE_MODAL_SUBMIT_FAIL,
        });
        global.debug && console.log('Отклик не отправлен, ошибка:\n');
        global.debug && console.log(error.response);
        history.push('/500/');
      });
  };
};
