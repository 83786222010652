import { useQuery } from 'react-query';
import axios from 'axios';

const getUserNotifications = async () => {
  const { data } = await axios.get(`${global.backendURL}users/notifications/`);
  return data;
};

export default function useUserNotifications() {
  return useQuery(['notificationsData'], getUserNotifications);
}
