import { useQuery } from 'react-query';
import axios from 'axios';

const getPromoData = async (_, key1, key2, key3, key4, key5) => {
  const { data } = await axios({
    method: 'get',
    url: global.backendURL + 'promopages/',
    params: {
      L1: key1,
      L2: key2 || '',
      L3: key3 || '',
      L4: key4 || '',
      L5: key5 || ''
    }
})
  
  return data;
};

export default function usePromoData(key1, key2, key3, key4, key5) {
  return useQuery(['promoData', key1, key2, key3, key4, key5], getPromoData);
}
