import './OrderPage.scss';
import icon_rate_star_active from '../../assets/images/icon_rate_star_active.svg';

import React from 'react';
import { useDispatch } from 'react-redux';
import { order_create_again } from '../../actions';
import { useHistory } from 'react-router-dom';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import Button from '../../components/shared/Button/Button';
import OrderResponse from '../../components/pageSpecific/OrderPage/OrderResponse/OrderResponse';
import OrderReviewCustomer from '../../components/pageSpecific/OrderPage/OrderReview/OrderReviewCustomer';
import OrderChat from '../../components/pageSpecific/OrderPage/OrderChat/OrderChat';

function OrderPage_Review_Customer({ order_id, order }) {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Заказ выполнен' />

        <OrderInfo order={order} />

        <Button
          type='button'
          onClick={() => dispatch(order_create_again(order_id, history))}
          text='Опубликовать заказ еще раз'
          size='large'
          color='dark'
          width='360rem'
          extraClassName='recreateOrderButton'
        />

        <div className='orderResponses'>
          <OrderResponse response={order.applications[0]} order_selected_executor={true} />
        </div>

        <OrderReviewCustomer />

        <OrderChat chat_id={order.applications[0].chat_id} />
      </div>
    </section>
  );
}

export default OrderPage_Review_Customer;
