import './Checkbox.scss';

import React from 'react';

/**
 * checkbox component
 * @param {Object} props
 * @param {string} props.text checkbox label
 * @param {boolean} props.value checkbox state
 * @param {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} props.onClick onClick handler
 * @param {string} props.extraClassName additional class name
 */
function Checkbox(props) {
  const { text, value, onClick, extraClassName } = props;

  return (
    <div onClick={onClick} className={`fieldset checkbox ${extraClassName || ''}`}>
      <div className={`checkboxIcon ${extraClassName || ''} ${value ? 'active' : ''}`}>&#10003;</div>
      <div className={`checkboxLabel ${extraClassName || ''}`}>{text}</div>
    </div>
  );
}

export default Checkbox;
