import './OrderCreatePage.scss';

import React from 'react';

import OrderCreateForm from '../../components/pageSpecific/OrderCreatePage/OrderCreateForm/OrderCreateForm';
import OrderStages from '../../components/shared/OrderStages/OrderStages';
import PageTitle from '../../components/shared/PageTitle/PageTitle';

function OrderCreatePage() {
  return (
    <main className='orderCreatePage'>
      <div className='container'>
        <PageTitle text='Создайте заказ' />
        <OrderCreateForm />
        <OrderStages activeStage='1' />
      </div>
    </main>
  );
}

export default OrderCreatePage;
