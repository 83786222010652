import {
  CHAT_MODAL_DISPLAY_TOGGLE,
  CHAT_LIST_HEADER_DISPLAY_TOGGLE,
  CHAT_LIST_LOAD,
  CHAT_LIST_LOAD_SUCCESS,
  CHAT_LIST_LOAD_FAIL,
  CHAT_LOAD_HISTORY,
  CHAT_LOAD_HISTORY_SUCCESS,
  CHAT_LOAD_HISTORY_FAIL,
  CHAT_MESSAGE_VALUE_CHANGE,

  // CHAT_SEND_MESSAGE,
  CHAT_SEND_MESSAGE_SUCCESS,
  // CHAT_SEND_MESSAGE_FAIL,

  // CHAT_READ_MESSAGE,
  CHAT_READ_MESSAGE_SUCCESS,
  // CHAT_READ_MESSAGE_FAIL
} from '../actions/types';

const INITIAL_STATE = {
  // * Показать / скрыть модальное окно чата
  chat_modal_display_status: false,
  chat_modal_chat_id: null,
  chat_modal_theme: 'Подключить стиральную машину',
  // * Показать / скрыть список чатов, отображаемый по нажатию кнопки в хедере
  chat_list_header_display_status: false,

  chat_list_is_loading: false,
  chat_list: [],
  chat_list_new_messages: false,
  chat_list_need_to_be_updated: false,

  chat_history_is_loading: false,
  chat_history: undefined,
  chat_history_need_to_be_updated: true,

  chat_message_value: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть модальное окно чата
    case CHAT_MODAL_DISPLAY_TOGGLE:
      return { ...state, chat_modal_display_status: action.payload.display_status, chat_modal_chat_id: action.payload.chat_id };

    // * Показать / скрыть список чатов, отображаемый по нажатию кнопки в хедере
    case CHAT_LIST_HEADER_DISPLAY_TOGGLE:
      return { ...state, chat_list_header_display_status: action.payload}

    case CHAT_LIST_LOAD: {
      return { ...state, chat_list_is_loading: true }
    }
    case CHAT_LIST_LOAD_SUCCESS: {
      return { ...state, chat_list_is_loading: false, chat_list: action.payload.chats, chat_list_new_messages: action.payload.unread }
    }
    case CHAT_LIST_LOAD_FAIL: {
      return { ...state, chat_list_is_loading: false }
    }

    case CHAT_LOAD_HISTORY:
      return { ...state, chat_history_is_loading: true };
    case CHAT_LOAD_HISTORY_SUCCESS: {
      return {
        ...state,
        chat_history_is_loading: false,
        chat_history: action.payload.reverse(),
        chat_history_need_to_be_updated: false,
      };
    }
    case CHAT_LOAD_HISTORY_FAIL: {
      return { ...state, chat_history_is_loading: false, chat_history: undefined };
    }

    case CHAT_MESSAGE_VALUE_CHANGE:
      return { ...state, chat_message_value: action.payload };

    case CHAT_SEND_MESSAGE_SUCCESS:
      return { ...state, chat_message_value: '', chat_history_need_to_be_updated: true };

    case CHAT_READ_MESSAGE_SUCCESS:
      return { ...state, chat_history_need_to_be_updated: true, chat_list_need_to_be_updated: true }

    default:
      return state;
  }
};
