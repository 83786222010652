import { ADD_EMAIL_DISPLAY_TOGGLE } from '../actions/types';

/** @type {import('../typedef').TAddEmailReducer} */
const INITIAL_STATE = {
  add_email_display_status: false,
};

/** @returns {import('../typedef').TAddEmailReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_EMAIL_DISPLAY_TOGGLE:
      return { ...state, add_email_display_status: action.payload };
    default:
      return { ...state };
  }
};
