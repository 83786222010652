import {
  CITY_SELECT_MODAL_DISPLAY_TOGGLE,
  CITY_SELECT_MODAL_SELECTED_CITY_ID_VALUE_CHANGE,
  CITY_SELECT_MODAL_SUBMIT,
  CITY_SELECT_MODAL_SUBMIT_FAIL,
  CITY_SELECT_MODAL_SUBMIT_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
  city_select_modal_display_status: true,
  city_select_modal_selected_city_id: 183,
  city_select_modal_submiting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CITY_SELECT_MODAL_DISPLAY_TOGGLE:
      return { ...state, city_select_modal_display_status: action.payload };
    case CITY_SELECT_MODAL_SELECTED_CITY_ID_VALUE_CHANGE:
      return { ...state, city_select_modal_selected_city_id: action.payload };
    case CITY_SELECT_MODAL_SUBMIT:
      return { ...state, city_select_modal_submiting: true };
    case CITY_SELECT_MODAL_SUBMIT_SUCCESS:
      return { ...state, city_select_modal_submiting: false, city_select_modal_display_status: false };
    case CITY_SELECT_MODAL_SUBMIT_FAIL:
      return { ...state, city_select_modal_submiting: false };
    default:
      return { ...state };
  }
};
