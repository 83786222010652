import './ProfileSideBlockActions.scss';

import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';

import {
  preface_legal_entity_modal_display_toggle,
  preface_modal_display_toggle,
  executor_categories_modal_display_toggle,
} from '../../../../actions';
import { useWindowDimensions } from '../../../../functions';

const controlsCount = 2;

/**
 * profile side block user's actions component
 */
function ProfileSideBlockActions() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const [position, setPosition] = useState(0);
  const { width } = useWindowDimensions();
  /** @type {React.LegacyRef<HTMLDivElement>} */
  const ref = React.createRef(null);
  const dispatch = useDispatch();

  /**
   * flip control
   * @param {-1 | 1} direction flip direction
   */
  const flipControl = (direction) => {
    let update = position + direction;
    if (update < 0) {
      update = 0;
    }
    if (update > controlsCount - 1) {
      update = controlsCount - 1;
    }
    setPosition(update);
  };

  const becomeExecutor = () => {
    if (user.verification_status !== 'verified') {
      dispatch(preface_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем" - страница профиля'
      })
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      dispatch(executor_categories_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем ( добавить категории )" - страница профиля'
      })
    }
  };

  // swipe events
  useEffect(() => {
    /** @type {HTMLDivElement} */
    let current = null;
    /** @type {number} */
    let start = null;

    const startTouchEvent = (event) => {
      if (event.touches.length === 1) {
        start = event.touches.item(0).clientX;
      } else {
        start = null;
      }
    };

    const endTouchEvent = (event) => {
      const offset = 100;
      if (start) {
        const end = event.changedTouches.item(0).clientX;

        if (end > start + offset) {
          flipControl(-1);
        }
        if (end < start - offset) {
          flipControl(1);
        }
      }
    };

    if (ref && ref.current) {
      current = ref.current;
      current.addEventListener('touchstart', startTouchEvent);
      current.addEventListener('touchend', endTouchEvent);
    }

    return () => {
      if (current) {
        current.removeEventListener('touchstart', startTouchEvent);
        current.removeEventListener('touchend', endTouchEvent);
      }
    };
  }, [ref]);

  // reset position for higher resolutions
  useEffect(() => {
    if (width > 500) {
      setPosition(0);
    }
  }, [width]);

  return (
    <div className='profileSideBlockActions' ref={ref}>
      <div className='blockActions' style={{ marginLeft: `${position * 300 * -1}rem` }}>
        {!user.is_executor && (
          <div type='button' className='becomeExecutor' onClick={() => becomeExecutor()}>
            <div className='icon'></div>
            <div className='caption'>
              Стать
              <br />
              исполнителем
            </div>
          </div>
        )}
        {user.law_verification_status !== 'verified' && (
          <div type='button' className='shareProfile' onClick={() => {
            dispatch(preface_legal_entity_modal_display_toggle(true))
            ReactGA.event({
              category: 'BecomeLegal',
              action: 'Кнопка "Верифицировать юр.лицо" - страница профиля'
            })
          }}>
            <div className='icon'></div>
            <div className='caption'>
              Стать юридическим
              <br />
              лицом
            </div>
          </div>
        )}
      </div>
      {width <= 500 && !user.is_executor && (
        <div className='controls'>
          {Array.from({ length: controlsCount }, (value, index) => index === position).map((value, index) => (
            <div key={index} className={`control ${value ? 'active' : ''}`} onClick={() => setPosition(index)}></div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ProfileSideBlockActions;
