import './Input.scss';

import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';

/**
 * input component
 * @param {object} props
 * @param {'text' | 'tel' | 'textarea'} props.type input type
 * @param {string} [props.name] input name
 * @param {string} [props.label] input label
 * @param {string} [props.placeholder] input placeholder
 * @param {string} [props.extraClassName] input extra CSS class name
 * @param {(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void} [props.onClick] input onClick handler
 * @param {string | number | string[]} [props.value] input value
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} [props.onChange] input onChange handler
 * @param {string} [props.notValid] input error text
 * @param {boolean} [props.required] input required
 * @param {boolean} [props.readonly] input readonly
 * @param {boolean} [props.requiredStatusDescription] show required status explanation
 * @param {boolean | (string | RegExp)[] | ((value: string) => (string | RegExp)[])} [props.mask] mask for input
 * @param {string} [props.placeholderChar] placeholder character for masked input
 * @param {boolean} [props.guide] masked input guided mode (will show placeholder chars if true)
 * @param {boolean} [props.keepCharPositions] will keep showing mask if true
 * @param {number} [props.maxLength] max length
 * @param {(conformedValue: string, config: any) => string | false | {value: string; indexesOfPipedChars: number[]; }} [props.pipe]
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} [props.onBlur] on blur handler
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void} [props.onFocus] on focus handler
 */

function Input(props) {
  const {
    type,
    name,
    label,
    placeholder,
    extraClassName,
    onClick,
    value,
    onChange,
    notValid,
    required,
    requiredStatusDescription,
    readonly,
    icon,
    iconClassName,
    mask,
    guide,
    keepCharPositions,
    maxLength,
    pipe,
    onBlur,
    onFocus,
  } = props;

  const [error, setError] = useState('');

  useEffect(() => {
    setError(notValid);
    const timeout = setTimeout(() => {
      setError('');
    }, 5e3);

    return () => clearTimeout(timeout);
  }, [notValid]);

  if (type === 'text' || type === 'tel' || type === 'email') {
    return (
      <fieldset className={`fieldset ${extraClassName || ''}`}>
        <label htmlFor={name} className={`label ${extraClassName || ''}`}>
          <span className='text'>{label}&nbsp;</span>
          {required && <span className='required'>*</span>}
          {requiredStatusDescription && (
            <span className='requiredStatusDescription'>
              <span className='required'>*&nbsp;</span>Обязательные поля
            </span>
          )}
        </label>
        {!mask ? (
          <input
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            className={`input  ${extraClassName || ''} ${error && 'withError'} ${icon && 'withIcon'}`}
            onChange={onChange}
            onClick={onClick}
            value={value}
            readOnly={readonly && 'readonly'}
            maxLength={maxLength}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ) : (
          <MaskedInput
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            className={`input  ${extraClassName || ''} ${error && 'withError'} ${icon && 'withIcon'}`}
            onChange={onChange}
            onClick={onClick}
            value={value}
            readOnly={readonly && 'readonly'}
            mask={mask}
            placeholderChar={'\u2000'}
            guide={guide}
            keepCharPositions={keepCharPositions}
            maxLength={maxLength}
            pipe={pipe}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        )}
        {icon && <img src={icon} className={`fieldIcon ${iconClassName ? iconClassName : ''}`} alt='' />}
        {error && <div className='errorField'>{error}</div>}
      </fieldset>
    );
  } else if (type === 'textarea') {
    return (
      <fieldset className={`fieldset ${extraClassName || ''}`}>
        <label htmlFor={name} className={`label ${extraClassName || ''}`}>
          <span className='text'>{label}&nbsp;</span>
          {required && <span className='required'>*</span>}
          {requiredStatusDescription && (
            <span className='requiredStatusDescription'>
              <span className='required'>*&nbsp;</span>Обязательные поля
            </span>
          )}
        </label>
        <textarea
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          className={`textarea ${extraClassName || ''} ${error && 'withError'}`}
          onChange={onChange}
          value={value}
          readOnly={readonly && 'readonly'}
          maxLength={maxLength}
        />
      </fieldset>
    );
  }
}

export default Input;
