import './OrderReview.scss';
import icon_starActive from '../../../../assets/images/icon_starActive.svg';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { order_review_send } from '../../../../actions';
import { useHistory } from 'react-router-dom';

import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Button/Button';

function OrderReviewCustomer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const order_id = useSelector((state) => state.orderReducer.order.id);
  const reivew_decency = useSelector((state) => state.orderReducer.order.feedback?.decency);
  const review_adequacy = useSelector((state) => state.orderReducer.order.feedback?.adequacy);
  const review_punctuality = useSelector((state) => state.orderReducer.order.feedback?.punctuality);
  const review_text = useSelector((state) => state.orderReducer.order.feedback?.feedback_text);

  const [hoveredDecency, setHoveredDecency] = useState(undefined);
  const [decency, setDecency] = useState(undefined);

  const [hoveredAdequacy, setHoveredAdequacy] = useState(undefined);
  const [adequacy, setAdequacy] = useState(undefined);

  const [hoveredPunctuality, setHoveredPunctuality] = useState(undefined);
  const [punctuality, setPunctuality] = useState(undefined);

  const [reviewMessage, setReviewMessage] = useState('');

  return (
    <div className='orderReview'>
      {reivew_decency && review_adequacy && review_punctuality && review_text ? (
        <>
          <div className='title'>Ваш отзыв о исполнителе</div>
          <div className='reviewStats'>
            <div className='reviewStat'>
              <span className='text'>Цена-качество</span>
              <img className={`starIcon${reivew_decency >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
            <div className='reviewStat'>
              <span className='text'>Вежливость</span>
              <img className={`starIcon${review_adequacy >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
            <div className='reviewStat'>
              <span className='text'>Пунктуальность</span>
              <img className={`starIcon${review_punctuality >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
          </div>
          <div className='reviewMessageText'>{review_text}</div>
        </>
      ) : (
        <>
          <div className='title'>Оставьте отзыв о исполнителе</div>
          <div className='reviewStats'>
            <div className='reviewStat'>
              <span className='text'>Цена-качество</span>
              <img
                className={`starIcon${decency >= 1 ? ' active' : ''}${hoveredDecency >= 1 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredDecency(1)}
                onMouseLeave={() => setHoveredDecency(undefined)}
                onClick={() => setDecency(1)}
              />
              <img
                className={`starIcon${decency >= 2 ? ' active' : ''}${hoveredDecency >= 2 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredDecency(2)}
                onMouseLeave={() => setHoveredDecency(undefined)}
                onClick={() => setDecency(2)}
              />
              <img
                className={`starIcon${decency >= 3 ? ' active' : ''}${hoveredDecency >= 3 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredDecency(3)}
                onMouseLeave={() => setHoveredDecency(undefined)}
                onClick={() => setDecency(3)}
              />
              <img
                className={`starIcon${decency >= 4 ? ' active' : ''}${hoveredDecency >= 4 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredDecency(4)}
                onMouseLeave={() => setHoveredDecency(undefined)}
                onClick={() => setDecency(4)}
              />
              <img
                className={`starIcon${decency >= 5 ? ' active' : ''}${hoveredDecency >= 5 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredDecency(5)}
                onMouseLeave={() => setHoveredDecency(undefined)}
                onClick={() => setDecency(5)}
              />
            </div>
            <div className='reviewStat'>
              <span className='text'>Вежливость</span>
              <img
                className={`starIcon${adequacy >= 1 ? ' active' : ''}${hoveredAdequacy >= 1 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredAdequacy(1)}
                onMouseLeave={() => setHoveredAdequacy(undefined)}
                onClick={() => setAdequacy(1)}
              />
              <img
                className={`starIcon${adequacy >= 2 ? ' active' : ''}${hoveredAdequacy >= 2 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredAdequacy(2)}
                onMouseLeave={() => setHoveredAdequacy(undefined)}
                onClick={() => setAdequacy(2)}
              />
              <img
                className={`starIcon${adequacy >= 3 ? ' active' : ''}${hoveredAdequacy >= 3 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredAdequacy(3)}
                onMouseLeave={() => setHoveredAdequacy(undefined)}
                onClick={() => setAdequacy(3)}
              />
              <img
                className={`starIcon${adequacy >= 4 ? ' active' : ''}${hoveredAdequacy >= 4 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredAdequacy(4)}
                onMouseLeave={() => setHoveredAdequacy(undefined)}
                onClick={() => setAdequacy(4)}
              />
              <img
                className={`starIcon${adequacy >= 5 ? ' active' : ''}${hoveredAdequacy >= 5 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredAdequacy(5)}
                onMouseLeave={() => setHoveredAdequacy(undefined)}
                onClick={() => setAdequacy(5)}
              />
            </div>
            <div className='reviewStat'>
              <span className='text'>Пунктуальность</span>
              <img
                className={`starIcon${punctuality >= 1 ? ' active' : ''}${hoveredPunctuality >= 1 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredPunctuality(1)}
                onMouseLeave={() => setHoveredPunctuality(undefined)}
                onClick={() => setPunctuality(1)}
              />
              <img
                className={`starIcon${punctuality >= 2 ? ' active' : ''}${hoveredPunctuality >= 2 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredPunctuality(2)}
                onMouseLeave={() => setHoveredPunctuality(undefined)}
                onClick={() => setPunctuality(2)}
              />
              <img
                className={`starIcon${punctuality >= 3 ? ' active' : ''}${hoveredPunctuality >= 3 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredPunctuality(3)}
                onMouseLeave={() => setHoveredPunctuality(undefined)}
                onClick={() => setPunctuality(3)}
              />
              <img
                className={`starIcon${punctuality >= 4 ? ' active' : ''}${hoveredPunctuality >= 4 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredPunctuality(4)}
                onMouseLeave={() => setHoveredPunctuality(undefined)}
                onClick={() => setPunctuality(4)}
              />
              <img
                className={`starIcon${punctuality >= 5 ? ' active' : ''}${hoveredPunctuality >= 5 ? ' hover' : ''}`}
                src={icon_starActive}
                alt='star icon'
                onMouseEnter={() => setHoveredPunctuality(5)}
                onMouseLeave={() => setHoveredPunctuality(undefined)}
                onClick={() => setPunctuality(5)}
              />
            </div>
          </div>
          <Input
            type='textarea'
            label='Ваш отзыв'
            name='reviewMessage'
            placeholder='Минимум 10 символов'
            extraClassName='reviewMessage'
            value={reviewMessage}
            onChange={(event) => setReviewMessage(event.target.value)}
          />
          <Button
            type='button'
            onClick={() => dispatch(order_review_send(order_id, decency, adequacy, punctuality, reviewMessage, history))}
            text='Опубликовать'
            size='large'
            color='red'
            width='360rem'
            extraClassName='reviewSubmitButton'
            disabled={!decency || !adequacy || !punctuality || reviewMessage.length < 10}
          />
        </>
      )}
    </div>
  );
}

export default OrderReviewCustomer;
