import { useQuery } from 'react-query';
import axios from 'axios';

const getChatData = async (_, chat_id) => {
  const { data } = await axios.get(`${global.backendURL}tasks/chat/${chat_id}/`);
  return data;
};

export default function useChatHistory(chat_id) {
  return useQuery(['chatData', chat_id], getChatData);
}
