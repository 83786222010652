import './SafeDealBanner.scss';

import React from 'react';
import { Link } from 'react-router-dom';

/**
 * safe deal banner component
 * @param {Object} props props of component
 * @param {string} [props.extraClassName] optional additional CSS class name
 * @param {React.ReactNode} [props.children] optional content
 */
function SafeDealBanner(props) {
  const { extraClassName, children } = props;

  return (
    <Link to='/faq/what_is_safe_deal/' className={`safeDealBanner ${extraClassName || ''}`}>
      <div className='banner'></div>
      {children ? (
        children
      ) : (
        <>
          <div className='caption'>Сделка без риска</div>
          <div className='action'>
            Совершайте сделки <br />
            безопасно при помощи <br />
            банковских карт online
          </div>
        </>
      )}
    </Link>
  );
}

export default SafeDealBanner;
