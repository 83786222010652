import { PRERELEASE_MODAL_DISPLAY_TOGGLE } from '../actions/types';

const INITIAL_STATE = {
  prerelease_modal_display_status: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // * Показать / скрыть модальное окно отклика
    case PRERELEASE_MODAL_DISPLAY_TOGGLE:
      return { ...state, prerelease_modal_display_status: action.payload };

    default:
      return state;
  }
};
