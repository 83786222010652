import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function AboutUsPage() {
  return (
    <main className='aboutUsPage'>
      <DocumentContent>
        <h1>О сервисе</h1>
        <p>
          signedUP — это удобный сервис, который помогает быстро найти подходящих исполнителей для решения бытовых задач. Задачи могут быть
          разными: начиная от ремонта и строительства, заканчивая клинингом, курьерской доставкой и бьюти-услугами.
        </p>
        <p>
          Наши пользователи размещают заказы с описанием нужных им услуг, и мы быстро подбираем профессионалов, которые соответствуют
          требованиям и вписываются в заявленный бюджет. Заказчик может выбрать подходящего исполнителя из нескольких рекомендованных
          вариантов, либо подробно изучить всех, кто откликнулся на его заказ, и самостоятельно подобрать мастера по отзывам, рейтингу,
          стоимости услуг и квалификации.
        </p>
        <p>
          Мы заботимся о безопасности наших заказчиков, поэтому каждый исполнитель проходит специальную процедуру верификации и проверяется
          администрацией сервиса.
        </p>
      </DocumentContent>
    </main>
  );
}

export default AboutUsPage;
