import './Categories.scss';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { order_creation_category_id_value_change, order_creation_subcategory_id_value_change } from '../../../../actions';
import { useHistory } from 'react-router-dom';

function Categories() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: global.backendURL + 'tasks/category/list/',
      with_count: true,
    })
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => global.debug && console.log(error.responce));
  }, []);

  const renderCategory = (category) => {
    switch (category.title) {
      case 'Ремонтные и мелкие строительные работы':
        return (
          <div
            className='category construction'
            key={category.id}
            onClick={() => {
              dispatch(order_creation_category_id_value_change(8));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            <div className='icon'></div>
            <div className='title'>
              Ремонтные и мелкие <br />
              строительные работы
            </div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Клининговые услуги':
        return (
          <div
            className='category cleaning'
            key={category.id}
            onClick={() => {
              dispatch(order_creation_category_id_value_change(6));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            <div className='icon'></div>
            <div className='title'>Клининговые услуги</div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Курьерские услуги':
        return (
          <div
            className='category courier'
            key={category.id}
            onClick={() => {
              dispatch(order_creation_category_id_value_change(3));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            <div className='icon'></div>
            <div className='title'>Курьерские услуги</div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Логистические перевозки':
        return (
          <div className='category transportation' key={category.id}>
            <div className='icon'></div>
            <div className='title'>
              Логистические
              <br /> перевозки
            </div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Ремонт и обслуживание техники':
        return (
          <div
            className='category repairs'
            key={category.id}
            onClick={() => {
              dispatch(order_creation_category_id_value_change(5));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            <div className='icon'></div>
            <div className='title'>
              Ремонт и<br /> обслуживание техники
            </div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Онлайн задачи (дизайн, разработка, полиграфия)':
        return (
          <div className='category online' key={category.id}>
            <div className='icon'></div>
            <div className='title'>
              Онлайн задачи (дизайн,
              <br /> разработка и т.п.)
            </div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );

      case 'Красота и здоровье':
        return (
          <div
            className='category beauty'
            key={category.id}
            onClick={() => {
              dispatch(order_creation_category_id_value_change(7));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            <div className='icon'></div>
            <div className='title'>Красота и здоровье</div>
            {/* <div className='specialistsAmount'>{category.executors ? category.executors : '0'} исполнителей</div> */}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <section className='categories'>
      <div className='container'>
        <div className='popularServices'>
          <div className='title'>Популярные услуги:</div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(6));
              dispatch(order_creation_subcategory_id_value_change(11));
              history.push('/create_order/');
            }}
          >
            Уборка квартиры
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(5));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Ремонт техники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(8));
              dispatch(order_creation_subcategory_id_value_change(63));
              history.push('/create_order/');
            }}
          >
            Ремонт сантехники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(3));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Услуги курьера
          </div>
        </div>
        <div className='categoriesList'>{categories ? categories.map((category) => renderCategory(category)) : undefined}</div>
      </div>
    </section>
  );
}

export default Categories;
