import { PREFACE_MODAL_DISPLAY_TOGGLE } from '../actions/types';

/** @type {import('../typedef').TPrefaceReducer} */
const INITIAL_STATE = {
  preface_modal_display_status: false,
};

/** @returns {import('../typedef').TPrefaceReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PREFACE_MODAL_DISPLAY_TOGGLE:
      return { ...state, preface_modal_display_status: action.payload };
    default:
      return { ...state };
  }
};
