import React from 'react';
import { Link } from 'react-router-dom';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function UserAgreementPage() {
  return (
    <main className='userAgreementPage'>
      <DocumentContent>
        <h1>Пользовательское соглашение</h1>
        <p>
          <b>Общие положения</b>
        </p>
        <p>
          Настоящее пользовательское соглашение, далее — «Соглашение», регулирует отношения между компанией Товарищество с ограниченной
          ответственностью «Unicorn Prod» (БИН 200740022394), A05D5F5, Республика Казахстан, г. Алматы, Алмалинский,район ул. Жамбыла, д.
          114/85, блок В, офис 5, далее — «Компания» и Вами, именуемым в дальнейшем «Пользователь», по использованию веб-сайта{' '}
          <Link target='_blank' to='/'>
            signedup.me
          </Link>{' '}
          и (или) версий для мобильных устройств на платформе iOS и Android, далее — «Сервисы Компании» или «Сервисы».
        </p>
        <p>
          <b>1. Условия присоединения к настоящему Соглашению</b>
        </p>
        <p>
          1.1 Перед тем как начать пользоваться Сервисами Компании, Пользователь обязан ознакомиться с настоящим Соглашением, а также со
          всеми применимыми правилами и иными документами, размещенными в Сервисах и являющимися неотъемлемой частью настоящего Соглашения.
        </p>
        <p>
          1.2. Начало использования Сервисов Компании также является подтверждением присоединения Пользователя к условиям настоящего
          Соглашения. Под началом использования подразумевается любое взаимодействие с Сервисами Компании.
        </p>
        <p>
          1.4. Каждым использованием Сервисов Компании, Пользователь дает безоговорочное согласие (Акцепт) с условиями настоящего Соглашения
          в редакции, которая действовала на момент фактического использования Сервисов Компании.
        </p>
        <p>
          1.5. Действующая редакция Соглашения доступна по адресу:{' '}
          <Link target='_blank' to='/documents/user_agreement/' className='cutOnMobile'>
            signedup.me/documents/user_agreement
          </Link>
        </p>
        <p>
          1.6 Использование Сервисов Компании разрешается только на условиях настоящего Соглашения. Использование Сервисов компании на
          условиях и способами, не предусмотренными настоящим Соглашением, возможно только на основании дополнительного соглашения с
          Пользователем, заключенного отдельно.
        </p>
        <p>
          1.7. Пользователь соглашается с Политикой конфиденциальности, которая является неотъемлемой частью Пользовательского соглашения и
          размещенного по адресу{' '}
          <Link target='_blank' to='/documents/user_agreement/' className='cutOnMobile'>
            signedup.me/documents/user_agreement
          </Link>{' '}
          Пользователь дает свое безоговорочное согласие на обработку, хранение и использование персональных данных и согласен с условиями
          Политики конфиденциальности, размещенной по адресу{' '}
          <Link target='_blank' to='/documents/confidentiality_policy/' className='cutOnMobile'>
            signedup.me/documents/confidentiality_policy
          </Link>
        </p>
        <p>
          <b>2. Описание услуг и процесс регистрации</b>
        </p>
        <p>
          2.1 Сервисы Компании позволяют Пользователям регистрировать два типа учетных записей — Заказчик и Исполнитель. Каждый пользователь
          может создавать Заказы после регистрации и становиться Исполнителем Заказов после прохождения процедуры верификации. Под Заказами
          понимаются объявления о поиске Исполнителя определенного задания/оказания услуги, требующей участие человека, обладающего
          соответствующими навыками/квалификацией для выполнения работ.
        </p>
        <p>
          2.2 При регистрации в Сервисах Компании Заказчик и Исполнитель обязаны указать свой действующий номер телефона. Пользователь не
          имеет права указывать номер телефона, принадлежащий другим лицам. Пользователь обязуется сохранять конфиденциальность кодов
          подтверждения, присылаемых на его номер телефона Компанией для авторизации в личном кабинете Сервисов.
        </p>
        <p>
          2.3 Пользователю присваиваются Логин (номер мобильного телефона) и присылается Пароль — код из СМС-сообщения, отправляемого
          Компанией на мобильный телефон Пользователя. Пара логин + код из СМС используется для дальнейшего взаимодействия с Сервисами
          Компании. Регистрация одного и того же пользователя с разными идентификационными данными не допускается.
        </p>
        <p>2.4 В процессе регистрации Пользователя Компанией может быть запрошена дополнительная информация.</p>
        <p>
          2.5 Пользователь несет полную ответственность за действия и/или бездействия, которые привели к утечке, утере, краже и т.п. учетных
          данных и иной идентификационной информации, а также за любые действия и/или бездействие третьих лиц, использующих учетные данные
          Пользователя.{' '}
        </p>
        <p>
          2.6 Все действия, совершенные с учетной записью Пользователя, считаются произведенными от лица самого Пользователя, за исключением
          случаев, когда Пользователь предупредил Компанию о несанкционированном доступе к учетной записи и/или о любом нарушении
          (подозрениях в нарушении) конфиденциальности своих идентификационных данных.
        </p>
        <p>
          2.7 Заполняя анкету при регистрации и верификации, Пользователь должен сообщить Компании достоверную и актуальную информацию.
          Регистрируясь, Пользователь подтверждает, что делает сообщаемую в анкете информацию публичной, т.е. потенциально доступной всем
          Пользователям Сервисов.
        </p>
        <p>
          2.8 После создания учетной записи (после регистрации в качестве Заказчика) Пользователь получает доступ к функционалу создания
          Заказов. Чтобы стать Исполнителем услуг, Пользователь должен пройти процесс верификации, то есть сообщить Компании дополнительную
          информацию о себе: ФИО, email, дату рождения, ИИН, отправить личное фото, а также скан/фотографию своего паспорта или
          удостоверения личности. Для юридических лиц необходимо предоставить БИН, справку с egov о зарегистрированном юридическом лице,
          филиале или представительстве, или иной документ подтверждающий гос регистрацию. Для индивидуальных предпринимателей — документ,
          подтверждающий регистрацию в качестве индивидуального предпринимателя.
        </p>
        <p>
          2.9 Пользователь должен тщательно проверить информацию о размещаемых в Сервисах услугах, и, в случае обнаружения недостоверной
          информации, добавить недостающую информацию в описание услуги. Если это невозможно, исправить неверные данные, либо удалить Заказ
          с последующим повторным размещением.
        </p>
        <p>
          <b>3. Права и обязанности сторон и ограничения ответственности</b>
        </p>
        <p>
          3.1 Компания предоставляет Пользователям неисключительное и непередаваемое право на использование Сервисов и Программ, являющихся
          частью Сервисов Компании в соответствии с настоящим Соглашением, при условии, что Пользователь или третьи лица при содействии
          Пользователя не будут совершать действий по декомпилированию, дизассемблированию, дешифрованию и производить другие манипуляции по
          изменению программного обеспечения Сервисов Компании, в том числе для несанкционированного доступа к данным, а также осуществлять
          продажу, уступку, сдачу в аренду, или иную передачу прав на содержимое Сервисов Компании и иных аналогичных действий, нарушающих
          права Компании и третьих лиц.
        </p>
        <p>
          3.2 Компания не несет ответственности за ущерб персональному компьютеру, мобильному устройству или любому другому оборудованию или
          программному обеспечению Пользователя, связанный с использованием Сервисов.
        </p>
        <p>
          3.3 Сервисы Компании предоставляются Пользователю на условиях «как есть» (as is). Компания не предоставляет никаких гарантий в
          отношении безошибочной, бесперебойной, круглосуточной работы Сервисов, соответствия их любым целям и ожиданиям Пользователя, а
          также не предоставляет никаких других гарантий, прямо не указанных в настоящем Соглашении.
        </p>
        <p>3.4 Компания не несет ответственности при несанкционированном доступе третьих лиц к учетной записи Пользователя.</p>
        <p>
          3.5 Компания имеет право отказать Пользователю в Регистрации без объяснения причин независимо от усилий, приложенных Пользователем
          при прохождении процедуры верификации Исполнителя.
        </p>
        <p>
          3.6 Компания имеет право приостановить/заблокировать учетную запись Пользователя без объяснения причин.
          <br />
          Нарушения, которые могут повлечь за собой предупреждение или блокировку учетной записи Пользователя:
        </p>

        <ul>
          <li>Пользователь нарушил условия настоящего Соглашения;</li>
          <li>Заказчик не оплатил выполненный надлежащим образом заказ и перестал отвечать на звонки;</li>
          <li>Пользователь зарегистрировал несколько аккаунтов;</li>
          <li>Пользователь общается с другими пользователями от имени или вместо другого лица, предоставил фальшивые сведения о себе; </li>
          <li>Исполнитель не выполнил Заказ и перестал отвечать на звонки;</li>
          <li>Исполнитель получил пять и более отрицательных отзывов;</li>
          <li>
            От других Пользователей поступили жалобы на грубость, нецензурную брань, хамство, неадекватное поведение, нарушение
            договоренностей и т. д.;
          </li>
          <li>
            Исполнитель откликнулся на Заказ, но не выполнил его, не согласовывая с Заказчиком свой отказ и не удалив Отклик, что привело к
            конфликту или невыполнению Заказа;
          </li>
          <li>
            Исполнитель откликнулся на Заказ, но услугу по его выполнению предоставил другой человек, не зарегистрированный в Сервисах
            Компании без согласия Заказчика;
          </li>
          <li>Исполнитель нарушил правила общения с Администрацией Сервисов.</li>
        </ul>

        <p>
          3.7 Пользователь несет ответственность за соблюдение материальных и нематериальных прав третьих лиц на данные, предоставленные
          Администрации или третьим лицам при использовании Сервисов Компании.
        </p>
        <p>3.8 Пользователь имеет право:</p>

        <ul>
          <li>
            Использовать весь доступный функционал Сервисов для личных целей по прямому функциональному назначению в том числе создавать
            Заказы, переписываться с другими Пользователями Сервисов и откликаться на Заказы других Пользователей.
          </li>
          <li>Оставлять отзывы о других пользователях, будь то Заказчик или Исполнитель, и оценивать их по определенным критериям.</li>
          <li>Получать разъяснения и ответы на вопросы по использованию Сервисов от техподдержки и Администрации Сервисов.</li>
          <li>
            Оставлять жалобы на других Пользователей, если Пользователь столкнулся с нарушением ими условий Пользовательского соглашения или
            они были уличены в противоправных действиях.
          </li>
          <li>
            Заключать договор с Исполнителем или Заказчиком, предоставлять существенные условия договора или указывать порядок их
            определения.
          </li>
        </ul>

        <p>
          3.9 Для обсуждения с Администрацией спорных вопросов Пользователь имеет право использовать только указанные способы контакта:
          личный чат, телефон, e-mail. Запрещено публично спорить и обсуждать действия Администрации. Пожаловаться на деятельность
          Администрации можно, написав письмо на электронную почту:{' '}
          <a target='_blank' rel='noopener noreferrer' href='mailto:artur@signedup.me'>
            artur@signedup.me
          </a>
        </p>
        <p>3.10 Пользователь не имеет права:</p>

        <ul>
          <li>
            Создавать Заказы на оказание услуг, запрещенных законодательством Республики Казахстан или сообщать информацию, другим образом
            нарушающую правоприменительное законодательство юрисдикции, для которой предназначена эта информация;
          </li>
          <li>Рекламировать свои услуги или услуги, оказываемые третьими лицами, не связанные с тематикой Сервисов;</li>
          <li>Публиковать Заказ в разделе, который не соответствует содержанию Заказа;</li>
          <li>
            Публиковать Заказы, в тексте которых содержатся повторяющиеся знаки пунктуации и/или небуквенные символы, а также имеющие
            несвязанные или нечитаемые описание/заголовок/фотографии;
          </li>
          <li>Добавлять в Заказы ссылки на ресурсы, которые содержат вредоносные элементы, либо ссылки на главную страницу сайта;</li>
          <li>
            Воспроизводить, распространять, перерабатывать в коммерческих или некоммерческих целях содержимое Сервисов, являющиеся объектом
            авторских прав Компании или третьих лиц, при отсутствии разрешения соответствующих правообладателей на совершение данных
            действий;
          </li>
          <li>Копировать элементы дизайна или интерфейса Сервисов при ведении любой коммерческой деятельности в Интернете или вне его;</li>
          <li>Использовать Сервисы для распространения или иного использования незаконного контента или услуг;</li>
          <li>
            Изменять название Сервисов, изменять и/или удалять присутствующие в Сервисах, документы или иные материалы, знаки охраны
            авторского права или иные указания на правообладателя или иных лиц.
          </li>
        </ul>

        <p>
          <b>Подробнее см. правила размещения Заказов:</b>{' '}
          <Link target='_blank' to='/faq/orders_rules/'>
            signedup.me/faq/orders_rules
          </Link>
        </p>
        <p>3.11 В случае, если Пользователь является юридическим лицом, он обязуется:</p>

        <ul>
          <li>Предоставить информацию о порядке заключения договора;</li>
          <li>Выписывать Заказчикам электронные счета-фактуры;</li>
          <li>
            Если Заказчик является юридическим лицом, то он должен оплачивать налоги, удерживаемые с источника выплаты, в случае со
            взаимодействием с Исполнителем — физическим лицом.
          </li>
          <li>
            Если исполнитель является юридическим лицом, то он обязан предоставлять Заказчикам счет-фактуру и акт выполненных работ в сроки
            и в соответствии с требованиями действующего законодательства Республики Казахстан.
          </li>
        </ul>

        <p>
          3.12 В случае, если пользователь является Индивидуальным предпринимателем, он обязуется составить и передать заказчику акт
          выполненных работ.
        </p>
        <p>
          <b>4. Обновления/новые версии Программы, Соглашения.</b>
        </p>
        <p>
          4.1. Интерфейс Сервисов может время от времени меняться в связи с обновлениями и усовершенствованиями, вносимыми сотрудниками
          Компании. Пользователь настоящим уведомлен и соглашается с автоматическим обновлением Сервисов, включая запрос, загрузку и
          установку обновлений Программы на мобильное устройство Пользователя.
        </p>
        <p>
          4.2. Действие настоящего Соглашения распространяется на все последующие обновления/новые версии любого Сервиса/Приложения.
          Установка обновления/новой версии означает принятие Пользователем условий настоящего Соглашения для соответствующих обновлений,
          если обновление/установка новой версии Сервиса не сопровождается иным Пользовательским соглашением.
        </p>
        <p>4.3. Настоящее Соглашение может изменяться Компанией в одностороннем порядке.</p>
        <p>
          4.4. Уведомление Пользователя об изменениях условий настоящего Соглашения публикуется в соответствующем разделе на сайте Компании
          по адресу{' '}
          <Link target='_blank' to='/documents/user_agreement/' className='cutOnMobile'>
            signedup.me/documents/user_agreement
          </Link>{' '}
          Изменения вступают в силу с даты их публикации, если иное не указано в соответствующей редакции.
        </p>
        <p>
          4.5 К настоящему Соглашению и всем отношениям, связанным с любым использованием Сервисов, подлежит применению право Республики
          Казахстан, и любые претензии или иски, вытекающие из настоящего Соглашения или использования Сервисов, должны быть поданы и
          рассмотрены в суде по месту нахождения Компании.
        </p>
      </DocumentContent>
    </main>
  );
}

export default UserAgreementPage;
