import './OrderResponse.scss';
import icon_starActive from '../../../../assets/images/icon_starActive.svg';
import icon_chatActive from '../../../../assets/images/icon_chatActive.svg';
import icon_safeDealActive from '../../../../assets/images/icon_safeDealActive.svg';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  chat_modal_display_toggle,
  order_select_executor,
  order_user_inflicted_status_change,
  alert_modal_settings,
  alert_modal_display,
  order_change_details_modal_display_toggle,
} from '../../../../actions';

import Button from '../../../shared/Button/Button';

function OrderResponse({ response, order_selected_executor }) {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.orderReducer.order.task_status);
  const order = useSelector((state) => state.orderReducer.order);

  const onChooseExecutor = () => {
    if ((order.safe_payment && order.price_max !== order.price_min) || (order.price_max === null && order.price_min === null)) {
      dispatch(
        alert_modal_settings({
          title: `Нет точной цены`,
          content: `Уточните, пожалуйста, конечную стоимость заказа.`,
          extraClassName: 'alertModalBody',
          buttons: [
            {
              color: 'red',
              size: 'large',
              text: 'Уточнить',
              type: 'button',
              width: '360rem',
              onClick: () => {
                dispatch(alert_modal_display(false));
                dispatch(order_change_details_modal_display_toggle(true));
              },
            },
          ],
        })
      );
      dispatch(alert_modal_display(true));
    } else {
      dispatch(order_select_executor(response.executor.id));
      dispatch(order_user_inflicted_status_change('confirmingExecutor'));
    }
  };

  return (
    <>
      <div className={`response${order_selected_executor ? ' selected' : ''}`}>
        {order_selected_executor && <div className='title'>Исполнитель</div>}

        <div
          className={`avatar${!response.executor.image ? ' noAvatar' : ''}`}
          style={{ backgroundImage: `url('${response.executor.image}')` }}
        />

        <div className='fio'>{`${response.executor.first_name} ${response.executor.last_name}`}</div>

        <div className='online'>{response.executor.is_online ? 'Сейчас online' : 'Сейчас offline'}</div>

        {status === '1' && <div className='responseText'>{response.text}</div>}

        <div className='stats'>
          <div className='rating'>
            <img src={icon_starActive} className='ratingIcon' alt='Рейтинг' />
            {response.executor.executor_rate || <span className='statText'>Нет рейтинга</span>}
          </div>
          <div className='reviews'>
            <img src={icon_chatActive} className='reviewsIcon' alt='Отзывы' />
            {response.executor.feedback_count ? (
              `${response.executor.feedback_count} отзыв(-а)(-ов)`
            ) : (
              <span className='statText'>Нет отзывов</span>
            )}
          </div>
          <div className='verification'>
            <img src={icon_safeDealActive} className='verificationIcon' alt='Верифицирован' />
            Паспорт проверен
          </div>
        </div>

        {!order_selected_executor && (
          <div className='buttons'>
            <Button
              type='button'
              color='dark'
              size='small'
              width='240rem'
              text='Выбрать исполнителем'
              extraClassName='chooseExecuter'
              onClick={() => onChooseExecutor()}
            />
            <Button
              type='button'
              color='red'
              size='small'
              width='240rem'
              text='Чат c исполнитлем'
              extraClassName='chat'
              onClick={() => dispatch(chat_modal_display_toggle({ display_status: true, chat_id: response.chat_id }))}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OrderResponse;
