import './Button.scss';
import icon_loading from '../../../assets/images/icon_loading.svg';

import React from 'react';
import { Link } from 'react-router-dom';

function Button(props) {
  const {
    type,
    onClick,
    href,
    size,
    color,
    width,
    text,
    iconLeft,
    iconLeftExtraClassName,
    extraTextRight,
    extraClassName,
    disabled,
    isLoading,
  } = props;

  return (
    <>
      {type !== 'link' ? (
        <button className={`button ${size} ${color} ${extraClassName || ''}`} style={{ width }} onClick={onClick} disabled={disabled}>
          {isLoading ? (
            <img src={icon_loading} className='isLoading rotating' />
          ) : (
            <>
              {iconLeft ? <img src={iconLeft} className={`buttonIconLeft ${iconLeftExtraClassName || ''}`} alt='icon' /> : undefined}
              <span className='buttonText'>{text}</span>
              {extraTextRight ? <span className='buttonExtraTextRight'>{extraTextRight}</span> : undefined}
            </>
          )}
        </button>
      ) : (
        <Link className={`button ${size} ${color} ${extraClassName || ''}`} style={{ width }} to={href ? href : '/'}>
          {iconLeft ? <img src={iconLeft} className='buttonIconLeft' alt='icon' /> : undefined}
          <span className='buttonText'>{text}</span>
          {extraTextRight ? <span className='buttonExtraTextRight'>{extraTextRight}</span> : undefined}
        </Link>
      )}
    </>
  );
}

export default Button;
