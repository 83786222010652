import Tarriffs_icon02 from '../../../../assets/images/Tarriffs_icon02.svg';
import './Tariffs.scss';

import React from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { executor_categories_modal_display_toggle, preface_modal_display_toggle } from '../../../../actions';

import Button from '../../../shared/Button/Button';

function Tariffs() {
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const becomeExecutor = () => {
    if (user.verification_status !== 'verified') {
      dispatch(preface_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем" - 5-ый экран',
      });
    } else if (user.verification_status === 'verified' && !user.is_executor) {
      dispatch(executor_categories_modal_display_toggle(true));
      ReactGA.event({
        category: 'BecomeExecutor',
        action: 'Кнопка "Стать исполнителем ( добавить категории )" - 5-ый экран',
      });
    }
  };

  return (
    <section className='tariffs'>
      <div className='container'>
        <h2 className='h2'>
          Условия сотрудничества
          <small className='small'>
            Небольшая комиссия предусмотрена только на этапе утверждения вас
            <br /> исполнителем конкретного заказа, а также за услуги банка при выборе Сделки&nbsp;без&nbsp;риска.
          </small>
        </h2>
        <div className='freeTariff'>
          <img src={Tarriffs_icon02} className='icon' alt='tariff icon' />
          <div className='title'>А теперь отличные новости!</div>
          <div className='description'>
            В честь запуска проекта мы дарим вам первые два заказа без комиссии. <br /> Просто зарегистрируйтесь как исполнитель и начните
            зарабатывать.
          </div>
        </div>
        <Button
          type={user.is_executor ? 'link' : 'button'}
          href='/orders/available/'
          color='red'
          size='large'
          text={user.is_executor ? 'Найти заказ' : 'Стать исполнителем'}
          width={user.is_executor ? '360rem' : '340rem'}
          onClick={() => becomeExecutor()}
          extraClassName='becomeExecutorButton'
        />
      </div>
    </section>
  );
}

export default Tariffs;
