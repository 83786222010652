import './OrderPage.scss';

import React from 'react';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderReviewExecutor from '../../components/pageSpecific/OrderPage/OrderReview/OrderReviewExecutor';
import OrderChat from '../../components/pageSpecific/OrderPage/OrderChat/OrderChat';

function OrderPage_Review_Exectutor({ order }) {

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Заказ выполнен' />

        <OrderInfo order={order} />

        <OrderReviewExecutor />

        <OrderChat chat_id={order.applications[0].chat_id} forExecutor={true} />
      </div>
    </section>
  );
}

export default OrderPage_Review_Exectutor;