import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function PaymentPolicyPage() {
  return (
    <main className='userAgreementPage'>
      <DocumentContent>
        <h1>Типы оплаты</h1>

        <p>В нашем сервисе предусмотрены три варианта оплат:</p>

        <p>
          1. <b>За заказы</b> — это списание средств с карты исполнителя в зависимости от установленной сервисом суммы. Каждому Исполнителю
          требуется пополнить свой кошелек для отклика на заказ. Внесенные денежные средства конвертируются в валюту Сервиса, что позволяет
          Исполнителю видеть, какое количество заказов ему доступно. Списание в валюте сервиса происходит в момент подтверждения Заказчиком
          Исполнителя на свой опубликованный заказ.
          <br />
          2. <b>Сделка без риска</b> — данный тип списания является комиссией сервиса за резервирование средств Заказчика на специальном
          счете в платежной системе до тех пор, пока Исполнитель не выполнит заказ. В комиссию 12% входят такие сборы как комиссия платежной
          системы за зачисление и вывод средств, комиссия банка и комиссия, уплачиваемая государству за безналичный расчет. В случае Сделки
          без риска сумма списывается с карты Заказчика, затем, в случае успешного выполнения заказа, денежная сумма, соответствующая сумме
          договора между Заказчиком и Исполнителем в конкретном зака за вычетом комиссии в 12% переводится Исполнителю. В случае
          неудовлетворительного выполнения заказа, сумма возвращается Заказчику на карту.
          <br />
          3. <b>Платные услуги</b> — списание по желанию Исполнителя за такие услуги как подъем вверх в результатах поиска, присвоение
          виджета «PRO» и иные услуги, позволяющие Исполнителю чаше получать заказы.
        </p>

        <p>
          Все средства, не являющиеся сборами третьих лиц и/или вознаграждением Исполнителю, перечисляются на расчетный счет ТОО «Unicorn
          Prod», реквизиты:
          <br />
          БИН 200740022394
          <br />
          АО ДБ «Альфабанк»
          <br />
          БИК ALFAKZKA
          <br />
          ИИК KZ089470398992952612.
        </p>
      </DocumentContent>
    </main>
  );
}

export default PaymentPolicyPage;
