import './PromoPopularServices.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { order_creation_category_id_value_change, order_creation_subcategory_id_value_change } from '../../../../actions';

function PromoPopularServices() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <section className='promoPopularServices'>
      <div className='container'>
        <div className='popularServices'>
          <div className='title'>Популярные услуги:</div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(6));
              dispatch(order_creation_subcategory_id_value_change(11));
              history.push('/create_order/');
            }}
          >
            Уборка квартиры
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(5));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Ремонт техники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(8));
              dispatch(order_creation_subcategory_id_value_change(63));
              history.push('/create_order/');
            }}
          >
            Ремонт сантехники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(3));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Услуги курьера
          </div>
        </div>
        <div className='popularServices'>
          <div className='title'>Другие услуги:</div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(6));
              dispatch(order_creation_subcategory_id_value_change(11));
              history.push('/create_order/');
            }}
          >
            Уборка квартиры
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(5));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Ремонт техники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(8));
              dispatch(order_creation_subcategory_id_value_change(63));
              history.push('/create_order/');
            }}
          >
            Ремонт сантехники
          </div>
          <div
            className='link'
            onClick={() => {
              dispatch(order_creation_category_id_value_change(3));
              dispatch(order_creation_subcategory_id_value_change(undefined));
              history.push('/create_order/');
            }}
          >
            Услуги курьера
          </div>
        </div>
      </div>
    </section>
  );
}

export default PromoPopularServices;
