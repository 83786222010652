import axios from 'axios';
import ReactGA from 'react-ga';
import { format } from 'date-fns';
import { alert_modal_settings, alert_modal_display } from '../actions';

import {
  PROFILE_PASSPORT_ADDRESS_CHANGE,
  PROFILE_PASSPORT_BIRTHDAY_CHANGE,
  PROFILE_PASSPORT_CITY_CHANGE,
  PROFILE_PASSPORT_DISPLAY_STATUS_CHANGE,
  PROFILE_PASSPORT_FIRST_NAME_CHANGE,
  PROFILE_PASSPORT_GENDER_CHANGE,
  PROFILE_PASSPORT_IMAGE1_CHANGE,
  PROFILE_PASSPORT_IMAGE1_ERROR_CHANGE,
  PROFILE_PASSPORT_IMAGE2_CHANGE,
  PROFILE_PASSPORT_IMAGE2_ERROR_CHANGE,
  PROFILE_PASSPORT_IMAGE3_CHANGE,
  PROFILE_PASSPORT_IMAGE3_ERROR_CHANGE,
  PROFILE_PASSPORT_LAST_NAME_CHANGE,
  PROFILE_PASSPORT_SAVE,
  PROFILE_PASSPORT_SAVE_ERROR,
  PROFILE_PASSPORT_SAVE_SUCCESS,
  PROFILE_PASSPORT_SURNAME_CHANGE,
} from './types';

/**
 * save passport data
 * @param {number} id profile id
 * @param {Object} data
 * @param {string} data.first_name first name
 * @param {string} data.last_name last name
 * @param {string} data.surname middle name
 * @param {Date} data.birthday birthday
 * @param {number} data.city_id city
 * @param {number} data.gender gender
 * @param {string} data.address address
 * @param {File} [data.passport_image1] first image
 * @param {File} [data.passport_image2] second image
 * @param {File} [data.passport_image3] third image
 */
export const profile_passport_save = (id, data, history) => {
  return (dispatch) => {
    dispatch({ type: PROFILE_PASSPORT_SAVE });

    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    data.surname && formData.append('surname', data.surname);
    formData.append('birthday', format(data.birthday, 'yyyy-MM-dd'));
    formData.append('gender', data.gender);
    formData.append('address', data.address);
    data.passport_image1 && formData.append('passport_image1', data.passport_image1);
    data.passport_image2 && formData.append('passport_image2', data.passport_image2);
    data.passport_image3 && formData.append('passport_image3', data.passport_image3);

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${id}/document/`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        dispatch({ type: PROFILE_PASSPORT_SAVE_SUCCESS });
        ReactGA.event({
          category: 'BecomeExecutor',
          action: 'Отправка документов для верификации - успешно'
        });
        global.debug && console.log('Отправка документов для верификации - успешно:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: PROFILE_PASSPORT_SAVE_ERROR, payload: error.response.data });
        if (error?.response?.status === 413
          || error?.response?.data?.passport_image1?.[0] === 'Размер файла должен быть не более 5 Мб.'
          || error?.response?.data?.passport_image2?.[0] === 'Размер файла должен быть не более 5 Мб.'
          || error?.response?.data?.passport_image3?.[0] === 'Размер файла должен быть не более 5 Мб.') {
          dispatch(
            alert_modal_settings({
              title: `Ошибка загрузки`,
              content: `Размер одного из загруженных файлов превышает максимально допустимый размер - 5мб.`,
              extraClassName: 'alertModalBody',
              buttons: [
                {
                  color: 'red',
                  size: 'large',
                  text: 'Понятно',
                  type: 'button',
                  width: '250rem',
                  onClick: () => dispatch(alert_modal_display(false)),
                }
              ],
            })
          );
          dispatch(alert_modal_display(true));
        }
        ReactGA.event({
          category: 'BecomeExecutor',
          action: 'Отправка документов для верификации - неуспешно'
        })
        global.debug && console.log('Отправка документов для верификации - неуспешно:');
        global.debug && console.log(error.response);
      });
  };
};

/**
 * @param {boolean} display
 * @param {boolean} [single_mode]
 */
export const profile_passport_display_status_change = (display, single_mode) => ({
  type: PROFILE_PASSPORT_DISPLAY_STATUS_CHANGE,
  payload: { display, single_mode },
});

/**
 * @param {string} payload
 */
export const profile_passport_first_name_change = (payload) => ({
  type: PROFILE_PASSPORT_FIRST_NAME_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_last_name_change = (payload) => ({
  type: PROFILE_PASSPORT_LAST_NAME_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_surname_change = (payload) => ({
  type: PROFILE_PASSPORT_SURNAME_CHANGE,
  payload,
});

/**
 * @param {Date} payload
 */
export const profile_passport_birthday_change = (payload) => ({
  type: PROFILE_PASSPORT_BIRTHDAY_CHANGE,
  payload,
});

/**
 * @param {number} payload
 */
export const profile_passport_gender_change = (payload) => ({
  type: PROFILE_PASSPORT_GENDER_CHANGE,
  payload,
});

/**
 * @param {number} payload
 */
export const profile_passport_city_change = (payload) => ({
  type: PROFILE_PASSPORT_CITY_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_address_change = (payload) => ({
  type: PROFILE_PASSPORT_ADDRESS_CHANGE,
  payload,
});

/**
 * @param {File} payload
 */
export const profile_passport_image1_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE1_CHANGE,
  payload,
});

/**
 * @param {File} payload
 */
export const profile_passport_image2_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE2_CHANGE,
  payload,
});

/**
 * @param {File} payload
 */
export const profile_passport_image3_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE3_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_image1_error_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE1_ERROR_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_image2_error_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE2_ERROR_CHANGE,
  payload,
});

/**
 * @param {string} payload
 */
export const profile_passport_image3_error_change = (payload) => ({
  type: PROFILE_PASSPORT_IMAGE3_ERROR_CHANGE,
  payload,
});
