import React from 'react';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function RatesPage() {
  return (
    <main className='ratesPage'>
      <DocumentContent>
        <h1>Рейтинги и критерии оценки пользователей</h1>
        <p>
          При выборе заказчика или исполнителя всегда обращайте внимание на отзывы и рейтинг пользователя. Рейтинг формируется на основе
          оценок других пользователей, которые с ним сотрудничали. Добросовестные заказчики и исполнители хотят, чтобы их выбирали чаще,
          поэтому заботятся о своей репутации. О таких людях как правило много положительных отзывов, и они имеют высокий рейтинг.
        </p>
        <p>
          Отзыв можно оставить как об исполнителе, так и о заказчике после завершения работ по заказу. Критерии «вежливость» и
          «пунктуальность» — общие для оценки всех пользователей сервиса. Кроме того, заказчики оценивают исполнителей по соотношению цены и
          качества работы, а исполнители могут оценить общие впечатления от работы с заказчиком.{' '}
        </p>
      </DocumentContent>
    </main>
  );
}

export default RatesPage;
