import {
  ORDER_CREATION_CATEGORY_ID_VALUE_CHANGE,
  ORDER_CREATION_CITY_ID_VALUE_CHANGE,
  ORDER_CREATION_CREATE,
  ORDER_CREATION_CREATE_FAIL,
  ORDER_CREATION_CREATE_SUCCESS,
  ORDER_CREATION_DATE_VALUE_CHANGE,
  ORDER_CREATION_DESCRIPTION_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_DATE_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_PLACE_VALUE_CHANGE,
  ORDER_CREATION_EXECUTOR_PRICE_VALUE_CHANGE,
  ORDER_CREATION_FIRST_NAME_VALUE_CHANGE,
  ORDER_CREATION_IMAGE1_VALUE_CHANGE,
  ORDER_CREATION_IMAGE2_VALUE_CHANGE,
  ORDER_CREATION_IMAGE3_VALUE_CHANGE,
  ORDER_CREATION_LAST_NAME_VALUE_CHANGE,
  ORDER_CREATION_NEED_FAST_VALUE_CHANGE,
  ORDER_CREATION_ONLY_YUR_VALUE_CHANGE,
  ORDER_CREATION_PHONE_VALUE_CHANGE,
  ORDER_CREATION_PLACE_VALUE_CHANGE,
  ORDER_CREATION_PRICE_MAX_VALUE_CHANGE,
  ORDER_CREATION_PRICE_MIN_VALUE_CHANGE,
  ORDER_CREATION_SAFE_PAYMENT_VALUE_CHANGE,
  ORDER_CREATION_SEND_EMAIL_VALUE_CHANGE,
  ORDER_CREATION_SUBCATEGORY_ID_VALUE_CHANGE,
  ORDER_CREATION_TIME_VALUE_CHANGE,
  ORDER_CREATION_TITLE_VALUE_CHANGE,
  USER_NEED_TO_BE_UPDATED,
} from './types';
import { alert_modal_display, alert_modal_settings } from '../actions';

import axios from 'axios';
import { format } from 'date-fns';

// * Измение значения полей формы создания заказа.
// * Main часть.
export const order_creation_title_value_change = (text) => {
  return {
    type: ORDER_CREATION_TITLE_VALUE_CHANGE,
    payload: text,
  };
};

export const order_creation_category_id_value_change = (number) => {
  return {
    type: ORDER_CREATION_CATEGORY_ID_VALUE_CHANGE,
    payload: number,
  };
};

export const order_creation_subcategory_id_value_change = (number) => {
  return {
    type: ORDER_CREATION_SUBCATEGORY_ID_VALUE_CHANGE,
    payload: number,
  };
};

export const order_creation_description_value_change = (text) => {
  return {
    type: ORDER_CREATION_DESCRIPTION_VALUE_CHANGE,
    payload: text,
  };
};

export const order_creation_city_id_value_change = (number) => {
  return {
    type: ORDER_CREATION_CITY_ID_VALUE_CHANGE,
    payload: number,
  };
};

// * Место.
export const order_creation_executor_place_value_change = (bool) => {
  return {
    type: ORDER_CREATION_EXECUTOR_PLACE_VALUE_CHANGE,
    payload: bool,
  };
};

export const order_creation_place_value_change = (text) => {
  return {
    type: ORDER_CREATION_PLACE_VALUE_CHANGE,
    payload: text,
  };
};

// * Сроки.
export const order_creation_executor_date_value_change = (bool) => {
  return {
    type: ORDER_CREATION_EXECUTOR_DATE_VALUE_CHANGE,
    payload: bool,
  };
};

export const order_creation_date_value_change = (text) => {
  return {
    type: ORDER_CREATION_DATE_VALUE_CHANGE,
    payload: text,
  };
};

export const order_creation_time_value_change = (text) => {
  return {
    type: ORDER_CREATION_TIME_VALUE_CHANGE,
    payload: text.replace(/[^0-9]/g, ''),
  };
};

export const order_creation_need_fast_value_change = () => {
  return {
    type: ORDER_CREATION_NEED_FAST_VALUE_CHANGE,
  };
};

// * Цена.
export const order_creation_executor_price_value_change = (bool) => {
  return {
    type: ORDER_CREATION_EXECUTOR_PRICE_VALUE_CHANGE,
    payload: bool,
  };
};

export const order_creation_price_min_value_change = (number) => {
  return {
    type: ORDER_CREATION_PRICE_MIN_VALUE_CHANGE,
    payload: number.replace(/[^0-9]/g, ''),
  };
};

export const order_creation_price_max_value_change = (number) => {
  return {
    type: ORDER_CREATION_PRICE_MAX_VALUE_CHANGE,
    payload: number.replace(/[^0-9]/g, ''),
  };
};

// * Контакты.
export const order_creation_first_name_value_change = (text) => {
  return {
    type: ORDER_CREATION_FIRST_NAME_VALUE_CHANGE,
    payload: text,
  };
};

export const order_creation_last_name_value_change = (text) => {
  return {
    type: ORDER_CREATION_LAST_NAME_VALUE_CHANGE,
    payload: text,
  };
};

export const order_creation_phone_value_change = (text) => {
  return {
    type: ORDER_CREATION_PHONE_VALUE_CHANGE,
    payload: text,
  };
};

// * Уведомления.
export const order_creation_send_email_value_change = () => {
  return {
    type: ORDER_CREATION_SEND_EMAIL_VALUE_CHANGE,
  };
};

// * Безопасная сделка & юр. лица.
export const order_creation_safe_payment_value_change = () => {
  return {
    type: ORDER_CREATION_SAFE_PAYMENT_VALUE_CHANGE,
  };
};

export const order_creation_only_yur_value_change = () => {
  return {
    type: ORDER_CREATION_ONLY_YUR_VALUE_CHANGE,
  };
};

// * Создание заказа
/**
 * @param {Object} props props of operation
 * @param {string} props.title
 * @param {number} props.category_id
 * @param {number} props.subcategory_id
 * @param {string} props.description
 * @param {boolean} props.executor_place
 * @param {string} props.place
 * @param {boolean} props.executor_date
 * @param {string} props.date
 * @param {string} props.time
 * @param {boolean} props.need_fast
 * @param {boolean} props.executor_price
 * @param {string} props.price_min
 * @param {string} props.price_max
 * @param {boolean} props.send_email
 * @param {boolean} props.safe_payment
 * @param {boolean} props.only_yur
 * @param {string} props.first_name
 * @param {string} props.last_name
 * @param {string} props.phone
 * @param {History} props.history
 * @param {File} props.image_1
 * @param {File} props.image_2
 * @param {File} props.image_3
 */

export const order_creation_create = (props) => {
  return (dispatch) => {
    const { price_min, price_max, date, history, image_1, image_2, image_3 } = props;
    const data = new FormData();

    dispatch({ type: ORDER_CREATION_CREATE });

    date ? (props.date = format(date, 'yyyy-MM-dd')) : delete props.date;
    price_min ? (props.price_min = parseInt(price_min.replace(/[^0-9]/g, '')) || 0) : delete props.price_min;
    price_max ? (props.price_max = parseInt(price_max.replace(/[^0-9]/g, '')) || 0) : delete props.price_max;

    if (!image_1) {
      delete props.image_1;
    }
    if (!image_2) {
      delete props.image_2;
    }
    if (!image_3) {
      delete props.image_3;
    }

    // remove the history object from data for the backend
    Object.keys(props)
      .filter((prop) => prop !== 'history')
      .forEach((prop) => data.append(prop, props[prop]));

    axios({
      method: 'post',
      url: global.backendURL + 'tasks/task/',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        dispatch({ type: ORDER_CREATION_CREATE_SUCCESS });
        dispatch({ type: USER_NEED_TO_BE_UPDATED, payload: true });
        history.push(`/order/${response.data.task_id}/`);
        global.debug && console.log('%cORDER_CREATION_CREATE_SUCCESS:', 'color: #00ee00;');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: ORDER_CREATION_CREATE_FAIL });
        global.debug && console.log('%cORDER_CREATION_CREATE_FAIL:', 'color: red;');
        global.debug && console.log(error);
        global.debug && console.log(error.response);

        if (
          error?.response?.status === 413 ||
          error?.response?.data?.image_1[0] === 'Размер файла должен быть не более 5 Мб.' ||
          error?.response?.data?.image_1[1] === 'Размер файла должен быть не более 5 Мб.' ||
          error?.response?.data?.image_1[2] === 'Размер файла должен быть не более 5 Мб.'
        ) {
          dispatch(
            alert_modal_settings({
              title: `Ошибка загрузки`,
              content: `Размер загруженного файла превышает максимально допустимый - 5мб.`,
              extraClassName: 'alertModalBody',
              buttons: [
                {
                  color: 'red',
                  size: 'large',
                  text: 'Понятно',
                  type: 'button',
                  width: '250rem',
                  onClick: () => dispatch(alert_modal_display(false)),
                },
              ],
            })
          );
          dispatch(alert_modal_display(true));
        }
      });
  };
};

export const order_creation_image1_change = (payload) => ({
  type: ORDER_CREATION_IMAGE1_VALUE_CHANGE,
  payload,
});
export const order_creation_image2_change = (payload) => ({
  type: ORDER_CREATION_IMAGE2_VALUE_CHANGE,
  payload,
});
export const order_creation_image3_change = (payload) => ({
  type: ORDER_CREATION_IMAGE3_VALUE_CHANGE,
  payload,
});
