import './PromoPage.scss';

import React from 'react';
import PromoIndex from '../../components/pageSpecific/PromoPage/PromoIndex/PromoIndex';
import PromoFirstOrder from '../../components/pageSpecific/PromoPage/PromoFirstOrder/PromoFirstOrder';
import PromoSafeDeal from '../../components/pageSpecific/PromoPage/PromoSafeDeal/PromoSafeDeal';
import DownloadOurApp from '../../components/pageSpecific/HomePage/DownloadOurApp/DownloadOurApp';
import PromoJoinUs from '../../components/pageSpecific/PromoPage/PromoJoinUs/PromoJoinUs';
import PromoPopularServices from '../../components/pageSpecific/PromoPage/PromoPopularServices/PromoPopularServices';
import { useParams } from 'react-router-dom';
import usePromoData from '../../hooks/usePromoData';
import Loader from '../../components/shared/Loader/Loader';

function PromoPage() {
  const { key1, key2, key3, key4, key5 } = useParams();
  const { data: promoData, status: promoDataLoadingStatus } = usePromoData(key1, key2, key3, key4, key5);

  return (
    <main className='promoPage'>
      {promoDataLoadingStatus === 'success' ?
        <>
          <PromoIndex promoData={promoData} />
          <PromoFirstOrder promoData={promoData}/>
          <PromoSafeDeal />
          <DownloadOurApp />
          <PromoJoinUs />
          <PromoPopularServices />
        </> : 
        <Loader />
      }
    </main>
  );
}

export default PromoPage;
