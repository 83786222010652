import './ProfileUserDataTab.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import { sizeLarge } from '../../../../const';
import { useWindowDimensions } from '../../../../functions';
import ProfileBio from '../ProfileBio/ProfileBio';
import ProfileCategories from '../ProfileCategories/ProfileCategories';
import ProfileFeedback from '../ProfileFeedback/ProfileFeedback';
import ProfilePortfolio from '../ProfilePortfolio/ProfilePortfolio';
import ProfileRating from '../ProfileRating/ProfileRating';
import ProfileSideBlockData from '../ProfileSideBlockData/ProfileSideBlockData';

/**
 * user data tab
 */
function ProfileUserDataTab() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const { width } = useWindowDimensions();

  return (
    <div className='profileUserDataTabContainer'>
      <div className='profileUserDataTabMain'>
        <ProfileBio />
        {width <= sizeLarge && <ProfileSideBlockData />}
        {user.is_executor && <ProfileCategories />}
        {user.is_executor && <ProfilePortfolio />}
      </div>
      <div className='profileUserDataTabExtra'>
        {/* <ProfileRating emptyBlockCaption='Оценки появятся после того, как вы создадите или выполните заказ.' /> */}
        <ProfileFeedback emptyBlockCaption='Отзыв появится после того, как вы создадите или выполните заказ.' />
      </div>
    </div>
  );
}

export default ProfileUserDataTab;
