import { PROFILE_CATEGORIES_LOAD, PROFILE_CATEGORIES_LOAD_ERROR, PROFILE_CATEGORIES_LOAD_SUCCESS } from '../actions/types';

/**
 * @type {import('../typedef').TProfileCategoriesReducer}
 */
const INITIAL_STATE = {
  is_loading: false,
};

/** @returns {import('../typedef').TProfileCategoriesReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_CATEGORIES_LOAD:
      return { ...state, is_loading: true };
    case PROFILE_CATEGORIES_LOAD_SUCCESS:
      return { ...action.payload, is_loading: false };
    case PROFILE_CATEGORIES_LOAD_ERROR:
      return { ...state, is_loading: false };
    default:
      return { ...state };
  }
};
