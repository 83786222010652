import './OrderPage.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { order_response_modal_display_toggle, order_response_modal_order_id_value_change, user_load } from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import Button from '../../components/shared/Button/Button';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderChat from '../../components/pageSpecific/OrderPage/OrderChat/OrderChat';

function OrderPage_InWork_Customer({ order_id, order }) {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.userReducer.user_id);
  const [alreadyResponded, setAlreadyResponded] = useState(false);
  const [chatId, setChatId] = useState(null);

  useEffect(() => {
    order.applications.map((application) => {
      if (application.executor.id === parseInt(user_id)) {
        setAlreadyResponded(true);
        setChatId(application.chat_id);
        console.log(application.chat_id);
      }
    });
  }, []);

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle text='Заказ выполняется' />

        <OrderInfo order={order} />

        {alreadyResponded && <OrderChat forExecutor={true} chat_id={chatId} />}
      </div>
    </section>
  );
}

export default OrderPage_InWork_Customer;
