import './ChatModal.scss';

import React, { useEffect, useRef } from 'react';
import {
  chat_message_value_change,
  chat_modal_display_toggle,
  chat_modal_load_history,
  chat_read_message,
  chat_send_message,
} from './../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval, useWindowDimensions } from './../../../functions';

import Input from '../../shared/Input/Input';
import Modal from '../../shared/Modal/Modal';
import Spinner from '../../shared/Spinner/Spinner';
import useChatHistory from '../../../hooks/useChatHistory';

function ChatModal() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.orderReducer.order.title);
  const chat_id = useSelector((state) => state.chatModalReducer.chat_modal_chat_id);
  const user_id = useSelector((state) => state.userReducer.user_id);
  // const chatHistory = useSelector((state) => state.chatModalReducer.chat_history);
  // const chat_history_need_to_be_updated = useSelector((state) => state.chatModalReducer.chat_history_need_to_be_updated);
  const chatMessage = useSelector((state) => state.chatModalReducer.chat_message_value);

  const { width } = useWindowDimensions();
  const chatNode = useRef();
  const messageNode = useRef();
  const lastMessageNode = useRef();
  const { data, status, isFetching } = useChatHistory(chat_id);

  useEffect(() => {
    let positions;
    if (lastMessageNode.current) {
      positions = lastMessageNode.current.getBoundingClientRect();
    }
    positions && chatNode.current.scrollTo(0, positions.bottom);
  }, [data]);

  useInterval(() => {
    dispatch(chat_modal_load_history(chat_id));
  }, 30000);

  return (
    <Modal extraClassName='chatModal' onClose={() => dispatch(chat_modal_display_toggle({ display_status: false, chat_id: null }))}>
      <div className='title'>
        <span className='titleText'>Чат</span>
        <span className='theme'>{theme}</span>
      </div>
      <div className='chat' ref={chatNode}>
        {status === 'success' ? (
          data.messages.map((chatMessage, index) => (
            <>
              <div
                ref={index === data.messages.length - 1 ? lastMessageNode : messageNode}
                className='chatMessage'
                key={`ChatMessage + ${chatMessage.id}`}
                onMouseEnter={() => {
                  !chatMessage.read && chatMessage.author.id !== parseInt(user_id) && dispatch(chat_read_message(chat_id, chatMessage.id));
                }}
              >
                {width > 500 && (
                  <div
                    className={`avatar${chatMessage.author.image == '' ? ' noAvatar' : ''}`}
                    style={{ backgroundImage: `url('${chatMessage.author.image}')` }}
                  />
                )}
                <div className={`name${chatMessage.author.id === parseInt(user_id) ? ' yourName' : ''}`}>
                  {chatMessage.author.id === parseInt(user_id) ? 'Вы' : `${chatMessage.author.first_name} ${chatMessage.author.last_name}`}
                </div>
                <div className='messageTime'>{chatMessage.created_at}</div>
                <div className='messageText'>{chatMessage.text}</div>
                <div className={`messageStatus${chatMessage.read ? ' read' : ' unread'}`}></div>
              </div>
            </>
          ))
        ) : (
          <Spinner />
        )}
      </div>
      <div className='chatNewMessage'>
        <Input
          type='text'
          value={chatMessage}
          onChange={(event) => dispatch(chat_message_value_change(event.target.value))}
          placeholder='Написать'
          extraClassName='newMessageInput'
        />
        <button type='submit' className='newMessageSubmit' onClick={() => dispatch(chat_send_message(chat_id, chatMessage))}></button>
      </div>
    </Modal>
  );
}

export default ChatModal;
