import {
  CUSTOMER_ORDERS_DASHBOARD_LOAD,
  CUSTOMER_ORDERS_DASHBOARD_LOAD_SUCCESS,
  CUSTOMER_ORDERS_DASHBOARD_LOAD_FAIL,
  EXECUTOR_ORDERS_DASHBOARD_LOAD,
  EXECUTOR_ORDERS_DASHBOARD_LOAD_SUCCESS,
  EXECUTOR_ORDERS_DASHBOARD_LOAD_FAIL,
} from '../actions/types';

/** @type {import('../typedef').TLoginReducer} */
const INITIAL_STATE = {
  created_orders_all: [],
  created_orders_processing: [],
  created_orders_in_work: [],
  created_orders_completed: [],
  is_loading: false,
  available_orders_all: [],
  available_orders_you_responeded: [],
  available_orders_in_work: [],
  available_orders_archive: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_ORDERS_DASHBOARD_LOAD: {
      return {
        ...state,
        is_loading: true,
      };
    }
    case CUSTOMER_ORDERS_DASHBOARD_LOAD_SUCCESS: {
      return {
        ...state,
        created_orders_all: action.payload,
        created_orders_processing: action.payload.filter((order) => order.task_status === '1'),
        created_orders_in_work: action.payload.filter((order) => order.task_status === '2'),
        created_orders_completed: action.payload.filter((order) => order.task_status === '3'),
        is_loading: false,
      };
    }
    case CUSTOMER_ORDERS_DASHBOARD_LOAD_FAIL: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }

    case EXECUTOR_ORDERS_DASHBOARD_LOAD: {
      return {
        ...state,
        is_loading: true,
      };
    }
    case EXECUTOR_ORDERS_DASHBOARD_LOAD_SUCCESS: {
      return {
        ...state,
        //available_orders_all: action.payload,
        available_orders_all: action.payload.filter((order) => (order.task_status === '0' || order.task_status === '1') && order.user_status === 0),
        available_orders_you_responeded: action.payload.filter((order) => order.user_status === 1),
        available_orders_in_work: action.payload.filter((order) => order.user_status === 2 && order.task_status === '2'),
        available_orders_archive: action.payload.filter((order) => order.user_status === 2 && order.task_status === '3'),
        is_loading: false,
      };
    }
    case EXECUTOR_ORDERS_DASHBOARD_LOAD_FAIL: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};
