import './OrderReview.scss';
import icon_starActive from '../../../../assets/images/icon_starActive.svg';

import React from 'react';
import { useSelector } from 'react-redux';

function OrderReviewExecutor() {
  const reivew_decency = useSelector((state) => state.orderReducer.order.feedback?.decency);
  const review_adequacy = useSelector((state) => state.orderReducer.order.feedback?.adequacy);
  const review_punctuality = useSelector((state) => state.orderReducer.order.feedback?.punctuality);
  const review_text = useSelector((state) => state.orderReducer.order.feedback?.feedback_text);

  return (
    <div className='orderReview'>
      {reivew_decency && review_adequacy && review_punctuality && review_text ? (
        <>
          <div className='title'>Заказчик оставил отзыв</div>
          <div className='reviewStats'>
            <div className='reviewStat'>
              <span className='text'>Цена-качество</span>
              <img className={`starIcon${reivew_decency >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${reivew_decency >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
            <div className='reviewStat'>
              <span className='text'>Вежливость</span>
              <img className={`starIcon${review_adequacy >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_adequacy >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
            <div className='reviewStat'>
              <span className='text'>Пунктуальность</span>
              <img className={`starIcon${review_punctuality >= 1 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 2 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 3 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 4 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
              <img className={`starIcon${review_punctuality >= 5 ? ' active' : ''}`} src={icon_starActive} alt='star icon' />
            </div>
          </div>
          <div className='reviewMessageText'>{review_text}</div>
        </>
      ) : (
        <>
          <div className='title'>Заказчик пока не оставил отзыв</div>
          <div className='reviewMessageText noMargin'>Если прошло какое-то время, а заказчик всё ещё не оставил отзыв - вы можете напомнить ему об этом в чате.</div>
        </>
      )}
    </div>
  );
}

export default OrderReviewExecutor;