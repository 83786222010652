import axios from 'axios';
import {
  CHAT_MODAL_DISPLAY_TOGGLE,
  CHAT_LIST_HEADER_DISPLAY_TOGGLE,
  CHAT_LIST_LOAD,
  CHAT_LIST_LOAD_SUCCESS,
  CHAT_LIST_LOAD_FAIL,
  CHAT_LOAD_HISTORY,
  CHAT_LOAD_HISTORY_SUCCESS,
  CHAT_LOAD_HISTORY_FAIL,
  CHAT_MESSAGE_VALUE_CHANGE,
  CHAT_SEND_MESSAGE,
  CHAT_SEND_MESSAGE_SUCCESS,
  CHAT_SEND_MESSAGE_FAIL,
  CHAT_READ_MESSAGE,
  CHAT_READ_MESSAGE_SUCCESS,
  CHAT_READ_MESSAGE_FAIL,
} from './types';
import { queryCache } from 'react-query';

export const chat_modal_display_toggle = (payload) => ({
  type: CHAT_MODAL_DISPLAY_TOGGLE,
  payload,
});

export const chat_list_header_display_toggle = (payload) => ({
  type: CHAT_LIST_HEADER_DISPLAY_TOGGLE,
  payload,
});

export const chat_list_load = () => {
  return (dispatch) => {
    dispatch({ type: CHAT_LIST_LOAD });

    axios({
      method: 'get',
      url: global.backendURL + `tasks/chat/all/`,
    })
      .then((result) => {
        dispatch({ type: CHAT_LIST_LOAD_SUCCESS, payload: result.data });
        global.debug && console.log('%cCHAT_LIST_LOAD_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: CHAT_LIST_LOAD_FAIL });
        global.debug && console.log('%cCHAT_LIST_LOAD_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

export const chat_modal_load_history = (chat_id) => {
  return (dispatch) => {
    dispatch({ type: CHAT_LOAD_HISTORY });

    axios({
      method: 'get',
      url: global.backendURL + `tasks/chat/${chat_id}/`,
    })
      .then((result) => {
        dispatch({ type: CHAT_LOAD_HISTORY_SUCCESS, payload: result?.data?.messages });
        global.debug && console.log('%cCHAT_MODAL_LOAD_HISTORY_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data.messages);
      })
      .catch((error) => {
        dispatch({ type: CHAT_LOAD_HISTORY_FAIL });
        global.debug && console.log('%cCHAT_MODAL_LOAD_HISTORY_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

export const chat_message_value_change = (payload) => ({
  type: CHAT_MESSAGE_VALUE_CHANGE,
  payload,
});

export const chat_send_message = (chat_id, message) => {
  return (dispatch) => {
    dispatch({ type: CHAT_SEND_MESSAGE });

    axios({
      method: 'post',
      url: global.backendURL + `tasks/chat/${chat_id}/`,
      data: {
        text: message,
      },
    })
      .then((result) => {
        dispatch({ type: CHAT_SEND_MESSAGE_SUCCESS, payload: result.data });
        queryCache.invalidateQueries('chatData', chat_id);
        global.debug && console.log('%cCHAT_SEND_MESSAGE_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result.data);
      })
      .catch((error) => {
        dispatch({ type: CHAT_SEND_MESSAGE_FAIL });
        global.debug && console.log('%cCHAT_SEND_MESSAGE_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};

export const chat_read_message = (chat_id, message_id) => {
  return (dispatch) => {
    dispatch({ type: CHAT_READ_MESSAGE });
    axios({
      method: 'post',
      url: global.backendURL + `tasks/chat/${chat_id}/`,
      data: {
        message_id,
      },
    })
      .then((result) => {
        dispatch({ type: CHAT_READ_MESSAGE_SUCCESS });
        queryCache.invalidateQueries('chatData', chat_id);
        global.debug && console.log('%cCHAT_READ_MESSAGE_SUCCESS', 'color: #00ee00;');
        global.debug && console.log(result);
      })
      .catch((error) => {
        dispatch({ type: CHAT_READ_MESSAGE_FAIL });
        global.debug && console.log('%cCHAT_READ_MESSAGE_FAIL', 'color: red;');
        global.debug && console.log(error.response);
      });
  };
};
