import axios from 'axios';

import {
  PROFILE_FEEDBACKS_COUNT_CHANGE,
  PROFILE_FEEDBACKS_LOAD,
  PROFILE_FEEDBACKS_LOAD_ERROR,
  PROFILE_FEEDBACKS_LOAD_SUCCESS,
  PROFILE_FEEDBACKS_START_CHANGE,
} from './types';

export const profile_feedbacks_load = (props) => {
  const { user_id } = props;

  return (dispatch) => {
    dispatch({ type: PROFILE_FEEDBACKS_LOAD });

    axios({
      method: 'get',
      url: `${global.backendURL}users/user/${user_id}/feedbacks/`,
    })
      .then((result) => {
        dispatch({ type: PROFILE_FEEDBACKS_LOAD_SUCCESS, payload: result.data });
        global.debug && console.log('%cPROFILE_FEEDBACKS_LOAD_SUCCESS', 'color: #00ee00');
        global.debug && console.log(result.data);
      })
      .catch((error) => dispatch({ type: PROFILE_FEEDBACKS_LOAD_ERROR, payload: error.response }));
  };
};

/**
 * set first feedback to load
 * @param {number} payload
 */
export const profile_feedbacks_start = (payload) => ({
  type: PROFILE_FEEDBACKS_START_CHANGE,
  payload,
});

/**
 * set amount of feedbacks to load
 * @param {number} payload
 */
export const profile_feedbacks_count = (payload) => ({
  type: PROFILE_FEEDBACKS_COUNT_CHANGE,
  payload,
});
