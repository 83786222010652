import './PromoFirstOrder.scss';
import FirstOrder_illustration01 from '../../../../assets/images/FirstOrder_illustration01.svg';
import FirstOrder_illustration02 from '../../../../assets/images/FirstOrder_illustration02.svg';
import FirstOrder_illustration03 from '../../../../assets/images/FirstOrder_illustration03.svg';

import React from 'react';

function PromoFirstOrder({promoData}) {
  const renderH2 = () => {
    if (promoData.L5) {
      return promoData.L5;
    } else if (promoData.L4) {
      return promoData.L4;
    } else if (promoData.L3) {
      return promoData.L3;
    } else if (promoData.L2) {
      return promoData.L2;
    } else if (promoData.L1) {
      return promoData.L1;
    }
  };
  
  return (
    <section className='promoFirstOrder'>
      <div className='container'>
        <h2 className='h2'>Заказать {renderH2().toLowerCase()} просто</h2>
        <div className='step'>
          <div className='stepIllustrationWrapper'>
            <img className='stepIllustration' src={FirstOrder_illustration01} />
          </div>
          <div className='stepNumber'>1</div>
          <div className='stepTitle'>Разместите заказ на сайте</div>
          <div className='stepDescription'>
            Опишите, что именно и в какие сроки вам нужно сделать. Умный алгоритм бесплатно подберет наиболее подходящих исполнителей.
          </div>
        </div>
        <div className='step'>
          <div className='stepIllustrationWrapper'>
            <img className='stepIllustration' src={FirstOrder_illustration02} />
          </div>
          <div className='stepNumber'>2</div>
          <div className='stepTitle'>Выберите лучшего мастера</div>
          <div className='stepDescription'>
            Пообщайтесь подобранными для вас мастерами в чате signedUP или по телефону. Выберите самого подходящего по рейтингу, стоимости
            работ и отзывам.
          </div>
        </div>
        <div className='step'>
          <div className='stepIllustrationWrapper'>
            <img className='stepIllustration' src={FirstOrder_illustration03} />
          </div>
          <div className='stepNumber'>3</div>
          <div className='stepTitle'>Договоритесь об условиях</div>
          <div className='stepDescription'>
            Обсудите с выбранным мастером детали, сроки и место работы. Все устраивает? Отлично. Теперь просто подождите, пока вашу задачу
            решит профессионал.
          </div>
        </div>
      </div>
    </section>
  );
}

export default PromoFirstOrder;
