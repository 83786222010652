import './ProfileRating.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ProfileStars from '../ProfileStars/ProfileStars';

/**
 * default ratings values
 */
const defaultRatings = { adequacy: 0, decency: 0, punctuality: 0, rate: 0 };

/**
 * profile rating component
 * @param {Object} props
 * @param {string} [props.extraClassName] additional CSS class name
 * @param {string} [props.emptyBlockCaption] message to be shown for the empty block
 */
function ProfileRating(props) {
  const { extraClassName, emptyBlockCaption } = props;
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const ratings_data = useSelector((state) => state.profileReducer.ratings_data);
  const [ratings, setRatings] = useState({ ...defaultRatings });

  useEffect(() => {
    if (user.is_customer) {
      setRatings({
        adequacy: ratings_data.customer_adequacy || 0,
        decency: ratings_data.customer_decency || 0,
        punctuality: ratings_data.customer_punctuality || 0,
        rate: ratings_data.customer_rate || 0,
      });
    } else if (user.is_executor) {
      setRatings({
        adequacy: ratings_data.executor_adequacy || 0,
        decency: ratings_data.executor_decency || 0,
        punctuality: ratings_data.executor_punctuality || 0,
        rate: ratings_data.executor_rate || 0,
      });
    } else {
      setRatings({ ...defaultRatings });
    }
  }, [user, ratings_data]);

  return (
    <div className={`profileRating ${extraClassName || ''}`}>
      {ratings.rate ? (
        <div className='full'>
          <div className='overall'>
            <span className='caption'>Средняя оценка:</span> <div className='mark'>{ratings.rate}</div>
            <ProfileStars rate={ratings.rate} extraClassName='rating' />
          </div>
          <div className='details'>
            <div className='detail'>
              Адекватность <ProfileStars rate={ratings.adequacy} extraClassName='rating' />
            </div>
            <div className='detail'>
              Вежливость <ProfileStars rate={ratings.decency} extraClassName='rating' />
            </div>
            <div className='detail'>
              Пунктуальность <ProfileStars rate={ratings.punctuality} extraClassName='rating' />
            </div>
          </div>
        </div>
      ) : (
        <div className='empty'>
          <div className='icon'></div>
          <div className='data'>
            <div className='caption'>Оценок пока нет</div>
            <div className='value'>{emptyBlockCaption}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileRating;
