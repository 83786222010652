import './CitySelectModal.scss';

import React, { useEffect, useState } from 'react';
import {
  city_select_modal_display_toggle,
  city_select_modal_selected_city_id_value_change,
  city_select_modal_submit,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';
import Select from '../../shared/Select/Select';
import axios from 'axios';

function CitySelectModal() {
  const dispatch = useDispatch();
  const city_id = useSelector((state) => state.citySelectModalReducer.city_select_modal_selected_city_id);
  const is_submitting = useSelector((state) => state.citySelectModalReducer.city_select_modal_submiting);
  const user_id = useSelector((state) => state.userReducer.user_id);

  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${global.backendURL}cities/`,
    })
      .then((result) => setCities(result.data.cities))
      .catch((error) => global.debug && console.log(error));
  }, []);

  return (
    <Modal extraClassName='citySelectModal' onClose={() => dispatch(city_select_modal_display_toggle(false))}>
      <div className='title'>Вы находитесь в {cities.find((city) => city.city_id === city_id)?.title}?</div>
      <div className='citySelectForm'>
        <Select
          name='city'
          label='Город'
          selection={city_id}
          options={cities}
          onClick={(event) => dispatch(city_select_modal_selected_city_id_value_change(parseInt(event.target.id)))}
        />
        <Button
          type='submit'
          onClick={() => dispatch(city_select_modal_submit(user_id, city_id))}
          color='red'
          size='normal'
          text='Подтверить'
          width='300rem'
          extraClassName='submitButton'
          isLoading={is_submitting}
          disabled={is_submitting || !city_id}
        />
      </div>
    </Modal>
  );
}

export default CitySelectModal;
