import axios from 'axios';
import ReactGA from 'react-ga';
import { signedUpPhone, signedUpToken, signedUpUserId } from '../const';
import {
  GLOBAL_VARIABLES_SET_TOKEN,
  GLOBAL_VARIABLES_SET_USER_ID,
  LOGIN_MODAL_CONFIRM_OTP,
  LOGIN_MODAL_CONFIRM_OTP_FAIL,
  LOGIN_MODAL_CONFIRM_OTP_SUCCESS,
  LOGIN_MODAL_DISPLAY_TOGGLE,
  LOGIN_MODAL_GET_OTP,
  LOGIN_MODAL_GET_OTP_FAIL,
  LOGIN_MODAL_GET_OTP_SUCCESS,
  LOGIN_MODAL_OTP_VALUE_CHANGE,
  LOGIN_MODAL_PHONE_VALUE_CHANGE,
} from './types';
import { user_load } from './UserActions';

// * Показать / скрыть модальное окно логине
export const login_modal_display_toggle = (status) => {
  return {
    type: LOGIN_MODAL_DISPLAY_TOGGLE,
    payload: status,
  };
};

// * Измение значения полей модального онка логине
export const login_modal_phone_value_change = (phone_number) => {
  return {
    type: LOGIN_MODAL_PHONE_VALUE_CHANGE,
    payload: phone_number.replace(/[^0-9]/g, ''),
  };
};
export const login_modal_otp_value_change = (text) => {
  return {
    type: LOGIN_MODAL_OTP_VALUE_CHANGE,
    payload: text,
  };
};

// * Получить ОТП ( проверочный смс-код ) при логине
export const login_modal_get_otp = (phone) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_MODAL_GET_OTP });
    axios({
      method: 'post',
      url: global.backendURL + 'auth/login/',
      data: {
        phone,
        real_sms: global.send_sms,
      },
    })
      .then((response) => {
        dispatch({
          type: LOGIN_MODAL_GET_OTP_SUCCESS,
          payload: response?.data?.sms_key,
        });
        ReactGA.event({
          category: 'Login',
          action: 'Получить ОТП ( проверочный смс-код ) - успешно'
        })
        global.debug && console.log('Получение OTP - Success:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_MODAL_GET_OTP_FAIL,
          payload: error?.response?.data?.error,
        });
        ReactGA.event({
          category: 'Login',
          action: 'Получить ОТП ( проверочный смс-код ) - неуспешно'
        })
        global.debug && console.log('Получение OTP - Fail:');
        global.debug && console.log(error.response);
      });
  };
};

// * Подтвердить ОТП ( проверочный смс-код ) и зарегистрироваться
export const login_modal_confirm_otp = (sms_key, otp, phone) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_MODAL_CONFIRM_OTP });
    axios({
      method: 'post',
      url: global.backendURL + 'auth/code/',
      data: {
        sms_key,
        code: otp,
      },
    })
      .then((response) => {
        const { token, user_id } = response.data;
        localStorage.setItem(signedUpToken, token);
        localStorage.setItem(signedUpUserId, user_id);
        localStorage.setItem(signedUpPhone, phone);
        dispatch({ type: GLOBAL_VARIABLES_SET_TOKEN, payload: token, });
        dispatch({ type: GLOBAL_VARIABLES_SET_USER_ID, payload: user_id, });
        dispatch({ type: LOGIN_MODAL_PHONE_VALUE_CHANGE, payload: phone });
        dispatch({ type: LOGIN_MODAL_CONFIRM_OTP_SUCCESS });
        dispatch(user_load(user_id));
        ReactGA.event({
          category: 'Login',
          action: 'Подтвердить ОТП ( проверочный смс-код ) & завершить логин - успешно'
        })
        global.debug && console.log('Подтверждение OTP - Success:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: LOGIN_MODAL_CONFIRM_OTP_FAIL, payload: error?.response?.data?.error, });
        ReactGA.event({
          category: 'Login',
          action: 'Подтвердить ОТП ( проверочный смс-код ) & завершить логин - неуспешно'
        })
        global.debug && console.log('Подтверждение OTP - Fail:');
        global.debug && console.log(error.response);
      });
  };
};
