import axios from 'axios';
import ReactGA from 'react-ga';
import { signedUpPhone, signedUpToken, signedUpUserId } from '../const';
import {
  GLOBAL_VARIABLES_SET_TOKEN,
  GLOBAL_VARIABLES_SET_USER_ID,
  LOGIN_MODAL_PHONE_VALUE_CHANGE,
  REGISTR_MODAL_CONFIRM_OTP,
  REGISTR_MODAL_CONFIRM_OTP_FAIL,
  REGISTR_MODAL_CONFIRM_OTP_SUCCESS,
  REGISTR_MODAL_DISPLAY_TOGGLE,
  REGISTR_MODAL_FIRST_NAME_VALUE_CHANGE,
  REGISTR_MODAL_GET_OTP,
  REGISTR_MODAL_GET_OTP_FAIL,
  REGISTR_MODAL_GET_OTP_SUCCESS,
  REGISTR_MODAL_LAST_NAME_VALUE_CHANGE,
  REGISTR_MODAL_OTP_VALUE_CHANGE,
  REGISTR_MODAL_PHONE_VALUE_CHANGE,
} from './types';
import { user_load } from './UserActions';

// * Показать / скрыть модальное окно регистрации
export const registr_modal_display_toggle = (status, initiation_point) => {
  return {
    type: REGISTR_MODAL_DISPLAY_TOGGLE,
    payload: { status, initiation_point },
  };
};

// * Измение значения полей модального онка регистрации
export const registr_modal_first_name_value_change = (text) => {
  return {
    type: REGISTR_MODAL_FIRST_NAME_VALUE_CHANGE,
    payload: text,
  };
};
export const registr_modal_last_name_value_change = (text) => {
  return {
    type: REGISTR_MODAL_LAST_NAME_VALUE_CHANGE,
    payload: text,
  };
};
export const registr_modal_phone_value_change = (phone_number) => {
  return {
    type: REGISTR_MODAL_PHONE_VALUE_CHANGE,
    payload: phone_number.replace(/[^0-9]/g, ''),
  };
};
export const registr_modal_otp_value_change = (text) => {
  return {
    type: REGISTR_MODAL_OTP_VALUE_CHANGE,
    payload: text,
  };
};

// * Получить ОТП ( проверочный смс-код ) при регистрации
export const registr_modal_get_otp = (first_name, last_name, phone) => {
  return (dispatch) => {
    dispatch({ type: REGISTR_MODAL_GET_OTP });
    axios({
      method: 'post',
      url: global.backendURL + 'auth/registration/',
      data: {
        first_name,
        last_name,
        phone: phone.replace(/[^0-9]/g, ''),
        real_sms: global.send_sms,
      },
    })
      .then((response) => {
        dispatch({
          type: REGISTR_MODAL_GET_OTP_SUCCESS,
          payload: response.data.sms_key,
        });
        ReactGA.event({
          category: 'Registration',
          action: 'Получить ОТП ( проверочный смс-код ) - успешно'
        })
        global.debug && console.log(response);
      })
      .catch((error) => {
        dispatch({
          type: REGISTR_MODAL_GET_OTP_FAIL,
          payload: error.response.data.error,
        });
        ReactGA.event({
          category: 'Registration',
          action: 'Получить ОТП ( проверочный смс-код ) - неуспешно'
        })
        global.debug && console.log(error.response);
      });
  };
};

// * Подтвердить ОТП ( проверочный смс-код ) и зарегистрироваться
export const registr_modal_confirm_otp = (sms_key, otp, phone) => {
  return (dispatch) => {
    dispatch({ type: REGISTR_MODAL_CONFIRM_OTP });
    axios({
      method: 'post',
      url: global.backendURL + 'auth/code/',
      data: {
        sms_key,
        code: otp,
      },
    })
      .then((response) => {
        localStorage.setItem(signedUpToken, response.data.token);
        localStorage.setItem(signedUpUserId, response.data.user_id);
        dispatch({
          type: GLOBAL_VARIABLES_SET_TOKEN,
          payload: response.data.token,
        });
        dispatch({
          type: GLOBAL_VARIABLES_SET_USER_ID,
          payload: response.data.user_id,
        });
        dispatch(user_load(response.data.user_id));
        global.debug && console.log(response.data);
      })
      .then(() => {
        dispatch({ type: REGISTR_MODAL_CONFIRM_OTP_SUCCESS });
        localStorage.setItem(signedUpPhone, phone);
        dispatch({ type: LOGIN_MODAL_PHONE_VALUE_CHANGE, payload: phone });
        ReactGA.event({
          category: 'Registration',
          action: 'Подтвердить ОТП ( проверочный смс-код ) & завершить регистрацию - успешно'
        })
      })
      .catch((error) => {
        dispatch({
          type: REGISTR_MODAL_CONFIRM_OTP_FAIL,
          payload: error.response.data.error,
        });
        ReactGA.event({
          category: 'Registration',
          action: 'Подтвердить ОТП ( проверочный смс-код ) & завершить регистрацию - неуспешно'
        })
        global.debug && console.log(error.response);
      });
  };
};
