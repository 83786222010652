import './ProfilePortfolio.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  profile_portfolio_count,
  profile_portfolio_delete,
  profile_portfolio_load,
  profile_portfolio_save,
  profile_portfolio_start,
} from '../../../../actions';
import { PROFILE_PORTFOLIO_RESET } from '../../../../actions/types';
import { acceptImageTypes } from '../../../../const';
import { useWindowDimensions } from '../../../../functions';
import Button from '../../../shared/Button/Button';
import PortfolioImage from '../../../shared/PortfolioImage/PortfolioImage';
import Spinner from '../../../shared/Spinner/Spinner';

/**
 * profile portfolio component
 */
function ProfilePortfolio() {
  /** @type {import('../../../../typedef').TUserDescription} */
  const { user, user_id } = useSelector((state) => state.profileReducer);
  /** @type {import('../../../../typedef').TProfilePortfolioReducer} */
  const { images, images_count, start, count, image_deleted, is_loading } = useSelector((state) => state.profilePortfolioReducer);
  const [linkToFullPortfolio, setLinkToFullPortfolio] = useState('');
  /** @type {React.MutableRefObject<React.LegacyRef<HTMLInputElement>>} */
  const ref = useRef(null);
  const [limit, setLimit] = useState(3);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useHistory();

  // update number of images shown on a page
  useEffect(() => setLimit(width <= 500 ? 4 : 3), [width]);

  // reset image loading parameters
  useEffect(() => {
    dispatch(profile_portfolio_start(0));
    dispatch(profile_portfolio_count(limit));

    return () => dispatch({ type: PROFILE_PORTFOLIO_RESET });
  }, [limit, dispatch]);

  // load user's portfolio
  useEffect(() => {
    if (user_id && ((start === 0 && count === limit) || image_deleted)) {
      dispatch(profile_portfolio_load({ user_id, start, count, limit }));
    }
  }, [user_id, start, count, image_deleted, limit, dispatch]);

  // generate link to full portfolio
  useEffect(() => setLinkToFullPortfolio(`/profile/${user_id}/portfolio`), [user_id]);

  /**
   * upload an image to backend
   * @param {File} file file to upload
   */
  const uploadCallback = (file) => {
    dispatch(profile_portfolio_save({ user_id, file, images, limit }));
  };

  /**
   * delete image
   * @param {number} portfolio_image_id image id
   */
  const deleteCallback = (portfolio_image_id) => {
    dispatch(profile_portfolio_delete({ user_id, portfolio_image_id }));
  };

  /**
   * generate label with amount of photos
   * @param {number} count number of photos
   */
  const imagesCount = (count) => {
    const amount = count.toString().slice(-1);
    const preAmount = count.toString().slice(-2);
    let ending = 'й';
    if (amount === '1') {
      ending = 'я';
    }
    if (amount === '2' || amount === '3' || amount === '4') {
      ending = 'и';
    }
    if (preAmount === '11' && preAmount === '12' && preAmount === '13' && preAmount === '14') {
      ending = 'й';
    }
    return `${count} фотографи${ending}`;
  };

  /**
   * open full portfolio
   * @param {import('../../../../typedef').TPortfolioImage} [image] image data
   */
  const clickCallback = (image) => {
    history.push(`${linkToFullPortfolio}${image && user.public ? `/${image.id}` : ''}`);
  };

  /**
   * add photo if the portfolio is empty
   */
  const addFirstPhoto = () => {
    if (ref && ref.current) {
      ref.current.click();
    }
  };

  /**
   * input handler
   * @param {React.ChangeEvent<HTMLInputElement>} event event object
   */
  const handleFileChange = (event) => {
    const { files } = event.currentTarget;
    if (files && files.length) {
      const file = files.item(0);
      if (file) {
        uploadCallback(file);
      }
    }
  };

  return (
    <div className='profilePortfolio'>
      {is_loading && <Spinner />}
      {!images_count && (
        <>
          <input type='file' ref={ref} onChange={handleFileChange} accept={acceptImageTypes} hidden />
          <div className='emptyPortfolio'>
            <div className='icon'></div>
            <div className='data'>
              <div className='caption'>Загрузите фото работ</div>
              <div className='value'>Фотографии показывают заказчику ваш опыт и помогают принять решение в вашу пользу.</div>
              <Button
                color='white'
                size='normal'
                text='Загрузить фотографию'
                width='236rem'
                onClick={() => addFirstPhoto()}
                extraClassName='addFirstPhoto'
              />
            </div>
          </div>
        </>
      )}
      {!!images_count && (
        <>
          <div className='caption'>
            <div className='title'>Выполненные работы</div>
            <div className='count'>{imagesCount(images_count)}</div>
            {!user.public && <div className='edit' onClick={() => clickCallback()}></div>}
          </div>
          <div className='gallery'>
            {images.map((image, index) => {
              /** @type {import('../../../../typedef').TPortfolioImage} */
              const settings = { ...image, canDelete: !user.public, uploadCallback, deleteCallback, clickCallback };
              return <PortfolioImage key={index} {...settings} />;
            })}
          </div>
        </>
      )}
      {user.public && (
        <Button
          color='white'
          size='normal'
          text='Смотреть все фотографии'
          width='268rem'
          extraClassName='showAll'
          onClick={() => clickCallback()}
        />
      )}
    </div>
  );
}

export default ProfilePortfolio;
