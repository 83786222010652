import './OrderPage.scss';

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { order_user_inflicted_status_change, order_select_executor, order_select_executor_confirm } from '../../actions';

import PageTitle from '../../components/shared/PageTitle/PageTitle';
import OrderInfo from '../../components/pageSpecific/OrderPage/OrderInfo/OrderInfo';
import OrderResponses from '../../components/pageSpecific/OrderPage/OrderResponses/OrderResponses';
import Button from '../../components/shared/Button/Button';

function OrderPage_WithResponses_Customer({ order_id, order }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const order_selected_executor = useSelector((state) => state.orderReducer.order_selected_executor);

  return (
    <section className='order'>
      <div className='container'>
        <PageTitle
          text='Подтвердить исполнителя'
          smallText='После назначения исполнителя ваш заказ уходит в работу, и вы больше не сможете редактировать его. Проверьте все детали.'
        />

        <OrderInfo order={order} />

        <OrderResponses id={order.id} responses={order.applications} order_selected_executor={order_selected_executor} />

        <div className='buttons confirmingExecutor'>
          <Button
            type='button'
            onClick={() => dispatch(order_select_executor_confirm(order_id, order_selected_executor, history))}
            text='Подтвердить'
            size='large'
            color='dark'
            width='360rem'
            extraClassName='cancelButton'
          />

          <Button
            type='button'
            onClick={() => {
              dispatch(order_user_inflicted_status_change(undefined));
              dispatch(order_select_executor(null));
            }}
            text='Отмена'
            size='large'
            color='white'
            width='360rem'
            extraClassName='cancelButton'
          />
        </div>
      </div>
    </section>
  );
}

export default OrderPage_WithResponses_Customer;
