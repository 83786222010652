import axios from 'axios';
import ReactGA from 'react-ga';

import {
  EXECUTOR_CATEGORIES_MODAL_DISPLAY_TOGGLE,
  EXECUTOR_CATEGORIES_MODAL_SAVE,
  EXECUTOR_CATEGORIES_MODAL_SAVE_ERROR,
  EXECUTOR_CATEGORIES_MODAL_SAVE_SUCCESS,
} from './types';

import { profile_load, user_load } from '../actions';

/**
 * save user profile
 * @param {number} id user id
 * @param {{subcategories: number[]}} data user selection
 */
export const executor_categories_modal_save = (id, data) => {
  return (dispatch) => {
    dispatch({ type: EXECUTOR_CATEGORIES_MODAL_SAVE });

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${id}/categories/`,
      data,
    })
      .then((response) => {
        dispatch({ type: EXECUTOR_CATEGORIES_MODAL_SAVE_SUCCESS });
        dispatch(profile_load(id));
        dispatch(user_load(id));
        ReactGA.event({
          category: 'BecomeExecutor',
          action: 'Добавление / изменение категори - успешно:'
        });
        global.debug &&console.log('Добавление / изменение категори - Success:')
        global.debug &&console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: EXECUTOR_CATEGORIES_MODAL_SAVE_ERROR });
        ReactGA.event({
          category: 'BecomeExecutor',
          action: 'Добавление / изменение категори - неуспешно:'
        });
        global.debug &&console.log('Добавление / изменение категорий - Fail:')
        global.debug &&console.log(error.response);
      });
  };
};

/**
 * @param {boolean} payload
 */
export const executor_categories_modal_display_toggle = (payload) => ({
  type: EXECUTOR_CATEGORIES_MODAL_DISPLAY_TOGGLE,
  payload,
});
