import React from 'react';
import { Link } from 'react-router-dom';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function HowToGetMoreOrdersPage() {
  return (
    <main className='howToGetMoreOrdersPage'>
      <DocumentContent>
        <h1>Как заполнить профиль, чтобы получать больше заказов</h1>
        <p>
          Профиль — это первое, что видят заказчики, просматривая отклик исполнителя. Очень важно правильно преподнести информацию о себе и
          своем опыте, чтобы больше людей доверили вам выполнение заказов.
        </p>
        <p>При заполнении информации о себе обязательно выберете категории, в которых вы хотите работать.</p>
        <p>
          В поле «О себе» расскажите о своем образовании и опыте работы, опишите преимущества работы с вами. Лучше избегать абстрактных
          формулировок типа: «имею богатый опыт работы». Вместо этого напишите, сколько лет вы работаете в данной области, и приведите
          примеры успешных заказов особой сложности или больших объемов.
        </p>
        <p>
          У вас есть фото результатов вашей работы? Если так, обязательно загрузите их в галерею своего профиля. Результаты работы — один из
          самых наглядных способов показать потенциальному заказчику, что вы умеете. Занимаетесь ремонтом или уборкой — продемонстрируйте с
          помощью фото «до» и «после», как преображаются помещения после проделанной работы. Если вы — курьер, выкладывайте фотографии, на
          которых вы доставляете груз или посылку. А для мастера маникюра фото работ являются вообще главным козырем. Рекомендуем добавлять
          не менее 10 фотографий, чтобы сделать ваш профиль наиболее привлекательным.
        </p>
        <p>
          После заполнения данных каждый профиль исполнителя отправляется на проверку модераторами. Наши сотрудники должны сверить данные на
          фотографии вашего удостоверения личности с информацией, которую вы указали при заполнении профиля. Мы сверяем данные исключительно
          в целях безопасности пользователей сервиса. Подробнее о безопасности можете почитать <Link to='/faq/why_we_are_safe/'>здесь</Link>
          .
        </p>
        <p>
          Чтобы всегда быть в курсе новых заказов в выбранных вами категориях, настройте уведомления на e-mail и на «шторку» вашего
          смартфона. Это поможет мгновенно узнавать о новых заказах и оперативно откликаться на самые выгодные из них. Также уведомления
          помогут вам быть в курсе событий с заказами, на которые вы уже откликнулись.{' '}
        </p>
        <p>
          Чтобы настроить уведомления, зайдите в ваш профиль → Настройки. Поставьте галочки напротив видов уведомлений, которые вы хотите
          получать.
        </p>
        <p>Также вы можете подписаться на уведомления в Telegram. Наш бот будет присылать вам сообщение при каждом изменении в заказе. </p>
      </DocumentContent>
    </main>
  );
}

export default HowToGetMoreOrdersPage;
