import './AddPaymentMetodModal.scss';
import safe_deal_illustration from '../../../assets/images/sideBlockBanner.svg';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { add_payment_method_modal_display_toggle, add_payment_method_modal_get_form_data } from '../../../actions';
import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';

function AddPaymentMetodModal() {
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.userReducer.user_id);
  const add_payment_method_modal_form_data = useSelector((state) => state.addPaymentMethodModalReducer.add_payment_method_modal_form_data);

  useEffect(() => {
    dispatch(add_payment_method_modal_get_form_data(user_id, `${global.siteURL}/profile/${user_id}/finances`));
  }, [dispatch, user_id]);

  return (
    <Modal extraClassName='addPaymentMetodModal' onClose={() => dispatch(add_payment_method_modal_display_toggle(false))}>
      <div className='title'>Данные банковской карты</div>
      <div className='description'>
        Добавьте данные своей банковской карты, чтобы совершать <span className='red'>Сделки без риска</span>
      </div>
      <img src={safe_deal_illustration} className='illustration' alt='safe_deal_illustration' />
      {add_payment_method_modal_form_data && (
        <>
          <form action={add_payment_method_modal_form_data.action} method='post' className='add_payment_method_modal_hidden_form'>
            <input type='hidden' name='PlatformId' value={add_payment_method_modal_form_data.PlatformId} id='id_PlatformId' />
            <input type='hidden' name='Signature' value={add_payment_method_modal_form_data.Signature} id='id_Signature' />
            <input type='hidden' name='Timestamp' value={add_payment_method_modal_form_data.Timestamp} id='id_Timestamp' />
            <input
              type='hidden'
              name='PlatformBeneficiaryId'
              value={add_payment_method_modal_form_data.PlatformBeneficiaryId}
              id='id_PlatformBeneficiaryId'
            />
            <input type='hidden' name='PhoneNumber' value={add_payment_method_modal_form_data.PhoneNumber} id='id_PhoneNumber' />
            <input type='hidden' name='Title' value={add_payment_method_modal_form_data.Title} id='id_Title' />
            <input type='hidden' name='ReturnUrl' value={add_payment_method_modal_form_data.ReturnUrl} id='id_ReturnUrl' />
            <input type='hidden' name='Language' value={add_payment_method_modal_form_data.Language} id='id_Language' />
            <input
              type='hidden'
              name='RedirectToPaymentToolAddition'
              value={add_payment_method_modal_form_data.RedirectToPaymentToolAddition}
              id='id_RedirectToPaymentToolAddition'
            />
            <Button type='button' color='red' size='normal' text='Добавить' width='245rem' />
          </form>
        </>
      )}
    </Modal>
  );
}

export default AddPaymentMetodModal;
