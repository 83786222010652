import axios from 'axios';

import {
  PROFILE_PORTFOLIO_COUNT_CHANGE,
  PROFILE_PORTFOLIO_DELETE,
  PROFILE_PORTFOLIO_DELETE_ERROR,
  PROFILE_PORTFOLIO_DELETE_SUCCESS,
  PROFILE_PORTFOLIO_LOAD,
  PROFILE_PORTFOLIO_LOAD_ERROR,
  PROFILE_PORTFOLIO_LOAD_SUCCESS,
  PROFILE_PORTFOLIO_SAVE,
  PROFILE_PORTFOLIO_SAVE_ERROR,
  PROFILE_PORTFOLIO_SAVE_SUCCESS,
  PROFILE_PORTFOLIO_START_CHANGE,
} from './types';

const placeholder = { id: null, url: null, placeholder: true };

/**
 * add placeholder to list of images
 * @param {import('../typedef').TProfilePortfolioImage[]} images list of images
 * @param {number} limit number of images after which placeholder should be appended
 * @param {boolean} [prepend] add placeholder at the beginning of array of images
 */
const addPlaceholder = (images, limit, prepend) => {
  const control = images.find((image) => image.placeholder);
  if (prepend) {
    if (!control) {
      images.unshift({ ...placeholder });
    }
  } else {
    // append container for image upload if array contains less than 3 entries
    if (images.length < limit && !control) {
      images.push({ ...placeholder });
    }
  }
  return images;
};

/**
 * load images for user's portfolio
 * @param {Object} props props of operation
 * @param {string} props.user_id user id
 * @param {import('../typedef').TProfilePortfolioImage[]} [props.loaded_images] list of already loaded images
 * @param {number} [props.start] starting position
 * @param {number} [props.count] number of images to load
 * @param {boolean} [props.loadAll] load all images
 * @param {boolean} [props.prepend] add placeholder at the beginning of array of images
 * @param {boolean} [props.overwrite] overwrite image list with data from backend
 * @param {number} [props.limit] number of images after which placeholder should be appended
 */
export const profile_portfolio_load = (props) => {
  const { user_id, loaded_images, start, count, loadAll, prepend, overwrite, limit } = props;

  return (dispatch) => {
    dispatch({ type: PROFILE_PORTFOLIO_LOAD });

    axios({
      method: 'get',
      url: `${global.backendURL}users/user/${user_id}/portfolio-images/`,
      params: loadAll ? {} : { start, count, images_list: true },
    })
      .then((response) => {
        const { images, images_count } = response.data;
        const update = !overwrite && loaded_images instanceof Array ? [...loaded_images].concat(images) : images;
        dispatch({ type: PROFILE_PORTFOLIO_LOAD_SUCCESS, payload: { images: addPlaceholder(update, limit, prepend), images_count } });
      })
      .catch((error) => dispatch({ type: PROFILE_PORTFOLIO_LOAD_ERROR, payload: error.response }));
  };
};

/**
 * save image to user's portfolio
 * @param {Object} props props of operation
 * @param {string} props.user_id user id
 * @param {File} props.file image to upload
 * @param {import('../typedef').TProfilePortfolioImage[]} props.images list of loaded images
 * @param {boolean} [props.prepend] add placeholder at the beginning of array of images
 * @param {number} [props.limit] number of images after which placeholder should be appended
 */
export const profile_portfolio_save = (props) => {
  const { user_id, file, images, prepend, limit } = props;

  return (dispatch) => {
    dispatch({ type: PROFILE_PORTFOLIO_SAVE });

    const data = new FormData();

    data.append('portfolio_image', file);
    data.append('filename', file.name);

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${user_id}/portfolio-images/`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        const { filename, id, images_count, url } = response.data;
        const index = images.findIndex((item) => item.placeholder);
        const payload = { images, images_count };
        if (index !== -1) {
          /** @type {import('../typedef').TProfilePortfolioImage[]} */
          let update = [...images];
          update[index] = { filename, id, url };
          payload.images = addPlaceholder([...update], limit, prepend);
        }
        dispatch({ type: PROFILE_PORTFOLIO_SAVE_SUCCESS, payload });
      })
      .catch((error) => dispatch({ type: PROFILE_PORTFOLIO_SAVE_ERROR, payload: error.response }));
  };
};

/**
 * delete photo from portfolio
 * @param {Object} props props of operation
 * @param {string} props.user_id user id
 * @param {number} props.portfolio_image_id image id
 */
export const profile_portfolio_delete = (props) => {
  const { user_id, portfolio_image_id } = props;

  return (dispatch) => {
    dispatch({ type: PROFILE_PORTFOLIO_DELETE });

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${user_id}/portfolio-images/`,
      data: { portfolio_image_id },
    })
      .then(() => dispatch({ type: PROFILE_PORTFOLIO_DELETE_SUCCESS }))
      .catch((error) => dispatch({ type: PROFILE_PORTFOLIO_DELETE_ERROR, payload: error.response }));
  };
};

/**
 * change start position of loading
 * @param {number} payload starting position
 */
export const profile_portfolio_start = (payload) => ({
  type: PROFILE_PORTFOLIO_START_CHANGE,
  payload,
});

/**
 * change number of images to load
 * @param {number} payload number of images to load
 */
export const profile_portfolio_count = (payload) => ({
  type: PROFILE_PORTFOLIO_COUNT_CHANGE,
  payload,
});
