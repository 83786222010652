import { ORDER_INFO_GALLERY_DISPLAY_TOGGLE } from '../actions/types';

/** @type {import("../typedef").TOrderInfoReducer} */
const INITIAL_STATE = {
  order_info_gallery_display: false,
};

/** @returns {import("../typedef").TOrderInfoReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORDER_INFO_GALLERY_DISPLAY_TOGGLE:
      return { ...state, order_info_gallery_display: action.payload };
    default:
      return { ...state };
  }
};
