import axios from 'axios';
import ReactGA from 'react-ga';

import {
  LEGAL_ENTITY_CONFIRM_BIN_VALUE_CHANGE,
  LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE,
  LEGAL_ENTITY_CONFIRM_MODAL_DISPLAY_TOGGLE,
  LEGAL_ENTITY_CONFIRM_SAVE,
  LEGAL_ENTITY_CONFIRM_SAVE_ERROR,
  LEGAL_ENTITY_CONFIRM_SAVE_SUCCESS
} from './types';
import { alert_modal_settings, alert_modal_display } from "../actions";

/**
 * toggle confirm modal display status
 * @param {boolean} payload display status
 */
export const legal_entity_confirm_modal_display_toggle = (payload) => ({
  type: LEGAL_ENTITY_CONFIRM_MODAL_DISPLAY_TOGGLE,
  payload,
});

/**
 * legal entity bin value
 * @param {string} payload bin value
 */
export const legal_entity_confirm_bin_value_change = (payload) => ({
  type: LEGAL_ENTITY_CONFIRM_BIN_VALUE_CHANGE,
  payload,
});

/**
 * confirmation image value
 * @param {File} payload confirmation image
 */
export const legal_entity_confirm_image_value_change = (payload) => ({
  type: LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE,
  payload,
});

/**
 * save legal entity confirm modal data
 * @param {Object} payload action payload
 * @param {string} payload.user_id user id
 * @param {string} payload.bin user bin
 * @param {File} payload.image confirmation image
 */
export const legal_entity_confirm_save = (payload, history) => {
  const { user_id, bin, image } = payload;

  return (dispatch) => {
    dispatch({ type: LEGAL_ENTITY_CONFIRM_SAVE });

    const data = new FormData();

    data.append('bin', bin);
    image && data.append('image', image);

    axios({
      method: 'post',
      url: `${global.backendURL}users/user/${user_id}/lawdocument/`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        dispatch({ type: LEGAL_ENTITY_CONFIRM_SAVE_SUCCESS });
        ReactGA.event({
          category: 'BecomeLegal',
          action: 'Отправка документов для верификации - успешно'
        })
        global.debug && console.log('Отправка данных юрлица - успешно:');
        global.debug && console.log(response.data);
      })
      .catch((error) => {
        dispatch({ type: LEGAL_ENTITY_CONFIRM_SAVE_ERROR, payload: error.response });
        if (error?.response?.status === 413 || error?.response?.data?.image[0] === 'Размер файла должен быть не более 5 Мб.') {
          dispatch(
            alert_modal_settings({
              title: `Ошибка загрузки`,
              content: `Размер загруженного файла превышает максимально допустимый - 5мб.`,
              extraClassName: 'alertModalBody',
              buttons: [
                {
                  color: 'red',
                  size: 'large',
                  text: 'Понятно',
                  type: 'button',
                  width: '250rem',
                  onClick: () => dispatch(alert_modal_display(false)),
                }
              ],
            })
          );
          dispatch(alert_modal_display(true));
          dispatch({ type: LEGAL_ENTITY_CONFIRM_IMAGE_VALUE_CHANGE, payload: null });
        }
        ReactGA.event({
          category: 'BecomeLegal',
          action: 'Отправка документов для верификации - неуспешно'
        })
        global.debug && console.log('Отправка данных юрлица - неуспешно:');
        global.debug && console.log(error.response);
      });
  };
};

