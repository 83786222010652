import './Modal.scss';

import React from 'react';

import closeIcon from '../../../assets/images/closeIcon.svg';
import Spinner from '../Spinner/Spinner';

/**
 * modal component
 * @param {Object} props
 * @param {ReactNode} [props.children] modal data
 * @param {(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void} props.onClose onClose handler
 * @param {string} [props.extraClassName] additional class name
 * @param {boolean} [props.isWaiting] spinner display status
 */

function Modal(props) {
  const { children, onClose, extraClassName, isWaiting } = props;

  return (
    <div className='modalWrapper'>
      <div className={`modal ${extraClassName || ''}`}>
        <img onClick={onClose} src={closeIcon} className='closeButton' alt='closeIcon' />
        {isWaiting && <Spinner />}
        {children}
      </div>
    </div>
  );
}

export default Modal;
