import './OrderPage.scss';

import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { order_load } from '../../actions';

import OrderPage_Created_Customer from './OrderPage_s0_Created_Customer';
import OrderPage_WithResponses_Customer from './OrderPage_s1_1_WithResponses_Customer';
import OrderPage_ConfirmingExecutor_Customer from './OrderPage_s1_2_ConfirmingExecutor_Customer';
import OrderPage_InWork_Customer from './OrderPage_s2_InWork_Customer';
import OrderPage_Review_Customer from './OrderPage_s3_Review_Customer';
import OrderPage_Canceled_Customer from './OrderPage_s5_Canceled_Customer';
import OrderPage_NotInWork_Executor from './OrderPage_NotInWork_Executor';
import OrderPage_InWork_Executor from './OrderPage_InWork_Executor';
import OrderPage_Review_Executor from './OrderPage_Review_Executor';
import NeetToLogin from '../../components/shared/NeedToLogIn/NeedToLogin';

function OrderPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user_id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const user_is_executor = useSelector((state) => state.userReducer.user.is_executor);
  const order = useSelector((state) => state.orderReducer.order);
  const order_user_inflicted_status = useSelector((state) => state.orderReducer.order_user_inflicted_status);
  // * Получаем данные заказа на основе id в урле
  const { order_id } = useParams();

  useEffect(() => {
    dispatch(order_load(order_id, history));
  }, [order_id, dispatch]);

  useEffect(() => {
    if (order_user_inflicted_status === 'NeedToReload') {
      dispatch(order_load(order_id, history));
    }
    window.scrollTo(0, 0);
  }, [order_user_inflicted_status]);

  return (
    <>
      {user_id ? (
        <main className='orderPage'>
          {order && (
            <>
              {/* Нет исполнителей, просматривает заказчик */}
              {order.task_status === '0' && user_id.toString() === order.customer_id.toString() && (
                <OrderPage_Created_Customer order_id={order_id} order={order} />
              )}
              {/* Есть исполнители, просматривает заказчик */}
              {order.task_status === '1' && user_id.toString() === order.customer_id.toString() && !order_user_inflicted_status && (
                <OrderPage_WithResponses_Customer order_id={order_id} order={order} />
              )}
              {/* Есть исполнители, просматривает заказчик и финально подтверждает исполнителя */}
              {order.task_status === '1' &&
                user_id.toString() === order.customer_id.toString() &&
                order_user_inflicted_status === 'confirmingExecutor' && (
                  <OrderPage_ConfirmingExecutor_Customer order_id={order_id} order={order} />
                )}
              {/* Заказ в работе и просматривает заказчик */}
              {order.task_status === '2' && user_id.toString() === order.customer_id.toString() && !order_user_inflicted_status && (
                <OrderPage_InWork_Customer order_id={order_id} order={order} />
              )}
              {/* Заказ завершён и просматривает заказчик */}
              {order.task_status === '3' && user_id.toString() === order.customer_id.toString() && !order_user_inflicted_status && (
                <OrderPage_Review_Customer order_id={order_id} order={order} />
              )}
              {/* Заказ отменён и просматривает заказчик */}
              {order.task_status === '5' && user_id.toString() === order.customer_id.toString() && !order_user_inflicted_status && (
                <OrderPage_Canceled_Customer order_id={order_id} order={order} />
              )}

              {/* Исполнитель ещё не выбран и просматривает исполнитель  */}
              {(order.task_status === '0' || order.task_status === '1') &&
                user_id.toString() !== order.customer_id.toString() &&
                user_is_executor && <OrderPage_NotInWork_Executor order_id={order_id} order={order} />}
              {/* Исполнитель выбран и просматривает выбранный исполнитель  */}
              {order.task_status === '2' && user_is_executor && user_id.toString() === order.applications[0].executor.id.toString() && (
                <OrderPage_InWork_Executor order_id={order_id} order={order} />
              )}
              {/* Заказ завершён и просматривает выбранный исполнитель  */}
              {order.task_status === '3' && user_is_executor && user_id.toString() === order.applications[0].executor.id.toString() && (
                <OrderPage_Review_Executor order_id={order_id} order={order} />
              )}
              {/* Заказ отменён и просматривает исполнитель */}
              {order.task_status === '5' && user_id.toString() !== order.customer_id.toString() && user_is_executor && (
                <OrderPage_Canceled_Customer
                  order_id={order_id}
                  order={order}
                  is_executor={user_id.toString() !== order.customer_id.toString()}
                />
              )}
            </>
          )}
        </main>
      ) : (
        <NeetToLogin />
      )}
    </>
  );
}

export default OrderPage;
