import React from 'react';
import { Link } from 'react-router-dom';

import DocumentContent from '../../components/pageSpecific/DocumentsPages/DocumentContent/DocumentContent';

function HowToRegistPage() {
  return (
    <main className='howToRegistPage'>
      <DocumentContent>
        <h1>Как зарегистрироваться</h1>
        <p>
          В signedUP вы можете быть одновременно и заказчиком, и исполнителем. Регистрация в сервисе бесплатна, достаточно указать
          свой номер телефона и получить на него код доступа. Все, теперь можно приступать к созданию заказа.{' '}
        </p>
        <p>Чтобы зарегистрироваться в сервисе, нажмите кнопку «Регистрация»в правом верхнем углу страницы и следуйте подсказкам.</p>
        <p>Чтобы начать оказывать услуги и зарабатывать деньги, вам необходимо дополнительно стать исполнителем.</p>
        <p>
          <b>Что от вас потребуется как от исполнителя</b>
        </p>
        <p>
          Согласитесь, что работать с реальными людьми гораздо приятнее, чем с анонимными исполнителями, которых в случае вопросов
          невозможно разыскать. Исключительно в целях безопасности наших пользователей мы просим каждого исполнителя пройти процедуру
          верификации, то есть сообщить свои реальные имя и фамилию, данные удостоверения личности и отправить нам скан или фото документа.
        </p>
        <p>
          Фотография удостоверения личности нужна, чтобы проверить, что исполнитель указал достоверные данные. Мы трепетно относимся к
          приватности наших пользователей, поэтому обещаем использовать персональные данные только в целях и способами, обозначенными в
          нашей Политике конфиденциальности. Подробнее с документом вы можете ознакомиться{' '}
          <Link to='/documents/confidentiality_policy/'>здесь</Link>.
        </p>
        <p>После прохождения процедуры верификации у исполнителя появляется значок «Проверен», и он может начинать зарабатывать.</p>
      </DocumentContent>
    </main>
  );
}

export default HowToRegistPage;
