import './AlertPopup.scss';

import React from 'react';

/**
 * alert popup for errors/notifications/suggestions
 * - in order to work properly the parent __must__ be relative or absolute
 * @param {Object} props props of component
 * @param {React.ReactNode} props.children content
 * @param {string} [props.extraClassName] additional class name
 */
function AlertPopup(props) {
  const { children, extraClassName } = props;

  return <div className={`alertPopup ${extraClassName || ''}`}>{children}</div>;
}

export default AlertPopup;
