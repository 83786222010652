import AddEmailReducer from './AddEmailReducer';
import AddPaymentMethodModalReducer from './AddPaymentMethodModalReducer';
import AlertModalReducer from './AlertModalReducer';
import ChatModalReducer from './ChatModalReducer';
import CitySelectModalReducer from './CitySelectModalReducer';
import ExecutorCategoriesModalReducer from './ExecutorCategoriesModalReducer';
import GlobalVariablesReducer from './GlobalVariablesReducer';
import HeaderReducer from './HeaderReducer';
import { LOGOUT } from '../actions/types';
import LegalEntityConfirmModalReducer from './LegalEntityConfirmModalReducer';
import LegalEntityPrefaceModalReducer from './LegalEntityPrefaceModalReducer';
import LoaderReducer from './LoaderReducer';
import LoginReducer from './LoginReducer';
import NotificationsReducer from './NotificationsReducer.js';
import OrderCreationReducer from './OrderCreationReducer';
import OrderInfoReducer from './OrderInfoReducer';
import OrderReducer from './OrderReducer';
import OrderResponseModalReducer from './OrderResponseModalReducer';
import OrdersDashboardsReducer from './OrdersDashboardsReducer';
import PrefaceReducer from './PrefaceReducer';
import PrereleaseModalReducer from './PrereleaseModalReducer';
import ProfileCategoriesReducer from './ProfileCategoriesReducer';
import ProfileFeedbacksReducer from './ProfileFeedbacksReducer';
import ProfileHeaderReducer from './ProfileHeaderReducer';
import ProfileIdentityModalReducer from './ProfileIdentityModalReducer';
import ProfilePassportReducer from './ProfilePassportReducer';
import ProfilePortfolioReducer from './ProfilePortfolioReducer';
import ProfileReducer from './ProfileReducer';
import RegistrationReducer from './RegistrationReducer';
import UserReducer from './UserReducer';
import { combineReducers } from 'redux';

const appReducers = combineReducers({
  notificationsReducer: NotificationsReducer,
  addPaymentMethodModalReducer: AddPaymentMethodModalReducer,
  chatModalReducer: ChatModalReducer,
  addEmailReducer: AddEmailReducer,
  alertModalReducer: AlertModalReducer,
  executorCategoriesModalReducer: ExecutorCategoriesModalReducer,
  globalVariablesReducer: GlobalVariablesReducer,
  headerReducer: HeaderReducer,
  legalEntityConfirmModalReducer: LegalEntityConfirmModalReducer,
  legalEntityPrefaceModalReducer: LegalEntityPrefaceModalReducer,
  loaderReducer: LoaderReducer,
  loginReducer: LoginReducer,
  orderReducer: OrderReducer,
  orderCreationReducer: OrderCreationReducer,
  orderInfoReducer: OrderInfoReducer,
  orderResponseModalReducer: OrderResponseModalReducer,
  ordersDashboardsReducer: OrdersDashboardsReducer,
  prefaceReducer: PrefaceReducer,
  prereleaseModalReducer: PrereleaseModalReducer,
  profileCategoriesReducer: ProfileCategoriesReducer,
  profileFeedbacksReducer: ProfileFeedbacksReducer,
  profileHeaderReducer: ProfileHeaderReducer,
  profileIdentityModalReducer: ProfileIdentityModalReducer,
  profilePassportReducer: ProfilePassportReducer,
  profilePortfolioReducer: ProfilePortfolioReducer,
  profileReducer: ProfileReducer,
  registrationReducer: RegistrationReducer,
  userReducer: UserReducer,
  citySelectModalReducer: CitySelectModalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
