import './AlertModal.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alert_modal_display } from '../../../actions/AlertModalActions';

import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';

function AlertModal() {
  /** @type {string} */
  const title = useSelector((state) => state.alertModalReducer.title);
  /** @type {string} */
  const extraClassName = useSelector((state) => state.alertModalReducer.extraClassName);
  /** @type {React.ReactNode} */
  const content = useSelector((state) => state.alertModalReducer.content);
  /** @type {import('../../../typedef').TButton[]} */
  const buttons = useSelector((state) => state.alertModalReducer.buttons);
  const dispatch = useDispatch();

  return (
    <Modal extraClassName='alertModal' onClose={() => dispatch(alert_modal_display(false))}>
      <div className='title'>{title}</div>
      <div className={`body ${extraClassName || ''}`}>{content}</div>
      {buttons instanceof Array && (
        <div className='buttons' style={{ gridTemplateColumns: buttons.map(() => '1fr').join(' ') }}>
          {buttons.map((button, index) => (
            <Button key={index} {...button} />
          ))}
        </div>
      )}
    </Modal>
  );
}

export default AlertModal;
