import './ProfileHeader.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { header_mobile_menu_display_toggle, profile_header_menu_mode_toggle, user_photo } from '../../../../actions';
import { profile_photo_change, profile_save } from '../../../../actions/ProfileActions';
import { acceptImageTypes } from '../../../../const';
import AlertPopup from '../../../shared/AlertPopup/AlertPopup';
import Files from '../../../shared/Files/Files';
import ProfileHeaderRatings from './ProfileHeaderRatings';

/** @type {string[]} */
const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

/**
 * header profile
 * @param {Object} props props of component
 * @param {import('../../../../typedef').TUser} props.user user data
 * @param {string} props.user_id user id
 */
function ProfileHeader(props) {
  const { user, user_id } = props;
  /** @type {import('../../../../typedef').TTaskData} */
  const task_data = useSelector((state) => state.profileReducer.task_data);
  /** @type {import('../../../../typedef').TRatingsData} */
  const ratings_data = useSelector((state) => state.profileReducer.ratings_data);
  /** @type {import('../../../../typedef').TProfileHeaderReducer} */
  const { profile_header_lite_mode, profile_header_menu_mode } = useSelector((state) => state.profileHeaderReducer);
  /** @type {import('../../../../typedef').TUserDescription} */
  const { is_loading, image_error } = useSelector((state) => state.profileReducer);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState('');
  const [shortBio, setShortBio] = useState('');
  const [error, setError] = useState(false);
  /** @type {[File, React.Dispatch<File>]} */
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * convert file into blob
   * @param {FileList} files list of files
   */
  const updateAvatar = (files) => {
    if (files && files.length) {
      const file = files.item(0);
      if (file) {
        setAvatar(file);
        dispatch(profile_save(user_id, { ...user, photo: file }));
      }
    }
  };

  /**
   * open user profile
   */
  const profile = () => {
    if (profile_header_menu_mode) {
      dispatch(profile_header_menu_mode_toggle(false));
      dispatch(header_mobile_menu_display_toggle(false));
      history.push(`/profile/${user_id}`);
    }
  };

  /**
   * photo component wrapper
   */
  const photo = () => (
    <div className={`photo ${profile_header_menu_mode ? 'photoActive' : ''}`} onClick={() => profile()}>
      {typeof user.photo === 'string' ? (
        <div className='avatar' style={{ backgroundImage: `url('${user.photo}')` }}></div>
      ) : (
          <div className='default'></div>
        )}
    </div>
  );

  /**
   * update user name
   */
  useEffect(() => {
    if (user.public) {
      const data = [user.first_name, user.last_name].join(' ');
      setName(data || '');
    } else {
      setName(`Здравствуйте${user.first_name ? ',\n' : '!'}${user.first_name ? user.first_name + '!' : ''}`);
    }
  }, [user.public, user.first_name, user.last_name]);

  /**
   * update short bio
   */
  useEffect(() => {
    let since = '';

    if (user.date_joined) {
      const date = new Date(user.date_joined);

      if (date instanceof Date) {
        since = `на сервисе с ${months[date.getMonth()]} ${date.getFullYear()}`;
      }
    }

    setShortBio([user.city_title || '', since].filter((item) => !!item).join(', '));
  }, [user.city_title, user.date_joined]);

  /**
   * update avatar
   */
  useEffect(() => {
    if (avatar && !is_loading && !image_error) {
      if (url) {
        URL.revokeObjectURL(url);
      }
      const blob = new Blob([avatar.slice()], { type: avatar.type });
      const link = URL.createObjectURL(blob);
      setUrl(link);
      dispatch(profile_photo_change(link));
      dispatch(user_photo(link));
      setAvatar(false);
    }
  }, [avatar, url, is_loading, image_error, dispatch]);

  useEffect(() => {
    let timeout;

    if (image_error) {
      setError(true);
      timeout = setTimeout(() => setError(false), 1e4);
    }

    return () => clearTimeout(timeout);
  }, [image_error]);

  return (
    <div className='profileHeader'>
      {/* {is_loading && <Spinner />} */}
      <div className='photoBlock'>
        {user.public || profile_header_lite_mode || profile_header_menu_mode ? (
          photo()
        ) : (
            <>
              {error && <AlertPopup extraClassName='profileHeaderError'>{image_error}</AlertPopup>}
              <Files onUpdate={updateAvatar} accept={acceptImageTypes}>
                {photo()}
                <label className='changePhotoTitle'>
                  Изменить
                <br />
                фото
              </label>
              </Files>
              {!user.photo && (
                <div className='changePhotoHint'>
                  Пожалуйста, загрузите
                  <br />
                вашу реальную
                  <br />
                фотографию
                </div>
              )}
            </>
          )}
      </div>
      <div className='userBlock'>
        <div className={`userName ${profile_header_menu_mode ? 'userProfile' : ''}`} onClick={() => profile()}>
          {name.split('\n').map((entry, index) => (
            <div key={index}>{entry}</div>
          ))}
        </div>
        {user.public && shortBio && <div className='userShortBio'>{shortBio}</div>}
        {!profile_header_lite_mode && (
          <>
            {/* user is customer */}
            {user.is_customer && !user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Создано заказов', mark: task_data.customer.tasks_count },
                  { label: 'Рейтинг', icon: true, mark: ratings_data.customer_rate || 0 },
                ]}
              />
            )}
            {/* user is executor */}
            {!user.is_customer && user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: task_data.executor.tasks_count },
                  { label: 'Рейтинг', icon: true, mark: ratings_data.executor_rate || 0 },
                ]}
              />
            )}
            {/* user is customer & executor */}
            {user.is_customer && user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: task_data.executor.tasks_count, details: ratings_data.executor_rate || 0 },
                  { label: 'Создано заданий', mark: task_data.customer.tasks_count, details: ratings_data.customer_rate || 0 },
                ]}
              />
            )}
            {/* special case */}
            {!user.is_customer && !user.is_executor && (
              <ProfileHeaderRatings
                blocks={[
                  { label: 'Выполнено заданий', mark: 0, details: 0 },
                  { label: 'Создано заданий', mark: 0, details: 0 },
                ]}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileHeader;
