import './App.scss';

import { applyMiddleware, createStore } from 'redux';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import ReactGA from 'react-ga';
import ReduxThunk from 'redux-thunk';
import Router from './Router';
import { YMInitializer } from 'react-yandex-metrika';
import axios from 'axios';
import reducers from './reducers';
import { signedUpToken } from './const';

function App() {
  const token = localStorage.getItem(signedUpToken);
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // * Google analytics
  ReactGA.initialize('UA-169042750-1', {
    debug: false,
  });

  const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

  return (
    <Provider store={store}>
      {global.siteURL === 'https://signedup.me' && (
        <YMInitializer
          accounts={[65098972]}
          options={{ defer: true, clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
          version='2'
        />
      )}
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
