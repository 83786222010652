import { USER_LOAD, USER_LOAD_ERROR, USER_LOAD_SUCCESS, USER_PHOTO_VALUE_CHANGE, USER_NEED_TO_BE_UPDATED } from '../actions/types';

/** @type {import('../typedef').TUserDescription} */
const INITIAL_STATE = {
  ratings_data: {
    customer_adequacy: null,
    customer_decency: null,
    customer_punctuality: null,
    customer_rate: null,
    executor_adequacy: null,
    executor_decency: null,
    executor_punctuality: null,
    executor_rate: null,
  },
  task_data: {
    customer: {
      tasks_count: 0,
    },
    executor: {
      tasks_count: 0,
    },
  },
  user: {
    first_name: '',
    last_name: '',
    surname: '',
    gender: '',
    birthday: null,
    photo: null,
    about: '',
    phone: null,
    phone_verified: false,
    email: '',
    email_verified: false,
    country_id: null,
    country_title: null,
    city_id: null,
    city_title: null,
    is_customer: false,
    is_executor: false,
    is_juridical: false,
    subscribed_news: false,
    subscribed_notifications: false,
    verification_status: 'not_verified',
    date_joined: '',
    public: false,
    feedbacks_count: 0,
    images_count: 0,
  },
  user_id: undefined,
  feedbacks_count: 0,
  is_empty: true,
  is_loading: true,
  need_to_be_updated: false,
};

/** @returns {import('../typedef').TUserDescription} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_LOAD:
      return { ...state, is_loading: true };
    case USER_LOAD_SUCCESS:
      return { ...action.payload, is_loading: false, need_to_be_updated: false };
    case USER_LOAD_ERROR:
      return { ...state, is_loading: false, need_to_be_updated: false };
    case USER_PHOTO_VALUE_CHANGE:
      return { ...state, user: { ...state.user, photo: action.payload } };
    case USER_NEED_TO_BE_UPDATED:
      return { ...state, need_to_be_updated: true, is_loading: true };
    default:
      return { ...state };
  }
};
