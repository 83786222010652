import './ProfileBio.scss';

import React, { useEffect, useState } from 'react';
import {
  profile_about_value_change,
  profile_birthday_value_change,
  profile_city_value_change,
  profile_first_name_value_change,
  profile_gender_value_change,
  profile_last_name_value_change,
  profile_middle_name_value_change,
  profile_save,
} from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../shared/Button/Button';
import DatepickerDate from '../../../shared/DatepickerDate/DatepickerDate';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import axios from 'axios';
import { format } from 'date-fns';
import { maskName } from '../../../../const';
import ru from 'date-fns/locale/ru';

/**
 * user profile bio component
 */
function ProfileBio() {
  /** @type {import('../../../../typedef').TUser} */
  const user = useSelector((state) => state.profileReducer.user);
  const id = useSelector((state) => state.globalVariablesReducer.user_id_value);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editMode, setEditMode] = useState(!(user.first_name && user.last_name && user.birthday && typeof user.gender === 'string'));

  const genders = [
    { id: 0, title: 'Мужской' },
    { id: 1, title: 'Женский' },
  ];
  const dispatch = useDispatch();

  const [cities, setCities] = useState([]);
  const selectCity = (id) => {
    const city = cities.find((item) => item.city_id === id);
    if (city) {
      dispatch(profile_city_value_change(city));
    }
  };
  useEffect(() => {
    axios({
      method: 'get',
      url: `${global.backendURL}cities/`,
    })
      .then((result) => setCities(result.data.cities))
      .catch((error) => global.debug && console.log(error));
  }, []);

  // update save button disabled state & check if profile is full
  useEffect(() => {
    setSaveDisabled(
      !user.first_name || !user.last_name || !user.birthday || !user.city_id || !user.about || typeof user.gender !== 'string'
    );
  }, [user]);

  // get gender's string representation
  const gender = () => {
    const data = genders.find((item) => item.id === parseInt(user.gender));
    return data ? data.title : '';
  };

  return (
    <div className='profileBio'>
      {editMode ? (
        <>
          <div className='userDataEdit'>
            <div className='userDataBio'>
              <Input
                label='Фамилия'
                type='text'
                value={user.last_name}
                onChange={(event) => dispatch(profile_last_name_value_change(event.target.value))}
                mask={maskName}
                guide={false}
                readonly={user.verification_status === 'verified'}
              />
              <Input
                label='Имя'
                type='text'
                value={user.first_name}
                onChange={(event) => dispatch(profile_first_name_value_change(event.target.value))}
                mask={maskName}
                guide={false}
                readonly={user.verification_status === 'verified'}
              />
              <Input
                label='Отчество'
                type='text'
                value={user.surname}
                onChange={(event) => dispatch(profile_middle_name_value_change(event.target.value))}
                mask={maskName}
                guide={false}
              />
            </div>
            <div className='userDataExtra'>
              <div className='datepickerContainer'>
                <DatepickerDate date={user.birthday} action={(date) => dispatch(profile_birthday_value_change(date))} />
              </div>
              <Select
                name='gender'
                label='Ваш пол'
                selection={parseInt(user.gender)}
                options={genders}
                onClick={(event) => dispatch(profile_gender_value_change(event.target.id))}
              />
            </div>
            <div className='userDataCity'>
              <Select
                name='city'
                label='Город'
                selection={user.city_id}
                options={cities}
                onClick={(event) => selectCity(parseInt(event.target.id))}
              />
            </div>
            <Input
              type='textarea'
              name='about'
              label={`Рассказать о себе (${user.about.length} из 500)`}
              value={user.about}
              onChange={(event) => dispatch(profile_about_value_change(event.target.value))}
              maxLength={500}
            />
          </div>
          <Button
            text='Сохранить'
            type='button'
            size='normal'
            color='red'
            extraClassName='saveProfile'
            disabled={saveDisabled}
            onClick={() => {
              dispatch(profile_save(id, { ...user }));
              setEditMode(false);
            }}
          />
        </>
      ) : (
        <div className='userDataView'>
          <div className='bio'>
            <div className='bioName'>
              <div className='nameLabel'>Фамилия, Имя, Отчество</div>
              <div className='nameValue'>{[user.last_name, user.first_name, user.surname].filter((item) => !!item).join(' ')}</div>
            </div>
            <div className='bioEdit' onClick={() => setEditMode(true)}></div>
          </div>
          <div className='extra'>
            <div className='extraData'>
              <div className='dataLabel'>Дата рождения</div>
              <div className='dataValue'>{format(user.birthday, 'd MMMM yyyy', { locale: ru })}</div>
            </div>
            <div className='extraData'>
              <div className='dataLabel'>Пол</div>
              <div className='dataValue'>{gender()}</div>
            </div>
            <div className='extraData'>
              <div className='dataLabel'>Город</div>
              <div className='dataValue'>{user.city_title}</div>
            </div>
          </div>
          {user.about && (
            <div className='about'>
              <div className='aboutLabel'>О себе</div>
              <div className='aboutValue'>{user.about}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileBio;
