export const signedUpPhone = 'signedUpPhone';
export const signedUpToken = 'signedUpToken';
export const signedUpUserId = 'signedUpUserId';

/** path to not found page */
export const pathNotFoundPage = '/404';
/** path to error page */
export const pathErrorPage = '/500';
/** path to service page */
export const pathServicePage = '/service';

/** default list of image file types to accept */
export const acceptImageTypes = '.jpg,.png,.jpeg,';

/** default mask for inputs like name */
export const maskName = Array.from({ length: 40 }, () => /[а-я-\s]/i);
/** mask for phone input */
export const maskPhone = ['+', ' ', '7', ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
/** placeholder for phone input */
export const placeholderPhone = '+ 7 ';
/** mask for sms input */
export const maskSms = [/\d/, /\d/, /\d/, /\d/];
/** placeholder for sms input */
export const placeholderSms = '';
/** mask for address input */
export const maskAddress = Array.from({ length: 200 }, () => /[а-я-\s.,\d"']/i);
/** mask for price */
export const maskPrice = ['₸', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
/** placeholder for price */
export const placeholderPrice = '₸ ';

/** empty placeholder character for masked input */
export const maskPlaceholderCharacter = '\u2000';

/** extra large breakpoint */
export const sizeExtraLarge = 1280;
/** large breakpoint */
export const sizeLarge = 1024;
/** medium breakpoint */
export const sizeMedium = 768;
/** small breakpoint */
export const sizeSmall = 500;
