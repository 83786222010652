import {
  PROFILE_PASSPORT_ADDRESS_CHANGE,
  PROFILE_PASSPORT_BIRTHDAY_CHANGE,
  PROFILE_PASSPORT_CITY_CHANGE,
  PROFILE_PASSPORT_DISPLAY_STATUS_CHANGE,
  PROFILE_PASSPORT_FIRST_NAME_CHANGE,
  PROFILE_PASSPORT_GENDER_CHANGE,
  PROFILE_PASSPORT_IMAGE1_CHANGE,
  PROFILE_PASSPORT_IMAGE1_ERROR_CHANGE,
  PROFILE_PASSPORT_IMAGE2_CHANGE,
  PROFILE_PASSPORT_IMAGE2_ERROR_CHANGE,
  PROFILE_PASSPORT_IMAGE3_CHANGE,
  PROFILE_PASSPORT_IMAGE3_ERROR_CHANGE,
  PROFILE_PASSPORT_LAST_NAME_CHANGE,
  PROFILE_PASSPORT_SAVE,
  PROFILE_PASSPORT_SAVE_ERROR,
  PROFILE_PASSPORT_SAVE_SUCCESS,
  PROFILE_PASSPORT_SURNAME_CHANGE,
} from '../actions/types';

/** @type {import('../typedef').TProfilePassportReducer} */
const INITIAL_STATE = {
  profile_passport_display_status: false,
  first_name: '',
  last_name: '',
  surname: '',
  birthday: null,
  gender: null,
  city_id: null,
  city_title: null,
  address: '',
  passport_image1: null,
  passport_image1_error: '',
  passport_image2: null,
  passport_image2_error: '',
  passport_image3: null,
  passport_image3_error: '',
  next_step: false,
  single_mode: false,
  is_loading: false,
};

/** @returns {import('../typedef').TProfilePassportReducer} */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFILE_PASSPORT_SAVE:
      return { ...state, is_loading: true };
    case PROFILE_PASSPORT_SAVE_SUCCESS:
      return { ...state, is_loading: false, next_step: true };
    case PROFILE_PASSPORT_SAVE_ERROR:
      const { passport_image1, passport_image2, passport_image3 } = action.payload;
      return {
        ...state,
        is_loading: false,
        // passport_image1_error: passport_image1 ? passport_image1[0] : '',
        // passport_image2_error: passport_image2 ? passport_image2[0] : '',
        // passport_image3_error: passport_image3 ? passport_image3[0] : '',
      };
    case PROFILE_PASSPORT_DISPLAY_STATUS_CHANGE:
      // reset flag to prevent window close
      const next_step = !action.payload.display;
      return {
        ...state,
        profile_passport_display_status: action.payload.display,
        next_step,
        single_mode: action.payload.single_mode || false,
      };
    case PROFILE_PASSPORT_FIRST_NAME_CHANGE:
      return { ...state, first_name: action.payload };
    case PROFILE_PASSPORT_LAST_NAME_CHANGE:
      return { ...state, last_name: action.payload };
    case PROFILE_PASSPORT_SURNAME_CHANGE:
      return { ...state, surname: action.payload };
    case PROFILE_PASSPORT_BIRTHDAY_CHANGE:
      return { ...state, birthday: action.payload };
    case PROFILE_PASSPORT_GENDER_CHANGE:
      return { ...state, gender: action.payload };
    case PROFILE_PASSPORT_CITY_CHANGE:
      return {
        ...state,
        city_id: action.payload.city_id,
        city_title: action.payload.city_title,
      };
    case PROFILE_PASSPORT_ADDRESS_CHANGE:
      return { ...state, address: action.payload };
    case PROFILE_PASSPORT_IMAGE1_CHANGE:
      return { ...state, passport_image1: action.payload };
    case PROFILE_PASSPORT_IMAGE2_CHANGE:
      return { ...state, passport_image2: action.payload };
    case PROFILE_PASSPORT_IMAGE3_CHANGE:
      return { ...state, passport_image3: action.payload };
    // case PROFILE_PASSPORT_IMAGE1_ERROR_CHANGE:
    //   return { ...state, passport_image1_error: action.payload };
    // case PROFILE_PASSPORT_IMAGE2_ERROR_CHANGE:
    //   return { ...state, passport_image2_error: action.payload };
    // case PROFILE_PASSPORT_IMAGE3_ERROR_CHANGE:
    //   return { ...state, passport_image3_error: action.payload };
    default:
      return state;
  }
};
